import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button, Form, OverlayTrigger,Tooltip } from "react-bootstrap";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { JobTrackerServices } from "../../Common/Services/JobTrackerServices";
import _ from "lodash";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMove } from 'react-sortable-hoc';
import { FaArrowUpFromBracket, FaCircleMinus, FaCirclePlus, FaEllipsis } from "react-icons/fa6";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { ConfirmationModal } from "../../Common/Components/ConfirmationModal";
import BootstrapTable from "react-bootstrap-table-next";
export class NCRCodeMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
         
          htmlItem: [],
          htmlErrorItem: [],
          htmlWarningItem: [],
          searcResult: [],
          productid:0,
          isSaveOpen: false,
          loading: false,
          isWait: false,
          newRow:
          {id:0,ncrCode:"",description:"",srno:0},
          formData :{processlist:[],},
          templateColumns: this.templateColumns
        }; 
      }

      private templateColumns = [

        {
          dataField: 'srno',
          text: 'Order No.',
          headerStyle: { width: '50px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
    
            return (
              <div>
                {rowIndex + 1}
              </div>
            );
          },
        },
        {
          dataField: "isSelected",
          text: "",
          classes: "expanding-bar",
          headerStyle: { width: '10px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <div className="d-flex align-items-center txt-bold">
    
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="toolp">Click to move up this step</Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    size="xs"
                    onClick={(e) => this.handleRowOrdering(e, row, 'up')}
                  />
                </OverlayTrigger>
              </div>
            );
          },
        },
    
        {
          dataField: "isSelected",
          text: "",
          classes: "expanding-bar",
          headerStyle: { width: '10px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <div className="d-flex align-items-center txt-bold">
    
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="toolp">Click to move down this step</Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    size="xs"
                    onClick={(e) => this.handleRowOrdering(e, row, 'down')}
                  />
                </OverlayTrigger>
              </div>
            );
          },
        },
        {
          dataField: 'ncrCode',
          text: 'NCR Code',
          headerStyle: { width: '250px' },
          hidden: false,
          editable: false,
          headerAttrs: (column, colIndex) => (
            { 'tabIndex': '-1' }
          ),
          formatter: (cell: any, row: any, rowIndex: any) => {
           
            return (
              <div>
               <Form.Control
                        // tabIndex={this.state.tabEnable}
                         id={"txtncrcode" + rowIndex + "txt"}
                        type="text"
                        // placeholder="Enter Part No"
                        value={row.ncrCode}
                        onChange={(e) => this.updateState(e, rowIndex, "ncrCode", rowIndex)}
                        autoComplete="off"
                        disabled={ row.isFixed==1 ? true: false  }
                        title=""
                        maxLength={500}
                      />

              </div>
    
            );  
          },
        },
        {
          dataField: 'description',
          text: 'Description',
          headerStyle: { width: '550px' },
        
          hidden: false,
          editable: false,
          headerAttrs: (column, colIndex) => (
            { 'tabIndex': '-1' }
          ),
          formatter: (cell: any, row: any, rowIndex: any) => {
         
            return (
             <div>
             <Form.Control
                        // tabIndex={this.state.tabEnable}
                        id={"txtdescription" + rowIndex + "txt"}
                        type="text"
                        // placeholder="Enter Part No"
                        value={row.description}
                          onChange={(e) => this.updateState(e, rowIndex, "description", rowIndex)}
                        autoComplete="off"
                       // disabled={ row.id==6 ? true: false }
                        title=""
                        maxLength={500}
                      />


             </div>
            );
          },
    
        },
        {
          dataField: 'Attachment',
          text: '',
          //headerFormatter: this.iconFormatter,
          headerAttrs: (column, colIndex) => (
            { 'tabIndex': '-1' }
          ),
          formatter: (cell: any, row: any, rowIndex: any) => {
            let confirmModalContent = [
              {
                title: "Delete Row",
                desc: "Are you sure you want to delete?",
                cancleAction: "Cancel",
                ActionTrue: "Delete",
              },
            ];
    
            if (   row.deleteAllowed == false  )  {
              return (
                <div></div>
              );
            }
    
    
            else {
              return (
                <Dropdown
             className="more-action confirmation-modal"
              alignRight
              //tabIndex={this.state.tabDisable}
            >
              <Dropdown.Toggle
                className="btn-outline-primary btn btn-primary more fa-trash"
                id="dropdown-more-delete"
              //  tabIndex={this.state.tabDisable}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5 5.83329C4.53917 5.83329 4.16667 5.83329 4.16667 5.83329V16.6666C4.16667 17.5866 4.91333 18.3333 5.83333 18.3333H14.1667C15.0867 18.3333 15.8333 17.5866 15.8333 16.6666V5.83329C15.8333 5.83329 15.4608 5.83329 15 5.83329H5ZM8.33333 15.8333H6.66667V8.33329H8.33333V15.8333ZM13.3333 15.8333H11.6667V8.33329H13.3333V15.8333ZM13.8483 3.33329L12.5 1.66663H7.5L6.15167 3.33329H2.5V4.99996H6.66667H13.3333H17.5V3.33329H13.8483Z" />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ConfirmationModal
                  confirmModalContent={confirmModalContent}
                  ishandleDeletePosition={this.ishandleDeleteRow.bind(
                    this,
                    row
                  )}
                />
              </Dropdown.Menu>
            </Dropdown>
              );
            }
          },
        },
    
      ];

      componentDidMount() {
        this.getNCRCodes();
      }

      getNCRCodes()
      {
        
        let requestlist = { ...this.state.requestlist };
        JobTrackerServices.GetNCRCodes(requestlist)
        .then(async (result: any[] | null) => {
          if (result != null) {
           
            
            let data = this.sortByKey(result, "ncrCode");
            //static
            data = data.map((item, index) => ({
              ...item,
              srno: index + 1,
              deleteAllowed: false
            }));
            this.setState({ searcResult: data.slice(0) });
            this.setState({ isWait: false });
          }
          resolve();
        })
        .catch((err: any) => {
          toast.error(`Server Error, ${err}`);
          reject();
        });
      }

      ReorderProcess = () => {
        
        let request = { ...this.state.requestlist };
        let searcResult = this.state.searcResult;
        searcResult.forEach((element, idx) => {
          //searcResult.forEach(element => {
          let item: any = {};

          item.Id = element.id ==null ?0 :element.id ;
          item.ncrCode = element.ncrCode;
          item.srno =idx;
          item.description = element.description;
          item.Tenantid = element.tenantid;
       

        
          this.state.formData.searcResult.push(item);
        });     
      };
    


      sortByKey = (array: any, key: any) => {
        return array.sort((a, b) => {
          let x = parseInt(a[key]);
          let y = b[key];
          return x < y ? -1 : x > y ? 1 : 0;
        });
      };
      startState = () => { };
      onSortMove = () => { };
      onSortEnd = ({ oldIndex, newIndex }) => {
        
        if (newIndex !== oldIndex) {
          this.setState({ isSaveOpen: true }, () => {
             //this.props.changeValueFuntion(true);
          });
        }
        this.setState({ isWait: false });
        this.setState(({ searcResult }) => ({
           searcResult: arrayMove(searcResult, oldIndex, newIndex),
        }));
      };

      updateState = (e: any, index: any, type: any, txtID) => {
        if (this != undefined) {
          let isStateChanged = true;
          let isSaveOpen =true;
          let start = e.target?.selectionStart;
          let end = e.target?.selectionEnd;
          let searcResult = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.searcResult))
          );
    
        
          if (type === "ncrCode") {
            
            e.preventDefault();
          
            searcResult[index].ncrCode = e.target.value;
    
            this.setState({ searcResult, isStateChanged,isSaveOpen }, () => {
              $("input[id^='txtncrcode" + txtID + "txt']").focus();
              
            let txtdesc =  $("input[id^='txtncrcode" + txtID + "txt']")[0] as any;
            if (txtdesc !== null && txtdesc !== undefined) {
              txtdesc.selectionStart = start;
              txtdesc.selectionEnd = end;
            }
            });
          }
          
          if (type === "description") {
            e.preventDefault();
    
            searcResult[index].description = e.target.value;
    
            this.setState({ searcResult, isStateChanged,isSaveOpen }, () => {
              $("input[id^='txtdescription" + txtID + "txt']").focus();
              let txtdesc =  $("input[id^='txtdescription" + txtID + "txt']")[0] as any;
            if (txtdesc !== null && txtdesc !== undefined) {
              txtdesc.selectionStart = start;
              txtdesc.selectionEnd = end;
            }
            });
          }
          
          if (type === "status") {
            e.preventDefault();
            
            if(e.target.checked)
            {
              
             // $("input[id^='txtstatus" + txtID + "txt']").isChecked =true;
            searcResult[index].status =1;
            }
          else
          {
           searcResult[index].status =0;
          }
            this.setState({ searcResult, isStateChanged,isSaveOpen }, () => {
            
             
            });
          }
       
        }
      };

      ishandleDeleteRow(row: any, mode: any) {
        if (mode) {

          let searcResult = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.searcResult))
          );

          let isStateChanged = true;
          this.setState({ searcResult: [] }, () => {
            const orderDataNewc = searcResult
              .filter((item) => item.id !== row.id || (row.id === 0 && item.srno !== row.srno))
              .map((item, index) => {
                item.srno = index + 1;
                return item;
              });

            this.setState(
              {
                searcResult: orderDataNewc,isStateChanged:isStateChanged
               
              },
              () => { }
            );
          });
        }
      }
      handleNewRow = () => {
        let searcResult: any = [];
        let newRow: any = {};
        let isStateChanged = true;
    
        searcResult = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.searcResult))
        );
        newRow = Object.assign({}, JSON.parse(JSON.stringify(this.state.newRow)));
    
        let maxValueOfRow = Math.max(...searcResult.map((o) => o.srNo), 0);
        let len = searcResult.length;
        let maxRno = maxValueOfRow;
         let maxSrno = searcResult[len - 1].srNo;
        newRow.id = Number(maxRno) + 1;
        newRow.id = Number(maxSrno) + 1;
        newRow.deleteAllowed=true;
        searcResult.push(newRow);
        this.setState({ searcResult: [] }, () => {
          this.setState({ searcResult: searcResult, isStateChanged });
        });
      };
 
      handleRowOrdering = (e: any, row: any, type: any) => {

        let isSaveOpen =true;
                let searcResult = this.state.searcResult;
                console.log("searcResult",searcResult);
                let nextOrderNo = type == 'down' ? row.srno + 1 : row.srno - 1;
                let currentRow = searcResult.filter((x) => x.srno == row.srno);
                let nextRow = searcResult.filter((x) => x.srno == nextOrderNo);
            
                if (nextRow != null && nextRow.length > 0) {
            
                  nextRow[0].srno = row.srno;
                  currentRow[0].srno = nextOrderNo;
            
            
                  searcResult = searcResult.sort((a, b) => a.srno - b.srno);
            
                  this.setState({ searcResult: searcResult }, () => {
                    this.setState({ searcResult: searcResult,isSaveOpen:isSaveOpen });
                  });
            
                 // this.ReorderProcess();
                }
            
              };
 

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      
      this.setState({
        showSlideout: true, productid: row.id
      });


    },
  };
  discard = () => {
    this.getNCRCodes();
    this.setState({ isSaveOpen: false }, () => {
       
    });
  };

  saveApi = () => {

    
    let { searcResult } = this.state;
  
    this.setState({ isWait: true });
    let ncrCodes: any = [];
    searcResult.forEach((items: any, index: any) => {
      let newIndex = index + 1;
     
      let item: any = {};


      item.Id = items.id ==null ?0 :items.id ;
      item.ncrCode = items.ncrCode;
      item.Srno =newIndex;
      item.createdDate = items.createdDate;
      item.createdBy = items.createdBy;
      item.description = items.description;
      item.Tenantid = items.tenantid;
      item.status=items.status;
      ncrCodes.push(item);
    });

    var hasDuplicate = false;
    ncrCodes.map(v => v.ncrCode).sort().sort((a, b) => {
      if (a === b) hasDuplicate = true;
    });
   // this.state.formData.processlist = processlistItem;
//alert(hasDuplicate);
    
if(hasDuplicate)
{
  this.setState({ isWait: false });
  toast.error("Please check duplicate NCR Code",
  { position: toast.POSITION.BOTTOM_RIGHT, containerId: "userManagementHierarchy" });
  
}
else
{
   // let request =Object.assign({}, JSON.parse(JSON.stringify(processlistItem))); 
    //Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));
 
 JobTrackerServices.SaveNCRCodes(ncrCodes).then(
      async (result: any | null) => {
        
          let requestlist = { ...this.state.requestlist };
          this.getNCRCodes();
        
          toast.error("NCR Code updated",
            { position: toast.POSITION.BOTTOM_RIGHT, containerId: "userManagementHierarchy" });
          
            this.setState({ isSaveOpen: false ,loading: false });
      }
    );
    }


  };

    render() { 
      let confirmModalContent = [
        {
          title: "Delete Row",
          desc: "Are you sure you want to delete?",
          cancleAction: "Cancel",
          ActionTrue: "Delete",
        },
      ];
      const {

        showSlideout,
  
      } = this.state; 

      // const SortableItem = SortableElement<{ value: any, idx: any }>(({ value, idx }) => (
      //   <div className="row" style={{ cursor: "move" }}>
      //     <div className="col-sm-1 td">
      //       <div className="tdBody diwidth20">{idx + 1}</div>
      //     </div>
      //     <div className="col-sm-3 td">
          
      
      //       <div className="tdBody">
      //                 <Form.Control
      //                   // tabIndex={this.state.tabEnable}
      //                    id={"txtncrcode" + idx + "txt"}
      //                   type="text"
      //                   // placeholder="Enter Part No"
      //                   value={value.ncrCode}
      //                     onChange={(e) => this.updateState(e, idx, "ncrCode", idx)}
      //                   autoComplete="off"
      //                    disabled={ value.isFixed==1 ? true: false }
      //                   title=""
      //                   maxLength={500}
      //                 />
      //               </div>
            
      //     </div>
      //     <div className="col-sm-4 td">
           
      //      <div className="tdBody">
      //                 <Form.Control
      //                   // tabIndex={this.state.tabEnable}
      //                   id={"txtdescription" + idx + "txt"}
      //                   type="text"
      //                   // placeholder="Enter Part No"
      //                   value={value.description}
      //                     onChange={(e) => this.updateState(e, idx, "description", idx)}
      //                   autoComplete="off"
      //                  // disabled={ row.id==6 ? true: false }
      //                   title=""
      //                   maxLength={500}
      //                 />
                   
              
      //         </div>
      //     </div>
      //    { /*<div className="col-sm-1 td">
      //     <div className="tdBody">
      //     <Form.Control
      //           className="knob"
      //                   // tabIndex={this.state.tabEnable}
      //                   id={"txtstatus" + idx + "txt"}
      //                   type="checkbox"
      //                   checked={value.status}
      //                   // placeholder="Enter Part No"
      //                   //value={value.pDescription}
      //                     onChange={(e) => this.updateState(e, idx, "status", idx)}
      //                   autoComplete="off"
      //                  // disabled={ row.id==6 ? true: false }
      //                   title=""
      //                   //maxLength={500}
      //                 />
      //      </div>
      //     </div>*/}
      //   </div>
      // ));
      
      
      
      // const SortableList = SortableContainer<{ searcResult: [] }>(({ searcResult }) => {
      //   searcResult = searcResult || [];
      //   return (
      //     <div className="tableTbody">
      //       {(searcResult || [])?.map((value, index) => (
      //         <SortableItem key={index} idx={index} index={index} value={value} />
      //       ))}
      //     </div>
      //   );
      // });
      
      let { searcResult, isSaveOpen, loading, isWait, d } = this.state;


        return (

          
            <>
<React.Fragment>

<div className="home d-flex">
  
        
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
            
            NCR Code Master
            <div className="action-group d-flex flex-row ml-auto">
              </div>
              </div>
              <ToastContainer
          enableMultiContainer
          autoClose={3000}
          containerId={"userManagementHierarchy"}
        />
     
        {/* <div className="hierarchyDragableTable">
          <div className="tableThead">
            <div className="row hasBorder">
              <div className="col-sm-1 th">
                Order
              </div>
              <div className="col-sm-3 th">
                 NCR Code 
              </div>
              <div className="col-sm-4 th">
                Description 
              </div>
            
            </div>
          </div>

          <div
            className={`${isSaveOpen ? "hasFixedAction hasTable" : "hasTable"}`}
          >
            {loading ? (
              ""
            ) : (
              <>
              

                <SortableList
                  helperClass="sortableHelper"
                  axis="y"
                  lockAxis="y"
                  searcResult={searcResult}
                  onSortEnd={this.onSortEnd}
                  onSortStart={this.startState}
                  onSortMove={this.onSortMove}
                />
                 
              </>
            )}
          </div>


          
            <div className="d-flex align-items-center justify-content-end">
                  <div className="d-flex action-btn align-items-center">
                  <Button
                          type="button"
                          className="btn-ghost mr-auto"
                          onClick={this.handleNewRow}
                        >
                          <FaCirclePlus className="align-middle lh-1`" /> <span className="align-middle lh-1">Add Row</span>
                        </Button>
                   
                   </div>
                 </div>
                 </div> */}


{this.state.searcResult.length > 0 && (
 <div className="table-responsive table-responsive1" >
                <section className="pb-3">
                <div
                          className="table table-borderless align-middle transactions-list"

                        >
<BootstrapTable
  id="tbltemplate2"
  keyField="templateid"
  data={this.state.searcResult}
  columns={this.state.templateColumns}
/>
</div>
</section>
</div>

)}



<div className="d-flex align-items-center justify-content-end height-20">
                  <div className="d-flex action-btn align-items-center">
                  <Button
                          type="button"
                          className="btn-ghost mr-auto"
                          onClick={this.handleNewRow}
                        >
                          <FaCirclePlus className="align-middle lh-1`" /> <span className="align-middle lh-1">Add Row</span>
                        </Button>
                   </div>
                 </div>

                 
                 <div className="d-flex align-items-center justify-content-end height-20">
          {isSaveOpen && (
            <div className="fixed-action">
              <div className="d-flex align-content-center flex-wrap">
                <div className="mr-auto message">
                  <span>You have unsaved changes</span>
                </div>
                <button
                  type="button"
                   onClick={this.discard}
                  className="btn btn-primary btn-discard"
                >
                  Discard
                </button>
                <button
                  type="button"
                  disabled={isWait}
                   onClick={this.saveApi}
                  className="btn btn-primary"
                >
                  {isWait ? "Loading..." : "Save Changes"}
                </button>
              </div>
            </div>
          )}
         </div>


   </Container>
       

      </div>
      </React.Fragment>
            </>
        );
    }

}
