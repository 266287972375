import React from "react";
import { FaPlay, FaRegStopCircle, FaPause, FaPauseCircle, FaPlayCircle, FaRegCheckCircle, FaRedoAlt } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import {
  Container, Form, Dropdown, Button, ButtonToolbar, DropdownButton,
  Spinner
} from "react-bootstrap";

import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import "../../App.scss";
import ".././slideout.scss";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import { JobServices } from "../../Common/Services/JobServices";
import { JobTrackerServices } from "../../Common/Services/JobTrackerServices";
import { CommentBox } from "../../Common/Components/CommentBox";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { JobOrderService } from "../../Common/Services/JobOrderService";
import { Utils } from "../../Common/Utilis";
import Dropzone from "react-dropzone";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { PartTemplateService } from "../../Common/Services/PartTemplateService";
//import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import 'react-tabs/style/react-tabs.css';
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
const { SearchBar } = Search;
let routeLeavingGuard: any = null;
let docViewerFiles: any = [];
export class TrackerSlideout extends React.Component<any, any> {
  private scrollvdRef: any;
  private parentScroll: any;
  private footerchk: any;
  private ChildComment: any;

  
  componentDidMount() {
    this.Getjoblist();
    
    
  }

  downloadTranactionFile = (row: any) => {
   
    JobTrackerServices.DownloadFile(row)
      .then(async (result: any | null) => {
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ isSaveSpinner: false });
      });
  };

  private trackerColumns = [
    {
      dataField: 'auditDate',
      text: 'Date',

      headerStyle: {
        backgroundColor: '#ffffff'
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div>
            {moment(row.auditDate).format("MM/DD/YYYY  HH:mm:ss")}
          </div>
        );
      }
    },
    {
      dataField: 'username',
      text: 'User Name',

      headerStyle: {
        backgroundColor: '#ffffff'
      }
    },
    {
      dataField: 'processname',
      text: 'Process',

      headerStyle: {
        backgroundColor: '#ffffff'
      }
    },
    {
      dataField: 'status',
      text: 'Status',

      headerStyle: {
        backgroundColor: '#ffffff'
      }
    },

    {
      dataField: 'explanation',
      text: 'Explanation',

      headerStyle: {
        backgroundColor: '#ffffff'
      }
    }

  ];

  private attachmentColumns = [
    {
      dataField: 'createDate',
      text: 'Date',

      headerStyle: {
        backgroundColor: '#ffffff'
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div>
            {moment(row.createDate).format("MM/DD/YYYY  HH:mm:ss")}
          </div>
        );
      }
    },
    {
      dataField: 'username',
      text: 'User Name',
      hidden: true,
      headerStyle: {
        backgroundColor: '#ffffff'
      }
    },
    {
      dataField: 'processname',
      text: 'Process',
      hidden: true,
      headerStyle: {
        backgroundColor: '#ffffff'
      }
    },
    {
      dataField: 'status',
      text: 'Status',
      hidden: true,
      headerStyle: {
        backgroundColor: '#ffffff'
      }
    },

    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      headerStyle: { width: '100px',backgroundColor: '#ffffff' },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
           <EllipsisWithTooltip placement="bottom">
           <Button className="btn-link" onClick={() => this.downloadTranactionFile(row)}>
           {row.name}
           </Button>
           </EllipsisWithTooltip>
        );
      },
    },

  ];

  private trackercommentColumns = [
    {
      dataField: 'processname',
      text: 'Process',
    },
    {
      dataField: 'createddate',
      text: '',

      headerStyle: {
        backgroundColor: '#ffffff'
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (


          <div style={{ alignSelf: 'flex-start', textAlign: 'left' }} >
            <div>

              {row.comment}
            </div>
            <br></br>
            <div style={{ alignSelf: 'flex-start', textAlign: 'right' }}>
              {row.firstnlastnamefrom}
            </div>
            <div style={{ alignSelf: 'flex-start', textAlign: 'right' }}>
              {moment(row.createddate).format("MM/DD/YYYY  HH:mm:ss")}
            </div>
          </div>
        );
      }
    },



  ];


  private trackerCompleteColumns = [
    {
      dataField: 'completedate',
      text: 'Date',


      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div>
            {moment(row.auditDate).format("MM/DD/YYYY  HH:mm:ss")}
          </div>
        );
      }
    },
    {
      dataField: 'completeqty',
      text: 'Qty',
      classes: 'amount',

    },
    {
      dataField: 'comments',
      text: 'comments',
      hidden: true,

    },

    {
      dataField: 'completeby',
      text: 'Complete by',

    },

  ];


  constructor(props: any) {
    super(props);
    this.parentScroll = React.createRef();
    this.scrollvdRef = React.createRef();

    // this.scrollheight = React.createRef();
    this.Getjoblist = this.Getjoblist.bind(this);
    this.GetTrackerlist = this.GetTrackerlist.bind(this);
    this.GetTrackercommentlist = this.GetTrackercommentlist.bind(this);
    this.GetTrackercompletelist = this.GetTrackercompletelist.bind(this);
    this.GetUploadFileList = this.GetUploadFileList.bind(this);
    this.Getprocess=this.Getprocess.bind(this);
    this.ChildComment = React.createRef();
    let storage = JSON.parse(localStorage.getItem("storage")!);
    
    this.state = {

      selected: [],
      jobtrackercomplete: 0,
      isUpdate: false,
      user_UniqueID: (storage === null ? 0 : (storage.user_UniqueID as any)),
      isNCRSelected: 0,
      ispopupshow: false,
      start: "Start",
      diffassigned: "",
      isStart: true,
      isHold: true,
      isCompleted: true,
      isNCR: true,
      isReset: true,
      progress: 0,
      progressper: 0,
      completed: 0,
      remaining: 0,
      startdate: "",
      enddate: "",
      holddate: "",
      qty: this.props.qty,
      customerpo:this.props.customerpo,
customername:this.props.customername,
orderdate:this.props.orderdate,
partno:this.props.partno,
partname:this.props.partname,
selectedcolumn:'All',
attachments:[],
      completedqty: 0,
      jobid: this.props.jobid,
      assignedid: 0,
      processname: "", processid: 0,
      selectedprocessid: 0,
      // templateColumns: this.templateColumns,
      trackerColumns: this.trackerColumns,
      attachmentColumns: this.attachmentColumns,
      trackercommentColumns: this.trackercommentColumns,
      trackerCompleteColumns: this.trackerCompleteColumns,
      processdata: [],
      trackerdata: [],
      trackercomment: [],
      trackerdatamain: [],
      trackercommentmain: [],
      trackercomplete: [],
      jobtracker: [],
      formData: { comment: "", explanation: "", completeqty: "", qtyComment: "", ncrqty: "", },
      files: [],
      files1: [],
      savedfiles: [],
      newSelectfiles: [],
      FormData: [],
      attachmentData: {},
      tableActions: [
        { action: "Add Comments", eventKey: "addcommen" },
        { action: "Add NCR", eventKey: "addncr" },
        { action: "Add Attachment", eventKey: "addattachment" }
      ],
      selectOptions :  []
    };
    this.scrollToBottom = this.scrollToBottom.bind(this);
    

  }


  rowEventscomplete = {
    onClick: (e: any, row: any, rowIndex: any) => {

      let formData1 = { ...this.state.formData };
      formData1.completeqty = row.completeqty;
      formData1.qtyComment = row.comments;
      let jobtrackercomplete = row.id;
      this.setState(
        {
          formData: formData1, isUpdate: true, jobtrackercomplete: jobtrackercomplete
        });


    }


  };



  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {


      if (this.state.isStateChanged == true) {

        let unsave = true;
        this.setState({ unsave: unsave,selectedcolumn:row.processname });

      }
      else {
        let isStart = false;
        let isHold = true;
        let isComplete = true;
        let isNCR = true;
        let isReset = true;
        let diffassigned = "";
        let start = "Start";
        let startdate = "";
        let enddate = "";
        let holddate = "";
        this.Getjobtracker();

        let processdata = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.processdata))
        );
        let processid = row.processid;
        let qty = row.qty;
        let completedqty = row.completedqty;
        let jobtrackerid = row.id;
        if (processdata.length > 0) {
          processdata.forEach((element: any, index: any) => {
            if (element.processid == processid && element.id == jobtrackerid) {





              if (element.assignedid == 0) {


                isStart = true;
                isHold = true;
                isComplete = true;
                isNCR = true;
                isReset = true;
                diffassigned = "Assigned to different User";
              }
              else {
                diffassigned = "";
                if (element.status == "progress") {
                  diffassigned = "";
                  isStart = true;
                  start = "Start";
                  isReset = false;
                  isHold = false;
                  isComplete = false;
                  isNCR = false;
                  startdate = moment(element.startdate).format("MM/DD/YYYY HH:mm:ss");

                }
                else if (element.status == "hold") {
                  isHold = true;
                  start = "";
                  isReset = true;
                  startdate = moment(element.startdate).format("MM/DD/YYYY HH:mm:ss");

                  holddate = moment(element.holddate).format("MM/DD/YYYY HH:mm:ss");

                }

                else if (element.status == "completed") {
                  isHold = true;
                  isStart = true;


                  isComplete = completedqty != qty ? false : true;
                  isReset = false;
                  start = "Start";
                  startdate = moment(element.startdate).format("MM/DD/YYYY HH:mm:ss");
                  enddate = moment(element.enddatetime).format("MM/DD/YYYY HH:mm:ss");

                }
                else {
                  isHold = true;
                  isStart = false;
                  isComplete = true;
                  isReset = true;
                  start = "Start";

                }

              }
            }


          }

          );
        }



        let selected: any[] = [];
        selected[0] = row.processid;


        this.setState({

          processname: row.processname, processid: row.processid, isSpinner: false,
          isStart: isStart, isHold: isHold, isCompleted: isComplete, startdate: row.startdate, enddate: row.enddatetime, holddate: row.holddate,
          assignedid: row.assignedid, selected: selected, selectedprocessid: row.processid, isNCR: isNCR, start: start, diffassigned: diffassigned, isReset: isReset,
          qty: qty,
          completedqty: completedqty, jobtrackerid: jobtrackerid,selectedcolumn:row.processname
        }
          ,
          () => {
            this.GetTrackerlist();
            this.GetTrackercommentlist();
            this.GetTrackercompletelist();
          }
        );



      }

      
      

    },

  };
  SaveComment = () => {
    let formData = { ...this.state.formData };


    if (this.state.formData.comment == "") {
      toast.error("Please enter comments", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });

    }
    else {
      let saveRequest: any = {};
      saveRequest.jobtrackid = this.state.jobtrackerid;
      saveRequest.jobid = this.state.jobid;
      saveRequest.processid = this.state.processid;
      saveRequest.assignedid = this.state.assignedid == null ? 0 :this.state.assignedid;
      saveRequest.createdby = this.state.user_UniqueID;

      var date = new Date();
      var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
        date.getUTCDate(), date.getUTCHours(),
        date.getUTCMinutes(), date.getUTCSeconds());
      saveRequest.createddate = new Date(now_utc);

      saveRequest.comment = this.state.formData.comment;
      JobTrackerServices.SaveJobTrackerComment(saveRequest)
        .then(async (resultinv: any | null) => {

          if (resultinv !== null) {
            let isStateChanged = false;
            formData.comment = "";

            this.setState({ iscomment: false, formData: formData, isStateChanged: isStateChanged, unsave: false }
              ,
              () => {
                this.GetTrackerlist();
                this.GetTrackercommentlist();
              }
            );
          };
          resolve();

        })
        .catch((error) => {

          reject();
        });

    }
  };



  CancelComment = () => {

    this.state.formData.comment = "";
    this.setState({ iscomment: false, isStateChanged: false, unsave: false });

  };
  GetUploadFileList = (uniqueNo, type) => {
    let requestlist: any = {};

    requestlist.orderid = uniqueNo;

    // CustomerService.GetUploadFileWithFileCode(requestlist)
    //   .then(async (result: any | null) => {
    //     if (result.length > 0) {
    //       for (let i = 0; i < result.length; i++) {
    //         this["myRef" + i] = React.createRef();
    //       }
    //       let filelist: any[] = [];
    //       result.forEach((element, i) => {
    //         element.fileCode =
    //           "data:application/pdf;base64," + element.fileCode;
    //         element.fileName = element.name;
    //         element.newname = element.name.toString().toLowerCase();
    //         filelist.push(element);
    //       });
    //       //filelist = _.sortBy(filelist, "newname");
    //       this.setState(
    //         (prevState) => ({
    //           savedfiles: filelist,
    //           isAttachmentLoading: false,
    //           isOpenViewer: prevState.isOpenViewer,
    //           newSelectedfiles: [],
    //           files: [],
    //           bulkSelect: true,
    //         }),
    //         () => {
    //           setTimeout(() => {
    //             this.scrollheight.current?.getElement().scrollTo({
    //               top: Number(this.state.currentScreenTop),
    //               behavior: "smooth",
    //             });
    //           }, 500);
    //         }
    //       );
    //     } else {
    //       this.setState({
    //         isOpenViewer: false,
    //         savedfiles: [],
    //         newSelectedfiles: [],
    //         files: [],
    //         bulkSelect: false,
    //       });
    //     }

    //     resolve();
    //   })
    //   .catch((error) => {
    //     reject();
    //     this.setState({
    //       isOpenViewer: false,
    //       savedfiles: [],
    //       newSelectedfiles: [],
    //       files: [],
    //       bulkSelect: false,
    //     });
    //  });
  };

  handleSelectFileUpload = (eventKey: any, file, index) => {
    if (eventKey === "delete") {
      let request: any = {};
      request = Object.assign(
        {},
        JSON.parse(JSON.stringify(this.state.formData))
      );
      let masg = "";

      masg = "Are you sure you want to delete this Attachment?";

      confirmAlert({
        title: "Delete Attachment",

        message: masg,
        buttons: [
          {
            label: "Yes",
            onClick: () => this.isDeleteattachment(file),
          },
          {
            label: "No",
            onClick: () => reject(),
          },
        ],

        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else if (eventKey === "download") {
      this.downloadTranactionFile(file);
    }
  };

  isDeleteattachment(file) {
    let newfiles: any = [];
    this.state.savedfiles.forEach((element, idx) => {
      if (Number(file.fileUniqueno) !== Number(element.fileUniqueno)) {
        newfiles.push(element);
      }
    });
    this.handleFileDelete(newfiles, file);
  }


  onFileChange = (event: any, mode) => {
   
    let newfiles: any = [];
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        let fileRow = event.target.files[i];
        newfiles.push(fileRow);
      }
      this.onDrop(newfiles, mode);
      let fileList = document.getElementById("formControlsFile") as any;
      fileList.value = "";
    }
  };

  onDrop = (filelist: any, mode: any) => {
 
    let size = 5242880;//5MB //this.state.fileSize; //
    let newfiles: any = [];
    let files: any = [];
    let isReturn = true;
    let filestext = "";
    let filesizeval = Number(Number(this.state.fileSize) / (1024 * 1024));
    filelist.forEach((element) => {
      if (element.size > size) {
        filestext = filestext + element.name + ",";
        isReturn = false;
      }
    });

    if (!isReturn) {
      let msg = "One or more files are greater than " + filesizeval + "MB.";
      toast.error(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });
    }
    let isInvalidFile = false;
    filelist.forEach((element) => {
      if (
        (element.name
          .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
          .toLowerCase() === "pdf" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "doc" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "docx" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "xls" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "xlsx" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "csv" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "txt" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "bmp" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "tif" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "ppt" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "pptx" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "rtf" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "jpg" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "jpeg" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "png" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "msg" ||
          element.name
            .substring(element?.name.lastIndexOf(".") + 1, element?.name.length)
            .toLowerCase() === "eml") &&
        element.size <= size
      ) {
        newfiles.push(element);
        files.push(element);
      } else {
        isInvalidFile = true;
      }
    });
    if (isInvalidFile && isReturn) {
      let msg = "";
      if (filelist.length === 1) {
        msg = "This file type is not supported.";
      } else {
        msg = "Some files are not supported.";
      }
      toast.error(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });
    }

    this.setState(
      {
        files,
        newSelectfiles: newfiles,
        isSave: true,
        ispopupshow: false,
        isSpinner: true,
      },
      () => {
        if (files.length > 0) {
          this.handleSaveFiles(files);
        }
      }
    );
  };

  
  handleSaveFiles(files: any) {
   
    let isFormValid = true;
    let request: any = {};
    let attachmentData: any = {};
    attachmentData.jobid = this.state.jobid;
    //attachmentData.tenantid = 101;
    attachmentData.processid = this.state.selectedprocessid;
    request = Object.assign(
      {},
      JSON.parse(JSON.stringify(attachmentData))
    );
    
    if (isFormValid) {
      this.setState({ isSpinner: true });
      JobTrackerServices.Savefiles(request, files)
        .then(async (result: any | null) => {

          this.setState({ isSpinner: false,showAttachmentPopup: false  });
        
          resolve();
        })
        .catch((error) => {
          reject();
        });
    }
  }

  handleFileDelete(newfiles, file) {
    let request: any = {};
    request.fileNo = file.fileUniqueno;
    request.orderid = file.uniqueNo;

    this.handleDeleteFileFromDb(request, newfiles, 2);
  }
  handleDeleteFileFromDb(request, newfiles, id) {
    let fileno = request.fileNo;
    // CustomerService.DeleteUploadedFile(request)
    //   .then(async (result: any | null) => {
    //     if (result !== null) {
    //     }
    //     resolve();
    //   })
    //   .catch((error) => {
    //     reject();
    //   });
  }

  CommentModalClose = () => {

    let uniqueno = this.state.formData.uniqueno;
    this.handleDiscard(uniqueno);
  };
  handleDiscard(postID) {
    this.setState({ isStateChanged: false }, () => {
      this.closeSlideOut();
    });
  }
  updatedCommentItem = () => {
    let updatedItem: any = {};
    this.setState({ isSave: true });
    updatedItem.transcationDate = this.state.formData.transcationDate;
    updatedItem.vendorName = this.state.formData.vendorName;
    updatedItem.amountTotal = this.state.totalAmount;
    return updatedItem;
  };

  Getjoblist = () => {
    let jobtrackid = this.state.jobtrackerid;
    let saveRequest: any = {};

    saveRequest.jobid = this.state.jobid;

    let request: any = {};
    let processdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.processdata))
    );

    let jobtrackerid = 0;
    JobServices.GetJobDataByJobid(saveRequest)
      .then(async (resultinv: any | null) => {

        if (resultinv !== null) {

          //jobtrackerid = resultinv.jobtrackerid;
          processdata = resultinv.jobdetailstracker;

          let prevcompleted = 0;
          let prevremaining = processdata.length;
          let prevprogress = 0;
          let prevhold = 0;

          let progressper = 0;
          if (processdata.length > 0) {
            processdata.forEach((element: any, index: any) => {
              if (element.status == "completed") {
                prevcompleted = Number(prevcompleted) + 1;

              }
              if (element.status == "progress") {
                prevprogress = Number(prevprogress) + 1;

              }
              if (element.status == "hold") {
                prevhold = Number(prevhold) + 1;

              }
            }
            );
          };
          progressper = Math.round((prevprogress + prevcompleted) / processdata.length * 100);

          prevremaining = Number(prevremaining) - Number(prevcompleted);
          let selected: any[] = [];
        
          selected[0] = this.state.selectedprocessid;
          // jobtracker=resultinv.jobtracker;
          this.setState(
            {
              processdata: processdata, remaining: processdata.length,
              progress: prevprogress, progressper: progressper, jobtrackerid: jobtrackid,
              isStateChanged: false, unsave: false,
              attachments: resultinv.uploadFileList
            },
            () => {
             
              this.GetTrackerlist();
              this.GetTrackercommentlist();
             
             
              this.GetTrackercompletelist();
              this.Getprocess();
              //this.setState({selected:selected});
              //this.handleBtnClick(this.state.selectedid);
            }
          );
        };
        resolve();

      })
      .catch((error) => {

        reject();
      });
    

  };
Getprocess=()=>{

  let selectOptions: any =  [];
 
  let requestlist = { ...this.state.requestlist };
  PartTemplateService.GetProcessListAll(requestlist)
  .then(async (result: any[] | null) => {
      
    if (result != null) {
    //  let data = result.filter(o=>o.status==1)

    let item: any = {};
        item.id = "All";
        item.name = "All";
       

        selectOptions.push(item);

    result.forEach(element => {

      if(element.status==1 && this.state.processdata.filter((x)=>x.processname == element.processName).length > 0)
        {
        let item: any = {};
        item.id = element.processName;
        item.name = element.processName;
       

        selectOptions.push(item);
        }
      });

      this.setState(
        {selectOptions:selectOptions});

    }
    resolve();
  })
  .catch((err: any) => {
    toast.error(`Server Error, ${err}`);
    this.setState({ isWait: false });
    reject();
  });
};

  GetTrackerlist = () => {

    let saveRequest: any = {};
    saveRequest.jobdetailsid = this.state.jobtrackerid;
    saveRequest.jobid = this.state.jobid;
    saveRequest.processid = this.state.selectedprocessid;
    let request: any = {};
    let trackerdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.trackerdata))
    );

    JobTrackerServices.GetTrackerlistAll(saveRequest)
      .then(async (resultinv: any | null) => {

        if (resultinv !== null) {

          //jobtrackerid = resultinv.jobtrackerid;
          trackerdata = resultinv;
          this.setState({ trackerdata: [], trackerdatamain: [] }, () => {
            this.setState(
              {
                trackerdata:  this.state.selectedcolumn=='All'? trackerdata : trackerdata.filter(o=>o.processname==this.state.selectedcolumn),
                trackerdatamain: trackerdata 
              },
              () => { }
            );
          });
        };
        resolve();

      })
      .catch((error) => {

        reject();
      });
  };
  GetTrackercommentlist = () => {

    let saveRequest: any = {};
    saveRequest.jobdetailsid = this.state.jobtrackerid;
    saveRequest.jobid = this.state.jobid;
    saveRequest.processid = this.state.selectedprocessid;
    let request: any = {};
    let trackercomment = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.trackercomment))
    );

    JobTrackerServices.GetTrackerComment(saveRequest)
      .then(async (resultinv: any | null) => {

        if (resultinv !== null) {

          //jobtrackerid = resultinv.jobtrackerid;
          trackercomment = resultinv;
          this.setState({ trackercomment: [],trackercommentmain: []  }, () => {
            this.setState(
              {
                trackercomment: this.state.selectedcolumn=='All'? trackercomment : trackercomment.filter(o=>o.processname==this.state.selectedcolumn),
                trackercommentmain: trackercomment
              },
              () => { }
            );
          });
        };
        resolve();

      })
      .catch((error) => {

        reject();
      });
  };

  GetTrackercompletelist = () => {

    let saveRequest: any = {};
    let isComplete = true;
    saveRequest.jobdetailsid = this.state.jobtrackerid;
    saveRequest.jobid = this.state.jobid;
    saveRequest.processid = this.state.selectedprocessid;
    let request: any = {};
    let trackercomplete = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.trackercomplete))
    );

    JobTrackerServices.GetTrackerComplete(saveRequest)
      .then(async (resultcom: any | null) => {






        if (resultcom !== null) {
          // let processdata = Object.assign(
          //   [],
          //   JSON.parse(JSON.stringify(this.state.processdata))
          // );

          let sumcompletedqty = resultcom.reduce(function (prev, current) {
            return prev + +current.completeqty;
          }, 0);
          isComplete = sumcompletedqty != this.state.qty ? false : true;
          //         
          //         const processdatac = processdata
          // .filter((item) => item.processid == this.state.selectedprocessid )
          // .map((item, index) => {
          //   item.completeqty = sumcompletedqty;
          //   return item;
          // });


          this.setState({ trackercomplete: [] }, () => {
            this.setState(
              {
                trackercomplete: resultcom, completedqty: sumcompletedqty, isComplete: isComplete
              },
              () => { }
            );
          });
        };
        resolve();

      })
      .catch((error) => {

        reject();
      });
  };
  Getjobtracker = () => {

    let saveRequest: any = {};

    saveRequest.jobtrackerid = this.state.jobtrackerid;

    let request: any = {};
    let processdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.processdata))
    );
    JobTrackerServices.GetJobTacker(saveRequest)
      .then(async (resultinv: any | null) => {

        if (resultinv !== null) {

          processdata = resultinv.jobdetailstracker;
          let prevcompleted = 0;
          let prevremaining = processdata.length;
          let prevprogress = 0;
          let prevhold = 0;

          let progressper = 0;
          if (processdata.length > 0) {
            processdata.forEach((element: any, index: any) => {
              
              if (element.status == "completed") {
                prevcompleted = Number(prevcompleted) + 1;

              }
              if (element.status == "progress") {
                prevprogress = Number(prevprogress) + 1;

              }
              if (element.status == "hold") {
                prevhold = Number(prevhold) + 1;

              }
            }
            );
          };
          progressper = Math.round((prevprogress + prevcompleted) / processdata.length * 100);

          prevremaining = Number(prevremaining) - Number(prevcompleted);
          this.setState(
            {
              isStateChanged: false, unsave: false,
              processdata: processdata, remaining: processdata.length,

              progress: prevprogress, progressper: progressper,
            },
            () => { }
          );
        };
        resolve();

      })
      .catch((error) => {

        reject();
      });
  };

  GetAttachmentList = () => {

    let request: any = {};
    request.jobdetailsid = this.state.jobtrackerid;
    request.jobid = this.state.jobid;
    request.processid = this.state.selectedprocessid;
    //let request: any = {};
    let trackerdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.trackerdata))
    );

    JobTrackerServices.GetTrackerlistAll(request)
      .then(async (resultinv: any | null) => {

        if (resultinv !== null) {

          //jobtrackerid = resultinv.jobtrackerid;
          trackerdata = resultinv;
          this.setState({ trackerdata: [], trackerdatamain: [] }, () => {
            this.setState(
              {
                trackerdata:  this.state.selectedcolumn=='All'? trackerdata : trackerdata.filter(o=>o.processname==this.state.selectedcolumn),
                trackerdatamain: trackerdata 
              },
              () => { }
            );
          });
        };
        resolve();

      })
      .catch((error) => {

        reject();
      });
  };


  scrollToBottom = () => {
    this.parentScroll.current.scrollTo({

      top: this.parentScroll.current.scrollHeight,
      behavior: "smooth",
    });
  };


  closeSlideOut = () => {
    this.props.closeSlideOut(this.state?.shouldReloadMainPage);
  };

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };








  HidecloseSlideOut() {
    this.props.closeSlideOut();
    this.setState({ isSaveSpinner: false });
  }


  Hidespinner() {
    this.setState({ isSpinner: false });

  }


  

  handleChange =
    (event: any, FieldType: string) => {

      let isStateChanged = true;
      let start = 0;
      let end = 0;
      let completedqty = this.state.complatedqty;
      let formData = { ...this.state.formData };
      if (FieldType === "txtqty") {
        event.preventDefault();
        // let newVal = Utils.removeInvaildCharFromAmount(event.target.value);
        let newVal = event.target.value.replace(/[^0-9\s]/g, '');
        event.target.value = newVal;
        completedqty =
          event.target.value.trim().length > 0 ? event.target.value : "";
      }
      if (FieldType === "ncrqty") {
        event.preventDefault();
        // let newVal = Utils.removeInvaildCharFromAmount(event.target.value);
        let newVal = event.target.value.replace(/[^0-9\s]/g, '');
        event.target.value = newVal;
        formData.ncrqty =
          event.target.value.trim().length > 0 ? event.target.value : "";
      }


      if (FieldType === "qtyComment") {
        formData.qtyComment =
          event.target.value.trim().length > 0 ? event.target.value : "";
      }



      if (FieldType === "explanation") {
        formData.explanation =
          event.target.value.trim().length > 0 ? event.target.value : "";
      }
      if (FieldType === "completeqty") {
        event.preventDefault();
        // let newVal = Utils.removeInvaildCharFromAmount(event.target.value);
        let newVal = event.target.value.replace(/[^0-9\s]/g, '');
        event.target.value = newVal;
        formData.completeqty =
          event.target.value.trim().length > 0 ? event.target.value : "";
      }


      if (FieldType === "comment") {
        formData.comment =
          event.target.value.trim().length > 0 ? event.target.value : "";
        isStateChanged = true;
      }
      this.setState({ formData, completedqty: completedqty, isStateChanged: isStateChanged });
    };

  clickreassigned = (event, row) => {
    this.setState({ processname: row.processname, jobtrackerid: row.id, jobid: row.jobid, processid: row.processid, assignedid: row.assignedid, isreassigned: true });
  };

  reassigned = (event) => {
    if (event == "Yes") {


      let saveRequest: any = {};
      saveRequest.id = this.state.jobtrackerid;
      //this.state.jobtrackerid;
      saveRequest.jobid = this.state.jobid;

      saveRequest.assignedid = this.state.assignedid;
      saveRequest.AssignedComment = this.state.formData.explanation;

      saveRequest.tenantid = 0;

      JobServices.UpdateJobData(saveRequest)
        .then(async (result: any | null) => {
          if (result !== null) {

            let diffassigned = "";
            this.setState({

              diffassigned: diffassigned,
            }
              ,
              () => {
                //  this.processstart("progress");
                this.Getjoblist();
                this.GetTrackerlist();
                this.GetTrackercommentlist();
                this.GetTrackercompletelist();
              }
            );

          }
          resolve();
        })
        .catch((error) => {
          this.setState({ isdefaultColDisabled: false, isSpinner: false, isStateChanged: false, unsave: false });
          reject();
        });



      this.setState({ isreassigned: false });
    }
    else {
      this.setState({ isreassigned: false });

    }

  };

  comment = (row) => {

    this.setState({ processname: row.processname, jobtrackerid: row.id, jobid: row.jobid, processid: row.processid, assignedid: row.assignedid, iscomment: true });
  };

  savecompleteqty = (event) => {
  
    if (event == "Yes") {
      this.setState({ iscompleted: false });
    }
    else if (event == "Update") {
    
      if (this.state.formData.completeqty == "" || this.state.formData.completeqty == 0) {

        toast.error("Please enter correct qty", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "OrderSlideout",
        });
      }
      else {


        if (this.state.formData.completeqty == "" || this.state.formData.completeqty == 0) {

          toast.error("Please enter correct qty", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });
        }
        else {
          
          let saveRequest: any = {};
          saveRequest.id = this.state.jobtrackercomplete;
          //this.state.jobtrackerid;

          saveRequest.completeqty = this.state.formData.completeqty == "" ? 0 : this.state.formData.completeqty;
          saveRequest.qty = this.state.qty;
          saveRequest.qtyComment = this.state.formData.qtyComment;
          saveRequest.userid = 0;

          JobTrackerServices.UpdateJobTracker(saveRequest)
            .then(async (resultinv: any | null) => {
              this.state.formData.completeqty = "";
              this.state.formData.qtyComment = "";

              this.setState({ trackercomplete: [] }, () => {
                this.setState(
                  {
                    trackercomplete: resultinv, isUpdate: false, jobtrackercomplete: 0, isStateChanged: false, unsave: false,
                  },
                  () => {
                    this.Getjoblist();
                    this.state.formData.completeqty = "";
                    this.state.formData.qtyComment = "";
                    this.GetTrackerlist();
                    this.GetTrackercommentlist();
                    this.GetTrackercompletelist();


                  }
                );
              });
            });
        }
      }
    }

    else {

      let formData1 = { ...this.state.formData };
      formData1.completeqty = "";
      formData1.qtyComment = "";



      this.setState({ iscompleted: false, formData: formData1, isUpdate: false, isStateChanged: false, unsave: false });


    }
  };
  processstart1 = (event) => {
  
    let processdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.processdata))
    );
    if (event == "ResetNo") {
      this.setState({ ispopupshow: false, processdata: processdata });

    }
    if (event == "ResetYes" || event=="completed") {

      if(event=="completed")
      {
      if(this.state.formData.completeqty == "")
      {
        toast.error("Please check completed qty", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "OrderSlideout",
        });
        return;
      }

      if(this.state.formData.completeqty > this.state.remaining )
        {
          toast.error("Please completed qty can't be greater that remaining qty", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });
          return;
        }

      }

      let saveRequest: any = {};
      saveRequest.id = 0;
      saveRequest.jobdetailsid = this.state.jobtrackerid;
      saveRequest.jobid = this.state.jobid;
      saveRequest.processid = this.state.processid;
      saveRequest.assignedid = this.state.assignedid == null ? 0 : this.state.assignedid;
      saveRequest.NCRQty = this.state.formData.ncrqty == "" ? 0 : this.state.formData.ncrqty;
      saveRequest.status = this.state.isNCRSelected == 1 ? "NCR" : event;
      saveRequest.Explanation = this.state.formData.explanation;
      saveRequest.completeqty = this.state.formData.completeqty == "" ? 0 : this.state.formData.completeqty;
      saveRequest.qty = this.state.qty;
      saveRequest.qtyComment = this.state.formData.qtyComment;
      saveRequest.userid = 0;
      this.setState({isSaveSpinner:true});
      JobTrackerServices.SaveJobTracker(saveRequest)
        .then(async (resultinv: any | null) => {

          if (resultinv !== null) {
            


            this.setState({ iscompleted:false,isSaveSpinner:false, isStateChanged: false, unsave: false, ispopupshow: false });
            this.state.formData.explanation = "";
            this.state.formData.ncrqty = "";


            this.Getjoblist();
            this.state.formData.completeqty = "";
            this.state.formData.qtyComment = "";
            this.GetTrackerlist();
            this.GetTrackercommentlist();
            this.GetTrackercompletelist();


          };
          resolve();

        })
        .catch((error) => {

          reject();
        });
    }





  };

  processstart = (event, row) => {

    let isvalidated = 0;

    let cgetstartdate = "";
    let cgetenddate = "";
    let cgetholddate = "";
    let ispopupshow = false;
    let isNCRSelected = 0;
    this.setState({selectedcolumn:row.processname});
    let processdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.processdata))
    );
    if (event == "ResetNo") {
      this.setState({ processname:row.processname,jobtrackerid:row.id,jobid:row.jobid,processid:row.processid,assignedid:row.assignedid,ispopupshow: false, processdata: processdata });

    }
    else if (event == "Reset" || event == "NCR") {
      if (event == "NCR") {
        isNCRSelected = 1;

      }
      else

      {

        isNCRSelected = 0; 
      }
      this.setState({ jobtrackerid: row.id, jobid: row.jobid, processid: row.processid, ispopupshow: true, isNCRSelected: isNCRSelected, processdata: processdata });

    }
    else if (event == "completedconfirm") {

      this.setState({isUpdate:false,remaining:(row.qty-row.completedqty), jobtrackercomplete:row.id, qty :row.qty, processname:row.processname,jobtrackerid:row.id,jobid:row.jobid,processid:row.processid,assignedid:row.assignedid,iscompleted: true });
    }
    else {
      var date1 = new Date();
      var now_utc1 = Date.UTC(date1.getUTCFullYear(), date1.getUTCMonth(),
        date1.getUTCDate(), date1.getUTCHours(),
        date1.getUTCMinutes(), date1.getUTCSeconds());
      let enddate = "";
      //let processid : any = processid // this.state.processid;
      //let jobtrackerid1 = this.state.jobtrackerid;

      let getstartdate = '';moment(new Date(now_utc1)).format("MM/DD/YYYY HH:mm:ss");
      //this.state.startdate==""? moment(new Date(now_utc1)).format("MM/DD/YYYY HH:mm:ss") : this.state.startdate;
      let getenddate = this.state.enddate==""?  moment(new Date(now_utc1)).format("MM/DD/YYYY HH:mm:ss") : this.state.enddate;
      let getholddate = ''; //this.state.holddate==""?  moment(new Date(now_utc1)).format("MM/DD/YYYY HH:mm:ss"): this.state.holddate;
      let start = "";

      if (processdata.length > 0) {
        processdata.forEach((element: any, index: any) => {
          if (element.processid == row.processid && element.rowid == row.rowid) {


            if (event == "completed") {
              if (eval(this.state.formData.completeqty) == 0 || this.state.formData.completeqty == "") {
                event = "completedconfirm";
                this.setState({processname:row.processname,jobtrackerid:row.id,jobid:row.jobid,processid:row.processid,assignedid:row.assignedid, iscompleted: true });
                toast.error("Please check completed qty", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "OrderSlideout",
                });
                isvalidated = 1;
                // this.setState({ isSaveSpinner: false });
                return false;

              }


              if (eval(this.state.formData.completeqty) + eval(element.completedqty == "" || element.completedqty == undefined ? 0 : element.completedqty) > this.state.qty) {
                event = "completedconfirm";
                this.setState({ processname:row.processname,jobtrackerid:row.id,jobid:row.jobid,processid:row.processid,assignedid:row.assignedid,iscompleted: true });
                toast.error("Please check completed qty", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "OrderSlideout",
                });
                isvalidated = 1;
                // this.setState({ isSaveSpinner: false });
                return false;

              }
            }


            element.status = event;
            if (event == "progress") {

              element.startdate = getstartdate;
              // element.enddate = "";

            }

            if (event == "completed") {
              this.setState({processname:row.processname,jobtrackerid:row.id,jobid:row.jobid,processid:row.processid,assignedid:row.assignedid, iscompleted: false });
              element.startdate = getstartdate;
              element.enddate = getenddate;


            }
            if (event == "hold") {
              element.holddate = getholddate;
            }

          }
        }
        );
      };

      let isStart = false;
      let isHold = false;
      let isComplete = false;
      let isNCR = true;
      let prevcompleted = 0;
      let prevremaining = processdata.length;
      let prevprogress = 0;
      let prevhold = 0;
      let isReset = true;
      let progressper = 0;


      if (event == "progress") {
        isStart = true;
        start = "Start";
        isNCR = false;
        isReset = false;
        getholddate = "";
        getenddate = "";
      }
      else if (event == "hold") {
        start = "Start";
        isHold = true;
        getenddate = "";
        isComplete = true;
      }

      else if (event == "completed") {
        isHold = true;
        isStart = true;
        isComplete = true;
        isNCR = true;
        start = "Start";
        getholddate = "";
        isReset = false;
        enddate = getenddate;
      }
      else if (event == "ResetYes") {
        isHold = true;
        isStart = false;
        isComplete = true;
        isNCR = true;
        start = "Start";
        getstartdate = "";
        getholddate = "";
        getenddate = "";
        ispopupshow = false;
      }



      if (processdata.length > 0) {
        processdata.forEach((element: any, index: any) => {
          if (element.status == "completed") {
            prevcompleted = Number(prevcompleted) + 1;

          }
          if (element.status == "progress") {
            prevprogress = Number(prevprogress) + 1;

          }
          if (element.status == "hold") {
            prevhold = Number(prevhold) + 1;

          }
        }
        );
      };
      progressper = Math.round((prevprogress + prevcompleted) / processdata.length * 100);

      prevremaining = Number(prevremaining) - Number(prevcompleted);


      let jobtrackerid = row.id;

      if (isvalidated == 0) {
        if (event != "Reset") {

          let saveRequest: any = {};
          saveRequest.id = 0;
          saveRequest.jobdetailsid = row.id;
          saveRequest.jobid = row.jobid;
          saveRequest.processid = row.processid;
          saveRequest.assignedid = row.assignedid == null ? 0 : row.assignedid;

          saveRequest.startdate = getstartdate;
          saveRequest.holdtime = getholddate;
          saveRequest.enddatetime = getenddate;
          saveRequest.status = event;  //this.state.isNCRSelected == 1 ? "NCR" : 
          saveRequest.Explanation = this.state.formData.explanation;
          saveRequest.completeqty = this.state.formData.completeqty == "" ? 0 : this.state.formData.completeqty;
          saveRequest.qty = this.state.qty;
          saveRequest.qtyComment = this.state.formData.qtyComment;
          saveRequest.userid = 0;

          JobTrackerServices.SaveJobTracker(saveRequest)
            .then(async (resultinv: any | null) => {

              if (resultinv !== null) {
                
                if (resultinv.status == "Inactive" || resultinv.status == "ResetYes") {
                  cgetstartdate = "";

                }
                else {
                  cgetstartdate = resultinv.startdate == "" ? "" : moment(resultinv.startdate).format("MM/DD/YYYY HH:mm:ss");

                }
                if (resultinv.status == "completed") {
                  cgetenddate = resultinv.enddate == "" ? "" : moment(resultinv.enddate).format("MM/DD/YYYY HH:mm:ss");
                }

                if (resultinv.status == "hold") {
                  cgetholddate = resultinv.holddate == "" ? "" : moment(resultinv.holddate).format("MM/DD/YYYY HH:mm:ss");
                }

                if (resultinv.status == "Inactive" || resultinv.status == "ResetYes") {
                  cgetstartdate = "";

                }
                // selectRow();
                this.setState({ selectedcolumn:row.processname,startdate: cgetstartdate, enddatetime: cgetenddate, holddate: cgetholddate, isStateChanged: false, unsave: false });
                this.state.formData.explanation = "";
                jobtrackerid = resultinv.id;


                this.Getprocess();
                this.Getjoblist();
                this.state.formData.completeqty = "";
                this.state.formData.qtyComment = "";
                this.GetTrackerlist();
                this.GetTrackercommentlist();
                this.GetTrackercompletelist();


              };
              resolve();

            })
            .catch((error) => {

              reject();
            });
        }
      }

      this.setState({
        processdata: processdata, isStart: isStart, isHold: isHold, isCompleted: isComplete,
        completed: prevcompleted, remaining: prevremaining, progress: prevprogress, progressper: progressper,
        startdate: cgetstartdate, enddatetime: cgetenddate, holddate: cgetholddate, jobtrackerid: jobtrackerid, isNCR: isNCR, start: start, isReset: isReset,
        ispopupshow: ispopupshow, enddate: enddate,
      }),
        () => {


        };
    }
  };

  handleBtnClick = (selectedid) => {

    this.setState(() => ({
      selected: [...this.state.selected, selectedid]
    }));


  };

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter(x => x !== row.id)
      }));
    }
  };
  handleSelectedItem1 = (control: any, id: any) => {
    let searcResultmain = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.trackercommentmain))
    );
  


    let searcResult1 = searcResultmain;
    // Object.assign(
    //   [],
    //   JSON.parse(JSON.stringify(this.state.trackercomment))
    // );
  
    
  
  
    this.setState({
      trackercomment: [], 
      selectedcolumn:id
    },
      () => {
        if(id=="All")
          {
        this.setState({ trackercomment: searcResultmain });
          }
          else

          {

            this.setState({ trackercomment: searcResult1.filter(o=>o.processname==id) });
          }
      }
  
  
    );
  
  };

  handleSelectedItem2 = (control: any, id: any) => {
  
    let searcResultmain = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.trackerdatamain))
    );

    let searcResult1 = searcResultmain;
    // Object.assign(
    //   [],
    //   JSON.parse(JSON.stringify(this.state.trackerdata))
    // );
  
    
  
  
    this.setState({
      trackerdata: [],selectedcolumn:id  
    },
      () => {
        if(id=="All")
          {
            this.setState({ trackerdata: searcResultmain});
          }
        else
        {
        this.setState({ trackerdata: searcResult1.filter(o=>o.processname==id) });
        }
      }
  
  
    );
  
  };

  handleCloseModel = () => {
    this.setState({ showAttachmentPopup: false });
  };
  


  render() {


    const templateColumns = [
      {
        dataField: 'id',
        text: '',
        hidden: true,
        headerStyle: {
          backgroundColor: '#ffffff'
        }
      },
      {
        dataField: 'processid',
        text: '',
        hidden: true,
        headerStyle: {
          backgroundColor: '#ffffff'
        }
      },
      {
        dataField: 'assignedid',
        text: '',
        hidden: true,
        headerStyle: {
          backgroundColor: '#ffffff'
        }
      },
      {
        dataField: '',
        text: 'Step No. ',

        headerStyle: { minWidth: '100px', width: '100px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <div className="text-center">
               {rowIndex + 1}
              </div>
            );
          
        },
      },
      {
        dataField: 'processname',
        text: 'Steps',

        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.assignedid == 0) {
            return (

              <div style={{ width: "100px" }} >
                {row.processname}
                <a href="#" className="badge badge-info color-blue"
                  onClick={(event: any) =>
                    this.clickreassigned(event, row)
                  }
                >Assign to me</a>
              </div>
            );


          }
          else {
            return (

              <div style={{ width: "100px" }} >
                {row.processname}
              </div>
            );
          }
        },
      },

      {
        dataField: '',
        text: 'Start',

        headerStyle: { minWidth: '100px', width: '100px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.status == "progress" || row.status == "completed") {

            let date =  '',time = '';
            if(row.startdate != '')
            {
              time = moment(row.startdate).format("h:mm:ss a");
              date = moment(row.startdate).format("MMMM DD YYYY");
            }
            
            return (
              <div style={{ width: "100px" }} >
                <button type="button" 
                className="btn btn-success btn-sm py-0 btn-sml w-100" disabled>{date}

                  <br></br>
                  
                  {time}
                </button>

              </div>
            );

          }
          else {
            return (
              <div className="text-center">
                <button className="btnprocess"

                  style={{ background: "#ffffff", border: 0 }} disabled={row.assignedid == 0 || row.status == "progress" || row.status == "completed" ? true : false} onClick={(e) => this.processstart("progress", row)}><FaPlayCircle size={20} /> </button>

                <br></br>


              </div>
            );
          }
        },
      },
      {
        dataField: '',
        text: 'Hold',
        headerStyle: { minWidth: '100px', width: '100px' },

        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.status == "hold") {
            let date =  '',time = '';
            if(row.holdtime != '')
            {
              time = moment(row.holdtime).format("h:mm:ss a");
              date = moment(row.holdtime).format("MMMM DD YYYY");
            }
            return (


              <div style={{ width: "100px" }} >
                <button type="button" className="btn btn-warning btn-sm py-0 btn-sml w-100" disabled>{date}

                  <br></br>

                  {time}
                </button>

              </div>
            );

          }
          else {
           let startStatus =  !(row.status == "progress" || row.status == "completed");
            return (<button className="btnprocess" disabled={row.assignedid == 0 || row.status == "completed" || startStatus || row.status == "NCR" ? true : false} style={{ background: "#ffffff", border: 0 }} onClick={(e) => this.processstart("hold", row)}><FaPauseCircle size={20} /></button>);
          }
        },
      },
      // {
      //   dataField: '',
      //   text: 'Stop',

      //   headerStyle: { minWidth: '100px', width: '100px' },
      //   formatter: (cell: any, row: any, rowIndex: any) => {
      //     if (row.status == "Stop") {
      //       return (
      //         <div style={{ width: "100px" }} >
      //           <button type="button" className="btn btn-danger btn-sm py-0 btn-sml w-100" disabled>{moment(row.holdtime).format("MMMM DD YYYY")}

      //             <br></br>

      //             {moment(row.holdtime).format("h:mm:ss a")}
      //           </button>

      //         </div>
      //       );

      //     }
      //     else {
      //       return (<button className="btnprocess" disabled={row.status == "completed" || row.status=="NCR"  ? true : false} style={{ background: "#ffffff", border: 0 }} onClick={(e) => this.processstart("Stop", row)}><FaRegStopCircle size={20} /></button>);
      //     }
      //   },
      // },

      {
        dataField: '',
        text: 'Stop',

        headerStyle: { minWidth: '100px', width: '100px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.status == "completed") {
            return (

              <div style={{ width: "100px" }} >
                <button type="button" className="btn btn-success btn-sm py-0 btn-sml w-100" disabled>{moment(row.enddatetime).format("MMMM DD YYYY")}

                  <br></br>

                  {moment(row.enddatetime).format("h:mm:ss a")}
                </button>

              </div>
            );


          }
          else {
            return (

              <div className="margin-y">
                <button className="btnprocess" disabled={row.assignedid == 0 || row.status == "NCR" || row.status != "progress" || row.status == "Stop" || row.status == "Reset" || row.status == "hold" ? true : false} style={{ background: "#ffffff", border: 0 }} onClick={(e) => this.processstart("completedconfirm", row)}><FaRegCheckCircle size={20} /></button>

              </div>
            );
          }
        },
      },

      {
        dataField: '',
        text: 'Reset',

        headerStyle: { minWidth: '100px', width: '100px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.status == "Reset") {
            return (
              <div style={{ width: "100px" }} >
                <button type="button" className="btn btn-danger btn-sm py-0 btn-sml w-100" disabled>{moment(row.holdtime).format("MMMM DD YYYY")}

                  <br></br>

                  {moment(row.holdtime).format("h:mm:ss a")}
                </button>

              </div>
            );

          }
          else {
            let startStatus =  !(row.status == "progress" || row.status == "completed");

            return (
              <div className="margin-y">
                <button className="btnprocess" disabled={row.assignedid == 0 || row.status == "completed" ||startStatus || row.status == "NCR" ? true : false} style={{ background: "#ffffff", border: 0 }} onClick={(e) => this.processstart("Reset", row)}><FaRedoAlt size={20} /></button>

              </div>
            );
          }
        },
      },

      {
        dataField: 'completedqty',
        text: 'Complete QTY',
        hidden: false,
        headerStyle: {
          backgroundColor: '#ffffff'
        }
      },
      {
        dataField: 'status',
        text: 'Job Status',
        headerStyle: {
          backgroundColor: '#ffffff'
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.status === "ResetYes" || row.status === "" || row.status == undefined ) {
            return <div>


            </div>;

          }

          else if (row.status === "completed") {

            if (row.completedqty == row.qty) {

              return (
                <div>


                  <h6>
                    {/* <span className="badge badge-pill badge-primary color-red"> {row.completedqty} / {row.qty} </span> */}
                    <span className="badge badge-pill badge-primary color-green">  {row.status} </span>

                    <span className="badge badge-pill badge-primary">   {row.totalhours} HRS</span>


                  </h6>

                </div>
              );

            }
            else {
              return (
                <div>


                  <h6>
                    {/* <span className="badge badge-pill badge-primary color-red"> {row.completedqty} / {row.qty} </span> */}
                    <span className="badge badge-pill badge-primary color-green">  progress </span>




                  </h6>

                </div>
              );
            }

          }
          else {

            if ((row.completedqty != 0) && row.completedqty != row.qty) {



              return (


                <div>


                  <h6>
                    {/* <span className="badge badge-pill badge-primary color-red"> {row.completedqty} / {row.qty} </span> */}
                    <span className="badge badge-pill badge-primary color-green">  {row.status} </span>




                  </h6>

                </div>
              );
            }

            else {
              return (


                <div>


                  <h6>

                    <span className="badge badge-pill badge-primary color-green">  {row.status} </span>




                  </h6>

                </div>
              );
            }
          }
        }
      },

      {
        dataField: '',
        text: 'Action',


        formatter: (cell: any, row: any, rowIndex: any) => {
          let startStatus =  !(row.status == "progress" || row.status == "completed");

          return (
            <div className="btn-group">
              <Dropdown
                onClick={(e) => { e.stopPropagation(); }}
                className="more-action"
                alignRight>
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary-white more"
                  id={"dropdown-more" + rowIndex}

                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventkey={"comments"} onClick={() => this.comment(row)} >Add Comments </Dropdown.Item>
                  <Dropdown.Item eventkey={"ncr"} disabled={startStatus} onClick={() => this.processstart("NCR", row)} >Add NCR</Dropdown.Item>
                  <Dropdown.Item eventkey={"attachments"} onClick={() => this.setState({showAttachmentPopup:true,selectedprocessid:row.processid})} >Add Attachments</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },

    ];

    const whiteBgFormatter = (column, colIndex) => {
      return <div className="white-bg"></div>;
    };
    const files = this.state.newSelectfiles.map((file, index) => (
      <>
        <li key={file.name}>
          <div className="d-flex align-items-center justify-content-start">
            {/* <div className="icon">
                                {(file?.type)?.split('/')[0] === 'image' && (
                                    <FontAwesomeIcon icon={faFileImage} />
                                )}
                                {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'pdf' && (
                                    <FontAwesomeIcon icon={faFilePdf} />
                                )}
                                {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'xlsx' && (
                                    <FontAwesomeIcon icon={faFileExcel} />
                                )}
                                {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'csv' && (
                                    <FontAwesomeIcon icon={faFileCsv} />
                                )}
                                {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'word' && (
                                    <FontAwesomeIcon icon={faFileWord} />
                                )}
                                {(((file?.type)?.split('/')[0] !== 'image') &&
                                    ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'pdf') &&
                                    ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'xlsx')
                                    && ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'csv')
                                    && ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'word'))
                                    && (
                                        <FontAwesomeIcon icon={faFile} />
                                    )}
                            </div> */}
            <div className="file-info d-flex align-items-center">
              <div className="name-loader d-flex align-items-center">
                <div className="name">
                  <EllipsisWithTooltip placement="bottom">
                    <div
                      className="full-name"
                      onClick={() => {
                        // this.handlePreview(file);
                      }}
                    >
                      {file.name}
                    </div>
                  </EllipsisWithTooltip>
                </div>
              </div>
              <div className="formate-percentage">
                <div className="formate d-flex justify-content-center align-items-center">
                  {file?.name.substring(
                    file?.name.lastIndexOf(".") + 1,
                    file?.name.length
                  )}
                </div>
              </div>
            </div>
          </div>
        </li>
      </>
    ));

    const files1 = this.state.savedfiles.map((file, index) => (
      <>
        <li key={file.name}>
          <div className="d-flex align-items-center justify-content-start">
            {/* <div className="icon">
                                {(file?.type)?.split('/')[0] === 'image' && (
                                    <FontAwesomeIcon icon={faFileImage}  size="xs" />
                                )}
                                {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'pdf' && (
                                    <FontAwesomeIcon icon={faFilePdf}  size="xs" />
                                )}
                                {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'xlsx' && (
                                    <FontAwesomeIcon icon={faFileExcel}   size = '10x' />
                                )}
                                {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'csv' && (
                                    <FontAwesomeIcon icon={faFileCsv}  size="xs" />
                                )}
                                {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'word' && (
                                    <FontAwesomeIcon icon={faFileWord}  size="xs" />
                                )}
                                {(((file?.type)?.split('/')[0] !== 'image') &&
                                    ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'pdf') &&
                                    ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'xlsx')
                                    && ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'csv')
                                    && ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'word'))
                                    && (
                                        <FontAwesomeIcon icon={faFile}   size="xs"/>
                                    )}
                            </div> */}
            <div className="file-info d-flex align-items-center">
              <div className="name-loader d-flex align-items-center">
                <div className="name">
                  <EllipsisWithTooltip placement="bottom">
                    <div className="full-name">{file.name}</div>
                  </EllipsisWithTooltip>
                </div>
              </div>
            </div>
            <div className="action">
              {
                <Dropdown
                  className="more-action"
                  alignRight
                  onSelect={(event: any) =>
                    this.handleSelectFileUpload(event, file, index)
                  }
                >
                  <Dropdown.Toggle
                    className="btn-outline-primary btn btn-primary more"
                    id="dropdown-more"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                      <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                      <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {this.state.moreActionFileUpload1.map(
                      (item: any, idx: any) => (
                        <>
                          {this.state.orderid === 0 && (
                            <Dropdown.Item
                              //  className={( this.state.moreActionFileUpload1[idx].eventKey === "delete") ? "isHide" : ""}
                              //className={((this.state.formData.actionType === "copy" || this.state.isError === "NotEdit") && this.state.formData.uniqueno > 0) ? "isHide" : ""}
                              eventKey={
                                this.state.moreActionFileUpload1[idx].eventKey
                              }
                              key={idx}
                            >
                              {this.state.moreActionFileUpload1[idx].name}
                            </Dropdown.Item>
                          )}

                          {this.state.orderid > 0 && (
                            <Dropdown.Item
                              // className={(    this.state.moreActionFileUpload1[idx].eventKey === "delete") ? "isHide" : ""}
                              eventKey={
                                this.state.moreActionFileUpload1[idx].eventKey
                              }
                              key={idx}
                            >
                              {this.state.moreActionFileUpload1[idx].name}
                            </Dropdown.Item>
                          )}
                        </>
                      )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              }
            </div>
          </div>
        </li>
      </>
    ));
    var date = new Date();
    var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
      date.getUTCDate(), date.getUTCHours(),
      date.getUTCMinutes(), date.getUTCSeconds());
    const today = new Date(now_utc);





    const toggleSwitch = (event: any) => {
      $(".scroll-area").animate(
        {
          scrollTop: 0,
        },
        500
      );
    };

    const selectRow = {
      mode: 'radio',
      clickToSelect: true,
      selected: this.state.selected,
      hideSelectColumn: true,
      style: (row, rowIndex) => {

        if (row.processid === this.state.selectedprocessid && row.id === this.state.jobtrackerid) {
          return {
            backgroundColor: 'rgb(37, 150, 190)'
          };
        }


      }
    };
    const options = {
      // pageStartIndex: 0,
      sizePerPage: 50,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true
    };
    const cardContainerStyles = "p-1 max-w-sm mx-auto bg-white rounded-lg border border-zinc-200 shadow-md dark:bg-zinc-800 dark:border-zinc-700 rounded";
    const titleStyles = "font-semibold text-xs text-zinc-900 dark:text-white";
    const subTitleStyles = "text-xs text-zinc-200 dark:text-zinc-200";
    const subTitleStyles2 = "text-xs text-zinc-200 dark:text-zinc-100";
    const subTitleStylesb = "text-xs text-zinc-200 dark:text-zinc-100";

    return (
      //  <div className="transaction-slideout invoice-entry pos-fxd index-1000">

      <section>
        <div className="details-drawer"
          style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
        // className="back-drop1  viewer-panel1 opacity "
        >
          <ToastContainer
            containerId={"userDetailSlideoutcontainer"}
            autoClose={3000}
          />

          <div className="d-flex">
            {!this.state.isOpenViewer && (
              <div
                className="back-drop1  viewer-panel1 opacity "
                onClick={() => this.closeSlideOut()}
              ></div>
            )}

            <Container
              fluid

              className="body-sec viewer-panel back-drop p-0 "
              style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}

            >
              <div
                className="container-fluid py-1 bg-white d-flex align-items-center  border-bottom round-4"
              >
                <div className="divheader">
                  <h5 className="my-title">
                    <span>Job No :  {this.props.jobno}</span>
                  </h5>


                  <div className="header-popout ml-auto">





                  <div className="container text-center">
  <div className="row align-items-start">
    <div className="col">
      </div>

      <div className="col">
      <button
                          type="button"
                          className="btn btn-primary cross"
                          onClick={this.closeSlideOut}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                              fill="#ffffff"
                            />
                          </svg>
                        </button>
      </div>
      </div>
      </div>
                  </div>
                </div>
              </div>
              <div
                className="scroll"
              >

                <section className="pb-3">
                  <div className="row g-3">

                   

                    <div className="col-12 col-md-10" >
                      {/* <Form.Group controlId="jobno">
                        <Form.Label className="font-semibold"> Order Details</Form.Label>

                        <div className="row g-1">
                          <div className="col-12 col-md-12" >
                            <div className={cardContainerStyles}>
                              <div className="row g-1">
                                <div className="col-12 col-md-4" >
                                  <div className={subTitleStylesb}> Customer Name</div>
                                </div>
                                <div className="col-12 col-md-4" >
                                  <div className={subTitleStyles2}>  {this.state.customername}</div>
                                </div>
                              </div>

                              <div className="row g-1">
                                <div className="col-12 col-md-4" >
                                  <div className={subTitleStylesb}>Order No</div>
                                </div>
                                <div className="col-12 col-md-4" >
                                  <div className={subTitleStyles2}>  {this.state.customerpo}</div>
                                </div>
                              </div>
                              <div className="row g-1">
                                <div className="col-12 col-md-4" >
                                  <div className={subTitleStylesb}> Order Date</div>
                                </div>
                                <div className="col-12 col-md-4" >
                                  <div className={subTitleStyles2}>  {moment(this.state.orderdate).format("MM/DD/YYYY")}</div>
                                </div>
                              </div>
                            


                            </div>
                          </div>

                        </div>
                      </Form.Group> */}

{/* <div className="card">
  <div className="card-header">
  Order Details
  </div>
  <div className="card-body">
  <h5 className="card-title">  {this.state.customerpo}</h5>
      <p>   {this.state.customername} </p>
      <p>  {moment(this.state.orderdate).format("MM/DD/YYYY")}</p>
        

    
  </div>
</div> */}
<div className="row">
<div className="col-sm-3">
    <div className="card">
    
    <div className="card-header">
    No. of Completed Steps
  </div>
 
       
       <div className="card-body d-flex" style={{height:"112px"}}>
            <h5 className="card-title m-auto">{this.state.processdata.filter(x => x.status ==='completed').length} / {this.state.processdata.length}</h5>
        </div>
    </div>
  </div>
<div className="col-sm-3">
    <div className="card">
    
    <div className="card-header">
    No. of finish Parts
  </div>
 
       
       <div className="card-body d-flex" style={{height:"112px"}}>
            <h5 className="card-title m-auto">{this.state.processdata.length == 0 ? 0 :Math.min(...this.state.processdata.map(item => item.completedqty))} / {this.state.qty}</h5>
        </div>
    </div>
  </div>
  <div className="col-sm-3">
    <div className="card">
    <div className="card-header">
  Order Details
  </div>
      <div className="card-body">
        <h5 className="card-title"> {this.state.customerpo}</h5>
        <p className="card-text text-xs"> {this.state.customername} <br></br>  {moment(this.state.orderdate).format("MM/DD/YYYY")}</p>
 
      </div>
    </div>
  </div>
  <div className="col-sm-3">
    <div className="card">
    <div className="card-header">
    Part Details
  </div>
      <div className="card-body">
        <h5 className="card-title">{this.state.partname}</h5>
        <p className="card-text text-xs">{this.state.partno} <br></br>Order QTY - {this.state.qty}</p>
        

      </div>
    </div>
  </div>
</div>

                    </div>
{/* 
                    <div className="col-12 col-md-4" >
                    <Form.Group controlId="jobno">
                        <Form.Label className="font-semibold"> Part Details</Form.Label>

                        <div className="row g-3">
                          <div className="col-12 col-md-12" >
                            <div className={cardContainerStyles}>
                            
                              <div className="row g-3">
                                <div className="col-12 col-md-4" >
                                  <div className={subTitleStylesb}> Part Name </div>
                                </div>
                                <div className="col-12 col-md-4" >
                                  <div className={subTitleStyles2}>  {this.state.partname}</div>
                                </div>
                              </div>

                              <div className="row g-3">
                                <div className="col-12 col-md-4" >
                                  <div className={subTitleStylesb}> Part No  </div>
                                </div>
                                <div className="col-12 col-md-4" >
                                  <div className={subTitleStyles2}>  {this.state.partno}</div>
                                </div>
                              </div>

                              <div className="row g-3">
                                <div className="col-12 col-md-4" >
                                  <div className={subTitleStylesb}> Order Qty  </div>
                                </div>
                                <div className="col-12 col-md-4" >
                                  <div className={subTitleStyles2}>  {this.state.qty}</div>
                                </div>
                              </div>


                            </div>
                          </div>

                        </div>
                      </Form.Group>

                    </div> */}
                  </div>

                </section>
                <section className="pb-3">
                  <div className="row g-3">

                    <div className="col-12 col-md-12" >
                      <div
                        className="table-responsive" id="table-responsive-cliente"
                      >

                        <BootstrapTable
                          id="tbltrackertemplate"
                          className="table-striped table-hover"
                          keyField="processid"
                          data={this.state.processdata}
                          columns={templateColumns}
                          rowEvents={this.rowEvents}
                          selectRow={selectRow}
                        />

                      </div>
                    </div>


                  </div>
                </section>
                <hr></hr>
                <section className="pb-3">

                  <div className="row g-3 height-72">
                    <div className="col-12 col-md-12" >
                      <div>
                        <Tab.Container transition={false} defaultActiveKey="worklog" >

                          <section className="pb-3">


                            <Nav className="nav nav-underline border-bottom">
                              <Nav.Item className="nav-item">
                                <Nav.Link eventKey="comments">Comments

                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="nav-item">
                                <Nav.Link eventKey="worklog">Work Log

                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="nav-item">
                                <Nav.Link eventKey="attachments">Attachments

                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </section>
                          <Tab.Content>
                            <Tab.Pane eventKey="comments">
                              <div
                                className="table-responsive divbottom" id="table-responsive-cliente"
                              >

<ToolkitProvider
                    keyField="id"
                     data={this.state.trackercomment}
                 columns={this.state.trackercommentColumns}
                   
                    search
                  >
                    {
                      props => (
                        <div >
                        <section className="d-flex flex-wrap gap-3 pb-2">
                       
                        <div className="position-relative d-none" >
                              <SearchBar {...props.searchProps} />
                            </div>
                        <div
                        className="position-relative"
                        >
                       
                          <Form.Label>
                            
                           {this.state.selectedcolumn != null && <SingleDropdownListWithoutSearch

                              itemList={this.state.selectOptions}
                              handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                              defaultItem={this.state.selectedcolumn}
                             // defaultText={this.state.selectedcolumn}
                            
                              defaultName={this.state.selectedcolumn}
                              controlID="1"
                              id={"ddlselected"}

                            />}</Form.Label>
                        </div>

                      </section>
                      <section className="pb-3">
                            <div className="table-responsive divbottom"  >
                              <div className="ml-auto transactions-list">
                                <BootstrapTable
                                  id="tbltracker"
                                  keyField="processid"
                                  {...props.baseProps}
                                  // data={this.state.trackercomment}
                                  // columns={this.state.trackercommentColumns}
                                 // pagination={paginationFactory(options)}
                                />
                                    </div>
                            </div>
                            </section>
                            </div>
                              )
                            }
                          </ToolkitProvider>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="worklog">
                              <div
                                className="table-responsive divbottom" id="table-responsive-cliente"
                              >
                                <ToolkitProvider
                    keyField="id"
                    data={this.state.trackerdata}
                    columns={this.state.trackerColumns}
                   
                    search
                  >
                    {
                      props => (
                        <div >
                        <section className="d-flex flex-wrap gap-3 pb-2">
                       
                        <div className="position-relative d-none" >
                              <SearchBar {...props.searchProps} />
                            </div>
                        <div
                        className="position-relative"
                        >
                          <Form.Label>
                          {this.state.selectedcolumn != null && <SingleDropdownListWithoutSearch

                              itemList={this.state.selectOptions}
                              handleSelectedItem={this.handleSelectedItem2.bind(this, 1)}
                              defaultItem={this.state.selectedcolumn}
                              defaultText={"Select Filter"}
                              defaultName={this.state.selectedcolumn}
                              controlID="1"
                              id={"ddlselected"}

                            />}</Form.Label>
                        </div>

                      </section>
                      <section className="pb-3">
                            <div className="table-responsive divbottom"  >
                              <div className="ml-auto transactions-list">
                                <BootstrapTable
                                  id="tbltracker"
                                  keyField="processid"
                                  {...props.baseProps}
                                //  pagination={paginationFactory(options)}
                                />
                                  </div>
                            </div>
                            </section>
                            </div>
                              )
                            }
                           
                          </ToolkitProvider>
                           </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="attachments">

                              <div
                                className="table-responsive" id="table-responsive-cliente"
                              >
                             <ToolkitProvider
                    keyField="id"
                    data={this.state.attachments}
                    columns={this.state.attachmentColumns}
                   
                    search
                  >
                    {
                      props => (
                        <div >
                        <section className="d-flex flex-wrap gap-3 pb-2">
                       
                        <div className="position-relative d-none" >
                              <SearchBar {...props.searchProps} />
                            </div>
                        <div
                        className="position-relative"
                        >
                          <Form.Label>
                          {this.state.selectedcolumn != null && <SingleDropdownListWithoutSearch

                              itemList={this.state.selectOptions}
                              handleSelectedItem={this.handleSelectedItem2.bind(this, 1)}
                              defaultItem={this.state.selectedcolumn}
                              defaultText={"Select Filter"}
                              defaultName={this.state.selectedcolumn}
                              controlID="1"
                              id={"ddlselected"}

                            />}</Form.Label>
                        </div>

                      </section>
                      <section className="pb-3">
                            <div className="table-responsive divbottom"  >
                              <div className="ml-auto transactions-list">
                                <BootstrapTable
                                  id="tblattachment"
                                  keyField="processid"
                                  {...props.baseProps}
                                //  pagination={paginationFactory(options)}
                                />
                                  </div>
                            </div>
                            </section>
                            </div>
                              )
                            }
                           
                          </ToolkitProvider>                           
                              </div>
                            </Tab.Pane>
                          </Tab.Content>

                        </Tab.Container>
                      </div>

                    </div>
                  </div>
                </section>



              </div>

            </Container>
          </div>

  

          {this.state.showAttachmentPopup && (
                  <div className="d-flex align-items-center pos-fxd2 index-1000">
                    <div className="react-confirm-alert-overlay confirm-modal undefined">
                      <div className="react-confirm-alert">
                        <div className="react-confirm-alert-body" style={{width:'520px'}}>
                          <div>
                            <div className="d-flex">
                              <h1 style={{ paddingTop: "6px!important" }}> Attachments</h1>
                              <div className="header-popout ml-auto">
                                <button
                                  type="button"
                                  className="btn wht-bg cross"
                                  onClick={this.handleCloseModel}
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                                      fill="#84888C"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="filter-sec upload-section" >
                        {!this.state.isRepeat && (
                          <Form.Group controlId="exampleForm.attachment">
                            <div className="d-flex">
                              <Form.Label className="mr-auto">
                                {
                                  <div className="upload-link mrgn-top">
                                  {true && <button
                                      type="button"
                                      className="btn wht-bg link-btn mr-auto"
                                    >
                                      Add Attachment
                                    </button>}
                                    <Form.Control
                                      id="formControlsFile"
                                      type="file"
                                      multiple
                                      onChange={(event: any) =>
                                        this.onFileChange(event, "1")
                                      }
                                      accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                                    >
                                       
                                    </Form.Control>
                                  </div>
                                }
                              </Form.Label>

                              {this.state.savedfiles.length > 0 && (
                                <div className="upload-link">
                                  <button
                                    type="button"
                                    className="btn wht-bg link-btn mr-auto"
                                   // onClick={this.OpenViewer}
                                  >
                                    {this.state.isOpenViewer
                                      ? "Close Viewer"
                                      : "Open Viewer"}
                                  </button>
                                </div>
                              )}
                            </div>
                            <div className="p-4 rounded-3 text-center text-secondary"
                      style={{ border: "solid 1px #c0c0c0" }}>
                              <Dropzone
                                // disabled={isBedgeProcessing}
                                onDrop={this.onDrop}
                              // accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                              >
                                {({
                                  getRootProps,
                                  getInputProps,
                                  isDragActive,
                                  isDragReject,
                                }) => (
                                  <>
                                    <section className="upload-area default">
                                      {!this.state.bulkSelect && (
                                        <div
                                          {...getRootProps({
                                            className:
                                              "dropzone d-flex align-items-center",
                                            onClick:
                                              this.state.newSelectfiles.length > 0 ||
                                                this.state.savedfiles.length > 0
                                                ? (event) => event.stopPropagation()
                                                : (event) => event,
                                          })}
                                        >
                                          <input {...getInputProps()} />
                                          {this.state.newSelectfiles.length < 1 &&
                                            this.state.savedfiles.length < 1 && (
                                              <p>
                                                Drag and drop attachment or{" "}
                                                <span>browse files</span> to upload.
                                              </p>
                                            )}
                                          {(this.state.newSelectfiles.length > 0 ||
                                            this.state.savedfiles.length > 0) &&
                                            isDragActive && (
                                              <p>
                                                Drag and drop attachment here to upload.
                                              </p>
                                            )}
                                          {/* {!isDragActive && (<p>Drag and drop or <span>browse files</span> to upload</p>)} */}
                                        </div>
                                        // </div>
                                      )}
                                      {/* {this.state.bulkSelect && (
                                                                        <div className="dropzone d-flex align-items-center">
                                                                            <p>Drag and drop or <span>browse files</span> to upload</p>
                                                                        </div>
                                                                    )} */}
                                      {
                                        <aside className="d-flex flex-column">
                                          <ul>{files}</ul>
                                          <ul>{files1}</ul>
                                        </aside>
                                      }
                                    </section>
                                  </>
                                )}
                              </Dropzone>
                            </div>
                          </Form.Group>
                        )}
                      </div>
                          <br></br>
                          <div className="react-confirm-alert-button-group">
                            <button
                              type="button"
                              className="btn"
                              onClick={() => this.handleCloseModel()}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}


          {this.state.ispopupshow && (
            <div className="row g-3>">
              <div className="col-12 col-md-12" >
                <div className="container d-flex align-items-center justify-content-center pos-fxd2 index-1000">
                  <div className="react-confirm-alert-overlay confirm-modal undefined">
                    <div className="react-confirm-alert">
                      <div className="react-confirm-alert-body">

                        <h1> {this.state.isNCRSelected == 1 ? "NCR" : "Reset"} Confirmation</h1>
                        {/* Are you sure to  {this.state.isNCRSelected == 1 ? "NCR" : "reset"} ? */}

                       { this.state.isNCRSelected == 1 && <Form.Group>
                          <Form.Label className="font-semibold">
                            NCR qty
                          </Form.Label>
                          <Form.Control
                            id="txtncrqty"
                            width="50px"
                            pattern="[+-]?\d+(?:[.,]\d+)?"
                            value={this.state.formData.ncrqty}
                            onChange={(event: any) =>
                              this.handleChange(event, "ncrqty")
                            }


                          />
                        </Form.Group>}

                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label className="font-semibold">
                            Explanation
                          </Form.Label>
                          <Form.Control
                            id="txtExplanation"
                            as="textarea"
                            wrap="hard"
                            rows={5}
                            value={this.state.formData.explanation}
                            onChange={(event: any) =>
                              this.handleChange(event, "explanation")
                            }
                            maxLength={99}
                          />
                        </Form.Group>
                        <br></br>
                        <div className="react-confirm-alert-button-group">
                          <button
                            type="button"
                            className="btn"
                            onClick={() => this.processstart1("ResetNo")}
                          >
                            No
                          </button>

                          <div className="upload-link">
                            <button type="button" className="btn"
                              onClick={() => this.processstart1("ResetYes")}
                            >
                              Yes
                            </button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.isreassigned && (
           <div className="row g-3>">
           <div className="col-12 col-md-12" >
             <div className="container d-flex align-items-center justify-content-center pos-fxd2 index-1000">
              <div className="react-confirm-alert-overlay confirm-modal undefined">
                <div className="react-confirm-alert">
                  <div className="react-confirm-alert-body">
                    <h1> Reassign Confirmation </h1>
                    Are you sure ?


                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label className="font-semibold">
                        Explanation
                      </Form.Label>
                      <Form.Control
                        id="txtExplanation"
                        as="textarea"
                        wrap="hard"
                        rows={5}
                        value={this.state.formData.explanation}
                        onChange={(event: any) =>
                          this.handleChange(event, "explanation")
                        }
                        maxLength={99}
                      />
                    </Form.Group>
                    <br></br>
                    <div className="react-confirm-alert-button-group">
                      <button
                        type="button"
                        className="btn"
                        onClick={() => this.reassigned("No")}
                      >
                        No
                      </button>

                      <div className="upload-link">
                        <button type="button" className="btn"
                          onClick={() => this.reassigned("Yes")}
                        >
                          Yes
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
          )}



          {this.state.iscompleted && (
            <div className="row g-3>">
            <div className="col-12 col-md-12" >
              <div className="container d-flex align-items-center justify-content-center pos-fxd2 index-1000">
              <div className="react-confirm-alert-overlay confirm-modal undefined">
                <div className="react-confirm-alert">
                  <div className="react-confirm-alert-body">
                    <h1> Process complete : {this.state.processname} </h1>
                    <hr></hr>

                    <Form.Group controlId="exampleForm.ControlTextarea1">


                      <Form.Label className="font-semibold">
                        Complete qty     
                      </Form.Label>
                      <Form.Label className="font-semibold">
                          (Remaining: {this.state.remaining})
                      </Form.Label>
                      <Form.Control
                        id="txtcompleteqty"
                        width="50px"
                        pattern="[+-]?\d+(?:[.,]\d+)?"
                        value={this.state.formData.completeqty}
                        onChange={(event: any) =>
                          this.handleChange(event, "completeqty")
                        }


                      />
                    </Form.Group>


                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label className="font-semibold">
                        Comment
                      </Form.Label>
                      <Form.Control
                        id="txtExplanation"
                        as="textarea"
                        wrap="hard"
                        rows={3}
                        value={this.state.formData.qtyComment}
                        onChange={(event: any) =>
                          this.handleChange(event, "qtyComment")
                        }
                        maxLength={99}
                      />
                    </Form.Group>
                    <br></br>
                    <div className="react-confirm-alert-button-group">

                      <button
                        type="button"
                        className="btn"
                        onClick={() => this.savecompleteqty("No")}
                      >
                        Close
                      </button>
                      {this.state.isUpdate == true && (

                        <button type="button" className="btn"

                          onClick={(e) => this.savecompleteqty("Update")}
                        >
                          Update
                        </button>


                      )
                      }
                      {this.state.isUpdate == false && (

                        <button type="button" className="btn"
                      // onClick={(e) => this.savecompleteqty("Yes")}
                        //onClick={() => this.savecompleteqty("Yes")}
                         onClick={(e) => this.processstart1("completed")}
                        >
                          Save
                        </button>


                      )
                      }
                    </div>

                    <hr></hr>

                    <BootstrapTable
                      id="tbltrackercomplete"
                      keyField="processid"
                      data={this.state.trackercomplete}
                      columns={this.state.trackerCompleteColumns}
                      pagination={paginationFactory(options)}
                      rowEvents={this.rowEventscomplete}
                    />

                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
          )}



          {this.state.iscomment && (
         <div className="row g-3>">
         <div className="col-12 col-md-12" >
           <div className="container d-flex align-items-center justify-content-center pos-fxd2 index-1000">
              <div className="react-confirm-alert-overlay confirm-modal undefined">
                <div className="react-confirm-alert">
                  <div className="react-confirm-alert-body">
                    <h1> Comment -  {this.state.processname} </h1>
                    <hr></hr>




                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label className="font-semibold">
                        Comment
                      </Form.Label>
                      <Form.Control
                        id="txtExplanation"
                        as="textarea"
                        wrap="hard"
                        rows={3}
                        value={this.state.formData.comment}
                        onChange={(event: any) =>
                          this.handleChange(event, "comment")
                        }
                        maxLength={99}
                      />
                    </Form.Group>

                    <div className="react-confirm-alert-button-group">
                      <button
                        type="button"
                        className="btn"
                        onClick={this.CancelComment}
                      >
                        Cancel
                      </button>

                      <div className="upload-link">
                        <button type="button" className="btn"
                          onClick={this.SaveComment}
                        >
                          Save
                        </button>

                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
          )}


          {this.state.unsave && (
            <div className="d-flex align-items-center pos-fxd2 index-1000">
              <div className="react-confirm-alert-overlay confirm-modal undefined">
                <div className="react-confirm-alert">
                  <div className="react-confirm-alert-body">
                    <h1>Confirmation</h1>
                    Please save comments.
                    <div className="react-confirm-alert-button-group">
                      <button
                        type="button"
                        className="btn"
                        onClick={this.CancelComment}
                      >
                        Discard
                      </button>
                      <button
                        type="button"
                        className="btn"
                        //  onClick={(e) => this.saveOrderfinal("order", false,false)}
                        onClick={this.SaveComment}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}







        </div>

        {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}

      </section>
    );
  }
}
