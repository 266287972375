import React from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Container,
  Dropdown,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { Utils } from "../../Common/Utilis";
import { BankService } from "../../Common/Services/BankService";
import { resolve, reject } from "q";
import "../../App.scss";

import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { FiChevronDown } from "react-icons/fi";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ScrollContainer from "react-indiana-drag-scroll";
import { CommentBox } from "../../Common/Components/CommentBox";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
let routeLeavingGuard: any = null;
let requestInProcess = false;
export class BankMasterSlidout extends React.Component<any, any> {

  private parentScroll: any;
  private accountNoRef: any;


  constructor(props: any) {
    super(props);
    this.parentScroll = React.createRef();
    this.accountNoRef = React.createRef();
    this.state = {
      editAccountNo:false,
      showField:false,
      accountTypeList: [{ id: 'Checking', name: 'Checking' }, { id: 'Savings', name: 'Savings' }],
      formData: {
      id:this.props.bankId,
      AccountNo:'',
      lastAccountNo:'',
      city: "",
      state: "",
      zip: "",
      phone: "",
      email: "",
      
      },
      
    };

  }


  beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
    if (
      this.state.isStateChanged &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  componentDidMount() {
   this.getBankMaster();  
   if(this.props.bankId == 0)
    this.setState({ showField: true,editAccountNo:true });
  }

  componentDidUpdate(prevProps, prevState) {
    // Focus the input field when editAccountNo is set to true
    if (this.state.editAccountNo && this.state.editAccountNo !== prevState.editAccountNo) {
      this.accountNoRef.current.focus();
    }
  }

  handleSelectedItem = (control: any, id: any) => {
    if (this != undefined) {
      if (control === 'AccountType') {
        let formData = this.state.formData;
        formData.AccountType = id;
        this.setState({ formData, isStateChanged: true });
      }
    
    }
  };

  getBankMaster = () => {

    let requestlist = { ...this.state.requestlist };
    requestlist.bankId = this.props.bankId;
    BankService.GetBankMasterById(requestlist)
  .then(async (result: any | null) => {
    
    if (result != null) {
     let formData = { ...this.state.formData };
   
     formData.id = result.id;
     formData.BankName = result.bankName;
     formData.TenantId = result.tenantId;
     formData.AccountNo = result.accountNo ;
     formData.lastAccountNo=result.lastAccountNo;
     formData.AccountType = result.accountType;
     formData.RoutingNumber = result.routingNumber;

     formData.Email = result.email;
     formData.Phone = result.phone;
     formData.street = result.street;

     formData.zip = result.zip;

     formData.city = result.city;
     formData.state = result.state;
     let editAccountNo = (formData.AccountNo == null || formData.AccountNo == '' || formData.AccountNo.length < 5 );
     this.setState({formData:formData,showField:true,editAccountNo: editAccountNo});
    }
    resolve();
  })
  .catch((err: any) => {
    toast.error(`Server Error, ${err}`);
    reject();
  });
  };

  
  //Save all entered data into db and handle the created vendor as selected in dropdown
  saveItem = async () => {
          
    if(requestInProcess == true)
    {
      toast.error("Subsequent request has been made.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return; 
    }


this.setState({isSaveSpinner:true});
    requestInProcess = true;
    BankService.SaveBankMaster(this.state.formData)
        .then(async (result: any | null) => {
          
         
          if (result !== null) {
            
            if (result.id != "" || result.id != null) {
                let requestlist: any = {};
                toast.success("Bank created successfully.",
                  {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  }
                );
              
            } else {
              toast.error(result.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          }
          this.setState({isSaveSpinner:false,editAccountNo:false});
          requestInProcess = false;
          this.closeSlideOut();
          resolve();
        })
        .catch((error) => {
          this.setState({isSaveSpinner:false});
          requestInProcess = false;
          reject();
        }
        );
      };



  closeSlideOut = () => {
    this.props.closeSlideOut(this.state?.shouldReloadMainPage);
  };

  handleChange = (event: any, FieldType: string) => {
  
    let isStateChanged = true;
    
    let formData = { ...this.state.formData };

    if (FieldType === "BankName") {
      formData.BankName = event.target.value;
    }
    else if (FieldType === "AccountNo") {
      formData.AccountNo =  event.target.value;
      formData.lastAccountNo =  event.target.value;
    }
    else if (FieldType === "RoutingNumber") {
      formData.RoutingNumber =  event.target.value;
    }
    else if (FieldType === "street") {
      formData.street =  event.target.value;
    }
    else if (FieldType === "email") {
      formData.Email =  event.target.value;
    }
    else if (FieldType === "phone_number") {

      let val = event.target.value.replace(/[^0-9()-\s]/g, '');      

      formData.Phone =  val;
    }
    else if (FieldType === "city") {
      formData.city =  event.target.value;
    }
    else if (FieldType === "state") {
      formData.state =  event.target.value;
    }
    else if (FieldType === "zip") {
      formData.zip =  event.target.value;
    }


    this.setState({ formData, isStateChanged, isSpinner: false }, () => {

    });
  };

  render() {
    const {


    } = this.state;
 

    return (
      //  <div className="transaction-slideout invoice-entry pos-fxd index-1000">

      <section>
        <div className="details-drawer"
          style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
        // className="back-drop1  viewer-panel1 opacity "
        >
          <ToastContainer
            containerId={"userDetailSlideoutcontainer"}
            autoClose={3000}
          />

          <div className="d-flex">
            {!this.state.isOpenViewer && (
              <div
                className="back-drop1  viewer-panel1 opacity "
                onClick={() => this.closeSlideOut()}
              ></div>
            )}

            <Container
              fluid

              className="body-sec viewer-panel back-drop p-0 "
              style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}

            >
              <div
                className="container-fluid py-1 bg-white d-flex align-items-center  border-bottom round-4"
              >
                <div className="divheader">
                  <h5 className="my-title">
                  {this.state.formData.id == 0 ? <span>New Bank </span>  : <span>{this.state.formData.bankName}  </span> }
              
              </h5>


                  <div className="header-popout ml-auto">





                  <div className="container text-center">
  <div className="row align-items-start">
    <div className="col">
      </div>

      <div className="col">
      <button
                          type="button"
                          className="btn btn-primary cross"
                          onClick={this.closeSlideOut}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                              fill="#ffffff"
                            />
                          </svg>
                        </button>
      </div>
      </div>
      </div>
                  </div>
                </div>
              </div>
              <div
                className="scroll"
              >

                <section className="pb-3">
                  <div className="row g-3">

                    <div className="col-12 col-md-3" >
                    <Form.Group className="rm-bottom-margin" controlId="bankName">
                        <Form.Label> Bank Name</Form.Label>
                        <div className="single-select">
                          <Form.Control type="text" id="txtbankName"
                            value={this.state.formData.BankName}
                            autoComplete='off'
                            disabled={false}                            
                            onChange={(event: any) =>
                              this.handleChange(event, "BankName")} 
                          />
                        </div>

                      </Form.Group>

                    </div>
                    <div className="col-12 col-md-3" >

                    <Form.Group className="rm-bottom-margin" controlId="AccountType">
                        <Form.Label> Account Type</Form.Label>
                        <div className="w-auto" >
                        <Form.Label>
                        {(this.state.formData.AccountType != null || this.state.showField) && <SingleDropdownListWithoutSearch
                              tabIndex={this.state.tabEnable}
                              itemList={this.state.accountTypeList}
                              handleSelectedItem={this.handleSelectedItem.bind(this, "AccountType")}
                              defaultItem={this.state.formData.AccountType}
                              //defaultText={"Select Employee"}
                              defaultName={this.state.formData.AccountType}
                              controlID="1"
                              id={"ddlAccountType"}
                              disabled={false}
                            />}
                          </Form.Label>
                        </div>

                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-3" >
                    <Form.Group className="rm-bottom-margin" controlId="AccountNo">
                        <Form.Label> Account No </Form.Label>
                                     <div className="single-select">
                                     {/* {!this.state.editAccountNo &&  <Form.Label onClick={()=>{ this.setState({editAccountNo:true});}}>{String(this.state.formData.AccountNo).slice(0, -4).replace(/./g, '*') + String(this.state.formData.AccountNo).slice(-4)}</Form.Label>} */}
                                     <Form.Control type="text" id="txtAccountNo"
                                      
                                      value={ this.state.formData.id ==0 ?this.state.formData.AccountNo : this.state.formData.lastAccountNo}
                                      autoComplete='off'
                                      disabled={false}
                                      onChange={(event: any) =>
                                      this.handleChange(event, "AccountNo")} 
                                      ref={this.accountNoRef}
                                    />
                      </div>
                      </Form.Group>
                    </div>

                    <div className="col-12 col-md-3" >
                    <Form.Group className="rm-bottom-margin" controlId="RoutingNumber">
                        <Form.Label> Routing Number</Form.Label>
                        <div className="date-picker calender-picker">
                        <Form.Control type="text" id="txtRoutingNumber"
                        value={this.state.formData.RoutingNumber}
                        autoComplete='off'
                        disabled={false}
                        onChange={(event: any) =>
                          this.handleChange(event, "RoutingNumber")} 
                        />                                     
                                 </div>  
                      </Form.Group>
                    </div>

                    
                    <div className="col-12 col-md-3" >
                    <Form.Group className="rm-bottom-margin" controlId="street">
  <Form.Label> Street</Form.Label>
  <div className="single-select">
  <Form.Control type="text" id="txtstreet"
   value={this.state.formData.street}
   autoComplete='off'
   className="form-controlCustom"
   disabled={false}
   onChange={(event: any) =>
   this.handleChange(event, "street")} 
  />
  </div>

</Form.Group>
                    </div>

                    <div className="col-12 col-md-3" >
                    <Form.Group className="rm-bottom-margin" controlId="email">
                        <Form.Label> Email</Form.Label>
                        <div className="date-picker calender-picker">
                        <Form.Control type="text" id="txtemail"
                         value={this.state.formData.Email}
                         autoComplete='off'
                         disabled={false}
                        onChange={(event: any) =>
                        this.handleChange(event, "email")} 
                           />         
                      </div>  
                      </Form.Group>
                    </div>

                    <div className="col-12 col-md-3" >
                    <Form.Group className="rm-bottom-margin" controlId="phone">
  <Form.Label> Phone Number  </Form.Label>
  <div className="single-select">
    <Form.Control type="text" id="txtphone"
      value={this.state.formData.Phone}
      className="form-controlCustom"
      autoComplete='off'
      disabled={false}
      onChange={(event: any) =>
        this.handleChange(event, "phone_number")} 
    />
  </div>

</Form.Group>
                    </div>

                    <div className="col-12 col-md-3" >
                    <Form.Group className="rm-bottom-margin" controlId="customercode">
                        <Form.Label> City  </Form.Label>
                                     <div className="single-select">
                                     <Form.Control type="text" id="txtCity"
value={this.state.formData.city}
autoComplete='off'
disabled={false}
onChange={(event: any) =>
  this.handleChange(event, "city")} 
/>
                      </div>
                      </Form.Group>
                    </div>

                    <div className="col-12 col-md-3" >
                    <Form.Group className="rm-bottom-margin" controlId="states">
  <Form.Label> State </Form.Label>
  <div className="single-select">
  <Form.Control type="text" id="txtstates"
   value={this.state.formData.state}
   autoComplete='off'
   className="form-controlCustom"
   disabled={false}
   onChange={(event: any) =>
   this.handleChange(event, "state")} 
  />
  </div>

</Form.Group>
                    </div>


                    <div className="col-12 col-md-3" >
                    <Form.Group className="rm-bottom-margin" controlId="OtherCharge">
  <Form.Label> Zip</Form.Label>
  <div className="single-select">
    <Form.Control type="text" id="txtOtherCharge"
      value={this.state.formData.zip} 
      autoComplete='off'
      className="form-controlCustom"
      disabled={false}
      onChange={(event: any) =>
        this.handleChange(event, "zip")}
    />

  </div>

</Form.Group>
                    </div>

                
                    
                  </div>
                </section>






             

                <div className={"footer-section viewer-panel"}
                  style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
                >
  <div>
                    <div className="d-flex align-items-center justify-content-end height-72">
                      <div className="d-flex align-items-center">
                        {
                          (
                            <>
                              <Button
                                variant="primary"
                                type="button"
                                className="space btn btn-primary btn-discard"
                                onClick={this.closeSlideOut}
                              >
                                Discard
                              </Button>
                              <Button
                                id="btnSave"
                                variant="primary"
                                type="button"
                                onClick={() => this.saveItem()}
                               
                              >
                                { this.state.formData.id ==0 ? "Create" : "Update"}
                              </Button>

                          
                              
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </Container>
          </div>

          1

          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}






        </div>

      </section>
    );
  }
}
