import * as React from "react";
import {
  Container,
  Dropdown,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify"; 
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import paginationFactory from "react-bootstrap-table2-paginator";
import { SingleSearchDropdownList } from "../Common/Components/SingleSearchDropdownList";
import _ from "lodash";
import { ConfirmationModal } from "../Common/Components/ConfirmationModal";
import createDOMPurify from "dompurify";
import { Utils } from "../Common/Utilis";
import moment from "moment";
import DatePicker from "react-datepicker";
import { ShippingService } from "../Common/Services/ShippingService";
import { User } from "../Common/Services/User";
import { confirmAlert } from "react-confirm-alert";

export class ShippingSlideout extends React.Component<any, any> {
  private parentScroll: any;  
  private trDate: any;
  constructor(props: any) {
    super(props);
    this.trDate = React.createRef();
    this.state = {
      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      jobList: [],
      CustomerCode:'',
      CustomerName:'',
      ShippingAddress:'',
      courierList:[],
      formData: {
        Id: 0,
        ShipmentNo: '',
        OrderId:this.props.orderid,
        ShipVia:'',
        Terms:'',
        ShipmentDate:null,
        TenantId:0,
        ShippingDetails:[],
        CourierTrackingNo:'',
        TotalBoxNo:0,
        ShipViaId:0,
      },  
    };


  }

  componentDidMount() {
    
    if(this.props.shipmentId > 0)
    {
     this.getShippingById();
    }
    else if(this.props.orderid >0)
    {
     let requestlist = { ...this.state.requestlist };
    requestlist.orderid = this.props.orderid;
    this.getShipping(requestlist);
    this.getShippedJobs();
    }
  
    this.getCourierList();      
    
  }



 
 getShipping = (requestlist: any) => {
  let isStateChanged = true;
  ShippingService.GetShipping(requestlist)
.then(async (result: any[] | null) => {
    
  if (result != null && result.length > 0) {
   let formData = { ...this.state.formData };
   formData.Id = result[0].id;
   formData.OrderId = result[0].orderId;
   formData.TenenatId = result[0].tenantId;
   formData.ShipmentNo = result[0].shipmentNo;
   formData.ShipVia = result[0].shipVia;
   formData.ShipViaId = result[0].shipViaId;
   formData.TotalBoxNo = result[0].totalBoxNo;
   formData.CourierTrackingNo = result[0].courierTrackingNo;
   formData.Terms = result[0].terms;
   formData.ShipmentDate = result[0].shipmentDate;
   
   this.setState({isStateChanged:isStateChanged, formData:formData,CustomerCode:result[0].customerCode,CustomerName:result[0].customerName,ShippingAddress:result[0].shippingAddress});
  }
  resolve();
})
.catch((err: any) => {
  toast.error(`Server Error, ${err}`);
  reject();
});
};


getShippingById = () => {
  
  let isStateChanged = true;
  let requestlist = { ...this.state.requestlist };
  requestlist.shipmentId = this.props.shipmentId;
  ShippingService.GetShippingById(requestlist)
.then(async (result: any[] | null) => {
  if (result != null && result.length > 0) {
   let formData = { ...this.state.formData };
   formData.Id = result[0].id;
   formData.OrderId = result[0].orderId;
   formData.TenenatId = result[0].tenantId;
   formData.ShipmentNo = result[0].shipmentNo;
   formData.ShipVia = result[0].shipVia;
   formData.ShipViaId = result[0].shipViaId;
   formData.TotalBoxNo = result[0].totalBoxNo;
   formData.CourierTrackingNo = result[0].courierTrackingNo;
   formData.Terms = result[0].terms;
   formData.ShipmentDate = result[0].shipmentDate;

   result[0].shippedJobs.forEach(element => {
    element.shippableQty = element.shippingQty;
  });

   
   this.setState({isStateChanged:isStateChanged, formData:formData,CustomerCode:result[0].customerCode,
    CustomerName:result[0].customerName,
    ShippingAddress:result[0].shippingAddress,
    jobList:result[0].shippedJobs,
  });
  }
  resolve();
})
.catch((err: any) => {
  toast.error(`Server Error, ${err}`);
  reject();
});
};

getCourierList = () => {
  let requestlist = { ...this.state.requestlist };
  ShippingService.GetCourierList(requestlist)
.then(async (result: any[] | null) => {
  
if (result != null) {
  //let courierList = [] as any;
  this.state.courierList.push({id:0,name:'Select Courier Name'});
  result.forEach(element => {
    let item: any = {};
    item.id = element.id;
    item.name = element.couriername;
    this.state.courierList.push(item);
  });
  
  //this.setState({courierList:this.state.courierList},()=>{});
}
resolve();
})
.catch((err: any) => {
toast.error(`Server Error, ${err}`);
reject();
});
};

getShippedJobs = () => {

 let requestlist = { ...this.state.requestlist };
 requestlist.jobIds =  this.props.jobids.join(','); 
 requestlist.orderId = this.props.orderid;
ShippingService.GetShippedJobs(requestlist)
.then(async (result: any[] | null) => {
    
  if (result != null && result.length > 0) {    
   
    result.forEach(element => {
      element.shippableQty = element.shippingQty;
    });
   
   this.setState({jobList:result});
  }
  resolve();
})
.catch((err: any) => {
  toast.error(`Server Error, ${err}`);
  reject();
});
};


downloadShippingFile = (file: any) => {
  let request: any = {};
  request.shipmentId = this.props.shipmentId;
  
  this.setState({ isSpinner:true});
  
  ShippingService.DownloadShippingFile(request)
    .then(async (result: any | null) => {
      //this.HidecloseSlideOut();
      this.setState({ showAfterSaveModelPopup: false, isSpinner:false, });
      resolve();
    })
    .catch((error) => {
      reject();
    this.setState({ showAfterSaveModelPopup: false, isSpinner:false, });
    });
};

printLabel = () => {
  let request: any = {};
  request.shipmentId = this.props.shipmentId;
  
  this.setState({ isSpinner:true});
  
  ShippingService.PrintLabel(request)
    .then(async (result: any | null) => {
      //this.HidecloseSlideOut();
      this.setState({ showAfterSaveModelPopup: false, isSpinner:false, });
      resolve();
    })
    .catch((error) => {
      reject();
    this.setState({ showAfterSaveModelPopup: false, isSpinner:false, });
    });
};


closeSlideOut = () => {
    this.props.closeSlideOut(this.state?.shouldReloadMainPage);
  };
 

handleDiscard(postID) {
    this.setState({ isStateChanged: false }, () => {
      this.closeSlideOut();
    });
  }

HidecloseSlideOut() {
    this.props.closeSlideOut();
    this.setState({ isSaveSpinner: false });
  }


saveItem = (isPrintRequired:any) => {
    let formData = { ...this.state.formData };
     this.setState({ isSaveSpinner: true });

    let isFormValid = true;
    let request: any = {};

    if (this.state.formData.ShipViaId ===0) {
      toast.error("Please enter Ship Via.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });
       this.setState({ isSaveSpinner: false });
      return false;
    }

    if(this.state.formData.ShipmentDate === null)
       this.state.formData.ShipmentDate = new Date();

       let shippingDetails: any = [];

       let jobList = this.state.jobList;
       jobList.forEach(element => {
   
         let item: any = {};
       
         item.ShippedQty = element.shippingQty;
         item.JobId = element.jobId;
         item.ShipmentId =  this.state.formData.Id;         
         shippingDetails.push(item);
       
       });
       this.state.formData.OrderId=this.props.orderid;
       this.state.formData.ShippingDetails = shippingDetails;  

    request = Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));

    isFormValid = true;

    if (isFormValid) {
      ShippingService.SaveShipping(request)
        .then(async (result: any | null) => {
          if (result !== null) {
             this.setState({ isSaveSpinner: false });
             toast.success( this.state.formData.Id > 0 ? 'Shipping information updated.':'Shipping information saved.', {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "OrderSlideout",
            });
          }
          this.closeSlideOut();
          // if(isPrintRequired)
          // {
          //   this.printItem();
          // }
          // else
          //   this.HidecloseSlideOut();

          resolve();
        })
        .catch((error) => {
          this.setState({ isdefaultColDisabled: false, isSpinner: false });
          reject();
        });
    }
  };

  printItem = () => {
  this.downloadShippingFile(null);
    
  };
  

  handleSelectedItem = (control: any, id: any) => {
  
    this.state.formData.ShipViaId = id;
    let item = this.state.courierList.filter((x)=>x.id === id);
    this.state.formData.ShipVia = item[0].name;
    //this.setState({jobprocessid :id});      
  };

  handleChange = (event: any, FieldType: string) => {
  
    let isStateChanged = true;
    
    let formData = { ...this.state.formData };

    if (FieldType === "Terms") {
      formData.Terms =
        event.target.value.trim().length > 0 ? event.target.value : "";
    }
    else if (FieldType === "CourierTrackingNo") {
      formData.CourierTrackingNo =
        event.target.value.trim().length > 0 ? event.target.value : "";
    }
    else if (FieldType === "ShipmentNo") {
      formData.ShipmentNo =
        event.target.value.trim().length > 0 ? event.target.value : "";
    }
    else if (FieldType === "TotalBoxNo") {
      formData.TotalBoxNo =
        event.target.value.trim().length > 0 ? event.target.value : "";
    }
    else if (FieldType === "ShipVia") {
      
      formData.ShipVia =
        event.target.value.trim().length > 0 ? event.target.value : "";
    }
    else if (FieldType === "ShipmentDate") {

      formData.ShipmentDate = event.toISOString();

        let txtID = "txtShipmentDate";
        setTimeout(() => {
         //this.trDate.current.setOpen(true);
        //  $("input[id^='" + txtID + "']").focus();
        }, 50);
    }

    this.setState({ formData, isStateChanged, isSpinner: false }, () => {

    });
  };

  updateState = (e: any, row: any, type: any, txtID) => {
    if (this != undefined) {
      let isStateChanged = true;
      let start = e.target?.selectionStart;
      let end = e.target?.selectionEnd;

      let jobList = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.jobList))
      );
 
      if (type === "shippingQty") {
        e.preventDefault();
        let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
        e.target.value = newVal;
        
        let itemRows = jobList.filter((x)=>x.jobId == row.jobId);
        if(itemRows != null && itemRows.length> 0)
        {
          if(Number(e.target.value) > itemRows[0].shippableQty)
          {
          toast.error("shipping quantity should be less than shippable quantity", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });
          itemRows[0].shippingQty = "";
        }
        else
        {
          //itemRows.forEach(element => {
            itemRows[0].shippingQty = e.target.value;
          //});
        }

        }


      
        this.setState({ jobList:jobList, isStateChanged }, () => {
          $("#tblJobs")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tblJobs").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
    }
  };

  moreAction = (eventKey: any) => {
    if (eventKey == "DeleteShipment") {
      // this.deleteShipment();
      let masg = "";

      masg = "Are you sure you want to delete this Shipment?";

      confirmAlert({
        title: "Delete Shipment",

        message: masg,
        buttons: [
          {
            label: "Yes",
            onClick: () => this.deleteShipment(),
          },
          {
            label: "No",
            onClick: () => reject(),
          },
        ],

        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
  };

  deleteShipment = () => {
    this.setState({ isSpinner: true });
    let request = { ...this.state.requestlist };
    request.shipmentId = this.props.shipmentId;

    ShippingService.DeleteShipment(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          this.closeSlideOut();

          toast.success("Shipment has been deleted.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });
          this.setState({ isSpinner: false });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isdefaultColDisabled: false, isSpinner: false });
        reject();
      });
  };

  render() {
    const columnJobs = [
      
      {
        dataField: "jobNo",
        text: "Job #",
        headerStyle: { width: '150px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
           
              <EllipsisWithTooltip placement="bottom">
                {row.jobNo}
              </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "partName",
        text: "Part Name",
        headerStyle: { width: '150px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
           
              <EllipsisWithTooltip placement="bottom">
                {row.partName}
              </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "jobDesc",
        text: "Description",
        headerStyle: { width: '250px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
           
              <EllipsisWithTooltip placement="bottom">
                {row.jobDesc}
              </EllipsisWithTooltip>
          );
        },
      },
      // {
      //   dataField: "shippableQty",
      //   text: "Shipping Available Qty",
      //   hidden: false,
      //   headerStyle: { width: '150px' },
      //   formatter: (cell: any, row: any, rowIndex: any) => {
      //     return (
      //       <EllipsisWithTooltip placement="bottom">
      //         {row.shippableQty}
      //       </EllipsisWithTooltip>
      //     );
      //   },
      // },
    
      {
        dataField: "shippingQty",
        text: "Qty",
        hidden: false,
        headerStyle: { width: '150px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          let id = "txtCompletedQty" + rowIndex + "txt";
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.manualTracking ? '':row.shippingQty}
              {row.manualTracking && <Form.Control
            tabIndex={this.state.tabEnable}
            id={id}
            type="text"
             placeholder="Please enter qty"
            value={row.shippingQty}
            onChange={(e) => this.updateState(e, row, "shippingQty", id)}
            autoComplete="off"
            //pattern="[+-]?\d+(?:[.,]\d+)?"
            maxLength={200}
            disabled={false}
            title=""
          />}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "rowId",
        text: "rowId",
        hidden: true,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.rowId}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "",
        text: "",
        headerClasses: "blankLastChildofChild",
      },
     
     
    ];

    return (
      //  <div className="transaction-slideout invoice-entry pos-fxd index-1000">

      <section>
        <div className="details-drawer"
          style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
        // className="back-drop1  viewer-panel1 opacity "
        >
          <ToastContainer
            containerId={"userDetailSlideoutcontainer"}
            autoClose={3000}
          />

          <div className="d-flex">
            {!this.state.isOpenViewer && (
              <div
                className="back-drop1  viewer-panel1 opacity "
                onClick={() => this.closeSlideOut()}
              ></div>
            )}

            <Container
              fluid

              className="body-sec viewer-panel back-drop p-0 "
              style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}

            >
              <div
                className="container-fluid py-1 bg-white d-flex align-items-center  border-bottom round-4"
              >
                <div className="divheader">
                  <h5 className="my-title">
                  {this.state.formData.ShipmentNo == "" && <span>Shipping</span>}
              {this.state.formData.ShipmentNo != "" && (
                <span>
                  Shipping No : {this.state.formData.ShipmentNo}
                </span>
              )}
                  </h5>


                  <div className="header-popout ml-auto">





                  <div className="container text-center">
  <div className="row align-items-start">
  <div className="col">
  { this.state.formData.ShipmentNo != ""
                       &&
                            
    <div className="action">
                   
                     <Dropdown
                       className="more-action"
                       alignRight
                       onSelect={(event: any) => { this.moreAction(event); }}
                     >
                       <Dropdown.Toggle
                         className="btn-outline-primary btn btn-primary more"
                         id="dropdown-more"
                       >
                         <svg
                           width="20"
                           height="20"
                           viewBox="0 0 20 20"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                         >
                           <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                           <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                           <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                         </svg>
                       </Dropdown.Toggle>
                       <Dropdown.Menu>
                           <Dropdown.Item eventKey={"DeleteShipment"}
                             key={1} >
                             {"Delete Shipment"}
                           </Dropdown.Item>
                       
                       </Dropdown.Menu>
                     </Dropdown>

                  
                  
                 </div>
               
                     
               }
    </div>


      <div className="col">
      <button
                          type="button"
                          className="btn btn-primary cross"
                          onClick={this.closeSlideOut}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                              fill="#ffffff"
                            />
                          </svg>
                        </button>
      </div>
      </div>
      </div>
                  </div>
                </div>
              </div>
              <div
                className="scroll"
              >

                <section className="pb-3">
                  <div className="row g-3">

                    <div className="col-12 col-md-3" >
                      <Form.Group controlId="customer">
                      <Form.Label  className="font-semibold">Customer Name</Form.Label>
                      <div
                        tabIndex={this.state.tabEnable}
                        className="type-head"
                      >
                       
                        <Form.Control
                          type="text"
                          id="txtTerms"
                          value={this.state.CustomerName}
                          
                          autoComplete="off"
                          title=""
                          disabled={true}
                        />
                      </div>
                      </Form.Group>

                    </div>
                    <div className="col-12 col-md-3" >
                      <Form.Group controlId="customercode">
                        <Form.Label className="font-semibold"> Customer Code</Form.Label>
                        <div className="single-select">
                          <Form.Control type="text" id="txtCustomercode"

                            value={this.state.CustomerCode}


                            autoComplete='off'
                            disabled={true}
                          />
                        </div>

                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-3" >
                      <Form.Group controlId="customercode">
                      
                        <Form.Label className="font-semibold">Shipment Date</Form.Label>
                       <div className="date-picker calender-picker">
                                   <DatePicker
                                     ref={this.trDate}
                                     id="txtShipmentDate"
                                     tabIndex={this.state.tabEnable}
                                     className="form-control"
                                     placeholderText={"MM/DD/YY"}
                                     dateFormat="MM/dd/yy"
                                     selected={
                                       this.state.formData.ShipmentDate === null
                                         ? new Date()
                                         : this.state.formData.ShipmentDate === ""
                                           ? new Date()
                                           : new Date(this.state.formData.ShipmentDate)
                                     }
                                     onChange={(event: any) =>
                                       this.handleChange(event, "ShipmentDate")
                                     }
                                     //onClick={(e: any) =>this.onTAB("txtOrderDate-1", 0, e)}
                                     autoComplete="off"
                                   />
                                 </div>     

                      </Form.Group>
                    </div>

                    <div className="col-12 col-md-3" >
                    <Form.Label className="font-semibold">Ship Via</Form.Label>
                       <div className="single-select type">
                         <Form.Label>
                       {  this.state.formData.ShipVia !== '' &&<SingleSearchDropdownList
              tabIndex={this.state.tabEnable}
              itemList={this.state.courierList}
              handleSelectedItem={this.handleSelectedItem.bind(this, 2)}
              defaultItem={this.state.formData.ShipViaId}
              defaultText={"Search Courier Name"}
              defaultName={this.state.formData.ShipVia }
              controlID="1"
              id={"ddlShipVia"}
            />}</Form.Label>
                      </div>

                    </div>
                    <div className="col-12 col-md-3" >
                    <Form.Label className="font-semibold">Tracking No</Form.Label>
                      <div className="date-picker calender-picker">
                      <Form.Control
                          type="text"
                          id="txtCourierTrackingNo"
                          value={this.state.formData.CourierTrackingNo}
                          onChange={(event: any) =>
                            this.handleChange(event, "CourierTrackingNo")
                          }
                          autoComplete="off"
                          title=""
                        />
                              </div>   

                    </div>
                    <div className="col-12 col-md-3" >
                    <Form.Label className="font-semibold">No of Box</Form.Label>
                      <div
                        tabIndex={this.state.tabEnable}
                      

                      >
                        <Form.Control
                          type="text"
                          id="txtTerms"
                          value={this.state.formData.TotalBoxNo}
                          onChange={(event: any) =>
                            this.handleChange(event, "TotalBoxNo")
                          } 
                          autoComplete="off"
                          title=""
                          className="form-control px-0 w-50 text-center"
                        />
                      </div>

                    </div>
                    <div className="col-12 col-md-3" >

                    <Form.Label className="font-semibold">Terms</Form.Label>
                      <div
                        tabIndex={this.state.tabEnable}
                        className="type-head"
                      >
                        <Form.Control
                          type="text"
                          id="txtTerms"
                          value={this.state.formData.Terms}
                          onChange={(event: any) =>
                            this.handleChange(event, "Terms")
                          }
                          autoComplete="off"
                          title=""
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-3" >

                    <Form.Label className="font-semibold">Shipping Address</Form.Label>
                      <div
                        tabIndex={this.state.tabEnable}
                        className="type-head"
                      >
                      
                        <Form.Control
                          id="txtaddress"
                          as="textarea"
                          wrap="hard"
                          rows={3}
                          value={this.state.ShippingAddress}
                          disabled={true}
                          maxLength={99}
                        />
                      
                      </div>
                    </div>
                   
                    
                  </div>
                </section>






                <section className="pb-3">

                  <div
                    className="table-responsive table table-borderless align-middle transactions-list"

                  >
                  

<BootstrapTable
id="tblJobs" 
keyField="Id"
data={this.state.jobList}
columns={columnJobs}
//rowEvents={this.rowEvents}

/>  

                   
                  </div>
                </section>

                <div className={"footer-section viewer-panel"}
                  style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
                >
 <div className="d-flex gap-3 align-items-center justify-content-end height-72">
                      <div className="d-flex gap-3 align-items-center">
                      {this.state.isStateChanged && (
                        <>
                          <Button variant="primary" type="button" className="space btn btn-primary btn-discard" onClick={() => this.handleDiscard(this.state.orderUniqueNo)}>
                            Discard
                          </Button>
                          {/* <Button id="btnSave" variant="primary" type="button"
                            onClick={() => this.saveItem(false)}
                          // disabled={this.state.formData.templatename === "" ? true : this.state.isDisabled}
                          //onKeyDown={(e: any) => this.onTAB('btnSave-1', 0, e)}

                          >{"Save"}</Button> */}

                          <Button id="btnSaveAndPrint" variant="primary" type="button"
                          onClick={() => this.saveItem(true)}> 
                          { this.state.formData.Id > 0 ? "Update" : "Save"} 
                          </Button>

                        </>
                      )}
   {  this.state.formData.Id > 0 &&

<Button id="btnSave" variant="primary" type="button" onClick={() => this.printLabel()}
 >{"Print Shipping Label"}</Button>
}
                      {  this.state.formData.Id > 0 &&

                        <Button id="btnSave" variant="primary" type="button" onClick={() => this.printItem() }
                         >{"Print Delivery Ticket"}</Button>
                      }

                      </div>
</div>
                </div>
              </div>

            </Container>
          </div>

          1

          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}






        </div>

      </section>
    );
  }
}
