import * as React from "react";
import {
  Container,
  Dropdown,
  Form,
  Button,
  Modal,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Spinner,
  Accordion,
  Card,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  FormControl,
  FormGroup,

} from "react-bootstrap";

import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { PartTemplateService } from "../../Common/Services/PartTemplateService";
import paginationFactory from "react-bootstrap-table2-paginator";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import _ from "lodash";
import { ConfirmationModal } from "../../Common/Components/ConfirmationModal";
import createDOMPurify from "dompurify";
import { Utils } from "../../Common/Utilis";
import moment from "moment";
import { ProductService } from "../../Common/Services/ProductService";
import {Roles } from "../../Common/Services/RolePermissionSetting";


import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";




export class RolePremissionSlideout extends React.Component<any, any> {
    private parentScroll: any;
    private singleSerch: any;
    private trDate: any;
  
   
  
    
  
   
    constructor(props: any) {
      super(props);
     
      this.state = {
       
      
     
     
      defaultstatus:"Select a new role tag",
      Status:[],
      
    
        formData: {
        
          Status:"",
       
         
        },
  
  
       
      };
  
  
    }
    
   
        
         
   
 
      
  
  
    closeSlideOut = () => {
      this.props.closeSlideOut(this.state?.shouldReloadMainPage);
  
    };
  
  
    render() {
        return (
          <div className="trasaction-slideout invoice-entry pos-fxd index-1000">
            <ToastContainer
              containerId={"userDetailSlideoutcontainer"}
              autoClose={3000}
            />
    
           
    <div className="d-flex">
              <Container
                fluid
                ref={this.parentScroll}
                className="body-sec viewer-panel back-drop p-0"
              >
                <div
                  className="page-heading underline d-flex top-0"
                  style={{
                    position: "fixed",
                    top: 0,
                    zIndex: 999,
                    backgroundColor: "#fff",
                    // backgroundColor: "red",
                    padding: "25px",
                    width: "75%",
                    // borderRadius:"1px",
                    borderBottom: "1px solid #fff",
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                  }}
                >
                  {this.state.templateid == 0 && <span></span>}
                  {this.state.templateid != 0 && (
                    <span>
                     Role & Permission 
                    </span>
                  )}
    
                  <div className="header-popout ml-auto">
                  <div className="container text-center">
  <div className="row align-items-start">
    <div className="col">
      </div>

      <div className="col">
      <button
                          type="button"
                          className="btn btn-primary cross"
                          onClick={this.closeSlideOut}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                              fill="#ffffff"
                            />
                          </svg>
                        </button>
      </div>
      </div>
      </div>
                  </div>
                </div>
                <Container fluid className="p-10">
                
                  <div className="filter-sec" style={{ marginTop: "80px" }}>
                  <div className="h3  mb-2"></div>
                   
                   <div className="container py-2">
    
                  
                    
                    <Form>
                   
                      
                    <FormGroup>
                    <Form.Control placeholder="Enter Role Name"></Form.Control>
               </FormGroup>

               <div className="row my-2">
                    <div className="col-2">
                      <Form.Label className="h6 font-semibold">Password Reset</Form.Label>
                    </div>
                     
                     <FormGroup controlId="customerName">
                     <div className="col-12">
                     
                    
                     <Form.Check
          disabled
          type="switch"
          label="disabled switch"
          id="disabled-custom-switch"
        />
             
                    </div>
                     </FormGroup>
                  </div>


                  <div className="row my-2">
                    <div className="col-2">
                      <Form.Label className="h6 font-semibold">Assigned</Form.Label>
                    </div>
                     
                     <FormGroup controlId="customerName">
                     <div className="col-12">
                   <span className=" h4 text-success">0 Department <br></br> Assign</span>
                   
                    </div>
                     </FormGroup>
                  </div>

    
    
    
                      <div className="row my-2">
                        <div className="col-2">
                          <Form.Label className="h6 font-semibold">Role Tag</Form.Label>
                        </div>
                         
                         <FormGroup controlId="customerName">
                         <div className="col-12 bg-light">
                         <SingleDropdownListWithoutSearch
                         
                         tabIndex={this.state.tabEnable}
                         itemList={this.state.Status}
                         
                          defaultItem={this.state.defaultstatus}
                         defaultText={"Search Status"}
                           defaultName={this.state.defaultstatus}
                         controlID="1"
                           id={"Status"}
                          // handleSelectedItem={this.handleSelectedItem.bind(this,"Status")}
                           value={ this.state.Status} 
                         
                       />
                                            
                        </div>
                         </FormGroup>
                      </div>
    
     
    
                     
                    </Form>
                    
                   
    
    
                   </div>
    
                  </div>
    
                  
                  <div className={"footer-section viewer-panel pos-sticky"}>
                    <div>
                      <div className="d-flex align-items-center justify-content-end height-72">
                        <div className="d-flex align-items-center">
                          {this.state.isStateChanged && (
                            <>
                              <Button variant="primary" type="button" className="space btn btn-primary btn-discard" 
                              //onClick={() => this.handleDiscard(this.state.orderUniqueNo)}
                              >
                                Discard
                              </Button>
                              <Button id="btnSave" variant="primary" type="button"
                               
    
                              >{"Save"}</Button>
    
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-sec">
                    <div className="d-flex flex-wrap mr-auto"></div>
                  </div>
                </Container>
              </Container>
            </div>
    
    
          </div>
        );
      }
    };