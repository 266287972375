import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { CustomerService } from "../../Common/Services/CustomerService";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { UserService } from "../../Common/Services/UserService";
import { UserSlideout } from "./UserSlideout";
import _ from "lodash";
 
import createDOMPurify from "dompurify";
 
import moment from "moment";
const columns = [
    {
      dataField: 'user_UniqueID',
      text: 'Empcode',
    },
   
    {
      dataField: 'userName',
      text: 'User Name',
      sort: true,
    },
    {
      dataField: 'firstName',
      text: 'First Name',
    },
    
    
    {
      dataField: 'lastName',
      text: 'Last Name',
    },
    {
        dataField: 'employeeType',
        text: 'Employee Type',
    },
    {
        dataField: 'email',
        text:'Email',
    },

    {
        dataField: 'phone1',
        text:'Phone No',
    },
    
    {
      dataField: 'status',
      text:'Status',
  },
     
  ];
  
export class User extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
         
          htmlItem: [],
          htmlErrorItem: [],
          htmlWarningItem: [],
          searcResult: [],
          orderid:0,
        };
        
     
      }
      componentDidMount() {
        let requestlist = { ...this.state.requestlist };
        this.getuserdata(requestlist);
      }


 
      getuserdata = (requestlist: any) => {
 

    UserService.GetUserMaster(requestlist)
    .then(async (result: any[] | null) => {
        console.log(result);
      if (result != null) {
        this.setState({ searcResult: result }, () => {
           
        });
      }
      resolve();
    })
    .catch((err: any) => {
      toast.error(`Server Error, ${err}`);
      reject();
    });
  };

  shownewslideout = () => {
    this.setState({ showSlideout: true, templateid: 0 });
  };

  closeSlideOut = () => {
    this.setState({ showSlideout: false });
    let requestlist = { ...this.state.requestlist };
    this. getuserdata(requestlist);
  };
  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.setState({
        showSlideout: true, templateid: row.user_UniqueID

      });


    },
  };

  render() {
    const {

      showSlideout,

    } = this.state; 

    return (
      <>
        <React.Fragment>



          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">

            Users
              <div className="action-group d-flex flex-row ml-auto">
              <Button onClick= {this.shownewslideout} >Add User</Button>

              </div>
            </div>

            <BootstrapTable
        keyField="id"
        data={this.state.searcResult}
        columns={columns}
        rowEvents={this.rowEvents}
       pagination={ paginationFactory() }
      />
      {(showSlideout &&
                <UserSlideout
                  closeSlideOut={this.closeSlideOut}
                  templateid={this.state.templateid}
                >


                </UserSlideout>
              )}






          </Container>



        </React.Fragment>
      </>
    );
  }
}