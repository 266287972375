import Instense from "./Axios-config";
import { Utils } from "../Utilis";

export class CommonService {
  
    public static GetComments = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        
        request.tenantId = tenantID;
       
        const headers = {
          Authorization: `Bearer ${Utils.GetUserToken()}` 
        };
        const url = `/Comment/GetComments`;
        return Instense.get(url, { params: request,headers  }).then((response) => {
         
          let result = response.data.result as any;
          return result;
        });
    };

    
    public static SaveComment = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
  
    const url = `/Comment/SaveComment`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };
  
}
