import React, { forwardRef, useState, useRef, useImperativeHandle, useEffect } from "react";
// import default react-pdf entry
import { Document, Page, pdfjs } from "react-pdf";
import {Dropdown, Form, Button, ButtonGroup} from "react-bootstrap";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";

import { render } from "@testing-library/react";
import ScrollContainer from 'react-indiana-drag-scroll';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


interface ISelectorProps {
  ref: any;
  uniqueno: any;
  filePreview: any;
  defaultView: any;
  fileNo: any;
  fileCount: any;
  fileName: any;
  docFile: any;
  updateState: any;
  updateFiles: any;
  showBulkSelect: any
  checkBulkSelect: any
  handleFileCheckBox: any
  handleUpdatedFile: any
  handleincompleteFile: any
  saveSplitFile: any;
  scrollAreaHeight: any;
  isModalOpen: any;
  pageType: any;
  handleRotate: any;
  handlePageRemove:any;
  selectedChk: any;
  selectedZoom: any;
  setTotalPages:any;
  isEditPermission: any;
}

const PDFViewer: React.FC<ISelectorProps> = forwardRef(
  (props: any, ref) => {
    const [file, setFile] = useState(props);
    const [numPages, setNumPages] = useState(null);
    const [error, setError] = useState(false);
    const [chkids, setchkIds] = useState<Array<any>>(props.selectedChk);
    const [files, setfiles] = useState<Array<any>>([]);
    const [bulkSelect, setBulkSelect] = useState(false);
    const [attachInvoice, setAttachInvoice] = useState(false);
    const chkRef = useRef<any>(null);
    const attachInvoiceModal = useRef<any>(null);
    const innWidthSec = useRef<any>(null);
    const [currentFileNo, setFileNo] = useState(null);
    const [dropdownIDs, setdropdownID] = useState<Array<any>>([]);
    const [incompleteFiles, setincompleteFile] = useState<Array<any>>([]);
    const [toggleIds, settoggleId] = useState('');
    const [inputValue, setinputValue] = useState('');
    const [selectedparm, setselectedparm] = useState<Array<any>>([]);


    // function onFileChange(event) {
    //   setFile(event.target.files[0]);
    // }


    useEffect(() => {
     // if (props.selectedChk.length > 0) {
        setBulkSelect(true);
    // }

     // chkindeterminate();
      updateFileList();


      setTimeout(() => {
        checkBulkSelect();

      }, 500);
      console.log(file);

    }, [chkids]);

    function handleId(event) {
      console.log(event.currentTarget.id);
    }

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
      setNumPages(nextNumPages);
      props.setTotalPages(nextNumPages);
      // updateFileList(props.fileNo, nextNumPages);
    }

    function renderError() {
      setError(true);
    }
    


    //function updateFileList(fileNo, numPages) {
    function updateFileList() {
      
      const propsFile = props.docFile;


      let newfiles = [...files];

      propsFile.forEach(element => {
        
        const count = files.filter((x) => x.fileNo === element.fileUniqueno).length;
        
        if (count === 0) {
          let newItem: any = [];
          newItem.fileNo = element.fileUniqueno;
          newItem.totalpage = Number(element.pageno);
          newfiles.push(newItem);
        }
      });
      setfiles(newfiles);
      
    }

 
    
 
  


    

 

    function handleRotate(eventKey: any, currentfileNo: any, currentPage: any) {
      const chkIds = [...chkids];
      props.handleRotate(chkIds, currentfileNo, currentPage);
    }

    function handlePageRemove(eventKey: any, currentfileNo: any, currentPage: any) {
      const chkIds = [...chkids];
      props.handlePageRemove(chkIds, currentfileNo, currentPage);
    }

    function handleSelectedItem(id: any, control: any) {
      console.log(control);
      console.log(id);

    }
    function onTAB(control: any, id: any) {
    }


    function handleFileCheckbox(event, fileNo) {
      if (!event.target.checked) {
        const newIds = chkids.filter((x) => x.fileNo !== fileNo);
        setchkIds(newIds);
        props.handleFileCheckBox(event, files, fileNo, "", "All");
      }
      else {

        const newIds = [...chkids];
        files.filter((x) => x.fileNo === fileNo).forEach(element => {
          for (let inx = 0; inx < element.totalpage; inx++) {
            let id = element.fileNo.toString() + '_' + inx.toString();
            const selectedId = `custom_${id}`;
            const newId = chkids.filter((x) => x.id === selectedId);
            if (newId.length === 0) {

              let item: any = {};
              item.id = selectedId;
              item.fileNo = element.fileNo;
              newIds.push(item);

            }
          }
          setchkIds(newIds);
          props.handleFileCheckBox(event, files, fileNo, "", "All");
          //const newIds = chkids.filter((x) => x.id !== selectedId);
        });


      }

    }

    function handleCheckbox(event, id, fileNo, totalpage) {
      
      //const selectedId = event.target.value;
      const selectedId = `custom_${id}`;

      if (!event.target.checked) {
        const newIds = chkids.filter((x) => x.id !== selectedId);
        setchkIds(newIds);
        props.handleFileCheckBox(event, files, fileNo, selectedId, "Single");
      } else {
        const newIds = [...chkids];
        let item: any = {};
        item.id = selectedId;
        item.fileNo = fileNo;
        newIds.push(item);
        setchkIds(newIds);
        // setFileNo(fileNo);
        props.handleFileCheckBox(event, files, fileNo, selectedId, "Single");


      }

      // setTimeout(() => {
      //   const newIds1 = [...chkids];
      //   const chklen = newIds1.filter((x) => x.fileNo == fileNo)?.length;
      //   const filelen = files.filter((x) => x.fileNo == fileNo)?.length;
      //   if (chklen > 0 && chklen !== filelen) {
      //     chkRef.current.indeterminate = true;
      //   }
      //   else {
      //     chkRef.current.indeterminate = false;
      //   }
      // }, 1000);

    }



    function handleBulkSelect() {

      props.showBulkSelect("show");
      //alert("bulk select")
    }



    function checkBulkSelect() {
      if (bulkSelect) {
        const newfiles: any = [];
        const newchkids: any = [];
        let newUpdatedFile: any = [];
        newUpdatedFile = props.handleUpdatedFile();

        files.forEach(element => {
          let inx = newfiles.filter(x => x.fileNo === element.fileNo);
          if (inx.length === 0) {
            newfiles.push(element);
          }
        });
        newUpdatedFile.forEach(element => {
          let inx1 = newchkids.filter(x => x.fileNo === element.fileNo);
          if (inx1.length === 0) {
            newchkids.push(element);
          }
        });

        let totalpage = 0;

        newfiles.forEach(element => {
          totalpage = totalpage + element.totalpage;
        });
        let chk = "";

        if (newUpdatedFile.length > 0) {
          chk = ((newfiles.length === newchkids.length) && (totalpage === newUpdatedFile.length)) ? "full" : "helf";
        }
        else {
          chk = "removeAll";
        }
        props.checkBulkSelect(chk, newUpdatedFile.length);
      }
    }

    

    function chkindeterminate() {
      
      if (bulkSelect) {
        const chklen = chkids.filter((x) => x.fileNo == props.fileNo)?.length;
        const file = files.filter((x) => x.fileNo == props.fileNo);

        if (chklen > 0 && Number(chklen) !== Number(file[0].totalpage)) {
          chkRef.current.indeterminate = true;
        }
        else {
          chkRef.current.indeterminate = false;
        }

      }
    }

    useImperativeHandle(ref, () => ({


      chkindeterminateCheckBox() {
        
        if (bulkSelect) {
          const propsFile = props.docFile;
          const chklen = chkids.filter((x) => x.fileNo == props.fileNo)?.length;
          const file = propsFile.filter((x) => x.fileUniqueno == props.fileNo);

          if (chklen > 0 && Number(chklen) !== Number(file[0].pageno)) {
            chkRef.current.indeterminate = true;
          }
          else {
            chkRef.current.indeterminate = false;
          }
        }
      },

      showBulkSelect() {
        setBulkSelect(true);

      },

      hideBulkSelect() {
        if (bulkSelect) {
          //chkRef.current.indeterminate = false;
        }
        setBulkSelect(false);
        setchkIds([]);



      },

      hanndleDeleteFile(fileNo) {
        //const newIds = chkids.filter((x) => x.fileNo !== fileNo);
        //setchkIds(newIds);
        const newfiles = files.filter((x) => x.fileNo !== fileNo);
        setfiles(newfiles);
        setchkIds([]);
      },

      handleAllCheckbox(event) {
        
        const newIds = [...chkids];
        if (event.target.checked) {
          files.forEach(element => {
            for (let inx = 0; inx < element.totalpage; inx++) {
              let id = element.fileNo.toString() + '_' + inx.toString();
              const selectedId = `custom_${id}`;
              const newId = chkids.filter((x) => x.id === selectedId);
              if (newId.length === 0) {

                let item: any = {};
                item.id = selectedId;
                item.fileNo = element.fileNo;
                newIds.push(item);

              }
            }
            setchkIds(newIds);
            props.handleFileCheckBox(event, files, 0, "", "bulkSelect");
            //const newIds = chkids.filter((x) => x.id !== selectedId);
          });
        }
        else {
          setchkIds([]);
          props.handleFileCheckBox(event, files, 0, "", "bulkSelect");
        }
      }
    }));

console.log(bulkSelect);


    return (
   
      <div ref={innWidthSec}>
        {/* <Page pageNumber={pageNumber} /> */}


        <Document file={file.filePreview} onLoadSuccess={onDocumentLoadSuccess} loading={""} error={renderError}>          
          <div className="custom-page-action d-flex justify-content-between align-items-center">
            <div className="page-ttl">
            
              {(props.defaultView != "doc-view" && !bulkSelect) && (
                <EllipsisWithTooltip placement="bottom">{file.fileName}</EllipsisWithTooltip>
              )}
            </div>

          </div>

          {Array.from(new Array(numPages), (_el, index) => (
            //updateFileList(props.fileNo,numPages),s
            <>
              {props.defaultView === "doc-view" && (
                <div className="custom-page-action d-flex justify-content-between align-items-center doc-header">
                  <div className="page-ttl">
                    {bulkSelect && (
                       <EllipsisWithTooltip placement="bottom">
                       {file.fileName}
                     </EllipsisWithTooltip>
                    )}

                    {!bulkSelect && (
                      <EllipsisWithTooltip placement="bottom">{file.fileName}</EllipsisWithTooltip>
                    )}
                  </div>
                  <div className="page-count">Page {index + 1} of {numPages}</div>
                
                </div>

              )}
              <div
                id={`page_${props.fileNo + index + 1}`}
                className={props.defaultView !== "doc-view" ? "grid-view" : "doc-panel"}

              //onClick={handleId}
              >
                {props.defaultView === "doc-view" && (
                  <ScrollContainer className="scroll-container">
                    <Page
                      key={`page_${props.fileNo + index + 1}`}
                      pageNumber={index + 1}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                      scale={props.defaultView === "grid-view" ? 1.0 : props.selectedZoom}
                      onClick={() => props.defaultView === "grid-view" ? props.updateState(`page_${index + 1}`) : ""}
                    />                  
                </ScrollContainer>
                )}
                  {props.defaultView !== "doc-view" && (
                    <Page
                        key={`page_${props.fileNo + index + 1}`}
                        pageNumber={index + 1}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}                    
                        onClick={() => props.defaultView === "grid-view" ? props.updateState(`page_${index + 1}`) : ""}
                      />
                  )}
                
                {props.defaultView !== "doc-view" && (
                  <div className="custom-page-action d-flex justify-content-between align-items-center">
                    <div className="page-count">
                      {bulkSelect && (
                        <Form.Check
                          custom
                          type="checkbox"
                          key={`custom_${props.fileNo.toString() + '_' + index.toString()}`}
                          id={`custom_${props.fileNo.toString() + '_' + index.toString()}`}
                          label={`Page ${index + 1}`}
                          onChange={(e: any) => handleCheckbox(e, props.fileNo.toString() + '_' + index.toString(), props.fileNo, numPages)}
                          checked={chkids !== undefined && chkids.filter((x) => x.id.toString() === `custom_${props.fileNo.toString() + '_' + index.toString()}`).length > 0 ? true : false}
                          disabled ={chkids === undefined || chkids.length == 0}
                        />
                      )}
                      {!bulkSelect && (
                        `Page ${index + 1}`
                      )}
                    </div>

                    {!bulkSelect && (
                      <div className="action">
                         {(
                        <Dropdown
                          className={dropdownIDs.filter((e) => e.id.toString() === `dropdown_${props.fileNo + index + 1}`).length > 0 ? "more-action show" : "more-action"}
                          alignRight>
                          <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id={`toggle_${props.fileNo + index + 1}`} >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                              <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                              <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                            </svg>
                          </Dropdown.Toggle>
                         
                        
                        </Dropdown>
                         )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          ))}
        </Document>                  
        {error && (
            <div className="custom-error-msg">Cannot be previewed - {file.fileName}</div>
          )}
      </div>
    );
  });

export default PDFViewer;


