import axios from "axios";
import Instense from "./Axios-config";
import { API_ROOT } from "./Api-config";
import {
  ICustomerorder
  } from "../Contracts/ICustomerorder";
export class VendorService {


  public static GetCustomerOrder = async (): Promise<
  ICustomerorder[] | null
> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let request = {} as any;
  request.tenantID = tenantID;

  const url = `/Customer/GetCustomerOrderList`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as ICustomerorder[];
   
    return result;
  });
};

public static GetAllVendorOrder = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  // debugger;
  request.tenantid = tenantID;
  const url = `/Vendor/GetAllVendorOrder`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

 
public static GetJobOrderlistByItem = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  // debugger;
  request.tenantID = tenantID;
  const url = `/Vendor/GetJobOrderlistByItem`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static Getorderdata = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Vendor/GetVendorOrderData`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetVendorReceivingData = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Vendor/GetVendorReceivingData`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};


public static GetVendorById = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Vendor/GetVendorById`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetVendorlist = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Vendor/GetVendorlist`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetCustomerAddress = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Customer/GetCustomerAddress`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetVendorContract = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Customer/GetCustomerAddress`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
 

public static SaveVendorData = async (request: any): Promise<any> => {

  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.TenantID = tenantID;
  request.UserName = userName;
  const url = `/Vendor/SaveVendorData `;
  return Instense.post(url, request).then((response) => {
    
      let result = response.data.result as any[];
      return result;
  });
};

public static DeleteVendorOrder = async (
  request:  any
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  request.tenantId = tenantID;
  const url = `/Vendor/DeleteVendorOrder`;
  return Instense.delete(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
 
public static Saveorder = async (request: any): Promise<any> => {
  // debugger;
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.TenantID = tenantID;
  request.UserName = userName;
  const url = `/Vendor/SaveOrder `;
  return Instense.post(url, request).then((response) => {
    // debugger;
      let result = response.data.result as any[];
      return result;
  });
};

public static SaveReceivingData = async (request: any): Promise<any> => {
  // debugger;
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.TenantID = tenantID;
  request.UserName = userName;
  const url = `/Vendor/SaveReceivingData `;
  return Instense.post(url, request).then((response) => {
    // debugger;
      let result = response.data.result as any[];
      return result;
  });
};

public static DownloadVendorOrderFile = async (
  request: any
): Promise<any | null> => {

  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.tenantid = tenantID;
  request.UserName = userName;
  const url = `/Vendor/GetVendorPDF`;
  return Instense.get(url,{params:request,  responseType: "blob", headers: {
    'Accept': '*/*',
  }, } ).then(
    (response: any) => {
      // Check if the response is successful
      if (response.status === 200) {
        
        const blob = new Blob([response.data], { type: 'application/pdf' });
        // Open the PDF in a new browser tab
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      } 
    }
  );
};



public static SendMailToVendor = async (
  request: any,mailRequest: any,
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.tenantid = tenantID;
  request.UserName = userName;
  const url = `/Vendor/GetVendorPDF`;
  return Instense.get(url,{params:request,  responseType: "blob", headers: {
    'Accept': '*/*',
  }, } ).then(
    (response: any) => {
      // Check if the response is successful
      if (response.status === 200) {
        mailRequest.files = new File([response.data], 'attachment.pdf');  
        
        let saveItems = mailRequest; //Object.assign({}, JSON.parse(JSON.stringify(item)));
        
    const url = `/Mail/SendMail`;
    return Instense.post(url,saveItems, {headers: {
      'Content-Type': 'multipart/form-data',
      'accept': '*/*',
    },}  ).then(
      (response: any) => {
        // Check if the response is successful
        if (response.status === 200) {
         
        } 
      }
    );

      } 
    }
  );
};

public static Savefiles = async (
  request: any, files: any[]
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  request.tenantID = tenantID;




  const url = `/Customer/SaveFile `;
  const formData: FormData = new FormData();
  files.forEach(element => {
    formData.append('file', element);
  });

  formData.append('formField', JSON.stringify(request));
  return Instense.post(url, formData).then((response) => {
    let result = response.data.result as any;
    return result;
  });



};




public static GetUploadFileList = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
 
  request.tenantID = tenantID;
  const url = `/Customer/GetUploadFileList`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetUploadFileWithFileCode = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
  // debugger

  request.tenantID = tenantID;
  const url = `/Customer/GetUploadFileWithFileCode`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static DeleteUploadedFile = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
  // debugger

  request.tenantID = tenantID;
  const url = `/Customer/DeleteUploadedFile`;
  return Instense.post(url, request).then((response) => {
    let result = response.data.result as any;
    return result;
  });

};


}


