import Instense from "./Axios-config";
import {
  IRolePermissionResponseDto,
  IRolePermissionResponseTreeViewDto,
  INewRolePermissionDto,
  IDeleteRolePermissionDto,
  IUpdateRoleOrderDto,
  ISaveRoleMatrixDto,
  IRoleTagResponseDto,
} from "../Contracts/IRolePermission";

export class Roles {
  public static GetUserRoleList = async (): Promise<
    IRolePermissionResponseDto[] | null
  > => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;

    const url = `/MenuMaster/GetUserRoleList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IRolePermissionResponseDto[];
     
      return result;
    });
  };
  public static GetRoleTags = async (): Promise<
    IRoleTagResponseDto[] | null
  > => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    const url = `/MenuMaster/GetUserRoleTag`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IRoleTagResponseDto[];
      return result;
    });
  };

  public static GetUserAssingedList = async (request:any): Promise<
 any
> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  request.TenantID = storage === null ? 0 : (storage.tenantID as any);
  const url = `/User/GetRoleUserDetails`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data;
    return result;
  });
};
public static GetSpecialPermissionRoleList = async (): Promise<
    any[] | null
  > => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;

    const url = `/MenuMaster/GetSpecialPermission`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any[];

      return result;
    });
  };
  public static GetSpecialPermissionEmployeeList = async (
    permissionID: number
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.permissionID = permissionID;
    const url = `/MenuMaster/GetSpecialPermissionEmployeeList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any[];
      return result;
    });
  };
  public static SearchEmployeeForSpecialPermission = async (permissionID: number): Promise<
    any[] | null
  > => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.permissionID=permissionID;
    const url = `/MenuMaster/SearchEmployeeForSpecialPermission`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any[];
      return result;
    });
  };
  public static SaveDeleteSpecialPermissionUsers = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/MenuMaster/SaveDeleteSpecialPermissionUsers`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;

      return result;
    });
  };
  public static GetRoleData = async (tenantid: number): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    const url = `/User/GetUserRole`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any[];
      return result;
    });
};


public static GetPermissionData = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  request.tenantid = tenantID;
  const url = `/User/GetPermissionData`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any[];
    return result;
  });
};


public static SaveUserRole = async (request: any): Promise<any> => {
  //debugger;
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  
  request.TenantID = tenantID;
 
 
  const url = `/User/SaveUserRole `;
  return Instense.post(url, request).then((response) => {
    // debugger;
      let result = response.data.result as any[];
      return result;
  });
};

public static SavePermissionRole = async (request: any): Promise<any> => {
  
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  
  request.TenantId = tenantID;
 
 
  const url = `/User/SavePermissionRole `;
  return Instense.post(url, request).then((response) => {
    
      let result = response.data.result as any[];
      return result;
  });
};

}




export class RoleMatrixClass {
  public static saveRoleMatrixData = async (
    request: ISaveRoleMatrixDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
  
    const url = `/MenuMaster/UpdateRolePermissionTreeViewData`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      console.log(response);
      return result;
    });
  };
}

export class RolesforTreeView {
  public static GetUserRoleforTreeView = async (
    roleid: number
  ): Promise<IRolePermissionResponseTreeViewDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    //let roleid = storage === null ? 0 : (localStorage.roleid as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.roleid = roleid;
    const url = `/MenuMaster/RolePermissionTreeViewData`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IRolePermissionResponseTreeViewDto[];
      return result;
    });
  };

  public static GetMatrixUserRoleforTreeView = async (
    roleid: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.roleid = roleid;
    const url = `/MenuMaster/RolePermissionTreeViewData`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IRolePermissionResponseTreeViewDto[];
      return result;
    });
  };

  public static RoleApplicableForLowSecurity = async (
    roleid: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.roleid = roleid;
    const url = `/MenuMaster/RoleApplicableForLowSecurity`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IRolePermissionResponseTreeViewDto[];
      return result;
    });
  };

   public static saveNewRolePermissioin = async (
    request: INewRolePermissionDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/MenuMaster/SaveNewRolePermission`;
    return Instense.post(url, request).then((response) => {
      let result = response.data as any;

      return result;
    });
  };

  public static updateExistingRolePermissioin = async (
    request: INewRolePermissionDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    // let roleid = storage === null ? 0 : (localStorage.rolId as any);
    request.TenantID = tenantID;
    //request.RoleID = Number(roleid);
    const url = `/MenuMaster/UpdateRolePermissionTreeViewData`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static deleteWithNewAssignRolePermissioin = async (
    request: IDeleteRolePermissionDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/MenuMaster/ReassignAndDeleteRole`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;

      return result;
    });
  };

  public static deleteRolePermissioin = async (
    request: IDeleteRolePermissionDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/MenuMaster/DeleteRole`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static UpdateRoleOrder = async (
    request: IUpdateRoleOrderDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/MenuMaster/UpdateRoleOrder`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static GetUserRoleList = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/MenuMaster/GetUserRoleList`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static GetCountHCovered = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/MenuMaster/GetCountHCovered`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any[];
      return result;
    });
  };
  public static GetPasswordAssistanceRole = async (
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    const url = `/MenuMaster/GetPasswordAssistanceRole`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any[];
      return result;
    });
  };

  public static DisableLowSecurityRole = async (): Promise<any[] | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      let request = {} as any;
      request.tenantID = tenantID;
      const url = `/MenuMaster/DisableLowSecurityRole`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data.result as any[];
        return result;
      });
  };

  public static GetFeatureFlagStatus = async (): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    const url = `/Global/GetFeatureFlagStatus`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any[];
      return result;
    });
};

  public static SavePasswordAssistanceRole = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/MenuMaster/SavePasswordAssistanceRole`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  
}

//GetUserRoleList
