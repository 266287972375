import axios from "axios";
import Instense from "./Axios-config";
import { API_ROOT } from "./Api-config";
 
export class ShippingService {

public static GetShipping = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Shipping/GetShipping`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
public static GetShippingById = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Shipping/GetShippingById`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
public static SaveShipping = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantID = tenantID;
  const url =`/Shipping/SaveShipping`;
  return Instense.post(url,request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static DeleteShipment = async (
  request:  any
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  request.tenantId = tenantID;
  const url = `/Shipping/DeleteShipment`;
  return Instense.delete(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
public static DownloadShippingFile = async (request: any): Promise<any | null> => {

  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.tenantId = tenantID;
  request.UserName = userName;
  const url = `/Shipping/GetShippingPDF`;
  return Instense.get(url,{params:request,  responseType: "blob", headers: {
    'Accept': '*/*',
  }, } ).then(
    (response: any) => {
      // Check if the response is successful
      if (response.status === 200) {
        
        const blob = new Blob([response.data], { type: 'application/pdf' });
        // Open the PDF in a new browser tab
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      } 
    }
  );
};
public static PrintLabel = async (request: any): Promise<any | null> => {

  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.tenantId = tenantID;
  request.UserName = userName;
  const url = `/Shipping/PrintLabel`;
  return Instense.get(url,{params:request,  responseType: "blob", headers: {
    'Accept': '*/*',
  }, } ).then(
    (response: any) => {
      // Check if the response is successful
      if (response.status === 200) {
        
        const blob = new Blob([response.data], { type: 'application/pdf' });
        // Open the PDF in a new browser tab
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      } 
    }
  );
};
public static GetShippedJobs = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantId = tenantID;
  const url = `/Shipping/GetShippedJobs`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetCourierList = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantId = tenantID;
  const url = `/Shipping/GetCourierList`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};





 
   



}