import axios from "axios";
import Instense from "./Axios-config";
import { API_ROOT } from "./Api-config";
 import { Utils } from "../Utilis";
export class JobTrackerServices {

 

public static SaveJobTracker = async (
  request: any
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  request.tenantid=tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/JobTracker/SaveJobTracker`;
  return Instense.post(url, request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
public static UpdateJobTracker = async (
  request: any
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  request.tenantid=tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/JobTracker/UpdateJobTracker`;
  return Instense.post(url, request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

 
// public static SaveJobTrackerComment = async (
//   request: any[]
// ): Promise<any | null> => {
//   let storage = JSON.parse(localStorage.getItem("storage")!);
//   let tenantID = storage === null ? 0 : storage.tenantID as any;
//   const headers = {
//     Authorization: `Bearer ${Utils.GetUserToken()}` 
//   };
//   const url = `/JobTracker/SaveJobTrackerComment`;
//   return Instense.post(url,  { params: request,headers  }).then((response) => {
//     let result = response.data.result as any;
//     return result;
//   });
// };

public static SaveJobTrackerComment = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
   
  request.userName = userName;
  request.tenantid = tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/JobTracker/SaveJobTrackerComment`;
  return Instense.post(url,   request ).then((response) => {
   
    let result = response.data.result as any;
    return result;
  });
};

public static GetJobTacker = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  
  request.tenantid = tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/JobTracker/GetJobTracker`;
  return Instense.get(url, { params: request,headers  }).then((response) => {
   
    let result = response.data.result as any;
    return result;
  });
};

public static GetTrackerlist = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  request.tenantid = tenantID;
  const url = `/JobTracker/GetTrackerlist`;
  return Instense.get(url, { params: request,headers  }).then((response) => {
   
    let result = response.data.result as any;
    return result;
  });
};

public static GetTrackerlistAll = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  request.tenantid = tenantID;
  const url = `/JobTracker/GetTrackerlistAll`;
  return Instense.get(url, { params: request,headers  }).then((response) => {
   
    let result = response.data.result as any;
    return result;
  });
};

public static GetTrackerComplete = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  request.tenantid = tenantID;
  const url = `/JobTracker/GetTrackercomplete`;
  return Instense.get(url, { params: request,headers  }).then((response) => {
   
    let result = response.data.result as any;
    return result;
  });
};
public static GetTrackerComment = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  request.tenantid = tenantID;
  const url = `/JobTracker/GetTrackerComment`;
  return Instense.get(url,  { params: request,headers  }).then((response) => {
   
    let result = response.data.result as any;
    return result;
  });
};

public static GetNCRJobData = async (request: any): Promise<any | null> => {  
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
    
  request.tenantId = tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/JobTracker/GetNCRJobData`;
  return Instense.get(url, { params: request,headers }).then((response) => {
   
    let result = response.data.result as any;
    
    return result;
  });
};

public static GetNonNCRJobs = async (request: any): Promise<any | null> => {  
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
    
  request.tenantId = tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/JobTracker/GetNonNCRJobs`;
  return Instense.get(url, { params: request,headers }).then((response) => {
   
    let result = response.data.result as any;
    
    return result;
  });
};
public static GetNonNCRPO = async (request: any): Promise<any | null> => {  
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
    
  request.tenantId = tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/JobTracker/GetNonNCRPO`;
  return Instense.get(url, { params: request,headers }).then((response) => {
   
    let result = response.data.result as any;
    
    return result;
  });
};

public static GetNCRCodes = async (request: any): Promise<any | null> => {  
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
    
  request.tenantId = tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/JobTracker/GetNCRCodes`;
  return Instense.get(url, { params: request,headers }).then((response) => {
   
    let result = response.data.result as any;
    
    return result;
  });
};

public static SaveNCRCodes = async (request: any): Promise<any> => {

 let storage = JSON.parse(localStorage.getItem("storage")!);
 let tenantID = storage === null ? 0 : (storage.tenantID as any);
 let userName = storage === null ? "" : (storage.userName as any);
 request.tenantid = tenantID;
 const url = `/JobTracker/UpdateNCRCodeMasters `;
 return Instense.post(url,    request  ).then((response) => {
   // debugger;
     let result = response.data.result as any[];
     return result;
 });
};


public static GetNCRCodeById = async (request: any): Promise<any | null> => {  
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
    
  request.tenantId = tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/JobTracker/GetNCRCodeById`;
  return Instense.get(url, { params: request,headers }).then((response) => {
   
    let result = response.data.result as any;
    
    return result;
  });
};

public static SaveNCRCode = async (
  request: any
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  request.tenantid=tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/JobTracker/SaveNCRCode`;
  return Instense.post(url, request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetNCRJobById = async (request: any): Promise<any | null> => {  
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
    
  request.tenantId = tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/JobTracker/GetNCRJobById`;
  return Instense.get(url, { params: request,headers }).then((response) => {
   
    let result = response.data.result as any;
    
    return result;
  });
};

public static Savefiles = async (
  request: any, files: any[]
): Promise<any | null> => {


  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  request.tenantid = tenantID;




  const url = `/JobTracker/SaveFile `;
  const formData: FormData = new FormData();
  files.forEach(element => {
    formData.append('file', element);
  });

  formData.append('formField', JSON.stringify(request));
  return Instense.post(url, formData).then((response) => {
    let result = response.data.result as any;
    return result;
  });



};

public static DownloadFile = async (
  request: any
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantId = tenantID;

  const url = `/JobTracker/DownloadFile`;
  return Instense.post(url, request, { responseType: "blob" }).then(
    (response: any) => {

      let csvURL = window.URL.createObjectURL(response.data);
      let tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", request.name);
      tempLink.click();
    }
  );
};


public static DeleteUploadedFile = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
  // debugger

  request.tenantID = tenantID;
  const url = `/JobTracker/DeleteUploadedFile`;
  return Instense.post(url, request).then((response) => {
    let result = response.data.result as any;
    return result;
  });

};

public static SaveNCR = async (
  request: any
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  request.tenantid=tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/JobTracker/SaveNCR`;
  return Instense.post(url, request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static DownloadNCRFile = async (
  request: any
): Promise<any | null> => {

  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.tenantid = tenantID;
  request.UserName = userName;
  const url = `/JobTracker/GetNCRPDF`;
  return Instense.get(url,{params:request,  responseType: "blob", headers: {
    'Accept': '*/*',
  }, } ).then(
    (response: any) => {
      // Check if the response is successful
      if (response.status === 200) {
        
        const blob = new Blob([response.data], { type: 'application/pdf' });
        // Open the PDF in a new browser tab
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      } 
    }
  );
};


}