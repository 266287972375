import React from "react";
import {
  Button,
  ProgressBar,
  OverlayTrigger,
  Tooltip,
  Form,
  Modal
} from "react-bootstrap";


//import { Container, Dropdown, Accordion, Card, Button ,Form,Spinner,} from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
 
import _ from "lodash";
import "../../Modules/customer.scss";
import { FiChevronDown } from "react-icons/fi";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
 
import { resolve, reject } from "q";
import { Utils } from "../Utilis";

import DatePicker from "react-datepicker";
let isValidInput: any = "";
export class DateFilter extends React.Component<any, any> {
  
  private trDate: any;
  constructor(props: any) {
    super(props);
     
    this.trDate = React.createRef();
    this.state = {
      steps:this.props.steps,
      currentStep:this.props.currentStep,
    };
  }

 

  componentDidMount() {
 
  }

  
  componentWillUnmount() {
     
  }

  handleNext = () => {
    if (this.state.currentStep < this.state.steps) {
      this.setState({currentStep:(this.state.currentStep + 1)});
    }
  };

  handleStep = (step:any) => {
      this.setState({currentStep:step});
      this.props.setCurrentStep(step);
  };

  handlePrev = () => {
    if (this.state.currentStep > 1) {
      this.setState({currentStep:(this.state.currentStep -1)});
    }
  };

 

  render() {
    const progressBarStyle: React.CSSProperties = {
      position: 'relative',
      width: '80%',
      marginBottom: '20px',
      marginTop: '15px', 
      marginLeft:'30px',
      marginRight:'20px',
    };
  
    const stepNumberStyle1: React.CSSProperties = {
      position: 'absolute',
      top: '-14px',
       left: `${((1 - 1) / (this.state.steps - 1)) * 100 + 4}%`,
      transform: 'translateX(-50%)',
      background: '#6cb86c',
      color: '#fff',
      padding: '5px',
      minWidth: '100px',width:'100px'
    };
  

    return (
      <div className="row g-3">
 
     
      <div style={progressBarStyle}>
      </div>

 <div >

<Form.Group controlId="date">
  <div className="date-picker calender-picker">
    <DatePicker
      ref={this.trDate}
      id="txtFrom"
      tabIndex={this.state.tabEnable}
      className="form-control px-0 w-75 text-center"
      placeholderText={"From"}
      dateFormat="MM/dd/yy"
      selected={
        (this.props.fromDate === null || this.props.fromDate === undefined)
          ? null
          : this.props.fromDate === ""
            ? null
            : new Date(this.props.fromDate)
      }
      onChange={(event: any) =>this.props.handleChange(event, "From") }
      //onClick={(e: any) =>this.onTAB("txtOrderDate-1", 0, e)}
      autoComplete="off"
      disabled={false}
    />
  </div>


</Form.Group>

                             </div>

                             <div >

<Form.Group controlId="date">


  <div className="date-picker calender-picker">
    <DatePicker
      ref={this.trDate}
      id="txtTo"
      tabIndex={this.state.tabEnable}
      className="form-control px-0 w-75 text-center"
      placeholderText={"To"}
      dateFormat="MM/dd/yy"
      selected={
        (this.props.toDate === null || this.props.toDate === undefined)
          ? null
          : this.props.toDate === ""
            ? null
            : new Date(this.props.toDate)
      }
      onChange={(event: any) =>this.props.handleChange(event, "To") }
      //onClick={(e: any) =>this.onTAB("txtOrderDate-1", 0, e)}
      autoComplete="off"
      disabled={false}
    />

<Button variant="primary"   className="btn btn-primary px-4" onClick={()=>this.props.applySearch()}> Apply</Button>
       
  </div>
</Form.Group>

                             </div>
      </div>
     
      
    );
  }


 
}
