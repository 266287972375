import * as React from "react";
import { Container, Dropdown, Accordion, Form, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { ProductService } from "../../Common/Services/ProductService";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { ProductSlideout } from './ProductSlideout';
import _ from "lodash";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
const { SearchBar } = Search;


  
export class ProductMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
         
          htmlItem: [],
          htmlErrorItem: [],
          htmlWarningItem: [],
          searcResult: [],
          productid:0,
          selectedcolumn: "All",
          selectOptions: [
            { id: 'All', name: 'All'},
            { id: 'Part Name', name: 'Part Name' },
            { id: 'Part No', name: 'Part No' },
            { id: 'Customer Name', name: 'Customer Name' },
            { id: 'Unit', name: 'Unit' },
            { id: 'Unit Price', name: 'Unit Price' },
          ],
        };
        
     
      }
      componentDidMount() {
       
        this.getProductMaster();
      }

      handleSelectedItem1 = (control: any, id: any) => {
        let searcResult1 = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.searcResult))
        );
    
        let chkispartname = id == "Part Name" ? true : false;
        let chkispartno = id == "Part No" ? true : false;
        let chkiscustomername = id == "Customer Name" ? true : false;
        let chkisunit = id == "Unit" ? true : false;
        let chkisunitprice = id == "Unit Price" ? true : false;
        
    
        if (id == "All") {
          chkispartname = true;
          chkispartno = true;
          chkiscustomername = true;
          chkisunit = true;
          chkisunitprice = true;
    
        }
    
    
        this.setState({
          searcResult: [], selectedcolumn: id, ispartname: chkispartname, ispartno: chkispartno,
          iscompanyname: chkiscustomername, isunit: chkisunit,isunitprice:chkisunitprice
        },
          () => {
            this.setState({ searcResult: searcResult1 });    
          }
    
    
        );
    
      };

 
      getProductMaster = () => {
 
        let requestlist = { ...this.state.requestlist };
        ProductService.GetProductOrderlist(requestlist)
    .then(async (result: any[] | null) => {
        
      if (result != null) {
        
        this.setState({ searcResult: result }, () => {
           
        });
      }
      resolve();
    })
    .catch((err: any) => {
      toast.error(`Server Error, ${err}`);
      reject();
    });
  };

  shownewslideout = () => {
    this.setState({ showSlideout: true, productid: 0 });
  };

  closeSlideOut = () => {
    this.setState({ showSlideout: false });
    this.getProductMaster();
  };
  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.setState({
        showSlideout: true, productid: row.id
      });


    },
  };
  showNewSlideOut = () => {
    this.setState({ showSlideout: true ,productid:0,isSpinner:false});  
  };


  render() {
    const {

      showSlideout,

    } = this.state; 

    const columns = [
      // {
      //   dataField: 'id',
      //   text: 'Product ID',
      // },
     
      {
        searchable: this.state.ispartname,
        dataField: 'partname',
        text: 'Part Name',
        sort: true,
      },
      {
        
        searchable: this.state.ispartno,
        dataField: 'partno',
        text: 'Part No',
        sort: true,
      },
      {
        
        searchable: this.state.iscompanyname,
        dataField: 'customername',
        text: 'Customer Name',
        sort: true,
      },
      {
        searchable: this.state.isunit,
        dataField: 'unit',
        text: 'Unit',
      },
      {
        
        searchable: this.state.isunitprice,
        dataField: 'unitPrice',
        text: 'Unit Price',
      },
    ];

    return (
      <>
        <React.Fragment>



          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex  ">

            Part Master
              <div className="action-group d-flex flex-row ml-auto">
              
                <Button onClick={this.showNewSlideOut}>Add Part</Button>
              </div>
            </div>
            <section className="mt-3">
            <ToolkitProvider
        keyField="id"
        data={this.state.searcResult}
        columns={columns}

         //className="table table-striped table-borderless align-middle"
        search
      >
        {
          props => (


            <div >
              <section className="d-flex flex-wrap gap-3 pb-2">
                <div className="position-relative">
                  <SearchBar {...props.searchProps} />
                </div>

                <div>
                  <Form.Label>
                    <SingleDropdownListWithoutSearch
                      
                      itemList={this.state.selectOptions}
                      handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                      defaultItem={this.state.selectedcolumn}
                      defaultText={"Select Filter"}
                      defaultName={this.state.selectedcolumn}
                      controlID="1"
                      id={"ddlselected"}

                    /></Form.Label>
                </div>

              </section>
              <section className="pb-3">
                <div className="table-responsive divbottom"  >
                  <div className="ml-auto transactions-list">
                  <BootstrapTable
                    {...props.baseProps}
                    rowEvents={this.rowEvents}
                    keyField="rowId"
                  

                  />
                  </div>
                </div>
            

              </section>
            </div>

          )
        }
 </ToolkitProvider>
</section>


 {(showSlideout &&
                <ProductSlideout
                  closeSlideOut={this.closeSlideOut}
                  productid={this.state.productid}
                >


                </ProductSlideout>
              )}





          </Container>



        </React.Fragment>
      </>
    );
  }
}