import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { HeaderMenu } from "../../Common/Services/HeaderMenu";
import { resolve, reject } from "q";
import { Player } from "video-react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Chart from "react-apexcharts";
import {
  RoleSubPermissionSetting,
  IPageSubPermissionRole,
} from "../../Common/Services/RoleSubPermissionSetting";
 
import { JobServices } from "../../Common/Services/JobServices";
import { DashboardServices } from "../../Common/Services/DashboardServices";
 
import _ from "lodash";
 
import createDOMPurify from "dompurify";
import { Utils } from "../../Common/Utilis";
import moment from "moment";
 
const DOMPurify = createDOMPurify(window);

export class Home extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      Partorders:0,
      JobCreated:0,
      vendorpendingorders:0,
      vendorsent:0,
      vendorreceived:0,
      customerOrderData:[],
      options: {
        chart: {
          id: "basic-bar"
        },
        xaxis: {
          categories: ["Order Booked", "Job Created", "Vendor  Order Pending", "Vendor  Order Sent", "Vendor  Order Received"]
        }
      },
      series: [
        {
          name: "No. of Count",
          data: []
        }
      ]
    ,

    options1: {
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: ["Total Job", "Job Assinged", "Job Running", "Job Completed"]
      }
    },
    series1: [
      {
        name: "No. of Count",
        data: [20,15,10,85]
      }
    ],

    customerOrderOptions: { chart: {
      width: 340,
      type: 'pie',
    },
    labels: ['Active', 'Ready for Shippping', 'Shipped'],
    responsive: [{
      breakpoint: 440,
      options: {
        chart: {
          width: 180
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  },
    customerOrderSeries: [44, 55, 41],
    
    jobOptions: { chart: {
      width: 340,
      type: 'pie',
    },
    labels: ['Created', 'Running', 'Paused','NCR'],
    responsive: [{
      breakpoint: 440,
      options: {
        chart: {
          width: 180
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  },
    jobSeries: [44, 55, 41,2 ],

    vendorOptions: { chart: {
      width: 340,
      type: 'pie',
    },
    labels: ['Created', 'Sent', 'Received'],
    responsive: [{
      breakpoint: 440,
      options: {
        chart: {
          width: 180
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  },
    vendorSeries: [44, 55, 41],


    workloadSeries: [{
      data: []
    }],
    workloadOptions: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [
        ],
      }



    }
  };
    this.getdashboardItems=this.getdashboardItems.bind(this);
 

    
  }

  componentDidMount() {
    
 
    this.getdashboardItems();
    this.getCustomerDashboardItems();
    this.getEmpDashboardItems();
     
  }

  getdashboardItems()
  {
    
    let requestlist = { ...this.state.requestlist };
    JobServices.GetDashboardData(requestlist)
    .then(async (result: any[] | null) => {
      if (result != null) {
         console.log(result);

      

       let Partorders= result.length > 0 ? result[0].partorders:0;
       let JobCreated= result.length > 0 ?result[0].jobCreated:0;
       let vendorpendingorders= result.length > 0 ? result[0].vendorpendingorders:0;
       let vendorsent= result.length > 0 ? result[0].vendorsent : 0;
       let vendorreceived= result.length > 0 ? result[0].vendorreceived : 0;
       let running= result.length > 0 ? result[0].running : 0;
       let ncr= result.length > 0 ? result[0].ncr : 0;
       let pause= result.length > 0 ? result[0].pause : 0;
       
       
      
        this.setState({ 
          vendorSeries:[vendorpendingorders,vendorsent,vendorreceived],
          jobSeries:[JobCreated,running,pause,ncr],
          series: [
            {
              name: "No. of count",
              data: [Partorders,JobCreated,vendorpendingorders,vendorsent,vendorreceived]
            }
          ],
          
          Partorders: Partorders,JobCreated:JobCreated,vendorpendingorders:vendorpendingorders,vendorsent:vendorsent,vendorreceived:vendorreceived ,running:running}, () => {
           
        });
      }
      resolve();
    })
    .catch((err: any) => {
      toast.error(`Server Error, ${err}`);
      reject();
    });
  }

  getCustomerDashboardItems()
  {
    
    let requestlist = { ...this.state.requestlist };
    DashboardServices.GetCustomerDashboard(requestlist)
    .then(async (result: any[] | null) => {
      if (result != null) {
         console.log(result);
      
        this.setState({ 
          customerOrderData: result,
          customerOrderSeries: [(result[0].partOrders - result[0].readyToShip - result[0].shipped), result[0].readyToShip, result[0].shipped ]
        
        }, () => {
           
        });
      }
      resolve();
    })
    .catch((err: any) => {
      toast.error(`Server Error, ${err}`);
      reject();
    });
  }

  getEmpDashboardItems()
  {
   
    let requestlist = { ...this.state.requestlist };
    DashboardServices.GetEmpDashboard(requestlist)
    .then(async (result: any[] | null) => {
      if (result != null) {
         console.log(result);

        let workloadOptions = {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              borderRadiusApplication: 'end',
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: result.map(x=>x.employee),
          }
    
        };

        this.setState({ 
          workloadOptions: workloadOptions,
          workloadSeries: [{
            data: result.map(x=>x.assignedJob)
          }]
          
        }, () => {
           
        });
      }
      resolve();
    })
    .catch((err: any) => {
      toast.error(`Server Error, ${err}`);
      reject();
    });
  }

  


  redirectToResetPassword = () => {
    ;
    this.setState({ isChangePwd: true });
    var btnProfile = $("[id*=defaultSet-Profile]");
    btnProfile[0].click();

    setTimeout(() => {
      var btnChangePassword = $("[id*=Change-Password]");
      btnChangePassword[0].click();
    }, 500);

    // btnChangePassword[0].className ="nav-link active";
    //localStorage.setItem("subMenu", "Change Password");
  };



  render() { 

    const popUpState = localStorage?.getItem("PopUpState")? JSON.parse(localStorage?.getItem("PopUpState") || "") : {};
  
  

    if (this.state.isChangePwd) {
      return <Redirect to="/change-password" />;
    }

    //let html ='<li className =''>      </li>'

    return (
<>
 

 
          <Container fluid className="body-sec"
           style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
          >
          <div  className="table-responsive">

          <section className="pb-3">
                  <div className="row g-3">
                  <div className="col-12 col-md-4" >
                    <div className="dashboard-text">
                    <h4> { this.state.customerOrderData.length > 0 ? this.state.customerOrderData[0].partOrders - this.state.customerOrderData[0].readyToShip - this.state.customerOrderData[0].shipped : 0} </h4>
           <h5> Customer Orders </h5>
       </div>
                    </div>

                    <div className="col-12 col-md-4" >
                    <div className="dashboard-text">
           
                    <h4> {this.state.running} </h4>
                    <h5> Active Jobs </h5>

                     </div>

                    </div>
                    <div className="col-12 col-md-4" >
                    <div className="dashboard-text" >
           
                    <h4> {this.state.vendorsent} </h4>
                    <h5> Vendor Requests </h5>

       </div>
                    </div>
                   
                  </div>
                </section>

          <section className="pb-3">
                  <div className="row g-3">
                  <div className="col-12 col-md-4" >
                    <div className="mixed-chart">
           
           <Chart 
           options={this.state.customerOrderOptions} 
           series={this.state.customerOrderSeries} 
           type="donut" width="380" />

       </div>
                    </div>

                    <div className="col-12 col-md-4" >
                    <div className="mixed-chart">
           
           <Chart 
           options={this.state.jobOptions} 
           series={this.state.jobSeries} 
           type="donut" width="330" />

                     </div>

                    </div>
                    <div className="col-12 col-md-4" >
                    <div className="mixed-chart">
           
           <Chart 
           options={this.state.vendorOptions} 
           series={this.state.vendorSeries} 
           type="donut" width="330" />

       </div>
                    </div>
                   
                  </div>
                </section>

                
          <section className="pb-3">
                  <div className="row g-3">
                  <div className="col-12 col-md-12" >
                    <div className="mixed-chart">
           
                    <Chart options={this.state.workloadOptions} 
                    series={this.state.workloadSeries} type="bar" height={350} />
            

       </div>
                    </div>

                
                   
                  </div>
                </section>

          <div className="filter-sec" >
                {/* <div className="transactions-list inpute" style={{ paddingTop: "8%" }}>

                  <div className="columns-3">
                    <div className="page-headingwithborder" > {this.state.Partorders} <br></br>    Order Booked</div>
                    <div className="page-headingwithborder">{this.state.JobCreated}<br></br> Job Created</div>
                    <div className="page-headingwithborder"> {this.state.vendorpendingorders} <br></br>  Vendor  Order Pending</div>
                    <div className="page-headingwithborder"> {this.state.vendorsent} <br></br>  Vendor  Order Sent</div>
                    <div className="page-headingwithborder"> {this.state.vendorreceived} <br></br>  Vendor  Order Received</div>
                  </div>
                  </div> */}

                  <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              width="500"
            />
          </div>
         {false && <div className="mixed-chart">
            <Chart
              options={this.state.options1}
              series={this.state.series1}
              type="bar"
              width="500"
            />
          </div>}

  
        

      

     

      
                  </div>

               

                  </div>
          </Container>
       
 
</>
    );
  }
}
