import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button,Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {TrackerSlideout}  from './TrackerSlideout';
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import {Customerorderslideout}  from '../Customerorderslideout';
import {Utils}  from "../../Common/Utilis";
import _ from "lodash";
import paginationFactory from 'react-bootstrap-table2-paginator';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import createDOMPurify from "dompurify";
 
import moment from "moment";
import { JobOrderService } from "../../Common/Services/JobOrderService";
import {ProcessJob}  from './ProcessJob';

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
import {Vendororderslideout}  from '../Vendororderslideout';
import {VendorOrder}  from '../Purchasing/VendorOrder';

const { SearchBar } = Search;
import {JobCreations}  from '../JobCreations';


const DOMPurify = createDOMPurify(window);

export class JobOrder extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
     
      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      searcResult: [],
      orderid:0,
      jobid:0,
      customername:'',
      customerpo:'',
      orderdate:null,
      jobno: '',
      partno: '',
      partname: '',
      jobNo:'',
      duedate:null,
      qty:0,
      jobAssignedId:0,
      assignedTo:'',
      selectedcolumn:"All",
      isassignedTo :true,
      isdueDate:true,
      ismaterialStatus:true,
      isjobNo:true,
      selectOptions :  [ {
       id: 'All',name: 'All'},
       { id: 'Job #',name: 'Job No.'},
       {id: 'Material Status',name:  'Material Status'},
       {id: 'Due Date',name:  'Due Date'},
       { id: 'Order Date',name:  'Assigned To'},
     
      
    ],

    };
    
 
  }
 

  componentDidMount() { 
  
   this.getJobItems();
  }

  getJobItems()
  {
    
    let requestlist = { ...this.state.requestlist };
    JobOrderService.GetJobOrderList(requestlist)
    .then(async (result: any[] | null) => {
      if (result != null) {
        // console.log(result);
        result.forEach(element => {
          if(element.manualTracking)
          {
            element.status = element.manualStatus == 0 ?'Ready to Ship':'Completed';
          }
          else if(element.runningSteps > 0)
          {
            element.status = 'Running';
          }
          else if (element.runningSteps == 0 && element.completedSteps > 0)
          {
            element.status = 'Completed';
          }
          else 
            element.status = 'Not Started';
          
            element.stepAssignment = element.totalSteps - element.stepsAssigned == 0 &&  element.totalSteps > 0? 'Completed':  !element.manualTracking ?'Pending':'NA';
            
            
        });
        
        this.setState({ searcResult: result }, () => {
           
        });
      }
      resolve();
    })
    .catch((err: any) => {
      toast.error(`Server Error, ${err}`);
      reject();
    });
  }

  hideVendorOrder = () => {
    this.setState({ showVendorOrder: false });
    this.getJobItems();
  };

  shownewslideout   = () => {
    this.setState({ showSlideout: true ,orderid:0,jobid:0, customername:'',customerpo:'',orderdate:null, jobno:'',partno:'',partname:'',duedate:null,qty:0, isSpinner:false,});
  };


  showslideout = () => {
    this.setState({ showSlideout: true , isSpinner:false, });

  };

  closeSlideOut = () => {
    this.setState({ showSlideout: false,showVendorSlideout:false, isSpinner:false,showTrackerSlideout:false });
    this.getJobItems();
};
 rowEvents = {
  onClick: (e: any, row: any, rowIndex: any) => {

    this.setState({ showSlideout: true ,orderid:row.orderId,jobid:row.jobId, customername:row.customerName,
      customerpo:row.customerPoNumber, poNumber: row.poNumber, orderdate: row.orderDate,jobno: row.jobNo,partno: row.partNo,
      partname: row.partName,qty: row.qty,duedate: row.dueDate, isSpinner:false,
    });  
    
  },
};

handleSelectedItem1 = (control: any, id: any) => {
  let searcResult1 = Object.assign(
    [],
    JSON.parse(JSON.stringify(this.state.searcResult))
  );

  let isassignedTo = id == "Assigned To" ? true : false;
  let isdueDate = id == "Due Date" ? true : false;
  let ismaterialStatus = id == "Material Status" ? true : false;
  let isjobNo = id == "Job #" ? true : false;


  if (id == "All") {
    isassignedTo = true;
    isdueDate = true;
    ismaterialStatus = true;
    isjobNo = true;
   

  }


  this.setState({
    searcResult: [], selectedcolumn: id, isassignedTo: isassignedTo, isdueDate: isdueDate,
    ismaterialStatus: ismaterialStatus, isjobNo: isjobNo
  },
    () => {
      this.setState({ searcResult: searcResult1 });

    }


  );

};

closeCustomerSlideout = () => {
  this.setState({ showCustomerSlideout: false, isSpinner:false, });
};



closeJobSlideout = () => {
  this.setState({ showJobSlideout: false, isSpinner:false, });
  this.getJobItems();
};

reopenSlideOutWithReworkJob = () => {
  this.setState({ showJobSlideout: false}, () => {
   
   let rNo = Utils.extractIncrementedRNumber(this.state.jobNo);
   this.setState({ showJobSlideout: true,showCustomerSlideout: false, jobid: 0,rNo:rNo});
  });
  

};
showSlideOut = (row,type) => {  
  
  if(type === 'customer'){
    this.setState({ showCustomerSlideout: true ,showJobSlideout: false,orderid: row.customerOrderId ? row.customerOrderId: row.orderId});
  }
  else if(type === 'job')
  {
   
    
    let jobNo =  Utils.removeRNumber(row.jobNo);
    let itemNo = Utils.extractNumberAfterHyphen(jobNo);
    let rNo = Utils.extractRNumber(row.jobNo);
    this.setState({ showJobSlideout: true,showCustomerSlideout: false, orderid:row.orderId, orderdetailid: row.customerOrderDetailId ? row.customerOrderDetailId:row.orderDetailId,jobid: row.jobId,qtyOrdered:row.qty,partname:row.partName,partNo:row.partNo,selectedCustomer:row.customerName,productid:row.partId,CustomerPoNumber:row.customerPoNumber,itemNo:itemNo,rNo:rNo,jobNo:row.jobNo});
  }
  
  else if(type === 'vendor')
  {
      this.setState({ showVendorOrder:false, showVendorSlideout: true,showCustomerSlideout: false,vendororderid:row.vendorOrderId,vendorordertype:'Material', orderid:0,orderdetailid: row.customerOrderDetailId ? row.customerOrderDetailId:row.orderDetailId,jobid: row.jobId,qtyOrdered:row.qty,partname:row.partName,partNo:row.partNo,selectedCustomer:row.customerName,productid:row.partId,customerpo:row.materialStatus,itemNo:row.itemno,receivedStatus:row.receivedStatus});
  }
  else if(type === 'vendorreceiving')
    {
        this.setState({ showVendorOrder:true, showVendorSlideout: false,showCustomerSlideout: false,vendororderid:row.vendorOrderId,vendorordertype:'Material', orderid:0,orderdetailid: row.customerOrderDetailId ? row.customerOrderDetailId:row.orderDetailId,jobid: row.jobId,qtyOrdered:row.qty,partname:row.partName,partNo:row.partNo,selectedCustomer:row.customerName,productid:row.partId,customerpo:row.materialStatus,itemNo:row.itemno,receivedStatus:row.receivedStatus});
    }
  else if(type==='assignedTo')
  {
    this.setState({ showSlideout: true ,orderid:row.orderId,jobid:row.jobId, customername:row.customerName,
      customerpo:row.customerPoNumber,orderdate: row.orderDate,jobAssignedId:row.jobAssignedId,assignedTo:row.assignedTo,jobno: row.jobNo,partno: row.partNo,partname: row.partName,qty: row.qty,duedate: row.dueDate, isSpinner:false,
    });  

  }
  else if(type === 'tracker')
  {
        this.setState({ showTrackerSlideout: true,vendororderid:row.vendorOrderId,orderid:0,orderdetailid: row.customerOrderDetailId ? row.customerOrderDetailId:row.orderDetailId,jobid: row.jobId,qtyOrdered:row.qty,partname:row.partName,partNo:row.partNo,selectedCustomer:row.customerName,productid:row.partId,customerpo:row.materialStatus,itemNo:row.itemno,orderdate: row.orderDate,jobno:row.jobNo});
  }
   
  
};


  render() {
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {

      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      },
      {
        text: '25', value: 25
      },
      {
        text: '50', value: 50
      },

      {
        text: 'All', value: this.state.searcResult.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };


    const columns = [
  
      {
        dataField: 'jobNo',
        text: 'Job No.',
        sort: true,
        headerStyle: { width: '200px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             <Button className="btn-link" onClick={() => this.showSlideOut(row,'job')}>
             {row.jobNo}
             </Button>
             </EllipsisWithTooltip>
          );
        },
        searchable:this.state.isjobNo,
      },
      {
        dataField: 'dueDate',
        text: 'Due Date',
        sort: true,
        headerStyle: { width: '200px' },
        searchable:this.state.isdueDate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
              <EllipsisWithTooltip placement="bottom">
                  {moment(row.dueDate).format("MM/DD/YYYY")}
              </EllipsisWithTooltip>
          );
      },
      },
      {
        dataField: 'stepAssignment',
        text: 'Step Assignment',
        sort: true,
        headerStyle: { width: '200px' },
        //searchable:this.state.isdueDate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            
            <>
            {!row.manualTracking &&
            <EllipsisWithTooltip placement="bottom">
              
                  <Button className="btn-link" onClick={() => this.showSlideOut(row,'assignedTo')}>
                  <span>{row.totalSteps - row.stepsAssigned == 0 && row.totalSteps > 0 ? 'Completed': !row.manualTracking ?'Pending':''}</span>
              
                  </Button>

                  {!row.manualTracking && (row.totalSteps > row.stepsAssigned || !(row.totalSteps - row.stepsAssigned == 0 && row.totalSteps > 0))   && <span className="badge badge-pill badge-primary color-red"> ({row.stepsAssigned} / {row.totalSteps}) </span>}


              </EllipsisWithTooltip>
        }
         <EllipsisWithTooltip placement="bottom">
        {row.manualTracking && "N/A" }
        </EllipsisWithTooltip>
              </>

          );
      },
      },
      {
        dataField: 'stepsAssigned',
        text: 'Steps Assigned',
        sort: true,
        hidden: true,
        headerStyle: { width: '50px' },
        headerClasses: 'amount',
        classes: 'text-end pe-4',
      },
      {
        dataField: 'totalSteps',
        text: 'Total Steps',
        sort: true,
        hidden: true,
        headerStyle: { width: '50px'},
        headerClasses: 'amount',
        classes: 'text-end pe-4',
      },
  
      {
        dataField: 'receivedStatus',
        text: 'Material Status',
        sort: true,
        headerStyle: { width: '250px' },
        searchable:this.state.ismaterialStatus,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
{row.materialStatus=="" ||  row.materialStatus==null ||  row.materialStatus==undefined  ? 
              "PO not created yet"  :

             <Button className="btn-link" onClick={() => this.showSlideOut(row, String(row.receivedStatus).toLowerCase() == 'waiting' ? 'vendorreceiving' : 'vendor')}>
             { row.materialStatus}
             </Button>
        }
             

             {row.materialStatus != '' && row.materialStatus != null && String(row.receivedStatus).toLowerCase() == 'waiting' &&
                    <span className="badge badge-pill badge-primary color-red"> {row.receivedStatus} </span>}

                    
             {row.materialStatus != '' && row.materialStatus != null && String(row.receivedStatus).toLowerCase() == 'received' &&
                    <span className="badge badge-pill badge-primary color-green"> {row.receivedStatus} </span>}
             </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: 'runningSteps',
        text: 'Steps Running',
        sort: true,
        hidden: true,
        headerStyle: { minWidth: '50px' },
        headerClasses: 'amount',
        classes: 'text-end pe-4',
      },
      {
        dataField: 'completedSteps',
        text: 'Steps Completed',
        sort: true,
        hidden: true,
        headerStyle: { minWidth: '50px' },
        headerClasses: 'amount',
        classes: 'text-end pe-4',
      },
      {
        dataField: 'manualStatus',
        text: 'Manual Status',
        sort: true,
        hidden: true,
        headerStyle: { minWidth: '50px' },
        headerClasses: 'amount',
        classes: 'text-end pe-4',
      },     
      {
        dataField: 'manualTracking',
        text: 'Type',
        sort: true,
        headerStyle: { minWidth: '100px' },
        searchable:this.state.isassignedTo,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
              {!row.manualTracking &&<Button className="btn-link" onClick={() => this.showSlideOut(row,'tracker')}>Tracked</Button>}
              {row.manualTracking && <span>Manual</span>}
             </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        headerStyle: { width: '100px' },
        //searchable:this.state.isdueDate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
              <EllipsisWithTooltip placement="bottom">
                  { row.status == 'Running' || row.status == 'Ready to Ship' ? <span className="badge badge-pill badge-primary color-orange"> {row.status} </span> : 
                  row.status == 'Completed' ? <span className="badge badge-pill badge-primary color-green">Completed</span> : <span className="badge badge-pill badge-primary color-red">Not Started</span>}
               
              </EllipsisWithTooltip>
          );
      },
      },
    
      {
        dataField: 'assignedTo',
        text: 'Owner',
        sort: true,
        hidden: true,
        headerStyle: { minWidth: '100px' },
        searchable:this.state.isassignedTo,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             {row.assignedTo}
             </EllipsisWithTooltip>
          );
        },
      },
    
      {
        dataField: 'assignment',
        text: 'Assignment',
        sort: true,
        hidden: true,
        headerStyle: { minWidth: '100px' },
        searchable:this.state.isassignedTo,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             <Button className="btn-link" onClick={() => this.showSlideOut(row,'assignedTo')}>
             {row.assignment}
             </Button>
             </EllipsisWithTooltip>
          );
        },
      },
     
    ];
    
    
        const {
          
          showSlideout,
          showVendorSlideout,
          showTrackerSlideout,
          showVendorOrder
          
      } = this.state;

    return (
      <>
        <React.Fragment>



          <Container fluid className="body-sec">
            {!showVendorOrder && (
              <>
            <div className="page-heading underline d-flex  ">

            Job Order
              <div className="action-group d-flex flex-row ml-auto">
                {/* <Button onClick={this.shownewslideout}>Add Customer Order</Button> */}
              </div>
            </div>

           

              <section className="mt-3">
               
                  <ToolkitProvider
                    keyField="id"
                    data={ this.state.searcResult }
                    columns={ columns }
                     
                    search
                  >
                    {
                      props => (


                        <div >
                          <section className="d-flex flex-wrap gap-3 pb-2">
                            <div className="position-relative">
                              <SearchBar {...props.searchProps} />
                            </div>

                            <div
                            //className="input-group w-auto"
                            >
                              <Form.Label>
                                <SingleDropdownListWithoutSearch

                                  itemList={this.state.selectOptions}
                                  handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                  defaultItem={this.state.selectedcolumn}
                                  defaultText={"Select Filter"}
                                  defaultName={this.state.selectedcolumn}
                                  controlID="1"
                                  id={"ddlselected"}

                                /></Form.Label>
                            </div>

                          </section>
                          <section className="pb-3">
                            <div className="table-responsive divbottom"  >
                              <div className="ml-auto transactions-list">
                                <BootstrapTable
                                  {...props.baseProps}
                                  // rowEvents={this.rowEvents}
                                   pagination={paginationFactory(options)}

                                />
                              </div>
                            </div>
                          </section>
                        </div>

                      )
                    }
                  </ToolkitProvider>

</section>
</>
)}

{(showSlideout &&

<ProcessJob
closeSlideOut={this.closeSlideOut}
orderid={this.state.orderid}
jobid = {this.state.jobid}
jobassignedid = {this.state.jobAssignedId}
assignedto = {this.state.assignedTo}
customername = {this.state.customername}
customerpo = {this.state.customerpo}
orderdate = {this.state.orderdate}
jobno = {this.state.jobno}
partno = {this.state.partno}
partname = {this.state.partname}
duedate = {this.state.duedate}
qty = {this.state.qty}

>


</ProcessJob>

              )}

{(this.state.showCustomerSlideout &&
            <Customerorderslideout
            closeSlideOut={this.closeCustomerSlideout}
            orderid={this.state.orderid}
            >
            </Customerorderslideout>
              )}

{this.state.showJobSlideout && (

<JobCreations
  closeSlideOut={this.closeJobSlideout}
  reopenSlideOutWithReworkJob = {this.reopenSlideOutWithReworkJob}
  jobid={this.state.jobid}
  orderid={this.state.orderid}
  CustomerPoNumber={this.state.CustomerPoNumber}
  poNumber={this.state.poNumber}
  productid={this.state.productid}
  partname={this.state.partname}
  partNo={this.state.partNo}
  qtyOrdered={this.state.qtyOrdered}

  itemNo={this.state.itemNo}
  selectedCustomer={this.state.selectedCustomer}
  orderdate={this.state.orderdate}
  dueDate={this.state.dueDate}
  jobDesc={this.state.jobDesc}
  id={this.state.orderid}
  rNo={this.state.rNo}

>
</JobCreations>
)}

{(showVendorSlideout &&

<Vendororderslideout
closeSlideOut={this.closeSlideOut}
orderid={this.state.orderid}
CustomerPoNumber={this.state.customerpo}
poNumber={this.state.poNumber}
productid={this.state.productid}
partname={this.state.partname}
partNo={this.state.partNo}
qtyOrdered={this.state.qtyOrdered}
jobid={this.state.jobid}
itemNo={this.state.itemNo}
vendororderid={this.state.vendororderid}
vendorordertype={this.state.vendorordertype}
selectedCustomer={this.state.selectedCustomer}
orderdate={this.state.orderdate}
dueDate={this.state.dueDate}
jobDesc={this.state.jobDesc}
id={this.state.id}
receivedStatus = {this.state.receivedStatus}
>


</Vendororderslideout>
              )}

{(showVendorOrder &&
<VendorOrder
CustomerPoNumber={this.state.customerpo}
taboption={"second"}
backToJob = { this.hideVendorOrder }
>
</VendorOrder>
              )}

{(showTrackerSlideout &&
<TrackerSlideout
closeSlideOut={this.closeSlideOut}

jobid = {this.state.jobid}
jobno={this.state.jobno}
customerpo={this.state.customerpo}
customername={this.state.selectedCustomer}
orderdate={this.state.orderdate}
partno={this.state.partNo}
partname={this.state.partname}
qty={this.state.qtyOrdered}
>


</TrackerSlideout>)}
             
          </Container>



        </React.Fragment>
      </>
    );
  }
}