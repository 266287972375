import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { Container, Dropdown, Form, Button, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { Utils } from "../../Common/Utilis";
import { VendorService } from "../../Common/Services/VendorService";
import { resolve, reject } from "q";
import "../../App.scss";

import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { FiChevronDown } from "react-icons/fi";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FaArrowUpFromBracket,
  FaCircleMinus,
  FaCirclePlus,
  FaEllipsis,
} from "react-icons/fa6";

import ScrollContainer from "react-indiana-drag-scroll";
import { CommentBox } from "../../Common/Components/CommentBox";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
import { MdOutlineContentPasteGo } from "react-icons/md";
import { ConfirmationModal } from "../../Common/Components/ConfirmationModal";

let routeLeavingGuard: any = null;
let requestInProcess = false;
export class VendorMasterSlideout extends React.Component<any, any> {
  private parentScroll: any;

  constructor(props: any) {
    super(props);
    this.parentScroll = React.createRef();
    this.state = {
      contactColumns: this.contactColumns,
      formData: {
        vendor_id: this.props.vendorid,
        company_name:this.props.customername ==undefined ? "" : this.props.customername,
        companyAlias:  this.props.companyAlias ==undefined ? "" : this.props.companyAlias,
        address:this.props.address ==undefined ? "" : this.props.address,
        apartment:this.props.apartment ==undefined ? "" : this.props.apartment,
        city: this.props.city ==undefined ? "" : this.props.city,
        states: this.props.states ==undefined ? "" : this.props.states,
        zip:  this.props.zip ==undefined ? "" : this.props.zip,
        phone: "",
        vendorcode: "",
        email: "",
        country:  this.props.country ==undefined ? "US" : this.props.country,
        customerContact: [],
        shippingZipCode:  this.props.IsActive ==undefined ? "" : this.props.IsActive==true? this.props.zip :"",
        shippingCountry:  this.props.IsActive ==undefined ? "US" :  this.props.IsActive==true?this.props.country :"",
        shippingStates:  this.props.IsActive ==undefined ? "" :this.props.IsActive==true?this.props.states :"",
        shippingCity:  this.props.IsActive ==undefined ? "" : this.props.IsActive==true?this.props.city :"",
        shippingApartment:  this.props.IsActive ==undefined ? "" : this.props.IsActive==true?this.props.apartment :"",
        shippingAddress:  this.props.IsActive ==undefined ? "" : this.props.IsActive==true?this.props.address :"",
      },
      customerContact: [
      {
        id:0,
        title: "",
        firstname: "",
        lastname: "",
        phoneno: "",
        email: "",
        isDefault:false,

      },
      ],

      newRow: {
        id: 0,
        title: "",
        firstname: "",
        lastname: "",
        phoneno: "",
        email: "",
        isDefault: false,
      },
    };
  }

  private contactColumns = [
    {
      dataField: "id",
      text: "#",
      hidden: true,
    },

    {
      dataField: "title",
      text: "Role",
      headerStyle: { width: "130px" },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txttitle" + rowIndex + "txt";

        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              placeholder=""
              value={row.title}
              onChange={(e) => this.updateState(e, rowIndex, "title", id)}
              autoComplete="off"
              title={row.title}
              maxLength={1000}
              className="form-control form-control-sm"
            />
          </div>
        );
      },
    },

    {
      dataField: "firstname",
      text: "Name",
      headerStyle: { width: "200px" },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtfirstname" + rowIndex + "txt";

        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              placeholder=""
              value={row.firstname}
              onChange={(e) => this.updateState(e, rowIndex, "firstname", id)}
              autoComplete="off"
              title={row.firstname}
              className="form-control form-control-sm"
            />
          </div>
        );
      },
    },
    {
      dataField: "lastname",
      text: "Last Name",
      headerStyle: { width: "100px" },
      hidden: true,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtlastname" + rowIndex + "txt";

        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              placeholder=""
              value={row.lastname}
              onChange={(e) => this.updateState(e, rowIndex, "lastname", id)}
              autoComplete="off"
              title={row.lastname}
              className="form-control form-control-sm"
            />
          </div>
        );
      },
    },
    {
      dataField: "phoneno",
      text: "Phone",
      headerStyle: { width: "100px" },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtphone" + rowIndex + "txt";

        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              placeholder=""
              value={row.phoneno}
              onChange={(e) => this.updateState(e, rowIndex, "phone", id)}
              //  onBlur={(e: any) => this.amountBlur(e, rowIndex, "qtyOrdered")}
              autoComplete="off"
              pattern="[+-]?\d+(?:[.,]\d+)?"
              title=""
              className="form-control form-control-sm"
            />
          </div>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: { width: "300px" },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtemail" + rowIndex + "txt";

        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              placeholder=""
              value={row.email}
              onChange={(e) => this.updateState(e, rowIndex, "email", id)}
              autoComplete="off"
              title={row.lastname}
              className="form-control form-control-sm"
            />
          </div>
        );
      },
    },
    {
      dataField: "isDefault",
      text: "Default",
      headerStyle: { width: "50px" },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtdescription" + rowIndex + "txt";
        let disable = false;
        return (
          <div className="form-check form-switch">
            <Form.Check
              className="knob"
              // tabIndex={this.state.tabEnable}
              id={"txtIsFixed" + rowIndex + "txt"}
              type="checkbox"
              checked={row.isDefault}
              // placeholder="Enter Part No"
              //value={value.pDescription}
              onChange={(e) =>
                this.updateState(e, rowIndex, "IsDefault", rowIndex)
              }
              autoComplete="off"
              // disabled={ row.id==6 ? true: false }
              title=""
              //maxLength={500}
            />
          </div>
        );
      },
    },
    {
      dataField: "Attachment",
      text: "",
      //headerFormatter: this.iconFormatter,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let confirmModalContent = [
          {
            title: "Delete Row",
            desc: "Are you sure you want to delete?",
            cancleAction: "Cancel",
            ActionTrue: "Delete",
          },
        ];
        if (this.state.customerContact.length === 1 || rowIndex == 0) {
          return <div></div>;
        } else {
          return (
            <Dropdown
              className="more-action confirmation-modal"
              alignRight
              tabIndex={this.state.tabDisable}
            >
              <Dropdown.Toggle
                className="btn-outline-primary btn btn-primary more fa-trash"
                id="dropdown-more-delete"
                tabIndex={this.state.tabDisable}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5 5.83329C4.53917 5.83329 4.16667 5.83329 4.16667 5.83329V16.6666C4.16667 17.5866 4.91333 18.3333 5.83333 18.3333H14.1667C15.0867 18.3333 15.8333 17.5866 15.8333 16.6666V5.83329C15.8333 5.83329 15.4608 5.83329 15 5.83329H5ZM8.33333 15.8333H6.66667V8.33329H8.33333V15.8333ZM13.3333 15.8333H11.6667V8.33329H13.3333V15.8333ZM13.8483 3.33329L12.5 1.66663H7.5L6.15167 3.33329H2.5V4.99996H6.66667H13.3333H17.5V3.33329H13.8483Z" />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ConfirmationModal
                  confirmModalContent={confirmModalContent}
                  ishandleDeletePosition={this.ishandleDeleteRow.bind(
                    this,
                    row
                  )}
                />
              </Dropdown.Menu>
            </Dropdown>
          );
        }
      },
    },
  ];

  updateState = (e: any, index: any, type: any, txtID) => {
    if (this != undefined) {
      let isStateChanged = true;
      let isSaveOpen = true;
      let start = e.target?.selectionStart;
      let end = e.target?.selectionEnd;
      let customerContact = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.customerContact))
      );
      if (type === "firstname") {
        e.preventDefault();

        customerContact[index].firstname = e.target.value;

        this.setState({ customerContact, isStateChanged, isSaveOpen }, () => {
          $("input[id^='txtfirstname" + index + "txt']").focus();

          let txtdesc = $(
            "input[id^='txtfirstname" + index + "txt']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      if (type === "lastname") {
        e.preventDefault();

        customerContact[index].lastname = e.target.value;

        this.setState({ customerContact, isStateChanged, isSaveOpen }, () => {
          $("input[id^='txtlastname" + index + "txt']").focus();

          let txtdesc = $("input[id^='txtlastname" + index + "txt']")[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      if (type === "title") {
        e.preventDefault();

        customerContact[index].title = e.target.value;

        this.setState({ customerContact, isStateChanged, isSaveOpen }, () => {
          $("input[id^='txttitle" + index + "txt']").focus();

          let txtdesc = $("input[id^='txttitle" + index + "txt']")[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      if (type === "phone") {
        e.preventDefault();

        customerContact[index].phoneno = e.target.value;

        this.setState({ customerContact, isStateChanged, isSaveOpen }, () => {
          $("input[id^='txtphone" + index + "txt']").focus();

          let txtdesc = $("input[id^='txtphone" + index + "txt']")[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      if (type === "email") {
        e.preventDefault();

        customerContact[index].email = e.target.value;

        this.setState({ customerContact, isStateChanged, isSaveOpen }, () => {
          $("input[id^='txtemail" + index + "txt']").focus();

          let txtdesc = $("input[id^='txtemail" + index + "txt']")[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }

      if (type === "IsDefault") {
        e.preventDefault();

        if (e.target.checked) {
          customerContact
            .filter((item) => item.id !== customerContact[index].id)
            .map((item, index) => {
              item.isDefault = false;
              return item;
            });
          customerContact[index].isDefault = true;
        } else {
          customerContact[index].isDefault = false;
        }
        this.setState(
          { customerContact, isStateChanged, isSaveOpen },
          () => {}
        );
      }
    }
  };

  ishandleDeleteRow(row: any, mode: any) {
    if (mode) {
      let customerContact = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.customerContact))
      );
      let isStateChanged = true;
      this.setState({ customerContact: [] }, () => {
        const customerContactNewc = customerContact
          .filter((item) => row.id === 0 || item.id !== row.id)
          .map((item, index) => {
            item.itemNo = index + 1;
            return item;
          });

        this.setState({
          customerContact: customerContactNewc,
          isStateChanged: isStateChanged,
        });
      });
    }
  }

  handleNewRow = () => {
    let customerContact: any = [];
    let newRow: any = {};
    let isStateChanged = true;

    customerContact = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.customerContact))
    );
    newRow = Object.assign({}, JSON.parse(JSON.stringify(this.state.newRow)));

    let maxValueOfRow = Math.max(...customerContact.map((o) => o.id), 0);
    let len = customerContact.length;
    let maxRno = maxValueOfRow;
    //let maxSrno = customerContact[len - 1].srno;

    let firstname = customerContact[len - 1].firstname;
    // if(customerContact[len - 1].partNo!="")
    // {
    if (firstname != "") {
      newRow.id = Number(maxRno) + 1;

      customerContact.push(newRow);

      this.setState({ customerContact: [] }, () => {
        this.setState(
          { customerContact: customerContact, isStateChanged },
          () => {
            let len = customerContact.length;
            let txtID = "txtpartno" + (len - 1) + "txt";
            setTimeout(() => {
              //  this.trDate.current.setOpen(true);
              $("input[id^='" + txtID + "']").focus();
              //       $("#tblOrder")
              // .find("input[class^='" + txtID + "']")
              // .focus();
            }, 50);
          }
        );
      });
      // }
    }
  };

  beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
    if (
      this.state.isStateChanged &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  componentDidMount() {
    this.getVendor();
  }

  copyaddress = () => {
    let formData = { ...this.state.formData };
    formData.shippingZipCode = formData.zip;
    formData.shippingCountry = formData.country;
    formData.shippingStates = formData.states;
    formData.shippingCity = formData.city;
    formData.shippingApartment = formData.apartment;
    formData.shippingAddress = formData.address;

    this.setState({ formData: formData });
  };

  getVendor = () => {
    let customerContact = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.customerContact))
    );
    let requestlist = { ...this.state.requestlist };
    requestlist.vendorid = this.props.vendorid;
    if(this.props.vendorid!=0)
      {
    VendorService.GetVendorById(requestlist)
      .then(async (result: any | null) => {
        if (result != null) {
          let formData = { ...this.state.formData };

          formData.vendor_id = result.vendor_id;
          formData.company_name = result.company_name;
          formData.companyAlias=result.companyAlias;
          formData.Tenantid = result.tenantid;
          formData.vendorcode = result.vendorcode;
          formData.email = result.email;
          formData.zip = result.zip;
          formData.phone_number = result.phone_number;
          formData.address = result.address;
          formData.firstname = result.firstname;
          formData.city = result.city;
          formData.states = result.state;
          formData.apartment = result.apartment;
          formData.country =
            result.country != null && result.country != ""
              ? result.country
              : "US";
          formData.pointofcontact = result.pointofcontact;
          formData.ship_via = result.ship_via;
          formData.term = result.term;
          formData.purchasing_agent = result.purchasing_agent;
          formData.shippingCountry =  result.shippingCountry != null && result.shippingCountry != '' ? result.shippingCountry : 'US';
          formData.shippingZipCode = result.shippingZipCode,
          formData.shippingStates = result.shippingStates,
          formData.shippingCity = result.shippingCity,
          formData.shippingApartment = result.shippingApartment,
          formData.shippingAddress = result.shippingAddress,

          customerContact = result.vendorContact.length == 0 ? customerContact : result.vendorContact,
          this.setState({ formData: formData,  customerContact: customerContact });
        }
        else
        {

          customerContact = result.vendorContact.length == 0 ? customerContact : result.vendorContact,
          this.setState({ customerContact: customerContact });
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  };
};

  //Save all entered data into db and handle the created vendor as selected in dropdown
  saveItem = async () => {
    if (requestInProcess == true) {
      toast.error("Subsequent request has been made.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    // if (
    //   this.state.formData.city == null ||
    //   this.state.formData.city?.trim() == "" ||
    //   this.state.formData.states == null ||
    //   this.state.formData.states?.trim() == "" ||
    //   this.state.formData.country == null ||
    //   this.state.formData.country?.trim() == "" ||
    //   this.state.formData.address == null ||
    //   this.state.formData.address?.trim() == "" ||
    //   this.state.formData.apartment == null ||
    //   this.state.formData.apartment?.trim() == "" ||
    //   this.state.formData.zipcode == null ||
    //   this.state.formData.zipcode?.trim() == ""
    // ) {
    //   toast.error("Please enter mandatory fields.", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //   });
    //   return;
    // }
    debugger;
    this.state.formData.vendorContact = this.state.customerContact;
    this.setState({ isSaveSpinner: true });
    requestInProcess = true;
    this.state.formData.zipcode=this.state.formData.zip;
    // console.log("save",this.state.formData);
    VendorService.SaveVendorData(this.state.formData)
      .then(async (result: any | null) => {
        if (result !== null) {
          debugger;
          if (result.vendor_id != "" || result.vendor_id != null) {

            if(this.props.IsActive !=undefined )
              {
               debugger;
               let custommerSelected: any = [];
               custommerSelected.company_name =this.state.formData.company_name;
               custommerSelected.address =this.state.formData.address;
               custommerSelected.vendor_id =result.vendor_id;
               custommerSelected.customercode = result.customercode;
               custommerSelected.firstname=result.firstname;
               custommerSelected.address= result.address;
               custommerSelected.apartment= result.apartment;
               custommerSelected.city= result.city;
               custommerSelected.state= result.state;
               custommerSelected.zipcode= result.zip;
               custommerSelected.country= result.country;
               custommerSelected.customerAlias= result.companyAlias;
               // this.customerSearch?.current.handleSelectFromParent(
               //   custommerSelected
               // );
   
               this.setState({custommerSelected:custommerSelected},()=>
               {
                 this.props.closeSlideOut(custommerSelected);
                 
               }
   
   
               );
   
               
               // this.props.handleSelectFromParent(
               //   custommerSelected
               // );
            
              //this.closeSlideOut2();
            // this.customerSearch.current.alertmessage();
              }
            let requestlist: any = {};
            toast.success("vendor created successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (result.message?.toLowerCase() === "vendorexists") {
            this.setState({ loader: false });
            toast.error("Vendor name already exists.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (result.message?.toLowerCase() === "samecontractexist") {
            this.setState({ loader: false });
            toast.error("Same contract exists for this EHID.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
        this.setState({ isSpinner: false,
          selectedCustomer: this.state.formData.company_name,
          selectedCustomercode : result.customercode=="" ? "" : " (" + result.customercode + ")", 
iscadd:true,
         });
        requestInProcess = false;
        this.closeSlideOut();
        resolve();
      })
      .catch((error) => {
        this.setState({ isSaveSpinner: false });
        requestInProcess = false;
        reject();
      });
  };

  closeSlideOut = () => {
    this.props.closeSlideOut(this.state?.shouldReloadMainPage);
  };

  // handleChange = (event: any, FieldType: string) => {
  //   let isStateChanged = true;

  //   let formData = { ...this.state.formData };

  //   if (FieldType === "address") {
  //     formData.address = event.target.value;
  //   } else if (FieldType === "firstname") {
  //     formData.firstname = event.target.value;
  //   } else if (FieldType === "company_name") {
  //     formData.company_name = event.target.value;
  //   } else if (FieldType === "apartment") {
  //     formData.apartment = event.target.value;
  //   } else if (FieldType === "email") {
  //     formData.email = event.target.value;
  //   } else if (FieldType === "phone_number") {
  //     formData.phone_number = event.target.value;
  //   } else if (FieldType === "city") {
  //     formData.city = event.target.value;
  //   } else if (FieldType === "states") {
  //     formData.states = event.target.value;
  //   } else if (FieldType === "zip") {
  //     formData.zipcode = event.target.value;
  //   } else if (FieldType === "country") {
  //     formData.country = event.target.value;
  //   } else if (FieldType === "ship_via") {
  //     formData.ship_via = event.target.value;
  //   } else if (FieldType === "term") {
  //     formData.term = event.target.value;
  //   } else if (FieldType === "purchasing_agent") {
  //     formData.purchasing_agent = event.target.value;
  //   } else if (FieldType === "pointofcontact") {
  //     formData.pointofcontact = event.target.value;
  //   }
  //   this.setState({ formData, isStateChanged, isSpinner: false }, () => {});
  // };

  handleChange = (event: any, FieldType: string) => {
    let isStateChanged = true;

    let formData = { ...this.state.formData };

    if (FieldType === "address") {
      formData.address = event.target.value;
    } else if (FieldType === "firstname") {
      formData.firstname = event.target.value;
    } else if (FieldType === "company_name") {
      formData.company_name = event.target.value;
    } 
    else if (FieldType === "companyAlias") {
      formData.companyAlias = event.target.value;
    }
    else if (FieldType === "apartment") {
      formData.apartment = event.target.value;
    } else if (FieldType === "email") {
      formData.email = event.target.value;
    } else if (FieldType === "phone_number") {
      formData.phone_number = event.target.value;
    } else if (FieldType === "city") {
      formData.city = event.target.value;
    } else if (FieldType === "states") {
      formData.states = event.target.value;
    } else if (FieldType === "zip") {
      formData.zip = event.target.value;
    } else if (FieldType === "country") {
      formData.country = event.target.value;
    } else if (FieldType === "ship_via") {
      formData.ship_via = event.target.value;
    } else if (FieldType === "term") {
      formData.term = event.target.value;
    } else if (FieldType === "purchasing_agent") {
      formData.purchasing_agent = event.target.value;
    } else if (FieldType === "pointofcontact") {
      formData.pointofcontact = event.target.value;
    }
    else if (FieldType === "shippingAddress") {
      formData.shippingAddress = event.target.value;
    }
    else if (FieldType === "shippingApartment") {
      formData.shippingApartment = event.target.value;
    }
    else if (FieldType === "shippingCity") {
      formData.shippingCity = event.target.value;
    }
    else if (FieldType === "shippingStates") {
      formData.shippingStates = event.target.value;
    }
    else if (FieldType === "shippingCountry") {
      formData.shippingCountry = event.target.value;
    }
    else if (FieldType === "shippingZipCode") {
      formData.shippingZipCode = event.target.value;
    }



    this.setState({ formData, isStateChanged, isSpinner: false }, () => {});
  };


  render() {
    const {} = this.state;
    return (
      //  <div className="transaction-slideout invoice-entry pos-fxd index-1000">

      <section>
        <div
          className="details-drawer"
          style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
          // className="back-drop1  viewer-panel1 opacity "
        >
          <ToastContainer
            containerId={"userDetailSlideoutcontainer"}
            autoClose={3000}
          />
          <div className="d-flex">
            {!this.state.isOpenViewer && (
              <div
                className="back-drop1  viewer-panel1 opacity "
                onClick={() => this.closeSlideOut()}
              ></div>
            )}

            <Container
              fluid
              className="body-sec viewer-panel back-drop p-0"
              style={{ minWidth: "75%", width: "992px", maxWidth: "100%" ,zIndex: "1000"}}
            >
              <div className="container-fluid py-1 bg-white d-flex align-items-center  border-bottom round-4">
                <div className="divheader">
                  <h5 className="my-title">
                    {this.state.formData.vendor_id == 0 ? (
                      <span>New Vendor </span>
                    ) : (
                      <span>
                        {this.state.formData.company_name} -{" "}
                        {this.state.formData.vendorcode}{" "}
                      </span>
                    )}
                  </h5>

                  <div className="header-popout ml-auto">
                    <div className="container text-center">
                      <div className="row align-items-start">
                        <div className="col"></div>

                        <div className="col">
                          <button
                            type="button"
                            className="btn btn-primary cross"
                            onClick={this.closeSlideOut}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                                fill="#ffffff"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="scroll">
                <section className="pb-3">
                  <div className="row g-3">
                    <div className="col-12 col-md-6">
                      <Form.Group
                        className="rm-bottom-margin"
                        controlId="vendor"
                      >
                        <Form.Label> Vendor Name</Form.Label>
                        <div className="single-select">
                          <Form.Control
                            type="text"
                            id="txtVendor"
                            value={this.state.formData.company_name}
                            autoComplete="off"
                            disabled={false}
                            onChange={(event: any) =>
                              this.handleChange(event, "company_name")
                            }
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-2">
                      <Form.Group
                        className="rm-bottom-margin"
                        controlId="vendorcode"
                      >
                        <Form.Label> Vendor Alias</Form.Label>
                        <div className="single-select">
                          <Form.Control
                            type="text"
                            id="txtVendorcode"
                            value={this.state.formData.companyAlias}
                            autoComplete="off"
                            onChange={(event: any) =>
                              this.handleChange(event, "companyAlias")
                            }
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-12 col-md-2">
                      <Form.Group
                        className="rm-bottom-margin"
                        controlId="ship_via"
                      >
                        <Form.Label> Ship Via</Form.Label>
                        <div className="single-select">
                          <Form.Control
                            type="text"
                            id="txtship_via"
                            value={this.state.formData.ship_via}
                            autoComplete="off"
                            className="form-controlCustom"
                            disabled={false}
                            onChange={(event: any) =>
                              this.handleChange(event, "ship_via")
                            }
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-12 col-md-2">
                      <Form.Group className="rm-bottom-margin" controlId="term">
                        <Form.Label> Terms</Form.Label>
                        <div className="single-select">
                          <Form.Control
                            type="text"
                            id="txtterm"
                            value={this.state.formData.term}
                            autoComplete="off"
                            className="form-controlCustom"
                            disabled={false}
                            onChange={(event: any) =>
                              this.handleChange(event, "term")
                            }
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </section>

                <section className="pb-3">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="card h-100">
                        <div className="card-header">
                          <div className="row">
                            <div className="col-4 float-start">
                              <b> Billing Address </b>
                            </div>
                            <div className="col float-end">
                              Use the same as Shipping Address{" "}
                              <button
                                onClick={this.copyaddress}
                                className="btntrn"
                              >
                                {" "}
                                <MdOutlineContentPasteGo></MdOutlineContentPasteGo>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="card-body">
                          <div className="row my-2">
                            <div className="col-md-12">
                              <Form.Group controlId="address">
                                <div className="parent my-data">
                                  <Form.Label className="font-semibold">
                                    Address Line 1
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    id="txtaddress"
                                    value={this.state.formData.address}
                                    autoComplete="off"
                                    className="form-controlCustom"
                                    disabled={false}
                                    onChange={(event: any) =>
                                      this.handleChange(event, "address")
                                    }
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row my-2">
                            <div className="col-md-12">
                              <Form.Group controlId="apartment">
                                <div className="parent my-data">
                                  <Form.Label className="font-semibold">
                                    Address Line 2
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    id="txtapartment"
                                    value={this.state.formData.apartment}
                                    autoComplete="off"
                                    className="form-controlCustom"
                                    disabled={false}
                                    onChange={(event: any) =>
                                      this.handleChange(event, "apartment")
                                    }
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row my-2">
                            <div className="col-md-6">
                              <Form.Group controlId="customerName">
                                <div className="parent my-data">
                                  <Form.Label className="font-semibold">
                                    City
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    id="txtCity"
                                    value={this.state.formData.city}
                                    autoComplete="off"
                                    disabled={false}
                                    onChange={(event: any) =>
                                      this.handleChange(event, "city")
                                    }
                                  />
                                </div>
                              </Form.Group>
                            </div>
                            <div className="col-md-6">
                              <Form.Group controlId="customerName">
                                <div className="parent my-data">
                                  <Form.Label className="font-semibold">
                                    State
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    id="txtstates"
                                    value={this.state.formData.states}
                                    autoComplete="off"
                                    className="form-controlCustom"
                                    disabled={false}
                                    onChange={(event: any) =>
                                      this.handleChange(event, "states")
                                    }
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row my-2">
                            <div className="col-md-6">
                              <Form.Group controlId="customerName">
                                <div className="parent my-data">
                                  <Form.Label className="font-semibold">
                                    Country
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    id="txtCountry"
                                    value={this.state.formData.country}
                                    autoComplete="off"
                                    disabled={false}
                                    onChange={(event: any) =>
                                      this.handleChange(event, "country")
                                    }
                                  />
                                </div>
                              </Form.Group>
                            </div>
                            <div className="col-md-6">
                              <Form.Group controlId="customerName">
                                <div className="parent my-data">
                                  <Form.Label className="font-semibold">
                                    Zip
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    id="txtOtherCharge"
                                    value={this.state.formData.zip}
                                    autoComplete="off"
                                    className="form-controlCustom"
                                    disabled={false}
                                    onChange={(event: any) =>
                                      this.handleChange(event, "zip")
                                    }
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-6">
                      <div className="card h-100">
                        <div className="card-header">
                          <b> Shipping Address</b>
                        </div>
                        <div className="card-body">
                          <div className="row my-2">
                            <div className="col-md-12">
                              <Form.Group controlId="customerName">
                                <div className="parent my-data">
                                  <Form.Label className="font-semibold">
                                    Address Line 1
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    id="txtaddress"
                                    value={this.state.formData.shippingAddress}
                                    autoComplete="off"
                                    className="form-controlCustom"
                                    disabled={false}
                                    onChange={(event: any) =>
                                      this.handleChange(
                                        event,
                                        "shippingAddress"
                                      )
                                    }
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row my-2">
                            <div className="col-md-12">
                              <Form.Group controlId="customerName">
                                <div className="parent my-data">
                                  <Form.Label className="font-semibold">
                                    Address Line 2
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    id="txtapartment"
                                    value={
                                      this.state.formData.shippingApartment
                                    }
                                    autoComplete="off"
                                    disabled={false}
                                    onChange={(event: any) =>
                                      this.handleChange(
                                        event,
                                        "shippingApartment"
                                      )
                                    }
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row my-2">
                            <div className="col-md-6">
                              <Form.Group controlId="customerName">
                                <div className="parent my-data">
                                  <Form.Label className="font-semibold">
                                    City
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    id="txtCity"
                                    value={this.state.formData.shippingCity}
                                    autoComplete="off"
                                    disabled={false}
                                    onChange={(event: any) =>
                                      this.handleChange(event, "shippingCity")
                                    }
                                  />
                                </div>
                              </Form.Group>
                            </div>
                            <div className="col-md-6">
                              <Form.Group controlId="customerName">
                                <div className="parent my-data">
                                  <Form.Label className="font-semibold">
                                    State
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    id="txtCity"
                                    value={this.state.formData.shippingStates}
                                    autoComplete="off"
                                    disabled={false}
                                    onChange={(event: any) =>
                                      this.handleChange(event, "shippingStates")
                                    }
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row my-2">
                            <div className="col-md-6">
                              <Form.Group controlId="customerName">
                                <div className="parent my-data">
                                  <Form.Label className="font-semibold">
                                    Country
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    id="txtCountry"
                                    value={this.state.formData.shippingCountry}
                                    autoComplete="off"
                                    disabled={false}
                                    onChange={(event: any) =>
                                      this.handleChange(
                                        event,
                                        "shippingCountry"
                                      )
                                    }
                                  />
                                </div>
                              </Form.Group>
                            </div>
                            <div className="col-md-6">
                              <Form.Group controlId="customerName">
                                <div className="parent my-data">
                                  <Form.Label className="font-semibold">
                                    Zip
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    id="txtOtherCharge"
                                    value={this.state.formData.shippingZipCode}
                                    autoComplete="off"
                                    className="form-controlCustom"
                                    disabled={false}
                                    onChange={(event: any) =>
                                      this.handleChange(
                                        event,
                                        "shippingZipCode"
                                      )
                                    }
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="pb-3">
                  <div className="table-responsive table-responsive1">
                    <section className="pb-3">
                      <div className="card h-100">
                        <div className="card-header">
                          <b> Contact Person</b>
                        </div>
                        <div className="card-body">
                          <div
                          //className="transactions-list"
                          >
                            <div
                              id="order"
                              //className="table-responsive"
                              //className={this.state.responsive}
                              //style={{minHeight:"170px"}}
                            >
                              {this.state.customerContact.length > 0 && (
                                <div className="table table-borderless align-middle transactions-list">
                                  <BootstrapTable
                                    id="tblContact"
                                    keyField="contactid"
                                    data={this.state.customerContact}
                                    columns={this.state.contactColumns}
                                    // rowEvents={this.rowEvents1}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="d-flex action-btn align-items-center">
                                <Button
                                  type="button"
                                  className="btn-ghost mr-auto"
                                  onClick={this.handleNewRow}
                                >
                                  <FaCirclePlus className="align-middle lh-1`" />{" "}
                                  <span className="align-middle lh-1">
                                    Add Row
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="filter-sec height-172">
                    <br></br>
                    <br></br>
                  </div>
                </section>

                {/* <div className="col-12 col-md-3" >
                    <Form.Group className="rm-bottom-margin" controlId="pointofcontact">
  <Form.Label> Point of Contact</Form.Label>
  <div className="single-select">
  <Form.Control type="text" id="txtpointofcontact"
   value={this.state.formData.pointofcontact}
   autoComplete='off'
   className="form-controlCustom"
   disabled={false}
   onChange={(event: any) =>
   this.handleChange(event, "pointofcontact")} 
  />
  </div>

</Form.Group>
                    </div>

                    <div className="col-12 col-md-3" >
                    <Form.Group className="rm-bottom-margin" controlId="vendorcode">
                        <Form.Label> First name and last name of recipient </Form.Label>
                                     <div className="single-select">
                                     <Form.Control type="text" id="txtfirstname"
                                      value={this.state.formData.firstname}
                                      autoComplete='off'
                                      disabled={false}
                                      onChange={(event: any) =>
                                      this.handleChange(event, "firstname")} 
                                    />
                      </div>
                      </Form.Group>
                    </div>

                    
                    

                    <div className="col-12 col-md-3" >
                    <Form.Group className="rm-bottom-margin" controlId="email">
                        <Form.Label> Email</Form.Label>
                        <div className="date-picker calender-picker">
                        <Form.Control type="text" id="txtemail"
                         value={this.state.formData.email}
                         autoComplete='off'
                         disabled={false}
                        onChange={(event: any) =>
                        this.handleChange(event, "email")} 
                           />         
                      </div>  
                      </Form.Group>
                    </div>

                    <div className="col-12 col-md-3" >
                    <Form.Group className="rm-bottom-margin" controlId="phone">
  <Form.Label> Phone Number  </Form.Label>
  <div className="single-select">
    <Form.Control type="text" id="txtphone"
      value={this.state.formData.phone_number}
      className="form-controlCustom"
      autoComplete='off'
      disabled={false}
      onChange={(event: any) =>
        this.handleChange(event, "phone_number")} 
    />
  </div>

</Form.Group>
                    </div>              

                    <div className="col-12 col-md-3" >
                    <Form.Group className="rm-bottom-margin" controlId="purchasing_agent">
  <Form.Label> Purchasing Agent </Form.Label>
  <div className="single-select">
    <Form.Control type="text" id="txtCountry"
      value={this.state.formData.purchasing_agent}
      className="form-controlCustom"
      autoComplete='off'
      disabled={false}
      onChange={(event: any) =>
        this.handleChange(event, "purchasing_agent")}
    />
  </div>

</Form.Group>
                    </div> */}

                <div
                  className={"footer-section viewer-panel"}
                  style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
                >
                  <div>
                    <div className="d-flex align-items-center justify-content-end height-72">
                      <div className="d-flex align-items-center">
                        {
                          <>
                            <Button
                              variant="primary"
                              type="button"
                              className="space btn btn-primary btn-discard"
                              onClick={this.closeSlideOut}
                            >
                              Discard
                            </Button>
                            <Button
                              id="btnSave"
                              variant="primary"
                              type="button"
                              onClick={() => this.saveItem()}
                            >
                              {this.state.formData.vendor_id == 0
                                ? "Create"
                                : "Update"}
                            </Button>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
          1
          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}
        </div>
      </section>
    );
  }
}
