import * as React from "react";
import {
  Container,
  Dropdown,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import paginationFactory from "react-bootstrap-table2-paginator";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import _ from "lodash";
import { ConfirmationModal } from "../../Common/Components/ConfirmationModal";
import createDOMPurify from "dompurify";
import { Utils } from "../../Common/Utilis";
import moment from "moment";
import { WorkstationService } from "../../Common/Services/WorkstationService";
import { User } from "../../Common/Services/User";
import { FaArrowUpFromBracket, FaCircleMinus, FaCirclePlus, FaEllipsis } from "react-icons/fa6";
export class WorkstationSlideout extends React.Component<any, any> {
  private parentScroll: any;
  constructor(props: any) {
    super(props);
    this.handleNewRow = this.handleNewRow.bind(this);
    this.state = {
      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      userList: [],
      workstationList: [],
      formData: {
        Id: this.props.workstationid,
        WorkstationName: this.props.workstationname,
        IsActive:this.props.isactive,
        UserWorkstationMappings:[]
      },      
      mappingColumns: this.mappingColumns,
      mappingData: [{
        userName: "", userId: 0, srno: 1, workstationName: '', workstationId: 0, tenantId: 0,
      }],      
      newRow: [
        {
          userName: "", userId: 0, srno: 1, workstationName: '', workstationId: 0, tenantId: 0,
        }

      ],
    };


  }

  ishandleDeleteRow(row: any, mode: any) {
    if (mode) {

      let mappingData = Object.assign([], JSON.parse(JSON.stringify(this.state.mappingData)));

      this.setState({ mappingData: [] }, () => {

        const mappingDataNew = mappingData.filter(item => item.id !== row.id);

        this.setState({
          mappingData: mappingDataNew,isStateChanged:true

        },
          () => {


          });
      });
    }
  }

  handleNewRow = () => {

    let mappingData: any = [];
    let newRow: any = {};
    let isStateChanged = true;

    mappingData = Object.assign([], JSON.parse(JSON.stringify(this.state.mappingData)));
    newRow = Object.assign({}, JSON.parse(JSON.stringify(this.state.newRow)));
   
    mappingData.push(newRow[0]);
    this.setState({ mappingData: [] }, () => {
      this.setState({ mappingData: mappingData, isStateChanged });

    });




  };

  private mappingColumns = [
    {
      dataField: 'srno',
      text: 'Sr No.',
      headerStyle: { width: '100px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div>{rowIndex + 1} </div>
        );
      },

    },
    {
      dataField: 'userName',
      text: 'Assigned Operator',
      headerStyle: { width: '200px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtUser" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
            <SingleSearchDropdownList
              tabIndex={this.state.tabEnable}
              itemList={this.state.userList}
              handleSelectedItem={this.handleSelectedItem.bind(row, 1 + '_' + rowIndex)}
              defaultItem={row.userName}
              defaultText={"Search User Name"}
              defaultName={row.userName}
              controlID="1"
              id={"ddlUser-" + rowIndex + "ddl"}
            //onTAB={this.onTAB.bind(this, "ddlprocess-" + rowIndex + "ddl", rowIndex)}
            // disabled={disable}
            />
          </div>
        );



      },

    },
    {
      dataField: 'workstationName',
      text: 'Work Station',
      headerStyle: { width: '200px' },
      hidden: true,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtWorkstation" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
            <SingleSearchDropdownList
              tabIndex={this.state.tabEnable}
              itemList={this.state.workstationList}
              handleSelectedItem={this.handleSelectedItem.bind(row, 2 + '_' + rowIndex)}
              defaultItem={row.workstationName}
              defaultText={"Search workstation"}
              defaultName={row.workstationName}
              controlID="1"
              id={"ddlWorkstation-" + rowIndex + "ddl"}
            //onTAB={this.onTAB.bind(this, "ddlprocess-" + rowIndex + "ddl", rowIndex)}
            // disabled={disable}
            />
          </div>
        );
      },

    },
    {
      dataField: 'Attachment',
      text: '',
      //headerFormatter: this.iconFormatter,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let confirmModalContent = [
          {
            title: "Delete Row",
            desc: "Are you sure you want to delete?",
            cancleAction: "Cancel",
            ActionTrue: "Delete",
          },
        ];
        if (this.state.mappingData.length === 1) {
          return (
            <div></div>
          );
        }
         

        else {
          return (
            <Dropdown className="more-action confirmation-modal" alignRight tabIndex={this.state.tabDisable}>
              <Dropdown.Toggle className="btn-outline-primary btn btn-primary more fa-trash" id="dropdown-more-delete" tabIndex={this.state.tabDisable}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 5.83329C4.53917 5.83329 4.16667 5.83329 4.16667 5.83329V16.6666C4.16667 17.5866 4.91333 18.3333 5.83333 18.3333H14.1667C15.0867 18.3333 15.8333 17.5866 15.8333 16.6666V5.83329C15.8333 5.83329 15.4608 5.83329 15 5.83329H5ZM8.33333 15.8333H6.66667V8.33329H8.33333V15.8333ZM13.3333 15.8333H11.6667V8.33329H13.3333V15.8333ZM13.8483 3.33329L12.5 1.66663H7.5L6.15167 3.33329H2.5V4.99996H6.66667H13.3333H17.5V3.33329H13.8483Z" />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ConfirmationModal
                  confirmModalContent={confirmModalContent}
                  ishandleDeletePosition={this.ishandleDeleteRow.bind( this, row  )}
                />
              </Dropdown.Menu>
            </Dropdown>
          );
        }
      },
    }
    
  ];

  componentDidMount() {
   // this.getWorkstations(requestlist);
    this.getUsers();

    let requestlist = { ...this.state.requestlist };
    requestlist.workstationId = this.props.workstationid;
    this.getUserWorkstationMapping(requestlist);
  }

  handleSelectedItem = (control: any, id: any) => {
 
    let controlID = Number(control.split('_')[0]);
    if (controlID === 1) {
     
      let rowIndex = Number(control.split('_')[1]);
      let filterRow = this.state.userList.filter(x => x.id === id);

      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
       

      this.state.mappingData[rowIndex].tenantId = tenantID;
      this.state.mappingData[rowIndex].userId = filterRow[0].id;
      this.state.mappingData[rowIndex].userName = filterRow[0].name;
      this.state.mappingData[rowIndex].workstationId = this.props.workstationid;
      this.setState({isStateChanged:true});
    }
    else if (controlID === 2) {

      let rowIndex = Number(control.split('_')[1]);
      let mappingData = Object.assign([], JSON.parse(JSON.stringify(this.state.mappingData)));
      let filterRow = this.state.workstationList.filter(x => x.id === id);


      mappingData[rowIndex].workstationId = filterRow[0].id;
      mappingData[rowIndex].workstationName = filterRow[0].name;
      
      this.setState({ mappingData: [] }, () => {
        this.setState({ mappingData: mappingData });
      });
    };
  };
  

  
getUserWorkstationMapping = (requestlist: any) => {

    WorkstationService.GetUserWorkstationMapping(requestlist)
.then(async (result: any[] | null) => {
    
  if (result != null) {
    if(result.length > 0)
    this.setState({mappingData:result});
  }
  resolve();
})
.catch((err: any) => {
  toast.error(`Server Error, ${err}`);
  reject();
});
};

getWorkstations = (requestlist: any) => {
 
    WorkstationService.GetWorkstations(requestlist)
.then(async (result: any[] | null) => {
    
  if (result != null) {
    this.state.workstationList.push({id:0,name:'Select Workstation'});
    result.forEach(element => {
      let item: any = {};
      item.id = element.id;
      item.name = element.workstationName;
      this.state.workstationList.push(item);
    });
  }
  resolve();
})
.catch((err: any) => {
  toast.error(`Server Error, ${err}`);
  reject();
});
};

getUsers = () =>{

    User.getAllUsers()
    .then(async (result: any | null) => {

      if (result !== null) {
        this.state.userList.push({id:0,name:'Unassigned'});
        result.forEach(element => {
          let item: any = {};
          item.id = element.user_UniqueID;
          item.name = element.userName;
          this.state.userList.push(item);
        });
      }
      resolve();
    })
    .catch((error) => {
      this.setState({ isSpinner: false });
      reject();
    });
  };

closeSlideOut = () => {
    this.props.closeSlideOut(this.state?.shouldReloadMainPage);
  };

  

handleDiscard(postID) {
    this.setState({ isStateChanged: false }, () => {
      this.closeSlideOut();
    });
  }

HidecloseSlideOut() {
    this.props.closeSlideOut();
    this.setState({ isSaveSpinner: false });
  }


saveItem = () => {
    let formData = { ...this.state.formData };
     this.setState({ isSaveSpinner: true });

    let isFormValid = true;
    let request: any = {};

    if (request.templatename === "") {
      toast.error("Please enter work station name.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });
       this.setState({ isSaveSpinner: false });
      return false;
    }

    this.state.formData.UserWorkstationMappings = this.state.mappingData;
    request = Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));



  

    isFormValid = true;

    if (isFormValid) {
      //this.setState({ saveRequest: request, ispopupshow: false });
      WorkstationService.SaveWorkstation(request)
        .then(async (result: any | null) => {
          if (result !== null) {
             this.setState({ isSaveSpinner: false });
             toast.success('Workstation saved.', {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "OrderSlideout",
            });
          }
          this.HidecloseSlideOut();

          resolve();
        })
        .catch((error) => {
          this.setState({ isdefaultColDisabled: false, isSpinner: false });
          reject();
        });
    }
  };

  handleChange = (event: any, FieldType: string) => {
  
    let isStateChanged = true;
    let start = 0;
    let end = 0;

    let formData = { ...this.state.formData };

    if (FieldType === "WorkstationName") {
      formData.WorkstationName =
        event.target.value.trim().length > 0 ? event.target.value : "";
    }
    else if (FieldType === "IsActive") {
      formData.IsActive = event.target.checked;
    }

    this.setState({ formData, isStateChanged, isSpinner: false }, () => {

    });
  };

  render() {
    const {

      showjobrModal,

    } = this.state;


    return (
      //  <div className="transaction-slideout invoice-entry pos-fxd index-1000">

      <section>
        <div className="details-drawer"
          style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
        // className="back-drop1  viewer-panel1 opacity "
        >
          <ToastContainer
            containerId={"userDetailSlideoutcontainer"}
            autoClose={3000}
          />

          <div className="d-flex">
            {!this.state.isOpenViewer && (
              <div
                className="back-drop1  viewer-panel1 opacity "
                onClick={() => this.closeSlideOut()}
              ></div>
            )}

            <Container
              fluid

              className="body-sec viewer-panel back-drop p-0 "
              style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}

            >
              <div
                className="container-fluid py-1 bg-white d-flex align-items-center  border-bottom round-4"
              >
                <div className="divheader">
                  <h5 className="my-title">
                  {this.props.workstationid == 0 && <span>New Workstation</span>}
              {this.props.workstationid != 0 && (
                <span>
                  {this.state.formData.WorkstationName}
                </span>
              )
              }
               </h5>


                  <div className="header-popout ml-auto">





                  <div className="container text-center">
  <div className="row align-items-start">
    <div className="col">
      </div>

      <div className="col">
      <button
                          type="button"
                          className="btn btn-primary cross"
                          onClick={this.closeSlideOut}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                              fill="#ffffff"
                            />
                          </svg>
                        </button>
      </div>
      </div>
      </div>
                  </div>
                </div>
              </div>
              <div
                className="scroll"
              >

                <section className="pb-3">
                  <div className="row g-3">

                    <div className="col-12 col-md-3" >
                    <Form.Group controlId="customerName">
                    <div className="parent my-data">
                      <Form.Label>Workstation Name</Form.Label>
                      <div
                        tabIndex={this.state.tabEnable}
                        className="type-head"
                      >
                        <Form.Control
                          type="text"
                          id="txtWorkstationName"
                          value={this.state.formData.WorkstationName}
                          onChange={(event: any) =>
                            this.handleChange(event, "WorkstationName")
                          }
                          autoComplete="off"
                          title=""
                        />
                      </div>
                    </div>
                  </Form.Group>

                    </div>
                    <div className="col-12 col-md-3" >
                    <Form.Group controlId="customerName">
                       <div className="parent my-data">
                       <Form.Label>Active</Form.Label>
                      <div
                        tabIndex={this.state.tabEnable}
                        className="type-head"
                      >
                         <Form.Check
                        //custom
                        type="checkbox"
                        //key={"custom-footer"}
                        checked={this.state.formData.IsActive}
                        id={"chkActive"}
                        label={"Active"}
                        onChange={(e: any) =>  this.handleChange(e, "IsActive")}
                        //ref={this.footerchk}
                      />
                      </div>
                       </div>
                     </Form.Group>
                    </div>
                  </div>
                </section>

                <section className="pb-3">

                  <div id="workstationDiv" style={{overflow:"visible!important;"}}
                    className="table-responsive table table-borderless align-middle transactions-list"
                  >
                        { this.state.mappingColumns.length > 0 && (
                  <div className="split-assign-coa" style={{ marginTop: "40px" }}>
                    <BootstrapTable
                      id="tblMapping"
                      keyField="srno"
                      data={this.state.mappingData}
                      columns={this.state.mappingColumns}
                    />
                  </div>

                  
               )}
                  </div>
                  <div className="d-flex action-btn align-items-center">
                  <Button
                          type="button"
                          className="btn-ghost mr-auto"
                          onClick={this.handleNewRow}
                        >
                          <FaCirclePlus className="align-middle lh-1`" /> <span className="align-middle lh-1">Add Row</span>
                        </Button>

              </div>
                </section>

                <div className={"footer-section viewer-panel"}
                  style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
                >
<div className="d-flex gap-3 align-items-center justify-content-end height-72">
                      <div className="d-flex gap-3 align-items-center">

                      {this.state.isStateChanged && (
                        <>
                          <Button variant="primary" type="button" className="space btn btn-primary btn-discard" onClick={() => this.handleDiscard(this.state.orderUniqueNo)}>
                            Discard
                          </Button>
                          <Button id="btnSave" variant="primary" type="button"
                            onClick={() => this.saveItem()}
                          // disabled={this.state.formData.templatename === "" ? true : this.state.isDisabled}
                          //onKeyDown={(e: any) => this.onTAB('btnSave-1', 0, e)}

                          >{"Save"}</Button>

                        </>
                      )}
                      </div>
</div>
                </div>
              </div>

            </Container>
          </div>

          1

          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}






        </div>

      </section>
    );
  }
}
