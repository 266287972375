import axios from "axios";
import Instense from "./Axios-config";
import { API_ROOT } from "./Api-config";
 
export class ProductService {

 

 


public static GetProductOrderlist = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Product/GetProductOrderlist`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
public static usernameList = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantID = tenantID;
  const url =`/User/GetUserData`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};


public static SaveTransactionComment = async (
  request: any[]
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;

  const url = `/Register/SaveTransactionComment`;
  return Instense.post(url, request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetProductById = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Product/GetProductById`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
   
public static SaveProductMaster = async (
  request: {}
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;

  const url = `/Product/SaveProductMaster`;
  return Instense.post(url, request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};


}