import axios from "axios";
import Instense from "./Axios-config";
import { API_ROOT } from "./Api-config";
 
export class InvoiceService {

public static GetInvoice = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Invoice/GetInvoiceById`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetInvoiceList = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Invoice/GetInvoices`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetInvoiceListorderid = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Invoice/GetInvoicesorderid`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static SaveInvoice = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantID = tenantID;
  const url =`/Invoice/SaveInvoice`;
  return Instense.post(url,request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
public static DownloadInvoiceFile = async (request: any): Promise<any | null> => {

  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.tenantId = tenantID;
  request.UserName = userName;
  const url = `/Invoice/GetInvoicePDF`;
  return Instense.get(url,{params:request,  responseType: "blob", headers: {
    'Accept': '*/*',
  }, } ).then(
    (response: any) => {
      // Check if the response is successful
      if (response.status === 200) {
        
        const blob = new Blob([response.data], { type: 'application/pdf' });
        // Open the PDF in a new browser tab
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      } 
    }
  );
};


public static GetVendorInvoice = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Invoice/GetVendorInvoiceById`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static DeleteInvoice = async (
  request:  any
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  request.tenantId = tenantID;
  const url = `/Invoice/DeleteInvoice`;
  return Instense.delete(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
 

public static SaveVendorInvoice = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantID = tenantID;
  const url =`/Invoice/SaveVendorInvoice`;
  return Instense.post(url,request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
public static CheckGenerate= async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantID = tenantID;
  const url =`/Invoice/CheckGeneratePayment`;
  return Instense.post(url,request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static PrintActualCheck = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  request.tenantID = tenantID;

  const url = `/Invoice/PrintActualCheck`;
  return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {
         

          var file = new Blob([response.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(file);
          InvoiceService.popupCenter({url: fileURL, title: "Print Check", w: 800, h: 600});
      }
  );
};

 //Print command show code with center of screen
 public static popupCenter = ({ url, title, w, h }) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth;
  const height = window.innerHeight;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(url, title,
      `scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top}, left=${left}`);
  newWindow?.window.print();
};

public static GetVendorInvoiceList = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Invoice/GetVendorInvoices`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetVendorInvoiceListGroup = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Invoice/GetVendorInvoicesGroup`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetVendorInvoiceListPaid = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Invoice/GetVendorInvoicesPaid`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetVendorInvoiceListPaidAll = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Invoice/GetVendorInvoicesPaidAll`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetRegister = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Invoice/GetRegister`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static DownloadVendorInvoiceFile = async (request: any): Promise<any | null> => {

  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.tenantId = tenantID;
  request.UserName = userName;
  const url = `/Invoice/GetVendorInvoicePDF`;
  return Instense.get(url,{params:request,  responseType: "blob", headers: {
    'Accept': '*/*',
  }, } ).then(
    (response: any) => {
      // Check if the response is successful
      if (response.status === 200) {
        
        const blob = new Blob([response.data], { type: 'application/pdf' });
        // Open the PDF in a new browser tab
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      } 
    }
  );
};

public static ApproveVendorInvoice = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantID = tenantID;
  const url =`/Invoice/ApproveVendorInvoice`;
  return Instense.post(url,request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static UpdatePrintConfirm = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  request.TenantID = tenantID;
  const url = `/Invoice/UpdatePrintConfirm`;
  return Instense.post(url, request).then((response) => {
      let result = response.data as any;
      return result;
  });
};


public static GetBankMaster = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Invoice/GetBankMaster`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static DownloadRegisterPDF = async (request: any): Promise<any | null> => {

  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.tenantid = tenantID;
  request.UserName = userName;
  const url = `/Invoice/GetRegisterPDF`;
  return Instense.get(url,{params:request,  responseType: "blob", headers: {
    'Accept': '*/*',
  }, } ).then(
    (response: any) => {
      // Check if the response is successful
      if (response.status === 200) {
        
        const blob = new Blob([response.data], { type: 'application/pdf' });
        // Open the PDF in a new browser tab
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      } 
    }
  );
};

}