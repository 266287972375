import React from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Container,
  Dropdown,
  Form,
  Button,
  Spinner,FormCheck
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";
import { Utils } from "../../Common/Utilis";
import { InvoiceService } from "../../Common/Services/InvoiceService";
import { resolve, reject } from "q";
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import "../../App.scss";
 
import { CustomerSearch } from "../../Common/Components/CustomerSearch";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import moment from "moment";

import { MdBuild } from 'react-icons/md';
import { debug } from "console";
import Dropzone from "react-dropzone";
import {
  faPlus,
  faMinus,
  faPaperclip,
  faFile,
  faFileImage,
  faFileExcel,
  faFilePdf,
  faFileCsv,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
 
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";

import PDFViewer from "../pdf-viewer";
import { isAnyArrayBuffer } from "util/types";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
let routeLeavingGuard: any = null;
let docViewerFiles: any = [];
export class InvoicePaymentslideout extends React.Component<any, any> {
  private scrollvdRef: any;
  private customerSearch: any;
  private trDate: any;
  private trdueDate: any;
  private checkAllRef: any = {};
  private scrollArea: any = {};
  private parentScroll: any;
  private scrollheight: any;
  private footerchk: any;
  private ChildComment: any;


 
 
  constructor(props: any) {
    super(props);
    this.parentScroll = React.createRef();
    this.scrollvdRef = React.createRef();
    this.trDate = React.createRef();
    this.ChildComment = React.createRef();
    this.state = {
      isgroup:0,
      // templateColumns: this.templateColumns,
     
      VendorCode:"",
      VendorName:"",
      payment:"",
      formData: {
        isCheckgroup:0,
        Id:0,
        InvoiceNo:'',
        TenantId:0,
        InvoiceDate:null,
        DueDate:null,
        AccountingPeriod:null,
        printpingCharge:0.0,
        Amount:0.0,
        TotalAmount:0.0,
        VendorCode:'',
        VendorName:'',
        VendorId:'',
        VendorInvoiceDetail:[],
        PaymentMethod:null,
        PaymentType:"",
        bankname:"",
      },
      Ispaid:false,
      IsPrinted:false,
      invoiceList:[],
      bankList:[],
      PaymentMethod:[{name:"ACH",id:"ACH"},
      {name:"Check",id:"Check"}],
      
    };

  }

  reprint = (row: any) => {
   this.genratePDFPrintPreview(row.ckno,row.series,row.payDate);
  };

  updateState = (e: any, index: any, type: any, txtID) => {
    if (this != undefined) {
      let isStateChanged = true;
      let start = e.target?.selectionStart;
      let end = e.target?.selectionEnd;
      let invoiceList = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.invoiceList))
      );
      if (type === "memo") {

        e.preventDefault();

        invoiceList[index].memo = e.target.value;

        this.setState({ invoiceList, isStateChanged }, () => {
          $("#tbltemplate")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tbltemplate").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      if (type === "PayDate") {
       

        let today = new Date(e);
        let dateNew =
          today.getMonth() +
          1 +
          "/" +
          today.getDate() +
          "/" +
          today.getFullYear().toString().substring(2, 4);
        let todaynew = new Date(dateNew);
        let dateNewt =
          todaynew.getMonth() +
          1 +
          "/" +
          todaynew.getDate() +
          "/" +
          todaynew.getFullYear().toString().substring(0, 4);
          invoiceList[index].payDate = dateNewt;
        //  this.setState({ formData, isStateChanged });

        this.setState({ invoiceList, isStateChanged }, () => {
          let id = "txtdescription" + index + "txt";
          $("#tbltemplate")
            .find("input[id^='" + id + "']")
            .focus();
          let txtdesc = $("#tbltemplate").find(
            "input[id^='" + id + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
    }
  
  };

  beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
    if (
      this.state.isStateChanged &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  componentDidMount() {
    this.getbankList();
   this.getInvoiceList(0);  

  }


  getInvoiceList = (isgroup) => {
    let requestlist = { ...this.state.requestlist };
    
   
     
    let str: any = "";
    this.props.invoiceIds.forEach(element => {
      
      
      if(str=="")
      {

        str=element;
      }
      else
      {
      str=str + "," + element;
      }
    });
    requestlist.ids= str; 
    //this.props.invoiceIds;
    requestlist.IsGroup= isgroup;

    InvoiceService.GetVendorInvoiceListGroup(requestlist)
  .then(async (result: any | null) => {
    
    if (result != null) {
    

      this.state.formData.PaymentType=result[0].paymentMethod;
      this.setState({ invoiceList: result ,payment:result[0].paymentMethod}, () => {
        //this.bindfilter();
      });
    }
    resolve();
  })
  .catch((err: any) => {
    toast.error(`Server Error, ${err}`);
    reject();
  });
  };


  getInvoiceListPaid = (pvrno) => {
    
    let requestlist = { ...this.state.requestlist };
    requestlist.isApproved= 1;
    requestlist.pvrno=pvrno;
    InvoiceService.GetVendorInvoiceListPaid(requestlist)
  .then(async (result: any | null) => {
    
    if (result != null) {
      

      this.state.formData.PaymentType=result[0].paymentMethod;
      this.setState({ invoiceList: result }, () => {
        //this.bindfilter();
      });
    }
    resolve();
  })
  .catch((err: any) => {
    toast.error(`Server Error, ${err}`);
    reject();
  });
  };

  getbankList = () => {
    let requestlist = { ...this.state.requestlist };
    InvoiceService.GetBankMaster(requestlist)
  .then(async (result: any | null) => {
    
    if (result != null) {

      let tempBankList: any = [];
      result.forEach(element => {
  
        let item: any = {};
      
        item.id = element.id;
        item.name = element.bankName;       
        tempBankList.push(item);
      
      });
      this.setState({ bankList: tempBankList }, () => { //this.bindfilter();
       });
    }
    resolve();
  })
  .catch((err: any) => {
    toast.error(`Server Error, ${err}`);
    reject();
  });
  };


  
saveItem = () => {


  let formData = { ...this.state.formData };
   this.setState({ isSaveSpinner: true });

  let isFormValid = true;
  let request: any = {};
 
  let reqArray: any = [];
     let invoiceList: any = [];

     let jobList = this.state.invoiceList;
     jobList.forEach(element => {
 
       let item: any = {};
      
       item.bankid = this.state.formData.PaymentMethod;
       item.uniqueno =element.ids.toString();
       item.tenantid = element.tenantId;
       item.memo =element.memo;
       item.CkDate = element.payDate;
       item.etype =this.state.formData.PaymentType;
       item.pvrno =  0;       
       reqArray.push(item);
     
     });
     //this.state.formData.OrderId=this.props.orderid;
   //  this.state.formData.VendorInvoiceDetail = invoiceList;  

 // request = Object.assign({}, JSON.parse(JSON.stringify(invoiceList)));
  isFormValid = true;


  if(this.state.formData.PaymentMethod==undefined || this.state.formData.PaymentMethod=="" || this.state.formData.PaymentMethod==null  )
  {
    toast.success('Please select bank account.', {
      position: toast.POSITION.BOTTOM_RIGHT,
      containerId: "OrderSlideout",
    });
    this.setState({isSaveSpinner:false});
    isFormValid=false;
  }
 
  if (isFormValid) {
    InvoiceService.CheckGenerate(reqArray)
      .then(async (result: any | null) => {
        if (result !== null) {
           this.setState({ isSaveSpinner: false,Ispaid:true });
           toast.success('Payment has been updated.', {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });
        }
        

        if( this.state.formData.PaymentType=="ACH")
        {

          this.closeSlideOut();          
        }
        else

        {
        this.getInvoiceListPaid(result.result);  
        } 
        //  this.closeSlideOut();

        resolve();
      })
      .catch((error) => {
        this.setState({ isdefaultColDisabled: false, isSpinner: false });
        reject();
      });
  }
};

genratePDFPrintPreview = (checkno,series,ckdate) => {
 

  let invoiceList = this.state.invoiceList;

  if (invoiceList.length > 0) {
      let request: any = {};
      
      request.bankid = this.state.formData.PaymentMethod;
      request.CheckType =  "I";
      request.pvrno = invoiceList[0].pvrno;
      request.CheckNo = checkno;
      request.Checkdate = ckdate;
      request.Downloadcopy = "";
      request.Series = series;
      request.isCopyCheck = "Not";
      request.browserName = "Chrome";
      request.vid = 0;
      //request.aid = 0;
      request.Uniquenos = "";
      InvoiceService.PrintActualCheck(request)
          .then(async (result: any | null) => {
            
              this.setState({IsPrinted:true});
              resolve();
          })
          .catch((error) => {
              toast.error("Something went wrong. " + error, {
                  position: toast.POSITION.BOTTOM_RIGHT,
              });
              reject();
          });
  }

};

  closeSlideOut = () => {
    this.props.closeSlideOut(this.state?.shouldReloadMainPage);
  };

  handleChange = (event: any, FieldType: string) => {
  
    let isStateChanged = true;
    
    let formData = { ...this.state.formData };

    
    if (FieldType === "InvoiceNo") {
      formData.InvoiceNo = event.target.value;
    }
    else if (FieldType === "InvoiceDate") {

      formData.InvoiceDate = event.toISOString();
      let txtID = "txtInvoiceDate";
        setTimeout(() => {
          this.trDate.current.setOpen(true);
          $("input[id^='" + txtID + "']").focus();
        }, 50);
    }
    else if (FieldType === "DueDate") {

      formData.DueDate = event.toISOString();
        let txtID = "txtDueDate";
        setTimeout(() => {
          this.trDate.current.setOpen(true);
          $("input[id^='" + txtID + "']").focus();
        }, 50);
    }

    this.setState({ formData, isStateChanged, isSpinner: false }, () => {

    });
  };
  onConfirnAllPrintedClick = () => {
    let isAnyPendingForConfirm = false;
    
        //let chks = chknoList.replace(/,(?=\s*$)/, '');
        this.printConfirmation("", this.state.invoiceList[0].pvrno, -1);
   
};

printConfirmation(checkNo, pvrNo, tblindex) {
  //this.setState({ confirmAlldisabled: true })
  
  let request: any = {};
  request.CheckNos = checkNo;
 
  request.bankid =  this.state.formData.PaymentMethod;
  request.Pvrno = pvrNo;
  InvoiceService.UpdatePrintConfirm(request)
      .then(async (result: any | null) => {
          if (result.success) {
            this.closeSlideOut();
              
            
          }
          resolve();
      })
      .catch((error) => {
          this.setState({ confirmAlldisabled: false });
          toast.error("Something went wrong. " + error, {
              position: toast.POSITION.BOTTOM_RIGHT,
          });
          reject();
      });
}


  handleSelectedItem1 = (control: any, id: any) => {
    let formData = { ...this.state.formData };

    if(control == 1)
    this.state.formData.AccountingPeriod = id;
    else if(control == 2)
      {
      let bankid = this.state.bankList.filter((x)=>x.id == id);
    let bankname =  bankid.length > 0 ? bankid[0].name : '';
      formData.PaymentMethod=id;
      formData.bankname=bankname;
      this.setState({formData:formData});
      }
   // this.state.formData.PaymentMethod = id;
  };

  switchToGroup =() =>{

    if(this.state.isgroup==0)
    {
      

      this.setState({ isgroup:1  }, () => {
        this.getInvoiceList(1);  
      });
   
    }
    else
    {

      this.setState({ isgroup:0  }, () => {
        this.getInvoiceList(0);  
      });

    }
  };



  render() {
  
    const templateColumns = [
      {
        dataField: 'id',
        text: 'id',
        sort: true,
        hidden:true,
        headerStyle: { width: '100px' },
      },
      {
        dataField: 'vendorCode',
        text: 'Vendor Code',
        sort: true,
      },
      {
        dataField: 'vendorName',
        text: 'Vendor Name',
        sort: true,
      },
      {
         dataField: 'invoiceNo',
        text: 'Invoice #',
         sort: true,
         hidden: this.state.formData.PaymentType=="ACH" ? false: true,
         headerStyle: { width: '100px' },
       },
       {
         dataField: 'invoiceDate',
        text: 'Invoice Date',
         sort: true,
         hidden:this.state.formData.PaymentType=="ACH" ? false:  true,
         headerStyle: { width: '100px' },
         formatter: (cell: any, row: any, rowIndex: any) => {
           return (
            <EllipsisWithTooltip placement="bottom">
               {moment(row.invoiceDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
           );
         },
       },
     
      {
        dataField: 'dueDate',
        text: 'Due Date',
        sort: true,
        hidden:true,
        headerStyle: { width: '100px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.orderDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },      
      {
        dataField: 'accountingPeriod',
        text: 'Accounting Period',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'totalAmount',
        text: 'Total Amount',
        headerClasses: 'amount',
        classes: 'text-end pe-4 divgreen',
        sort: true,
  
        searchable: false,
        headerStyle: { width: '100px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {Utils.currencyFormat(row.totalAmount)}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "payDate",
        text: "Pay Date",
        headerStyle: { width: "200px" },
        hidden: false,
        editable: false,
        headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
        formatter: (cell: any, row: any, rowIndex: any) => {
          let id = "txtduedate" + rowIndex + "txt";
          let disable = false;
          return (
            <div className="date-picker calender-picker">
              <DatePicker
                ref={this.trdueDate}
                id={id}
                tabIndex={this.state.tabEnable}
                className="form-control text-center p-0"
                placeholderText={"MM/DD/YY"}
                dateFormat="MM/dd/yy"
                selected={
                  row.payDate === null
                    ? new Date()
                    : row.payDate === ""
                      ? new Date()
                      : new Date(row.payDate)
                }
               onChange={(e) => this.updateState(e, rowIndex, "PayDate", id)}
                // onKeyDown={(e: any) =>
                //   this.onTAB.bind(
                //     this,
                //     "txtduedate-" + rowIndex.toString() + "txt",
                //     rowIndex
                //   )
                // }
                autoComplete="off"
              //onKeyDown={this.onTAB.bind(this, "txttotal-" + rowIndex.toString() + "txt", rowIndex)}
              // disabled={isBedgeProcessing && true}
              />
            </div>
          );
        },
      },
      {
        dataField: "memo",
        text: "Memo",
        headerStyle: { width: "300px" },
        hidden: this.state.formData.PaymentType=="ACH" ? true: false,
        editable: false,
        headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
        formatter: (cell: any, row: any, rowIndex: any) => {
          let id = "txtpartno" + rowIndex + "txt";
          let disable = false;
          return (
            <div>
              <Form.Control
                tabIndex={this.state.tabEnable}
                id={id}
                type="text"
                placeholder="Enter Memo"
                value={row.memo}
                 onChange={(e) => this.updateState(e, rowIndex, "memo", id)}
                autoComplete="off"
                disabled={disable}
                title=""
                maxLength={500}
              />
            </div>
          );
        },
      },
  
      {
        dataField: 'ckno',
        text: 'Ck No.',
      hidden :this.state.formData.PaymentType=="ACH" ? true: false,
        classes: 'amount',
        sort: true,
        searchable: false,
        headerStyle: { width: '100px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.ckno}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: '',
        text: '',
        sort: true,
        searchable: false,
        //headerStyle: { width: '300px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          {if  (row.ckno != ""  )
  
          return (
            <EllipsisWithTooltip placement="bottom">
              {<Button onClick={(x) => this.reprint(row)}>Reprint</Button>}
            </EllipsisWithTooltip>
          );
          }
         
  
        },
      },
  
      {
        dataField: 'pvrno',
        text: '',
        hidden:true,
        sort: false,
        searchable: false,
      
  
      },
  
     
  
  
    ];
  
    
    const {

      showjobrModal,

    } = this.state;
    
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var first = date.getDate() - date.getDay();

    return (
      //  <div className="transaction-slideout invoice-entry pos-fxd index-1000">

      <section>
        <div className="details-drawer"
          style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
        // className="back-drop1  viewer-panel1 opacity "
        >
          <ToastContainer
            containerId={"userDetailSlideoutcontainer"}
            autoClose={3000}
          />

          <div className="d-flex">
            {!this.state.isOpenViewer && (
              <div
                className="back-drop1  viewer-panel1 opacity "
                onClick={() => this.closeSlideOut()}
              ></div>
            )}

            <Container
              fluid

              className="body-sec viewer-panel back-drop p-0 "
              style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}

            >
              <div
                className="container-fluid py-1 bg-white d-flex align-items-center  border-bottom round-4"
              >
                <div className="divheader">
                  <h5 className="my-title">
                 Review Payment  
                  </h5>


                  <div className="header-popout ml-auto">





                  <div className="container text-center">
  <div className="row align-items-start">
    <div className="col">
      </div>

      <div className="col">
      <button
                          type="button"
                          className="btn btn-primary cross"
                          onClick={this.closeSlideOut}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                              fill="#ffffff"
                            />
                          </svg>
                        </button>
      </div>
      </div>
      </div>
                  </div>
                </div>
              </div>
              <div
                className="scroll"
              >

                <section className="pb-3">
                  <div className="row g-3">

                    <div className="col-12 col-md-3" >
                    <Form.Label className="font-semibold">  Bank</Form.Label>
                    <div  className="w-auto" >
                    
                <Form.Label>
                
                        { this.state.bankList.length > 0 && 
                        <SingleDropdownListWithoutSearch
                        tabIndex={this.state.tabEnable}
                        itemList={this.state.bankList}
                        handleSelectedItem={this.handleSelectedItem1.bind(this,2)}                        
                         defaultItem={this.state.formData.bankname}
                        defaultText={"Select Bank"}
                        defaultName={this.state.formData.bankname}
                       
                        controlID="1"
                        id={"ddlBank"}
                      />}
                       
                      </Form.Label>
                        </div>

                    </div>
                    <div className="col-12 col-md-6" >
                    <Form.Label className="font-semibold"> Payment Method</Form.Label>

                    <div className="row g-3">
                    <div className="col-12 col-md-6" >
                        { 
                        this.state.formData.PaymentType =="ACH" ? "ACH" :

                        <div className="default-theme-toggle">
                       <Form.Check
                              type="switch"
                              id="switchtodefault"
                              label="Group Check"
                              name="hasEnterpriseAccess"
                             // className="custom-switch1"
                              disabled ={ this.state.formData.PaymentType=="ACH" ? true:false}
                             // checked={useDefaultTheme == "true"}
                              onChange={(event: any) => {
                                this.switchToGroup();
                              }}
                            />
                            
                            </div>
                      }
                      </div>
                      
                    </div>

                       
                    </div>
                 
                    
                  </div>
                </section>






                <section className="pb-3">

                  <div
                    className="table-responsive table table-borderless align-middle transactions-list"

                  >
                     {  templateColumns.length > 0   && (
      <div className="split-assign-coa">
        <BootstrapTable
          id="tbltemplate"
          keyField="templateid"
          data={this.state.invoiceList}
          columns={ templateColumns }
        />
      </div>
    )}
                  </div>
                </section>

                <div className={"footer-section viewer-panel"}
                  style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
                >
 <div>
 <div className="d-flex gap-3 align-items-center justify-content-end height-72">
                      <div className="d-flex gap-3 align-items-center">
                        {
                          (
                            <>
                              <Button
                                variant="primary"
                                type="button"
                                className="space btn btn-primary btn-discard"
                                onClick={this.closeSlideOut}
                              >
                                Discard
                              </Button>
                              {(this.state.Ispaid ==false) && (
                              <Button
                                id="btnPayment"
                                variant="primary"
                                type="button"
                                onClick={() => this.saveItem()}
                               
                              >
                                {"Submit Payment"}
                              </Button>
                              )}
                              {(this.state.Ispaid ) && (
                              <Button
                                id="btnPayment"
                                variant="primary"
                                type="button"
                                onClick={() => this.genratePDFPrintPreview("","","")}
                               
                              >
                                {"Print Check"}
                              </Button>

                              
                              )}

{(this.state.IsPrinted ) && (
                              <Button
                                id="btnPayment"
                                variant="primary"
                                type="button"
                                onClick={() => this.onConfirnAllPrintedClick()}
                               
                              >
                                {"Confirmed Print"}
                              </Button>

                              
                              )}
  
  
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </Container>
          </div>

          1

          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}






        </div>

      </section>
    );
  }
}
