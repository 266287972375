import axios from "axios";
import Instense from "./Axios-config";
import { API_ROOT } from "./Api-config";
 
export class PartTemplateService {

 
  public static DeleteTMaster = async (
    request:  any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    request.tenantId = tenantID;
    const url = `/TMasters/DeleteTMaster`;
    return Instense.delete(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };
 
  public static UpdateTMasters = async (request: any): Promise<any> => {
    
   let storage = JSON.parse(localStorage.getItem("storage")!);
   let tenantID = storage === null ? 0 : (storage.tenantID as any);
   let userName = storage === null ? "" : (storage.userName as any);
   request.tenantid = tenantID;
   const url = `/TMasters/UpdateTMasters `;
   return Instense.post(url,    request  ).then((response) => {
    
       let result = response.data.result as any[];
       return result;
   });
 };

public static GetPartTemplatelist = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/TMasters/GetTemplatelist`;
  
  return Instense.get(url, { params: request }).then((response) => {
    
    let result = response.data.result as any;
    
    return result;
  });
};
 
public static GetProcessList = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantid = tenantID;
  const url = `/Product/GetProcessMasters`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
public static GetProcessListAll = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.tenantid = tenantID;
  const url = `/Product/GetProcessMastersAll`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
 
   
public static GetTemplatedata = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/TMasters/GetTemplatedata`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
public static GetPartdata = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Job/GetPartData`;

  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetProcessDataByJobId = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Job/JobDetails`;

  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
public static SaveTemplateData = async (request: any): Promise<any> => {
  // 
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.TenantID = tenantID;
  request.UserName = userName;
  const url = `/TMasters/SaveTemplate `;
  return Instense.post(url, request).then((response) => {
    // 
      let result = response.data.result as any[];
      return result;
  });
};
public static Saveprocess = async (request: any): Promise<any> => {
   
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.tenantid = tenantID;
  const url = `/Product/UpdateProcessMasters `;
  return Instense.post(url,    request  ).then((response) => {
    // debugger;
      let result = response.data.result as any[];
      return result;
  });
};

public static DeleteProcess = async (
  request:  any
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  request.tenantId = tenantID;
  const url = `/Product/DeleteProcess`;
  return Instense.delete(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

 
}