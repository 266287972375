import React from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Container,
  Dropdown,
  Form,
  Button,
  Spinner, OverlayTrigger, Tooltip,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";
import { Utils } from "../../Common/Utilis";
import { InvoiceService } from "../../Common/Services/InvoiceService";
import { resolve, reject } from "q";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import "../../App.scss";

import { CustomerSearch } from "../../Common/Components/CustomerSearch";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ProductService } from "../../Common/Services/ProductService";
import { ConfirmationModal } from "../../Common/Components/ConfirmationModal";
import { ConfirmationModalNonTable } from "../../Common/Components/ConfirmationModalNonTable";
import { FiChevronDown } from "react-icons/fi";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomerService } from "../../Common/Services/CustomerService";
import { MdBuild } from 'react-icons/md';
import { debug } from "console";
import Dropzone from "react-dropzone";
import {
  faPlus,
  faMinus,
  faPaperclip,
  faFile,
  faFileImage,
  faFileExcel,
  faFilePdf,
  faFileCsv,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
import ScrollContainer from "react-indiana-drag-scroll";
import { CommentBox } from "../../Common/Components/CommentBox";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
const { SearchBar } = Search;
import PDFViewer from "../pdf-viewer";
import { isAbsolute } from "path/posix";
let routeLeavingGuard: any = null;
let docViewerFiles: any = [];
export class Receivableslideout extends React.Component<any, any> {
  private scrollvdRef: any;
  private customerSearch: any;
  private trDate: any;
  private trdueDate: any;
  private checkAllRef: any = {};
  private scrollArea: any = {};
  private parentScroll: any;
  private scrollheight: any;
  private footerchk: any;
  private ChildComment: any;
  private icheckselectedall = (column, colIndex) => {
    return (
      <div className="d-flex align-items-center txt-bold">
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="postingRule">Click to copy below all parts</Tooltip>
          }
        >
          <FontAwesomeIcon
            icon={faArrowUp}
            size="xs"
            onClick={(e) => this.handleRowSelectionCheckAll(e, colIndex)}
          />
        </OverlayTrigger>
        {/* <input

                type="checkbox"
                onChange={(e) => this.handleRowSelectionAll(e, colIndex)}
            /> */}
      </div>
    );
  };
  private templateColumns = [
    {
      dataField: "customerPoNumber",
      text: "Order No",
      headerStyle: { minWidth: '100px', width: '100px' },
    },
    {
      dataField: 'orderDate',
      text: 'Order Date',
      sort: true,

      headerStyle: { minWidth: '100px', width: '100px' },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <EllipsisWithTooltip placement="bottom">
            {moment(row.orderDate).format("MM/DD/YYYY")}
          </EllipsisWithTooltip>
        );
      },
    },

    // {
    //   dataField: "description",
    //   text: "Description",
    //   headerStyle: { minWidth: '400px', width: '400px' },
    // },

    {
      dataField: "amount",
      text: "Total",
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div>{Utils.currencyFormat(this.state.TotalRAmount)}</div>
        );
      }
    },

  ];

  constructor(props: any) {
    super(props);
    this.parentScroll = React.createRef();
    this.scrollvdRef = React.createRef();
    this.trDate = React.createRef();
    this.trdueDate = React.createRef();
    this.ChildComment = React.createRef();
    // this.Previoustransactions = this.Previoustransactions.bind(this);
    // this.loadPreviousData = this.loadPreviousData.bind(this);
    // this.getInvoice=this.getInvoice.bind(this);
    this.state = {
      IsLoad: 0,
      isSlideopen: false,
      invoiceId: this.props.invoiceId,
      templateColumns: this.templateColumns,
      PaymentMethodname: "Select",
      accountingperiodname: "",
      CustomerCode: "",
      CustomerName: "",
      TotalRAmount: 0,
      expanded: [0],
      isprevious:false,
      formData: {
        PrefixInvoiceNo: "",
        Id: 0,
        InvoiceNo: "",
        TenantId: 0,
        InvoiceDate: null,
        DueDate: null,
        AccountingPeriod: null,
        printpingCharge: 0.0,
        OtherCharge: Utils.currencyFormat(0.0),
        SaleTax: 0.0,
        SaleTaxAmount: 0.0,
        Amount: 0.0,
        TotalAmount: 0.0,
        CustomerCode: "",
        CustomerName: "",
        CustomerId: "",
        InvoiceDetail: [],
        PaymentMethod: null,

        InternalNotes: "",
        CheckNo: "",
        PaymentDate: null,

      },
      invoicedetails: [

      ],
      orderIds: [],
      products: [],

      invoiceList: [],
      previousOrderData: [],
      orderData: [],
      customerOrderDetails: [],
      Accountingperiod: [],
      selectedRows: [],
      PaymentMethod: [{ name: "ACH", id: "ACH" },
      { name: "Check", id: "Check" }],
    };

  }


  beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
    if (
      this.state.isStateChanged &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  componentDidMount() {


    this.getAccountingPeriods();
    this.getInvoice(0);

  }

  getInvoiceList = (orderid) => {

    let requestlist = { ...this.state.requestlist };
    requestlist.orderid = orderid;
    InvoiceService.GetInvoiceListorderid(requestlist)
      .then(async (result: any | null) => {

        if (result != null) {
          // console.log(result);
          this.setState({ invoiceList: result }, () => {
            // this.bindfilter();
          });
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  };


  // Previoustransactions() {

  //   this.setState((prevState) => ({
  //     isPreviousTransactions: !prevState.isPreviousTransactions,
  //   }));

  //   let requestlistprev = { ...this.state.requestlistprev };
  //   requestlistprev.recordCount = 5;
  //   this.GetPreviousTransactionList(requestlistprev);
  //   this.setState({ requestlistprev });
  // }

  // GetPreviousTransactionList = (requestlistprev: any) => {
  //   // requestlist.customerid = this.state.formData.CustomerID;

  //   //requestlist.tenantid = this.state.tenantid;
  //   let saveRequest: any = {};
  //   saveRequest.customerid = this.state.formData.CustomerId;
  //   saveRequest.tenantid = 0;
  //   saveRequest.recordCount = requestlistprev.recordCount;
  //   CustomerService.GetPendingInvoiceCustomerOrder(saveRequest)
  //     .then(async (result: any | null) => {
  //       if (result !== null) {
  //         let totalCount = 0;
  //         if (result.length > 0) {
  //           totalCount = result[0].totalCount;
  //           result = result.filter((x) => this.state.invoicedetails.filter((a) => a.customerPoNumber == x.customerPoNumber).length == 0);

  //         }

  //         this.setState({ previousOrderData: result, totalCount: totalCount });
  //       }
  //       resolve();
  //     })
  //     .catch((error) => {
  //       reject();
  //       // this.setState({ isSaveSpinner: false});
  //     });
  // };

  // loadPreviousData() {

  //   let requestlistprev = { ...this.state.requestlistprev };
  //   let loadrecord = requestlistprev.recordCount;
  //   requestlistprev.recordCount = Number(requestlistprev.recordCount) + 5;
  //   if (Number(loadrecord) < Number(this.state.totalCount)) {
  //     this.GetPreviousTransactionList(requestlistprev);
  //     this.setState({ requestlistprev });
  //   }
  //   //    else {
  //   //        toast.error("No previous order exist.", {
  //   //          position: toast.POSITION.BOTTOM_RIGHT,
  //   //           containerId: "Customerorderslideout",
  //   //        });
  //   //      }
  // }
  getAccountingPeriods = () => {
    let accountingPeriod: any = [];
    let currentDate = new Date();


    currentDate.setDate(1);
    currentDate.setMonth(currentDate.getMonth() - 4);
    accountingPeriod.push({ id: moment(currentDate).format("MM/DD/YYYY"), name: moment(currentDate).format("MM/DD/YYYY") });
    currentDate.setMonth(currentDate.getMonth() + 1);
    accountingPeriod.push({ id: moment(currentDate).format("MM/DD/YYYY"), name: moment(currentDate).format("MM/DD/YYYY") });
    currentDate.setMonth(currentDate.getMonth() + 1);
    accountingPeriod.push({ id: moment(currentDate).format("MM/DD/YYYY"), name: moment(currentDate).format("MM/DD/YYYY") });
    currentDate.setMonth(currentDate.getMonth() + 1);
    accountingPeriod.push({ id: moment(currentDate).format("MM/DD/YYYY"), name: moment(currentDate).format("MM/DD/YYYY") });
    currentDate.setMonth(currentDate.getMonth() + 1);
    accountingPeriod.push({ id: moment(currentDate).format("MM/DD/YYYY"), name: moment(currentDate).format("MM/DD/YYYY") });
    currentDate.setMonth(currentDate.getMonth() + 1);
    accountingPeriod.push({ id: moment(currentDate).format("MM/DD/YYYY"), name: moment(currentDate).format("MM/DD/YYYY") });
    currentDate.setMonth(currentDate.getMonth() + 1);
    accountingPeriod.push({ id: moment(currentDate).format("MM/DD/YYYY"), name: moment(currentDate).format("MM/DD/YYYY") });
    currentDate.setMonth(currentDate.getMonth() + 1);
    accountingPeriod.push({ id: moment(currentDate).format("MM/DD/YYYY"), name: moment(currentDate).format("MM/DD/YYYY") });
    currentDate.setMonth(currentDate.getMonth() + 1);
    accountingPeriod.push({ id: moment(currentDate).format("MM/DD/YYYY"), name: moment(currentDate).format("MM/DD/YYYY") });

    this.setState({ Accountingperiod: accountingPeriod }, () => { });

  };

  getInvoice = (type) => {
    
    let requestlist = { ...this.state.requestlist };


    requestlist.invoiceId = type == 0 ? this.props.invoiceId : type;
    requestlist.orderIds = type == 0 ? this.props.orderIds.join(',') : null;
    requestlist.products = type == 0 ? this.props.products.join(',') : null;

    InvoiceService.GetInvoice(requestlist)
      .then(async (result: any | null) => {

        if (result != null) {
          
          let formData = { ...this.state.formData };
          formData.Id = result.id;
          formData.InvoiceNo = result.invoiceNo;
          formData.PrefixInvoiceNo = result.prefixInvoiceNo;
          formData.TenantId = result.tenantId;
          formData.InvoiceDate = result.invoiceDate;
          formData.DueDate = result.dueDate;
          formData.PaymentDate = result.paymentDate;
          formData.PaymentMethod = result.paymentMethod;


          let currentDate = new Date();
          currentDate.setDate(1);
          currentDate.setMonth(currentDate.getMonth());
          if (result.accountingPeriod == null)
            // formData.AccountingPeriod =currentDate.toLocaleDateString();
            formData.AccountingPeriod = moment(currentDate).format("MM/DD/YYYY");
          else
            formData.AccountingPeriod = result.accountingPeriod;
          formData.ShippingCharge = Utils.currencyFormat(result.shippingCharge);
          formData.OtherCharge = Utils.currencyFormat(result.otherCharge);
          formData.SaleTax = result.saleTax;
          formData.SaleTaxAmount = result.saleTaxAmount;
          // result.saleTaxAmount=== ""
          // ? 0
          // : !isNaN( result.saleTaxAmount)
          //   ? Utils.currencyFormat( result.saleTaxAmount)
          //   : result.saleTaxAmount;
          // Utils.currencyFormat( result.saleTaxAmount);
          //formData.Amount =  Utils.currencyFormat(result.amount);
          formData.TotalAmount = result.totalAmount;
          formData.CustomerCode = result.customerCode;
          formData.CustomerName = result.customerName;
          formData.CustomerId = result.customerId;
          let InvList = result.customerOrderDetails;
          let TotalRAmount = 0;
          InvList.forEach(element => {
            TotalRAmount += ((element.qty * element.unitPrice) - element.discount);
            element.selected = true;

          });

          formData.TotalAmount = TotalRAmount + result.shippingCharge + result.otherCharge + result.saleTaxAmount;




          formData.Amount = TotalRAmount;
          




          this.setState({ expanded: [result.invoiceDetail[0].orderId], invoiceId: result.id, orderid: result.invoiceDetail[0].orderId, TotalRAmount: TotalRAmount, formData: formData, invoicedetails: result.invoiceDetail, customerOrderDetails: InvList, isSpinner: false, PaymentMethodname: result.paymentMethod }
            ,
            () => {
              // this.Previoustransactions();
              this.getInvoiceList(result.invoiceDetail[0].orderId);
            }


          );

        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  };


  received = () => {

    this.setState({ ispopupshow: true });
  };
  receivedcancel = () => {

    this.setState({ ispopupshow: false });
  };
  saveItem = (isPrintRequired: any, ispayment: any) => {
    
    let formData = { ...this.state.formData };


    formData.Amount = formData.Amount == "" ? 0 : Utils.removecurrencyFormat(formData.Amount);
    formData.ShippingCharge = formData.ShippingCharge == "" ? 0 : Utils.removecurrencyFormat(formData.ShippingCharge);
    formData.OtherCharge = formData.OtherCharge == "" ? 0 : Utils.removecurrencyFormat(formData.OtherCharge);
    formData.SaleTaxAmount = formData.SaleTaxAmount == "" ? 0 : Utils.removecurrencyFormat(formData.SaleTaxAmount);

    if (ispayment == true) {

      if (this.state.formData.PaymentDate == null)
        formData.PaymentDate = new Date();
    }



    let isFormValid = true;



    this.setState({ isSaveSpinner: true, ispopupshow: false });

    let request: any = {};

    if (this.state.formData.InvoiceDate === null)
      this.state.formData.InvoiceDate = new Date();

    if (this.state.formData.DueDate === null)
      this.state.formData.DueDate = new Date();

    let invoicedetails: any = [];
    let invoicedetailslist = this.state.invoicedetails;
    let jobList = this.state.customerOrderDetails;

    jobList.forEach(element => {

      let item: any = {};
      
      if (element.selected == true) {

        item.Amount = Utils.removecurrencyFormat((element.qty * element.unitPrice) - element.discount);
        item.Description = element.jobDesc;
        item.OrderDate = element.dueDate;
        item.ProductId = element.productid;
        item.CustomerPoNumber = this.state.invoicedetails[0].customerPoNumber;
        //element.customerPoNumber;
        item.OrderId = element.orderID;
        item.InvoiceId = formData.Id;
        item.qty = element.qty == "" ? 0 : element.qty;
        item.price = element.unitPrice;
        item.discount = element.discount;

        invoicedetails.push(item);
      }

    });
    formData.InvoiceDetail = invoicedetails;

    if (invoicedetails.length == 0 || invoicedetails == undefined) {

      this.setState({ isSaveSpinner: false });
      toast.success('Atleast one part to select for create invoice', {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });
      isFormValid = false;
    }

    if (formData.TotalAmount <= 0) {
      this.setState({ isSaveSpinner: false });
      toast.success('Invoice Amount should not less than 0.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });
      isFormValid = false;
    }
    else if (moment(formData.InvoiceDate).format("MM/DD/YY") > moment(formData.DueDate).format("MM/DD/YY")) {

      this.setState({ isSaveSpinner: false });
      toast.success('Please check due date. Due date should be greater than or equal to invoice date.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });
      isFormValid = false;
      reject();
    }
    else {

      isFormValid = true;
    }


    request = Object.assign({}, JSON.parse(JSON.stringify(formData)));


    if (isFormValid) {
      InvoiceService.SaveInvoice(request)
        .then(async (result: any | null) => {
          if (result !== null) {

            if (this.state.formData.InvoiceNo != 0) {

              this.setState({ isSaveSpinner: false });
              toast.success('Invoice updated sucessfully.', {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "OrderSlideout",
              });
            }
            else {
              this.setState({ isSaveSpinner: false });
              toast.success('Invoice created sucessfully.', {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "OrderSlideout",
              });
            }
          }

          this.closeSlideOut();

          resolve();
        })
        .catch((error) => {
          this.setState({ isdefaultColDisabled: false, isSpinner: false });
          reject();
        });
    }
  };

  deleteinvoice = () => {
    let request: any = {};
    request.invoiceId = this.state.invoiceId;
    this.setState({ isSpinner: true });

    InvoiceService.DeleteInvoice(request)
      .then(async (result: any | null) => {
        this.closeSlideOut();

        toast.success("Invoice has been deleted.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "OrderSlideout",
        });


        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ showAfterSaveModelPopup: false, isSpinner: false, });
      });

  };

  printItem = () => {
    let request: any = {};
    request.invoiceId = this.state.invoiceId;
    this.setState({ isSpinner: true });

    InvoiceService.DownloadInvoiceFile(request)
      .then(async (result: any | null) => {
        //this.HidecloseSlideOut();
        this.setState({ showAfterSaveModelPopup: false, isSpinner: false, });
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ showAfterSaveModelPopup: false, isSpinner: false, });
      });
  };

  closeSlideOut = () => {
    
    if (this.state.isLoad == 1) {

      this.setState({ isLoad: 0, isSpinner: true, });
      this.getInvoice(0);
    }
    else {
      this.props.closeSlideOut(this.state?.shouldReloadMainPage);
    }
  };

  onAmountFocus = (e, index, txtID, type: any) => {
    let formData = { ...this.state.formData };

    if (type === "OtherCharge") {


      formData.OtherCharge = e.target.value.trim().length > 0 ? Utils.removecurrencyFormat(e.target.value) == 0 ? "" : Utils.removecurrencyFormat(e.target.value) : "";

    }
    if (type === "ShippingCharge") {


      formData.ShippingCharge = e.target.value.trim().length > 0 ? Utils.removecurrencyFormat(e.target.value) == 0 ? "" : Utils.removecurrencyFormat(e.target.value) : "";

    }
    this.setState({ formData });
  };

  amountBlur = (e, rowIndex, type: any) => {
    let formData = { ...this.state.formData };

    if (type === "ShippingCharge") {

      formData.ShippingCharge =
        e.target.value === ""
          ? ""
          : !isNaN(e.target.value)
            ? Utils.currencyFormat(e.target.value)
            : e.target.value;
    }
    if (type === "OtherCharge") {

      formData.OtherCharge =
        e.target.value === ""
          ? ""
          : !isNaN(e.target.value)
            ? Utils.currencyFormat(e.target.value)
            : e.target.value;
    }
    this.setState({ formData });

  };


  handleChange = (event: any, FieldType: string) => {

    let isStateChanged = true;

    let formData = { ...this.state.formData };

    if (FieldType === "ShippingCharge") {
      //formData.ShippingCharge =
      // event.target.value.trim().length > 0 ? event.target.value : 0;
      //formData.TotalAmount = parseFloat(formData.Amount) + parseFloat(formData.SaleTaxAmount) + parseFloat(formData.ShippingCharge) + parseFloat(formData.OtherCharge=="" ?  0 : formData.OtherCharge);

      let newVal = event.target.value == "" ? "" : Utils.removeInvaildCharFromAmount(event.target.value);
      event.target.value = newVal;
      const rx_live = /^[-]?\d*(?:[.]\d*)?$/;
      let lenbeforePoint = 8;
      if (event.target.value.includes("-")) {
        lenbeforePoint = 9;
      }

      if (rx_live.test(event.target.value)) {
        if (event.target.value.includes(".")) {
          const timeArray = (event.target.value)?.split(".");
          if (timeArray[0].length <= lenbeforePoint && timeArray[1].length <= 2) {
            formData.ShippingCharge = event.target.value;
          }
        } else {
          if (event.target.value.length <= lenbeforePoint) {
            formData.ShippingCharge = event.target.value;
          }
        }
      } else if (event.target.value.includes(".") && event.target.value.length === 1) {
        formData.ShippingCharge = event.target.value;

      }
      this.totalAmt(formData.Amount, formData.SaleTaxAmount, formData.OtherCharge, formData.ShippingCharge, formData);

      // this.setState({ formData, isStateChanged }, () => {

      // });


    }
    else if (FieldType === "OtherCharge") {


      let newVal = event.target.value == "" ? "" : Utils.removeInvaildCharFromAmount(event.target.value);
      event.target.value = newVal;
      const rx_live = /^[-]?\d*(?:[.]\d*)?$/;
      let lenbeforePoint = 8;
      if (event.target.value.includes("-")) {
        lenbeforePoint = 9;
      }

      if (rx_live.test(event.target.value)) {
        if (event.target.value.includes(".")) {
          const timeArray = (event.target.value)?.split(".");
          if (timeArray[0].length <= lenbeforePoint && timeArray[1].length <= 2) {
            formData.OtherCharge = event.target.value;
          }
        } else {
          if (event.target.value.length <= lenbeforePoint) {
            formData.OtherCharge = event.target.value;
          }
        }
      } else if (event.target.value.includes(".") && event.target.value.length === 1) {
        formData.OtherCharge = event.target.value;

      }

      this.totalAmt(formData.Amount, formData.SaleTaxAmount, formData.OtherCharge, formData.ShippingCharge, formData);


    }
    else if (FieldType === "SaleTax") {

      let newVal = event.target.value == "" ? "" : Utils.removeInvaildCharFromAmount(event.target.value);
      event.target.value = newVal;
      formData.SaleTax = event.target.value;

      formData.SaleTaxAmount = (parseFloat(Utils.removecurrencyFormat(formData.Amount)) * event.target.value / 100);
      this.totalAmt(formData.Amount, formData.SaleTaxAmount, formData.OtherCharge, formData.ShippingCharge, formData);
      // formData.TotalAmount = parseFloat(formData.Amount) + parseFloat(formData.SaleTaxAmount) + parseFloat(formData.ShippingCharge) + parseFloat(formData.OtherCharge);
    }
    else if (FieldType === "checkno") {

      formData.CheckNo = event.target.value;
    }
    else if (FieldType === "internalnotes") {
      formData.InternalNotes = event.target.value;
    }
    else if (FieldType === "paymentdate") {

      formData.PaymentDate = event.toISOString();

      let txtID = "txtpaymentdate";
      setTimeout(() => {
        //  this.trDate.current.setOpen(true);
        $("input[id^='" + txtID + "']").focus();
      }, 50);
    }
    else if (FieldType === "InvoiceDate") {

      formData.InvoiceDate = event.toISOString();

      let txtID = "txtInvoiceDate";
      setTimeout(() => {
        // this.trDate.current.setOpen(true);
        $("input[id^='" + txtID + "']").focus();
      }, 50);
    }
    else if (FieldType === "DueDate") {
      // let today = new Date(event);
      // let dateNew =
      //   today.getMonth() +
      //   1 +
      //   "/" +
      //   today.getDate() +
      //   "/" +
      //   today.getFullYear().toString().substring(2, 4);
      // let todaynew = new Date(dateNew);
      // let dateNewt =
      //   todaynew.getMonth() +
      //   1 +
      //   "/" +
      //   todaynew.getDate() +
      //   "/" +
      //   todaynew.getFullYear().toString().substring(0, 4);
      // formData.DueDate = dateNewt;
      // this.setState({ formData, isStateChanged });
      formData.DueDate = event.toISOString();

      let txtID = "txtDueDate";
      setTimeout(() => {
        // this.trdueDate.current.setOpen(true);
        $("input[id^='" + txtID + "']").focus();
      }, 50);
    }

    this.setState({ formData, isStateChanged, isSpinner: false }, () => {

    });
  };


  totalAmt = (Amount, ShippingCharge, OtherCharge, SaleTaxAmount, formData) => {
    

    Amount = Amount == "" ? 0 : Utils.removecurrencyFormat(Amount);
    ShippingCharge = ShippingCharge == "" ? 0 : Utils.removecurrencyFormat(ShippingCharge);
    OtherCharge = OtherCharge == "" ? 0 : Utils.removecurrencyFormat(OtherCharge);
    SaleTaxAmount = SaleTaxAmount == "" ? 0 : Utils.removecurrencyFormat(SaleTaxAmount);
    formData.TotalAmount = parseFloat(Amount) + parseFloat(SaleTaxAmount) + parseFloat(ShippingCharge) + parseFloat(OtherCharge);
    this.setState({ formData }, () => {

    });


  };

  handleSelectedItem1 = (control: any, id: any) => {
    
    if (control == 1) {
      this.state.formData.AccountingPeriod = id;
      this.setState({ accountingperiodname: id });
    }
    else if (control == 2) {
      this.state.formData.PaymentMethod = id;
      this.setState({ PaymentMethodname: id });
    }
  };


  // handleSelectedItem1 = (control: any, id: any) => {

  //   if (control == 1)
  //     this.state.formData.AccountingPeriod = id;

  //   else if (control == 2)
  //     this.state.formData.PaymentMethod = id;


  // };

  handleOnSelect = (row, isSelect) => {

    
    let customerOrderDetails = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.customerOrderDetails))
    );

    let listItem = customerOrderDetails.filter(
      (x) => x.id === row.id
    );

    listItem[0].selected = isSelect;

    if (isSelect == false) {

      listItem[0].qty = 0;
    }
    else {
      listItem[0].qty = listItem[0].qtyOrdered - listItem[0].remainingQty;

    }


    this.setState({ customerOrderDetails: customerOrderDetails }, () => {
      let invoicedetails = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.invoicedetails))
      );
      let formData = { ...this.state.formData };
      let TotalRAmount = 0;
      customerOrderDetails.forEach(element => {
        if (element.selected == true) {
          TotalRAmount += ((element.qty * element.unitPrice) - element.discount);
        }
      });
      invoicedetails[0].amount = TotalRAmount;
      this.setState({ TotalRAmount: TotalRAmount, customerOrderDetails: customerOrderDetails, invoicedetails: invoicedetails });
      formData.Amount = TotalRAmount;

      formData.SaleTaxAmount = (parseFloat(Utils.removecurrencyFormat(TotalRAmount)) * this.state.formData.SaleTax / 100);
      this.totalAmt(formData.Amount, formData.SaleTaxAmount, formData.OtherCharge, formData.ShippingCharge, formData);

    }


    )

      ;

    // row.selected=isSelect;
    // let customerOrderResult = Object.assign(
    //   [],
    //   JSON.parse(JSON.stringify(this.state.customerOrderResult))
    // );

    // let listItem = customerOrderResult.filter(
    //   (x) => x.rowId === row.rowId
    // );


    // this.setState({ customerOrderResult: customerOrderResult }, () => { this.setState({ customerOrderResult: this.state.customerOrderResult }); });



  };

  handleOnSelectAll = (isSelect, rows) => {


    let checklistItem = this.state.selectedRowscustomerid.filter(
      (x) => x !== rows[0].customerID
    );

    const ids = rows.map(r => r.rowId);
    let { invoiceTotal, selectedRows, selectedcustomerid, selectedRowscustomerid } = this.state;
    let customerOrderResult = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.customerOrderResult))
    );

    let listItems = customerOrderResult.filter(
      (x) => ids.includes(x.rowId)
    );




    if (checklistItem.length == 0) {
      if (isSelect) {

        listItems.forEach((element: any, index: any) => {
          invoiceTotal += element.totalAmount;

        });

        selectedcustomerid = rows[0].customerID;
      }
      else {
        listItems.forEach((element: any, index: any) => {
          invoiceTotal -= element.totalAmount;

        });

        selectedcustomerid = 0;
      }


      if (isSelect) {
        selectedRows = [...this.state.selectedRows, rows[0].orderID];
        selectedRowscustomerid = [...this.state.selectedRowscustomerid, rows[0].customerID];


      } else {
        selectedRows = this.state.selectedRows.filter(x => x !== rows[0].orderID);
        selectedRowscustomerid = this.state.selectedRowscustomerid.filter(x => x !== rows[0].customerID);
      }
    }
    else {

      toast.error("if you make one invoice for multiples order then customer should be same ");

    }
    if (checklistItem.length == 0) {
      if (listItems.length > 0) {
        listItems.forEach((element: any, index: any) => {

          if (checklistItem.length == 0) {
            element.selected = isSelect;
          }

        });
      };
    }

    this.setState({ customerOrderResult: customerOrderResult, footershow: selectedRows.length == 0 ? false : true, invoiceTotal: invoiceTotal, selectedRows: selectedRows, selectedRowscustomerid: selectedRowscustomerid });
  };


  ishandleDeleteRow(row: any, mode: any) {

    let invoicedetails = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.invoicedetails))
    );

    let customerOrderDetails = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.customerOrderDetails))
    );

    let previousOrderData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.previousOrderData))
    );
    let rowInvoiceDetails = invoicedetails.filter((x) => x.orderId == row.orderID);
    let rowCODetails = customerOrderDetails.filter((x) => x.orderID == row.orderID);

    let item: any = {};
    let element = { ...rowInvoiceDetails[0] };
    this.state.formData.TotalAmount = Utils.removecurrencyFormat(this.state.formData.TotalAmount) - element.amount;
    this.state.formData.Amount = this.state.formData.TotalAmount;

    item.customerPoNumber = element.customerPoNumber;
    item.orderDate = element.orderDate;
    item.totalAmount = element.amount;
    element = { ...rowCODetails[0] };
    item.qtyOrdered = element.qtyOrdered;
    item.orderID = element.orderID;
    item.unitPrice = element.unitPrice;
    item.partNo = element.partNo;
    item.partname = element.partname;
    item.dueDate = element.dueDate;
    item.amount = element.unitPrice * element.qty;
    item.jobDesc = element.jobDesc;
    item.discount = element.discount;
    item.mainType = 1;
    previousOrderData.push(item);

    rowCODetails.forEach(element => {
      let item: any = {};
      item.customerPoNumber = rowInvoiceDetails[0].customerPoNumber;
      item.orderDate = rowInvoiceDetails[0].orderDate;
      item.totalAmount = rowInvoiceDetails[0].amount;
      item.qtyOrdered = element.qtyOrdered;
      item.orderID = element.orderID;
      item.unitPrice = element.unitPrice;
      item.partNo = element.partNo;
      item.partname = element.partname;
      item.dueDate = element.dueDate;
      item.amount = element.unitPrice * element.qtyOrdered;
      item.jobDesc = element.jobDesc;
      item.discount = element.discount;
      item.mainType = 2;
      previousOrderData.push(item);

    });



    invoicedetails = invoicedetails.filter((x) => x.orderId !== row.orderID);
    customerOrderDetails = customerOrderDetails.filter((x) => x.orderID !== row.orderID);

    this.setState({ previousOrderData: previousOrderData, invoicedetails: invoicedetails, customerOrderDetails: customerOrderDetails });

  }


  showprevious =()=>{

    if(this.state.isprevious==false)
      {
    this.setState({isprevious:true});
      }
      else
      {
        this.setState({isprevious:false});
      }

  };

  handleRowSelectionCheck = (e, rowData, Orderid, isAll) => {
    let { selectedRows } = this.state;

    selectedRows = [...this.state.selectedRows, Orderid];
    let invoicedetails = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.invoicedetails))
    );

    let customerOrderDetails = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.customerOrderDetails))
    );

    let previousOrderData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.previousOrderData))
    );

    if (this != undefined) {
      let orderArray: any = [];

      orderArray = previousOrderData.filter(
        (x) => x.orderID === Orderid && x.mainType == 2
      );

      let item: any = {};
      let element = orderArray[0];
      item.id = 0;
      item.invoiceId = 0;
      item.orderId = element.orderID;
      item.productId = element.productid;
      item.customerPoNumber = element.customerPoNumber;
      item.description = '';
      item.orderDate = element.orderDate;
      item.amount = element.totalAmount;

      invoicedetails.push(item);
      this.state.formData.TotalAmount = parseFloat(Utils.removecurrencyFormat(this.state.formData.TotalAmount)) + item.amount;
      this.state.formData.Amount = this.state.formData.TotalAmount;
      orderArray.forEach(element => {
        let itemCOD: any = {};
        itemCOD.id = 0;
        itemCOD.qtyOrdered = element.qtyOrdered;
        itemCOD.orderID = element.orderID;
        itemCOD.unitPrice = element.unitPrice;
        itemCOD.partNo = element.partNo;
        itemCOD.partname = element.partname;
        itemCOD.dueDate = element.dueDate;
        itemCOD.amount = element.unitPrice * element.qtyOrdered;
        itemCOD.jobDesc = element.jobDesc;
        itemCOD.discount = element.discount;
        customerOrderDetails.push(itemCOD);

      });

      previousOrderData = previousOrderData.filter((x) => x.orderID !== Orderid);
      this.setState({ selectedRows: selectedRows, previousOrderData: previousOrderData, invoicedetails: invoicedetails, customerOrderDetails: customerOrderDetails });

      /* let orderlist: any = [];
       if (orderArray.length > 0) {
         orderArray.forEach((element: any, index: any) => {
           if (rowData === index) {
             let orderDatacheck = this.state.orderData.filter(
               (x) =>
                 x.productid === element.productid ||
                 x.partname === element.partname
             );
             if (orderDatacheck.length > 0) {
               this.setState({
                 isDuplicate: true,
                 Isadded: false,
                 Orderid: Orderid,
                 rowData: rowData,
               });
             } else {
               if (isAll === "all") {
                 this.handleRowSelectionn(e, 0, Orderid);
               } else {
                 this.handleRowSelection(e, rowData, Orderid);
               }
             }
           }
         });
       }
         */
    }
  };

  handleRowSelectionCheckAll = (e, Orderid) => {


    if (this != undefined) {
      let { selectedRows } = this.state;

      selectedRows = [...this.state.selectedRows, Orderid];
      let prevorderid = this.state.prevOrderidselected;

      let orderArray: any = [];
      let previousOrderData: any = [];
      previousOrderData = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.previousOrderData))
      );
      orderArray = previousOrderData.filter(
        (x) => x.orderID === prevorderid && x.mainType == 2
      );

      if (orderArray.length > 0) {
        orderArray.forEach((element: any, index: any) => {
          //  this.handleRowSelectionn(e,0,prevorderid);
          this.handleRowSelectionCheck(e, index, prevorderid, "all");
        });
      } else {
        previousOrderData = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.previousOrderData))
        );
      }
      this.setState({ selectedRows: selectedRows, previousOrderData: previousOrderData });
    }
  };

  handleRowSelectionn = (e, rowData, Orderid) => {

    let orderData: any = [];
    let defaultPartname: any = [];
    let isStateChanged = true;
    orderData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.orderData))
    );

    let previousOrderData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.previousOrderData))
    );

    if (this != undefined) {
      let orderArray: any = [];

      orderArray = previousOrderData.filter(
        (x) => x.orderID === Orderid && x.mainType == 2
      );

      let orderlist: any = [];
      if (orderArray.length > 0) {
        orderArray.forEach((element: any, index: any) => {

          let defaultPartname: any = [];
          let objPartname: any = {};
          objPartname.partname = element.partname;
          objPartname.label = element.partname;
          objPartname.id = element.productid;

          objPartname.partno = element.partNo;

          objPartname.tenantid = 0;
          objPartname.unit = element.unit;
          objPartname.unitPrice = Utils.currencyFormatWithOutdoller(element.unitPrice);
          objPartname.noofday = null;
          objPartname.jobDesc = element.jobDesc;

          defaultPartname.push(objPartname);
          element.defaultPartname = defaultPartname;

          element.itemNo = index + 1;
          element.dueDate = new Date();
          element.qtyOrdered = 0;
          // element.unitPrice = element.unitPrice === '' ? '' : !isNaN(element.unitPrice) ? Utils.currencyFormatWithOutdoller(element.unitPrice) : element.unitPrice;

          orderData.push(element);
          let orderlistItem: any = [];
          //   dueDate:'',jobDesc:'',qtyOrdered:'',unit:'',unitPrice:'',discount:'',total:''
          orderlistItem.itemNo = index + 1;
          orderlistItem.partname = element.partname;
          orderlistItem.partno = element.partNo;
          orderlistItem.dueDate = new Date();
          //element.dueDate;
          orderlistItem.jobDesc = element.jobDesc;
          orderlistItem.qtyOrdered = 0;
          //element.qtyOrdered;
          orderlistItem.unit = element.unit;
          orderlistItem.unitPrice = Utils.currencyFormatWithOutdoller(element.unitPrice);
          orderlistItem.discount = Utils.currencyFormatWithOutdoller(element.discount);
          orderlistItem.total = element.total;
          orderlistItem.productid = element.productid;
          orderlist.push(orderlistItem);
        });
      } else {
        orderData = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.orderData))
        );
      }

      this.setState({ orderData: orderData, defaultPartname, isStateChanged });

      this.setState({ orderData: [] }, () => {
        const orderDataNewc = orderData
          .filter((item) => item.partname !== "")
          .map((item, index) => {
            item.itemNo = index + 1;

            return item;
          });

        this.setState(
          {
            orderData: orderDataNewc,
          },
          () => { }
        );
      });
    }
  };

  handleRowSelectionAll = (e, rowIndex: any) => {
    if (this != undefined) {
      let prevorderid = this.state.prevOrderidselected;

      let orderArray: any = [];
      let previousOrderData: any = [];
      previousOrderData = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.previousOrderData))
      );
      orderArray = previousOrderData.filter(
        (x) => x.orderID === prevorderid && x.mainType == 2
      );

      if (orderArray.length > 0) {
        orderArray.forEach((element: any, index: any) => {
          this.handleRowSelectionn(e, 0, prevorderid);
        });
      } else {
        previousOrderData = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.previousOrderData))
        );
      }
      this.setState({ previousOrderData: previousOrderData });
    }
  };
  handleRowSelection = (e, rowData, Orderid) => {

    let orderData: any = [];
    let defaultPartname: any = [];
    let isStateChanged = true;
    orderData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.orderData))
    );

    let previousOrderData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.previousOrderData))
    );

    if (this != undefined) {
      let orderArray: any = [];

      orderArray = previousOrderData.filter(
        (x) => x.orderID === Orderid && x.mainType == 2
      );

      let orderlist: any = [];
      if (orderArray.length > 0) {
        orderArray.forEach((element: any, index: any) => {

          if (rowData === index) {

            let defaultPartname: any = [];
            let objPartname: any = {};
            objPartname.partname = element.partname;
            objPartname.label = element.partname;
            objPartname.id = element.productid;

            objPartname.partno = element.partNo;

            objPartname.tenantid = 0;
            objPartname.unit = element.unit;
            objPartname.unitPrice = Utils.currencyFormatWithOutdoller(element.unitPrice);
            objPartname.noofday = null;
            objPartname.jobDesc = element.jobDesc;

            defaultPartname.push(objPartname);
            element.defaultPartname = defaultPartname;

            element.itemNo = index + 1;
            element.dueDate = new Date();
            element.qtyOrdered = 0;
            // element.unitPrice = element.unitPrice === '' ? '' : !isNaN(element.unitPrice) ? Utils.currencyFormatWithOutdoller(element.unitPrice) : element.unitPrice;

            orderData.push(element);
            let orderlistItem: any = [];
            //   dueDate:'',jobDesc:'',qtyOrdered:'',unit:'',unitPrice:'',discount:'',total:''
            orderlistItem.itemNo = index + 1;
            orderlistItem.partname = element.partname;
            orderlistItem.partno = element.partNo;
            orderlistItem.dueDate = new Date();
            //element.dueDate;
            orderlistItem.jobDesc = element.jobDesc;
            orderlistItem.qtyOrdered = 0;
            //element.qtyOrdered;
            orderlistItem.unit = element.unit;
            orderlistItem.unitPrice = Utils.currencyFormatWithOutdoller(element.unitPrice);
            orderlistItem.discount = Utils.currencyFormatWithOutdoller(element.discount);
            orderlistItem.total = element.total;
            orderlistItem.productid = element.productid;
            orderlist.push(orderlistItem);
          }
        });
      } else {
        orderData = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.orderData))
        );
      }

      this.setState({ orderData: orderData, defaultPartname, isStateChanged });

      this.setState({ orderData: [] }, () => {
        const orderDataNewc = orderData
          .filter((item) => item.partname !== "")
          .map((item, index) => {
            item.itemNo = index + 1;

            return item;
          });

        this.setState(
          {
            orderData: orderDataNewc,
          },
          () => { }
        );
      });
    }
  };

  handleEdit() {
    this.setState({ isStateChanged: true, editMode: true });
  }

  updateState = (e: any, index: any, type: any, txtID) => {
    if (this != undefined && e != null) {

      let isStateChanged = true;
      let start = e.target?.selectionStart;
      let end = e.target?.selectionEnd;
      let customerOrderDetails = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.customerOrderDetails))
      );
      let invoicedetails = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.invoicedetails))
      );
      if (type === "qtyOrdered") {
        e.preventDefault();
        let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
        e.target.value = newVal;
        ///const rx_live = /^[-]?\d*(?:[.]\d*)?$/;
        customerOrderDetails[index].qty = e.target.value;
        // customerOrderDetails[index].remainingQty= customerOrderDetails[index].qtyOrdered- e.target.value;

        this.setState({ customerOrderDetails, isStateChanged }, () => {

          let formData = { ...this.state.formData };
          let TotalRAmount = 0;
          customerOrderDetails.forEach(element => {

            TotalRAmount += ((element.qty * element.unitPrice) - element.discount);
          });
          invoicedetails[0].amount = TotalRAmount;
          this.setState({ TotalRAmount: TotalRAmount, customerOrderDetails: customerOrderDetails, invoicedetails: invoicedetails });
          formData.Amount = TotalRAmount;

          formData.SaleTaxAmount = (parseFloat(Utils.removecurrencyFormat(TotalRAmount)) * this.state.formData.SaleTax / 100);
          this.totalAmt(formData.Amount, formData.SaleTaxAmount, formData.OtherCharge, formData.ShippingCharge, formData);

          console.log(customerOrderDetails);
          $("#tblinvchild")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tblinvchild").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }

    }
  };

  rowEvents = {


    onClick: (e: any, row: any, rowIndex: any) => {


      if (row.mainType === undefined) {
        

        if (row.invoiceNo !== 0) {
          this.setState({
            isSpinner: true,
            isLoad: 1

            //,

            //        oldorderIds: this.props.orderIds.join(','),  
            // oldproducts:this.props.products.join(','),
            // oldinvoiceId:this.props.invoiceId,
          });

          this.getInvoice(row.id);
          // if(this.state.showReceivableslideout==true)
          //   {
          //   this.closeInvoiceSlideOut();
          // alert('ok')  ;
          // }


          // let formData = { ...this.state.formData };
          // formData.InvoiceNo = row.invoiceNo;
          // formData.Id= row.id;
          //           this.setState({
          //             showReceivableslideout :  true,
          //              orderid: row.orderId,
          //              invoiceId: row.id,
          //             isSpinner: false,
          //             isSlideopen:true,
          //             IsLoad:1,
          //           //  formData:formData,
          //             // oldorderIds: this.props.orderIds.join(','),  
          //             // oldproducts:this.props.products.join(','),
          //             // oldinvoiceId:this.props.invoiceId,

          //           },
          //           () => {

          // this.getAccountingPeriods();
          // this.getInvoice(row.id);
          // }        

          //         );








        }
        else
          this.setState({
            showSlideout: true, orderid: row.orderID, invoiceId: 0, isSpinner: false,
          });

      }

    },
  };

  showReceivableslideout = (row: any) => {

    this.setState({
      showReceivableslideout: true, orderid: row.orderID,
      invoiceId: this.state.invoiceId,
      orderIds: this.state.customerOrderDetails.filter(x => x.selected === true).map(r => r.orderID),
      products: this.state.customerOrderDetails.filter(x => x.selected === true).map(r => r.productid),
      isSpinner: false,
    });

  };

  closeInvoiceSlideOut = () => {

    this.setState({ showReceivableslideout: false, isSpinner: false }, () => {


    }
    );



    //  this.props.closeSlideOut(this.state?.shouldReloadMainPage);
    // let formData = { ...this.state.formData };
    // formData.InvoiceNo = "";
    //     // formData.Id=0;
    // 
    // if(this.state.isSlideopen==true)
    //   {
    //     this.setState({isSlideopen:false});
    //     this.getInvoice(3);
    //   }
    //   else
    //   {

    //this.setState({ showReceivableslideout: false, isSpinner: false});
    //   }
    // //  this.getCustomerItems();
    // this.getCOItems();
    // this.getInvoiceList();
  };

  actionclick = (eventKey: any) => {
    if (eventKey == "Delete") {
      //this.deleteinvoice();
      confirmAlert({
        title: "Delete Invoice",

        message: "Are you sure you want to delete this Invoice?",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.deleteinvoice(),
          },
          {
            label: "No",
            onClick: () => reject(),
          },
        ],

        //  closeOnEscape: false,
        //  closeOnClickOutside: false,
      });


    }
    else if (eventKey == "Print") {
      

      this.printItem();

    }

  };


  render() {
    const {

      showjobrModal,
      showReceivableslideout,
    } = this.state;

    const expandSplitRow = {
      onlyOneExpanding: true,
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => renderItemTableChild(row, rowIndex),
      expanded: this.state.expanded,
      showExpandColumn: true,
      expandByColumnOnly: true,

      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          // this.setState({expanded:expanded},()=>{
          //   $('.selection-cell input').css('display', 'none');
          // });
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronExpended">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };
    const whiteBgFormatter = (column, colIndex) => {
      return <div className="white-bg"></div>;
    };

    const renderItemTableChild = (mainrow, rowIndex) => {

      let splitDetail: any = [];
      splitDetail = this.state.customerOrderDetails.filter(
        (x) => x.orderID === mainrow.orderId
      );

      const columnCOAChild = [
        // {
        //   dataField: "orderID",
        //   text: "#",
        //   hidden: false,
        // },    
        {
          dataField: "id",
          text: "#",
          hidden: true,
          classes: "expanding-bar",
        },
        {
          dataField: "itemNo",
          text: "#",
          hidden: true,
          classes: "expanding-bar",
        },

        {
          dataField: "srno",
          text: "#",
          hidden: false,
          editable: false,
          classes: "expanding-bar",
          headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
          formatter: (cell: any, row: any, rowIndex: any) => {

            return (
              <div>
                {rowIndex + 1}
              </div>
            );
          },
        },
        {
          dataField: "partNo",
          text: "Part No",
          classes: "expanding-bar",

        },
        {
          dataField: "partname",
          text: "Part Name",
          classes: "expanding-bar",
        },





        //   {
        //     dataField: 'dueDate',
        //     text: 'Due Date',
        //     sort: true,
        //     classes:"expanding-bar",

        //     headerStyle: { width: '100px' },
        //     searchable: this.state.isorderdate,
        //     formatter: (cell: any, row: any, rowIndex: any) => {
        //       return (
        //         <EllipsisWithTooltip placement="bottom">
        //           {moment(row.dueDate).format("MM/DD/YYYY")}
        //         </EllipsisWithTooltip>
        //       );
        //     },
        //   },



        {
          dataField: "jobDesc",
          text: "Description",
          classes: "expanding-bar",
          headerStyle: { width: "300px" },
        },

        {
          dataField: "unit",
          text: "Unit",
          classes: "expanding-bar",
        },
        {
          dataField: "",
          text: "Ordered Qty",
          classes: "expanding-bar",
          formatter: (cell: any, row: any, rowIndex: any) => {
            let id = "txtqty" + rowIndex + "txt";
            //let disable = this.state.formData.CustomerID == 0 ? true : false;
            return (
              <div>
                {row.qtyOrdered}
              </div>
            );
          },

        },
        {
          dataField: "qtyOrdered",
          text: "Inv Qty",
          classes: "expanding-bar",
          formatter: (cell: any, row: any, rowIndex: any) => {
            let id = "txtqty" + rowIndex + "txt";
            //let disable = this.state.formData.CustomerID == 0 ? true : false;
            return (
              <div>
                <Form.Control
                  tabIndex={this.state.tabEnable}
                  id={id}
                  type="text"
                  placeholder="Enter Qty"
                  value={row.qty}
                  onChange={(e) => this.updateState(e, rowIndex, "qtyOrdered", id)}
                  //  onBlur={(e: any) => this.amountBlur(e, rowIndex, "qtyOrdered")}
                  autoComplete="off"
                  pattern="[+-]?\d+(?:[.,]\d+)?"
                  maxLength={20}
                  // disabled={disable}
                  title=""
                  className="form-control form-control-sm"
                />
              </div>
            );
          },
        },

        // {
        //   dataField: "remainingQty",
        //   text: "Remaining Qty",
        //   classes:"expanding-bar",
        //   formatter: (cell: any, row: any, rowIndex: any) => {
        //     let id = "txtqty" + rowIndex + "txt";
        //     //let disable = this.state.formData.CustomerID == 0 ? true : false;
        //     return (
        //       <div>
        //      {row.remainingQty}
        //       </div>
        //     );
        //   },

        // },

        {
          dataField: "unitPrice",
          text: "Unit Price",
          classes: "expanding-bar",

        },

        {
          dataField: "discount",
          text: "Discount",
          classes: "expanding-bar",
        },
        {
          dataField: "",
          text: "Amount",
          classes: "expanding-bar text-end pe-4 divgreen",
          headerClasses: 'text-end pe-4',
          headerStyle: { minWidth: '100px', width: '100px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {Utils.currencyFormat((row.qty * row.unitPrice) - (row.qty == 0 ? 0 : row.discount))}
              </EllipsisWithTooltip>
            );
          },

        },


        // {
        //   dataField: "jobno",
        //   text: "Job Order",
        //   classes:"expanding-bar",

        // }

        // {
        //   dataField: 'Attachment',
        //   text: 'Delete',
        //   //headerFormatter: this.iconFormatter,
        //   headerAttrs: (column, colIndex) => (
        //     { 'tabIndex': '-1' }
        //   ),
        //   formatter: (cell: any, row: any, rowIndex: any) => {
        //     let confirmModalContent = [
        //       {
        //         title: "Delete Row",
        //         desc: "Are you sure you want to delete?",
        //         cancleAction: "Cancel",
        //         ActionTrue: "Delete",
        //       },
        //     ];

        //     if (this.state.invoicedetails.length === 1 ) {  //|| rowIndex == 0
        //       return (
        //         <div></div>
        //       );
        //     }


        //     else {
        //       return (
        //         <Dropdown className="more-action confirmation-modal" alignRight tabIndex={this.state.tabDisable}>
        //           <Dropdown.Toggle className="btn-outline-primary btn btn-primary more fa-trash" id="dropdown-more-delete" tabIndex={this.state.tabDisable}>
        //             <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        //               <path d="M5 5.83329C4.53917 5.83329 4.16667 5.83329 4.16667 5.83329V16.6666C4.16667 17.5866 4.91333 18.3333 5.83333 18.3333H14.1667C15.0867 18.3333 15.8333 17.5866 15.8333 16.6666V5.83329C15.8333 5.83329 15.4608 5.83329 15 5.83329H5ZM8.33333 15.8333H6.66667V8.33329H8.33333V15.8333ZM13.3333 15.8333H11.6667V8.33329H13.3333V15.8333ZM13.8483 3.33329L12.5 1.66663H7.5L6.15167 3.33329H2.5V4.99996H6.66667H13.3333H17.5V3.33329H13.8483Z" />
        //             </svg>
        //           </Dropdown.Toggle>
        //           <Dropdown.Menu>
        //             <ConfirmationModal
        //               confirmModalContent={confirmModalContent}
        //               ishandleDeletePosition={this.ishandleDeleteRow.bind(this, row )}
        //             />
        //           </Dropdown.Menu>
        //         </Dropdown>
        //       );
        //     }
        //   },
        // },


      ];
      return (
        <>

          <div className='px-4 p-4'>
            <BootstrapTable
              id="tblinvchild"
              keyField="id"
              data={splitDetail}
              columns={columnCOAChild}
              //rowEvents={this.rowEvents}
              selectRow={{
                mode: 'checkbox', clickToSelect: false, hideSelectAll: true,
                selected: splitDetail.filter((x) => x.selected === true).map(r => r.id),
                onSelect: this.handleOnSelect,
                onSelectAll: this.handleOnSelectAll,
                hideSelectColumn: false
              }}

            />
          </div>
        </>
      );
    };

    const invoiceColumns = [
      // {
      //   dataField: 'orderID',
      //   text: '#',
      //   headerStyle: { width: '10px' },
      // },
      {
        dataField: 'prefixInvoiceno',
        text: 'Invoice #',
        sort: true,
        headerStyle: { width: '100px' },
        searchable: this.state.iscustomerpo,
      },
      {
        dataField: 'invoiceDate',
        text: 'Invoice Date',
        sort: true,

        headerStyle: { width: '100px' },
        searchable: this.state.isorderdate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.invoiceDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },

      {
        dataField: 'customerPoNumber',
        text: 'Customer PO#',
        sort: true,
        headerStyle: { width: '100px' },
        searchable: this.state.iscustomername,
        // headerStyle: { width: '300px' },
      },

      {
        dataField: 'customerName',
        text: 'Customer Name',
        sort: true,
        searchable: this.state.iscustomername,
        headerStyle: { width: '300px' },

      },


      {
        dataField: 'dueDate',
        text: 'Due Date',
        sort: true,

        headerStyle: { width: '100px' },
        searchable: this.state.isorderdate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.dueDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },


      {
        dataField: 'accountingPeriod',
        text: 'Accounting Period',
        sort: true,
        searchable: this.state.iscustomername,
        // headerStyle: { width: '300px' },
      },

      {
        dataField: 'totalAmount',
        text: 'Total Amount',
        headerClasses: 'amount',
        classes: 'text-end pe-4 divgreen',
        sort: true,
        searchable: false,
        headerStyle: { width: '100px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {Utils.currencyFormat(row.totalAmount)}
            </EllipsisWithTooltip>
          );
        },
      },

      {
        dataField: '',
        text: '',

        sort: false,
        searchable: false,
        headerStyle: { width: '100px' },

      },

      // {
      //   dataField: '',
      //   text: '',
      //   headerStyle: { width: '100px' },
      // },


    ];



    ///////////////Previous order//////////////
    // const expandSplitRowP = {
    //   onlyOneExpanding: true,
    //   parentClassName: "parent-expand-foo",
    //   renderer: (row: any, rowIndex: any) => renderPreviousTableChildP(row),
    //   showExpandColumn: true,
    //   expandByColumnOnly: true,
    //   expandHeaderColumnRenderer: ({ isAnyExpands }) => {
    //     if (isAnyExpands) {
    //       return <div></div>;
    //     }
    //     return <div></div>;
    //   },
    //   expandColumnRenderer: ({ expanded }) => {
    //     if (expanded) {
    //       return (
    //         <span className="chevronExpended">
    //           <img src={chevronRight} alt="chevron Right" />
    //         </span>
    //       );
    //     }
    //     return (
    //       <span className="chevronCollpsed">
    //         <img src={chevronRight} alt="chevron Right" />
    //       </span>
    //     );
    //   },
    // };


    //     const renderPreviousTableChildP = (mainrow) => {
    //       let SplitPreviousDetail: any = [];
    //       SplitPreviousDetail = this.state.previousOrderData.filter(
    //         (x) => x.orderID === mainrow.orderID && x.mainType == 2
    //       );

    //       // this.setState({ prevOrderidselected:  mainrow.orderID  });

    //       const columnChildP = [
    //         {
    //           dataField: "isSelected",
    //           text: "",
    //           headerFormatter: this.icheckselectedall,
    //           classes: "expanding-bar",
    //           formatter: (cell: any, row: any, rowIndex: any) => {
    //             const prevOrderidselected = mainrow.orderID;
    //             this.setState({ prevOrderidselected: prevOrderidselected });
    //             return (
    //               <div className="d-flex align-items-center txt-bold">
    //                 {/* <input
    // id={row.itemNo}
    //                                 type="checkbox"
    //                                 onChange={(e) => this.handleRowSelection(e, rowIndex,row.orderID)}
    //                                  checked={row.isChecked}
    //                             /> */}
    //                 <OverlayTrigger
    //                   placement="bottom"
    //                   overlay={
    //                     <Tooltip id="toolp">Click to copy this part</Tooltip>
    //                   }
    //                 >
    //                   <FontAwesomeIcon
    //                     icon={faArrowUp}
    //                     size="xs"
    //                     onClick={(e) =>
    //                       this.handleRowSelectionCheck(
    //                         e,
    //                         rowIndex,
    //                         row.orderID,
    //                         "no"
    //                       )
    //                     }
    //                   />
    //                 </OverlayTrigger>
    //               </div>
    //             );
    //           },
    //         },
    //         {
    //           dataField: "partname",
    //           text: "Part Name",
    //           classes: "expanding-bar",
    //           formatter: (cell: any, row: any, rowIndex: any) => {
    //             return (
    //               <div className="d-flex align-items-center txt-bold">
    //                 <EllipsisWithTooltip placement="bottom">
    //                   {row.partname}
    //                 </EllipsisWithTooltip>
    //               </div>
    //             );
    //           },
    //         },
    //         {
    //           dataField: "partNo",
    //           text: "Part No",
    //           classes: "expanding-bar",
    //           formatter: (cell: any, row: any, rowIndex: any) => {
    //             return (
    //               <EllipsisWithTooltip placement="bottom">
    //                 {row.partNo}
    //               </EllipsisWithTooltip>
    //             );
    //           },
    //         },
    //         {
    //           dataField: "dueDate",
    //           text: "Due Date",
    //           classes: "expanding-bar",
    //           formatter: (cell: any, row: any, rowIndex: any) => {
    //             return (
    //               <EllipsisWithTooltip placement="bottom">
    //                 {moment(row.dueDate).format("MM/DD/YYYY")}
    //               </EllipsisWithTooltip>
    //             );
    //           },
    //         },
    //         {
    //           dataField: "jobDesc",
    //           text: "Description",
    //           classes: "expanding-bar",
    //           formatter: (cell: any, row: any, rowIndex: any) => {
    //             return (
    //               <EllipsisWithTooltip placement="bottom">
    //                 {row.jobDesc}
    //               </EllipsisWithTooltip>
    //             );
    //           },
    //         },
    //         {
    //           dataField: "unit",
    //           text: "Unit",
    //           hidden: true,
    //           classes: "expanding-bar",
    //           formatter: (cell: any, row: any, rowIndex: any) => {
    //             return (
    //               <EllipsisWithTooltip placement="bottom">
    //                 {Utils.amountFormatted(row.unit)}
    //               </EllipsisWithTooltip>
    //             );
    //           },
    //         },
    //         {
    //           dataField: "qtyOrdered",
    //           text: "Qty Ordered",
    //           headerClasses: "text-end pe-4",
    //           classes: "text-end pe-4 expanding-bar",
    //           formatter: (cell: any, row: any, rowIndex: any) => {
    //             return (
    //               <div>
    //                 {Utils.amountFormatted(Number(row.qtyOrdered).toFixed(2))}
    //               </div>
    //             );
    //           },
    //         },

    //         {
    //           dataField: "unitPrice",
    //           text: "Unit Price",
    //           headerClasses: "text-end pe-4",
    //           classes: "text-end pe-4 expanding-bar",
    //           formatter: (cell: any, row: any, rowIndex: any) => {
    //             return (
    //               <div>
    //                 {Utils.amountFormatted(Number(row.unitPrice).toFixed(2))}
    //               </div>
    //             );
    //           },
    //         },

    //         {
    //           dataField: "discount",
    //           text: "Discount",
    //           headerClasses: "text-end pe-4",
    //           classes: "text-end pe-4 expanding-bar",
    //           formatter: (cell: any, row: any, rowIndex: any) => {
    //             return (
    //               <div>
    //                 {Utils.amountFormatted(Number(row.discount).toFixed(2))}
    //               </div>
    //             );
    //           },
    //         },
    //         {
    //           dataField: "total",
    //           text: "Total",
    //           headerClasses: "text-end pe-4 ",
    //           classes: "text-end pe-4 expanding-bar",
    //           formatter: (cell: any, row: any, rowIndex: any) => {
    //             return (
    //               <div>
    //                 {Utils.amountFormatted(
    //                   Number(row.qtyOrdered * row.unitPrice - row.discount).toFixed(2)
    //                 )}
    //               </div>
    //             );
    //           },
    //         },
    //         {
    //           dataField: "",
    //           text: "",
    //           headerClasses: "blankLastChildofChild",
    //           classes: "expanding-bar",
    //         },
    //         {
    //           dataField: "",
    //           text: "",
    //           headerClasses: "cl",
    //           classes: "expanding-bar",
    //           headerFormatter: whiteBgFormatter,

    //         },
    //       ];

    //       return (
    //         <>
    //           <div className='px-4 p-4' id='divprevious'>
    //             <BootstrapTable
    //               id="tblCOAchild"
    //               keyField="uniqueNo"
    //               data={SplitPreviousDetail}
    //               columns={columnChildP}
    //              // rowEvents={this.rowEvents}
    //             // selectRow={ selectRow }
    //             //  onClick={(e) => this.handleRowSelectionCheck(e, rowIndex,row.orderID,"no")}
    //             />
    //           </div>
    //         </>

    //       );
    //     };
    // console.log(this.state.formData.customercode);
    // const Previouscolumns = [
    //   {
    //     dataField: "customerPoNumber",
    //     text: "Customer PO #",
    //     sort: true,
    //   },
    //   ,
    //   {
    //     dataField: "orderDate",
    //     text: "Order Date",
    //     sort: true,
    //     formatter: (cell: any, row: any, rowIndex: any) => {
    //       return (
    //         <EllipsisWithTooltip placement="bottom">
    //           {moment(row.orderDate).format("MM/DD/YYYY")}
    //         </EllipsisWithTooltip>
    //       );
    //     },
    //   },

    //   ,
    //   {
    //     dataField: "totalAmount",
    //     text: "Order Amount",
    //   },

    //   {
    //     dataField: "status",
    //     text: "Order Status",
    //   },
    // ];

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var first = date.getDate() - date.getDay();

    return (
      //  <div className="transaction-slideout invoice-entry pos-fxd index-1000">

      <section>
        <div className="details-drawer"
          style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
        // className="back-drop1  viewer-panel1 opacity "
        >
          <ToastContainer
            containerId={"userDetailSlideoutcontainer"}
            autoClose={3000}
          />

          <div className="d-flex">
            {!this.state.isOpenViewer && (
              <div
                className="back-drop1  viewer-panel1 opacity "
                onClick={() => this.closeSlideOut()}
              ></div>
            )}

            <Container
              fluid

              className="body-sec viewer-panel back-drop p-0 "
              style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}

            >
              <div
                className="container-fluid py-1 bg-white d-flex align-items-center  border-bottom round-4"
              >
                <div className="divheader">
                  <h5 className="my-title">
                    {this.state.formData.InvoiceNo == 0 ? <span>Invoice Generate </span> : <span>Invoice Generated : {this.state.formData.PrefixInvoiceNo}  </span>}
                  </h5>


                  <div className="header-popout ml-auto">





                    <div className="container text-center">
                      <div className="row align-items-start">


                        <div className="col">
                          {this.state.formData.InvoiceNo != 0
                            //  && this.state.jobTrackerId === 0 
                            //                             && !this.state.processVendorPOCreated && !this.state.materialVendorPOCreated
                            &&

                            <div className="action">

                              <Dropdown
                                className="more-action"
                                alignRight
                                onSelect={(event: any) => { this.actionclick(event); }}
                              >
                                <Dropdown.Toggle
                                  className="btn-outline-primary btn btn-primary more"
                                  id="dropdown-more"
                                >
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>



                                  <Dropdown.Item

                                    eventKey={"Print"}
                                    key={1}
                                  >
                                    {"Print"}
                                  </Dropdown.Item>
                                  {/* <Dropdown.Item
                             
                             eventKey={"Email"}
                             key={1}
                           >
                             {"Email"}
                           </Dropdown.Item> */}
                                  <Dropdown.Item

                                    eventKey={"Delete"}
                                    key={1}
                                  >
                                    {"Delete"}
                                  </Dropdown.Item>

                                </Dropdown.Menu>
                              </Dropdown>



                            </div>


                          }
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            className="btn btn-primary cross"
                            onClick={this.closeSlideOut}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                                fill="#ffffff"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="scroll"
              >

                <section className="pb-3">
                  <div className="row g-3">

                    <div className="col-12 col-md-4" >
                      <Form.Group controlId="customer">
                        <Form.Label className="font-semibold"> Customer</Form.Label>
                        <div className="single-select">
                          <Form.Control type="text" id="txtCustomer"

                            value={this.state.formData.CustomerName}


                            autoComplete='off'
                            disabled={true}
                          />
                        </div>

                      </Form.Group>

                    </div>
                    <div className="col-12 col-md-2" >
                      <Form.Group controlId="customercode">
                        <Form.Label className="font-semibold"> Customer Code</Form.Label>
                        <div className="single-select">
                          <Form.Control type="text" id="txtCustomercode"

                            value={this.state.formData.CustomerCode}


                            autoComplete='off'
                            disabled={true}
                          />
                        </div>

                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-2" >
                      <Form.Group controlId="customercode">
                        <Form.Label className="font-semibold"> Invoice  Date</Form.Label>
                        <div className="date-picker calender-picker">
                          <DatePicker
                            ref={this.trDate}
                            id="txtInvoiceDate"
                            tabIndex={this.state.tabEnable}
                            className="form-control px-0 w-75 text-center"
                            placeholderText={"MM/DD/YY"}
                            dateFormat="MM/dd/yy"
                            disable={true}
                            selected={
                              this.state.formData.InvoiceDate === null
                                ? new Date()
                                : this.state.formData.InvoiceDate === ""
                                  ? new Date()
                                  : new Date(this.state.formData.InvoiceDate)
                            }
                            onChange={(event: any) =>
                              this.handleChange(event, "InvoiceDate")
                            }
                            //onClick={(e: any) =>this.onTAB("txtOrderDate-1", 0, e)}
                            autoComplete="off"
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-2" >
                      <Form.Group controlId="customercode">

                        <Form.Label className="font-semibold"> Due  Date</Form.Label>
                        <div className="date-picker calender-picker">
                          <DatePicker
                            ref={this.trdueDate}
                            id="txtDueDate"
                            tabIndex={this.state.tabEnable}
                            className="form-control px-0 w-75 text-center"
                            placeholderText={"MM/DD/YY"}
                            dateFormat="MM/dd/yy"
                            selected={
                              this.state.formData.DueDate === null
                                ? new Date()
                                : this.state.formData.DueDate === ""
                                  ? new Date()
                                  : new Date(this.state.formData.DueDate)
                            }
                            onChange={(event: any) =>
                              this.handleChange(event, "DueDate")
                            }
                            //onClick={(e: any) =>this.onTAB("txtOrderDate-1", 0, e)}
                            autoComplete="off"
                          />
                        </div>


                      </Form.Group>

                    </div>
                    <div className="col-12 col-md-2" >

                      <Form.Group controlId="customercode">
                        <Form.Label className="font-semibold"> Accounting Period </Form.Label>


                        <div className="w-auto" >
                          <Form.Label>
                            {this.state.formData.AccountingPeriod != null &&
                              <SingleDropdownListWithoutSearch
                                tabIndex={this.state.tabEnable}
                                itemList={this.state.Accountingperiod}
                                handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                defaultItem={this.state.formData.AccountingPeriod}
                                //  defaultText={"Select Employee"}
                                defaultName={this.state.accountingperiodname == "" ? this.state.formData.AccountingPeriod : this.state.accountingperiodname}

                                controlID="1"
                                id={"ddlAccounting"}
                              />}
                          </Form.Label>
                        </div>


                      </Form.Group>
                    </div>
                  </div>
                </section>
                <section className="pb-3">
                  <div className="row g-3">



                    <div className="col-12 col-md-2" >
                      <Form.Group
                        controlId="customer">
                        <Form.Label className="font-semibold"> Shipping Charges</Form.Label>

                        <Form.Control type="text" id="txtCustomercode"
                          value={this.state.formData.ShippingCharge}
                          autoComplete='off'
                          className="form-control px-2 w-100"
                          disabled={false}
                          onChange={(event: any) =>
                            this.handleChange(event, "ShippingCharge")}
                          onFocus={(e: any) => this.onAmountFocus(e, 0, 0, "ShippingCharge")}
                          onBlur={(e: any) => this.amountBlur(e, 0, "ShippingCharge")}
                        />


                      </Form.Group>
                    </div>

                    <div className="col-12 col-md-2" >
                      <Form.Group controlId="OtherCharge">
                        <Form.Label className="font-semibold"> Other Charges</Form.Label>

                        <Form.Control type="text" id="txtOtherCharge"
                          value={this.state.formData.OtherCharge}
                          autoComplete='off'
                          className="form-control px-2 w-100"
                          onFocus={(e: any) => this.onAmountFocus(e, 0, 0, "OtherCharge")}
                          onBlur={(e: any) => this.amountBlur(e, 0, "OtherCharge")}
                          disabled={false}
                          onChange={(event: any) =>
                            this.handleChange(event, "OtherCharge")}
                        />



                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-2" >
                      <Form.Group controlId="SaleTax">
                        <Form.Label className="font-semibold"> Sale Tax %</Form.Label>

                        <Form.Control type="text" id="txtSaleTax"
                          value={this.state.formData.SaleTax}
                          className="form-control px-2 w-75"
                          autoComplete='off'
                          disabled={false}
                          onChange={(event: any) =>
                            this.handleChange(event, "SaleTax")}
                        />

                      </Form.Group>
                    </div>

                    <div className="col-12 col-md-3" > <Form.Group controlId="SaleTax">
                      <Form.Label className="font-semibold"> Sale Tax Amount</Form.Label>

                      <Form.Control type="text" id="txtSaleTax"
                        value={Utils.currencyFormat(this.state.formData.SaleTaxAmount)}
                        className="form-control px-2 w-75"
                        autoComplete='off'
                        disabled={true}
                        onChange={(event: any) =>
                          this.handleChange(event, "SaleTax")}
                      />

                    </Form.Group></div>
                    <div className="col-12 col-md-2" >

                      <Form.Group controlId="TotalAmount">
                        <Form.Label className="font-semibold"> Total Amount  </Form.Label>

                        <Form.Control type="text" id="txtTotalAmount"
                          value={Utils.currencyFormat(this.state.formData.TotalAmount)}
                          className="form-control px-2 w-100"
                          autoComplete='off'
                          disabled={true}
                        />


                      </Form.Group>
                    </div>
                  </div>

                </section>
                <section className="pb-3">
                  <div className="row g-3">



                    <div className="col-12 col-md-3" >
                    </div>
                    <div className="col-12 col-md-3" >
                    </div>



                  </div>


                </section>





                <section className="pb-3">
                  <div className="card">
                    <div className="card-header fw-bold">
                    <div > 
                    <div className="d-inline-block"> Order Details  
                     
                     <span className="px-2">
                             { (this.state.formData.InvoiceNo!="" || this.state.formData.InvoiceNo !=null)  &&  this.state.invoiceList.filter(item2 => item2.invoiceNo != this.state.formData.InvoiceNo).length > 0 && 
                              <a   onClick={() => this.showprevious()} className="orderLinks" >{this.state.isprevious==false ? "Show Partial Invoices" : "Hide Partial Invoices" }   </a>
  }
  </span>
  </div>
  </div>
                    </div>
                    <div className="card-body">
                      <div
                        className="table-responsive table table-borderless align-middle transactions-list"

                      >
                        {this.state.templateColumns.length > 0 && (

                          <BootstrapTable
                            id="tbltemplate"
                            keyField="orderId"
                            data={this.state.invoicedetails.slice(0, 1)}
                            columns={this.state.templateColumns}
                            expandRow={expandSplitRow}
                          />

                        )}
                      </div>
                    </div>
                  </div>


                  {this.state.isprevious && ( <div className="row g-3 mt-0">
                    <div className="col-12 col-md-12">

                      <div className="transactions-list inpute">
                        {this.state.invoiceList.filter(item2 => item2.invoiceNo != this.state.formData.InvoiceNo).length > 0 && true && (

                          <div className="card">
                            <div className="card-header fw-bold">
                              Partial Invoices Generated
                            </div>
                            <div className="card-body">
                              <div className="PreviousTransactions">

                                <ToolkitProvider
                                  keyField="id"

                                  data={this.state.invoiceList.filter(item2 => item2.invoiceNo != this.state.formData.InvoiceNo)}
                                  columns={invoiceColumns}

                                //search
                                >
                                  {
                                    props => (


                                      <div >

                                        <section className="pb-3">
                                          <div className="table-responsive divbottom"  >
                                            <div className="ml-auto transactions-list">
                                              <BootstrapTable
                                                {...props.baseProps}
                                                rowEvents={this.rowEvents}
                                              // pagination={paginationFactory(options)}
                                              // expandRow={expandSplitRow}

                                              />
                                            </div>

                                          </div>



                                        </section>
                                      </div>

                                    )
                                  }
                                </ToolkitProvider>


                              </div>

                            </div></div>
                        )}
                      </div>

                    </div>
                  </div>
                  )  
                }



                  {/* <div className="row g-3 mt-0">
                    <div className="col-12 col-md-12">
                   
                      <div className="transactions-list inpute">
                        {this.state.editMode && this.state.previousOrderData.length > 0 && (

<div className="card">
<div className="card-header">
  Add More Order 
</div>
<div className="card-body">
                          <div className="PreviousTransactions">

                         
                            <BootstrapTable
                              keyField="orderID"
                              data={
                                this.state.previousOrderData.filter(item2 => item2.mainType == 1 
                                    && 
                                    this.state.selectedRows.filter(x => x !== item2.OrderID)
                              )
                              //   this.state.previousOrderData.filter(
                              //   (x) => x.mainType == 1 && 
                              //   this.state.invoicedetails.filter((n) => n.orderid === x.orderID).map(r => r.orderid)==x.orderID
                              //   //this.state.invoicedetails.map(r => r.orderid).includes(x.OrderID)
                              // )
                            
                            }
                              columns={Previouscolumns}
                              expandRow={expandSplitRowP}
                            />
                            <button
                              type="button"
                              className="btn wht-bg link-btn"
                              onClick={this.loadPreviousData}
                            >
                              Show More
                            </button>
                          </div>

                          </div></div>
                        )}
                      </div>
                      
                    </div>
                  </div> */}

                </section>



                <div className={"footer-section viewer-panel"}
                  style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
                >
                  <div>
                    <div className="d-flex gap-3 align-items-center justify-content-end height-72">
                      <div className="d-flex gap-3 align-items-center">
                        {
                          (
                            <>
                              <Button
                                variant="primary"
                                type="button"
                                className="space btn btn-primary btn-discard"
                                onClick={this.closeSlideOut}
                              >
                                Discard
                              </Button>
                              <>
                                {this.state.formData.InvoiceNo != 0 && (
                                  <Button
                                    id="btnSave"
                                    variant="primary"
                                    type="button"
                                    onClick={() => this.received()}

                                  >
                                    {this.state.formData.PaymentDate == null ? "Received Payment" : "Update Payment"}
                                  </Button>
                                )}

                              </>

                              <Button
                                id="btnSave"
                                variant="primary"
                                type="button"
                                onClick={() => this.saveItem(false, false)}

                              >
                                {this.state.formData.InvoiceNo != 0 ? "Update Invoice" : "Create Invoice"}
                              </Button>

                              {/* 
                              {true &&
                            <Button
                              variant="primary"
                              type="button"
                              
                              onClick={() =>
                                this.handleEdit()
                              }
                            >
                              Add Item
                            </Button>
                                  } */}

                              {/* {this.state.formData.Id > 0 && <Button
                                id="btnPrint"
                                variant="primary"
                                type="button"
                                onClick={() => this.printItem()}

                              >
                                {"Print Invoice"}
                              </Button>} */}

                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {(showReceivableslideout &&
                <Receivableslideout
                  closeSlideOut={this.closeInvoiceSlideOut}
                  orderid={this.state.orderid}
                  orderIds={this.state.orderIds}
                  products={this.state.products}
                  invoiceId={this.state.invoiceId}
                >


                </Receivableslideout>
              )}
            </Container>
          </div>

          1
          {this.state.ispopupshow && (
            <div
            //className="d-flex align-items-center pos-fxd2 index-1000"
            >
              <div className="react-confirm-alert-overlay confirm-modal undefined">
                
                <div className="react-confirm-alert">
                  <div className="react-confirm-alert-body">
                    <h1>Received Confirmation</h1>
                    <div className="d-flex flex-wrap mr-auto">
                      <Form.Group className="rm-bottom-margin" controlId="customercode">
                        <Form.Label> Payment Method </Form.Label>
                        <div className="w-auto" >
                          <Form.Label>
                            {true && <SingleDropdownListWithoutSearch
                              tabIndex={this.state.tabEnable}
                              itemList={this.state.PaymentMethod}
                              handleSelectedItem={this.handleSelectedItem1.bind(this, 2)}
                              defaultItem={this.state.formData.PaymentMethod}
                              defaultText={"Select Payement"}
                              defaultName={this.state.PaymentMethodname}

                              controlID="1"
                              id={"ddlPaymentMethod"}
                            />}
                          </Form.Label>
                        </div>

                      </Form.Group>
                    </div>
                    <div className="d-flex flex-wrap mr-auto" style={{ width: "250px" }}>
                      <Form.Group className="rm-bottom-margin" controlId="paymentdate">
                        <Form.Label> Payment Date  </Form.Label>
                        <Form.Label>
                          <div className="date-picker calender-picker">
                            {/* <DatePicker
                              ref={this.trDate}
                              id="txtpaymentdate"
                              tabIndex={this.state.tabEnable}
                              className="form-controlCustom"
                              placeholderText={"MM/DD/YY"}
                              dateFormat="MM/dd/yy"
                              selected={
                                this.state.formData.PaymentDate === null
                                  ? new Date()
                                  : this.state.formData.PaymentDate === ""
                                    ? new Date()
                                    : new Date(this.state.formData.PaymentDate)
                              }
                              onChange={(event: any) =>
                                this.handleChange(event, "paymentdate")
                              }
                              //onClick={(e: any) =>this.onTAB("txtOrderDate-1", 0, e)}
                              autoComplete="off"
                            /> */}
                            <DatePicker
                              ref={this.trDate}
                              id="txtpaymentdate"
                              tabIndex={this.state.tabEnable}
                              className="form-control px-0 w-75 text-center"
                              placeholderText={"MM/DD/YY"}
                              dateFormat="MM/dd/yy"
                              disable={true}
                              selected={
                                this.state.formData.PaymentDate === null
                                  ? new Date()
                                  : this.state.formData.PaymentDate === ""
                                    ? new Date()
                                    : new Date(this.state.formData.PaymentDate)
                              }
                              onChange={(event: any) =>
                                this.handleChange(event, "paymentdate")
                              }
                              //onClick={(e: any) =>this.onTAB("txtOrderDate-1", 0, e)}
                              autoComplete="off"
                            />
                          </div>
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="d-flex flex-wrap mr-auto" style={{ width: "250px" }}>
                      <Form.Group className="rm-bottom-margin" controlId="checkno">
                        <Form.Label> {this.state.PaymentMethodname == "Check" ? "Check No" : "Transaction ID"} </Form.Label>
                        <Form.Label>
                          <div className="single-select">
                            <Form.Control type="text" id="textcheckno"

                              value={this.state.formData.CheckNo}
                              onChange={(event: any) =>
                                this.handleChange(event, "checkno")}

                              autoComplete='off'

                            />
                          </div>
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="d-flex flex-wrap mr-auto" style={{ width: "250px" }}>
                      <Form.Group className="rm-bottom-margin" controlId="internalnotes">
                        <Form.Label> Internal Notes </Form.Label>
                        <Form.Label>
                          <div className="single-select">
                            <Form.Control type="text" id="txtinternalnotes"

                              value={this.state.formData.InternalNotes}

                              onChange={(event: any) =>
                                this.handleChange(event, "internalnotes")}
                              autoComplete='off'

                            />
                          </div>



                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="react-confirm-alert-button-group">

                      <button type="button" className="btn"

                        onClick={() => this.saveItem(false, true)}
                      >
                        Yes
                      </button>


                      <div className="upload-link">
                        <button
                          type="button"
                          className="btn"
                          onClick={() => this.receivedcancel()}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}






        </div>

      </section>
    );
  }
}
