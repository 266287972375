import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button ,Form} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import {Utils}  from "../../Common/Utilis";
import _ from "lodash";
import paginationFactory from 'react-bootstrap-table2-paginator';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import createDOMPurify from "dompurify";
 
import moment from "moment";
import { JobOrderService } from "../../Common/Services/JobOrderService";
import {VendorReceivingSlideout}  from '../VendorReceivingSlideout';

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";

const { SearchBar } = Search;





const DOMPurify = createDOMPurify(window);

export class VendorReceiving extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOrderDate:true,
      isvendorname:true,
      isvendorOrderType:true,
      isvendorPoNumber:true,
      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      searcResult: [],
      orderid:0,
      jobid:0,
      customername:'',
      customerpo:'',
      orderdate:null,
      jobno: '',
      partno: '',
      partname: '',
      duedate:null,
      qty:0,
      vendororderid:0,
      vendorordertype:'Material',
      selectedcolumn:"All",
       selectOptions :  [ {
        id: 'All',name: 'All'},
      {  id: 'Vendor PO Number',name: 'Vendor PO Number'},
        {id: 'Vendor Name',name:  'Vendor Name'},
        {id: 'Order Type',name:  'Order Type'},
        { id: 'Order Date',name:  'Order Date'},
      
       
     ]
    };
    
 
  }
 

  componentDidMount() { 
   this.getJobItems();
  }

  getJobItems()
  {
    
    let requestlist = { ...this.state.requestlist };
    requestlist.showReceivedOrders = true;
    JobOrderService.GetVendorOrderList(requestlist)
    .then(async (result: any[] | null) => {
      if (result != null) {
        // console.log(result);
        this.setState({ searcResult: result }, () => {
           
        });
      }
      resolve();
    })
    .catch((err: any) => {
      toast.error(`Server Error, ${err}`);
      reject();
    });
  }

 
  shownewslideout   = () => {
    this.setState({ showSlideout: true ,orderid:0,jobid:0, customername:'',customerpo:'',orderdate:null, jobno:'',partno:'',partname:'',duedate:null,qty:0,vendororderid:0,vendorordertype:'Material',isSpinner:false,});
  };


  showslideout = () => {
    this.setState({ showSlideout: true , isSpinner:false, });
  };

  closeSlideOut = () => {
    
    this.setState({ showSlideout: false, isSpinner:false, });
    this.getJobItems();
};
 rowEvents = {
  onClick: (e: any, row: any, rowIndex: any) => {
    this.setState({ showSlideout: true ,orderid:0,jobid:row.jobId, customername:row.vendorName,
      customerpo:row.vendorPoNumber,orderdate: row.orderDate,jobno: row.jobNo,partno: row.partNo,partname: row.partName,qty: row.qty,duedate: row.dueDate,vendororderid:row.orderID,vendorordertype:row.vendorOrderType, isSpinner:false,
    });  
 
  },
};

handleSelectedItem1 = (control: any, id: any) => {
  let searcResult1 = Object.assign(
    [],
    JSON.parse(JSON.stringify(this.state.searcResult))
  );


  let isOrderDate = id == "Order Date" ? true : false;
  let isvendorname = id == "Vendor Name" ? true : false;
  let isvendorOrderType = id == "Order Type" ? true : false;
  let isvendorPoNumber = id == "Vendor PO Number" ? true : false;


  if (id == "All") {
    isOrderDate = true;
    isvendorname = true;
    isvendorOrderType = true;
    isvendorPoNumber = true;
   

  }


  this.setState({
    searcResult: [], selectedcolumn: id, isOrderDate: isOrderDate, isvendorname: isvendorname,
    isvendorOrderType: isvendorOrderType, isvendorPoNumber: isvendorPoNumber
  },
    () => {
      this.setState({ searcResult: searcResult1 });

    }


  );

};
   render() { 

    const columns = [
      // {
      //   dataField: 'orderID',
      //   text: '#',
      //   headerStyle: { width: '10px' },
      // },
      {
        dataField: 'vendorPoNumber',
        text: 'PO #',
        sort: true,
        headerStyle: { width: '100px' },
        searchable:this.state.isvendorPoNumber,
      },
      {
        dataField: 'vendorOrderType',
        text: 'Type',
        sort: true,
        headerStyle: { width: '100px' },
        searchable:this.state.isvendorOrderType,
      },
      {
        dataField: 'vendorName',
        text: 'Vendor',
        sort: true,
        headerStyle: { width: '150px' },
        searchable:this.state.isvendorname,
      },
      {
        dataField: 'OrderDate',
        text: 'Order Date',
        sort: true,
        headerStyle: { width: '100px' },
        searchable:this.state.isOrderDate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
              <EllipsisWithTooltip placement="bottom">
                  {moment(row.dueDate).format("MM/DD/YYYY")}
              </EllipsisWithTooltip>
          );
      },
      },
      {
        dataField: 'totalAmount',
        text: 'TotalAmount',
        sort: true,
        headerStyle: { width: '150px' },
        headerClasses: 'amount',
        classes: 'text-end pe-4 divgreen',
      }
    ];

  
    const {
      
      showSlideout,
      
  } = this.state;
    return (
<>
<React.Fragment>

<div className="home d-flex">
  
        
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex  ">
            
            Vendor Receiving
            <div className="action-group d-flex flex-row ml-auto">
            {/*<Button onClick={this.shownewslideout}>Add Vendor Order</Button>*/}
              </div>
              </div>
              
              {/* <BootstrapTable
        keyField="id"
        data={this.state.searcResult}
        columns={columns}
        rowEvents={this.rowEvents}
        pagination={ paginationFactory() }
         
      /> */}

<ToolkitProvider
  keyField="id"
  data={ this.state.searcResult }
  columns={ columns }
 
  search
>
  {
    props => (
      <div>
        
        <SearchBar { ...props.searchProps } />
   
    
     <Form.Label>
     <SingleDropdownListWithoutSearch
                       // tabIndex={this.state.tabEnable}
                        itemList={this.state.selectOptions}
                        handleSelectedItem={this.handleSelectedItem1.bind(this,1)}                        
                        defaultItem={this.state.selectedcolumn}
                        defaultText={"Select Filter"}
                        defaultName={this.state.selectedcolumn}
                        controlID="1"
                        id={"ddlselected"}
                      //onTAB={this.onTAB.bind(this, "ddlprocess-" + rowIndex + "ddl", rowIndex)}
                      // disabled={disable}
                      /></Form.Label>
        <hr />
        <BootstrapTable
          { ...props.baseProps }
          rowEvents={this.rowEvents}
        pagination={ paginationFactory() }
        />
      </div>
    )
  }
</ToolkitProvider>
{(showSlideout &&

<VendorReceivingSlideout
closeSlideOut={this.closeSlideOut}
orderid={this.state.orderid}
CustomerPoNumber={this.state.customerpo}
poNumber={this.state.poNumber}
productid={this.state.productid}
partname={this.state.partname}
partNo={this.state.partNo}
qtyOrdered={this.state.qtyOrdered}
jobid={this.state.jobid}
itemNo={this.state.itemNo}
vendororderid={this.state.vendororderid}
vendorordertype={this.state.vendorordertype}
selectedCustomer={this.state.selectedCustomer}
orderdate={this.state.orderdate}
dueDate={this.state.dueDate}
jobDesc={this.state.jobDesc}
id={this.state.id}
>


</VendorReceivingSlideout>


              )}
          </Container>
       
      
      </div>
      </React.Fragment>
</>
    );
  }
}
