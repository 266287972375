import * as React from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { Container, Dropdown, Form, Button, OverlayTrigger, Tooltip, Modal, Spinner } from "react-bootstrap";
import { resolve, reject } from "q";
import { Utils } from "../../Common/Utilis";
import "./comment.scss";
import profile from "../Assets/Images/profile.png";
 
import { ProductService } from "../Services/ProductService";
import { CommonService } from "../Services/CommonService";
import { toast } from "react-toastify";

export class CommentBox extends React.Component<any, any> {
    private scrollToBottomlist: any;
    constructor(props: any) {
        super(props);
        this.scrollToBottomlist = React.createRef();
        this.state = {
         
            hotelid: this.props.hotelid,
            entryType: this.props.entryType,
            uniqueNo: this.props.uniqueNo,
            precommentlist: this.props.precommentlist,
            value: '',
            mentionData: null,
            commentlist: [],
            userMentionData:[              
            ], 
            users: [],
            htmlcomment: [],
            userComments: [],
            logInUser: "",
            logInUserImage: "",
            isCommentAdd: false,
            addCommentDisabled : this.props.addCommentDisabled,
        };
    }

    onTAB = (event) => {   
        if( this.state.value.trim().length > 0)   
        {
        if (event.key.toLowerCase() === "tab") {
            let txtID = "btncomment";
           
            setTimeout(() => {
                $("button[id^='" + txtID + "']").focus();
            }, 50);
        }
        if (event.key.toLowerCase() === "enter") {
          // this.addComment();
        }
    }
    };

    handleChange = (event, newValue, newPlainTextValue, mentions) => {
        let text = newPlainTextValue;
        this.setState({
            value: newValue,
            mentionData: { newValue, newPlainTextValue: text, mentions: mentions }
        });
        setTimeout(() => {
            this.props.parentScrollMethod();
        }, 50);
    };
    handleFocus = () => {
        setTimeout(() => {
            this.props.parentScrollMethod();
        }, 50);
    };


    componentDidMount() {
        this.usernameList();
        this.loadComments();

    }

   
    loadPrevComment = () => {

        if (this.state.precommentlist.length > 0) {
            let itemComment: any[] = [];
            this.state.precommentlist.forEach(element => {
                let savedComment = element.htmlComment;
                if (element.displayUserTo.length > 0) {
                    let displayusers = element.displayUserTo.split(',');
                    displayusers.forEach(userTo => {

                        let logInUser = this.state.users.filter(x => x.username === userTo.replace("@", ""));

                        if (logInUser.length === 0) {
                            savedComment = savedComment.replaceAll(userTo, '<span class ="isNotPermission">' + userTo + '</span>');
                        }

                    });
                }

                // let  comment1 = element.htmlComment.replaceAll(element.display, '<span class ="isNotPermission">' + element.display + '</span>')

                itemComment.push(<li className="d-flex">
                    <div className="profile"><img className={element.profileimage === "" ? 'default' : ''}
                        src={element.profileimage === "" ? profile : element.profileimage}
                        width="36" height="36" alt={element.createdBy} /></div>
                    <div className="profile-details">
                        <div className="name">{element.createdBy}<span> {element.commentDate.length > 0 ?
                            Utils.getLocalDateTimeFromUtc(element.commentDate) : ""}</span></div>
                        <div className="comment" dangerouslySetInnerHTML={{ __html: savedComment.replace('\n', '<br/>') }}></div>
                    </div>
                </li>);

            });

            this.setState({ htmlcomment: itemComment });

        };
    };

    CommentToSave = () => {
        let array: any = [];
        if (this.state.uniqueNo > 0) {
            return array;
        }
        return this.state.userComments;
    };

    isCommentAdd = () => {
        return this.state.value.trim().length > 0 ? true : false;
    };

    retsetComment = () => {
        this.setState({ userComments: [] });
    };


    addComment = () => {

        let comment: any = "";
        let comment1: any = "";
        let itemComment: any[] = [];
        let displayUser: any[] = [];

        let updatedCommentItem: any = {};
        updatedCommentItem = this.props.updatedCommentItem();
        
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let user_UniqueID = (storage === null ? 0 : (storage.user_UniqueID as any));
        let userName = storage === null ? 0 : (storage.userName as any);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let currentDateTime = new Date(); //.toLocaleString(); //March 22nd at 3:32pm
        let userComments = Object.assign([], JSON.parse(JSON.stringify(this.state.userComments)));

        if (this.state.uniqueNo > 0) {
            userComments = [];
        }

        comment = this.state.mentionData.newPlainTextValue.trim();
        if (comment.length > 0) {
           
            var regex = /@([A-Za-z0-9._]+)/g;
            var matches = comment.match(regex);

         

            if (matches != null && matches.length > 0) {

                    let usercomment: any = {};
                    usercomment.uniqueNo = this.state.uniqueNo.toString();
                    usercomment.entryType = this.state.entryType;
                    usercomment.logInuser = userName;
                    usercomment.CreatedOn = currentDateTime;
                    usercomment.CreatedBy = user_UniqueID;
                    usercomment.CreatedForName = matches[0].trim().replace("@", "");
                    usercomment.Comments = this.state.mentionData.newPlainTextValue;
                    usercomment.htmlcomment = comment1.replaceAll('\n', '<br/>');
                    usercomment.tenantID = tenantID;
                    usercomment.date = updatedCommentItem.transcationDate;
                    userComments.push(usercomment);

                

                if (userComments.length > 0) {
                    userComments.forEach(ele => {
                       // ele.htmlcomment = ele.htmlcomment.replaceAll('\n', '<br/>');
                        ele.htmlcomment = comment1.replaceAll('\n', '<br/>');
                    });
                }
            }
            else {
                comment1 = this.state.mentionData.newPlainTextValue;
                let usercomment: any = {};
                usercomment.uniqueNo = this.state.uniqueNo.toString();
                usercomment.entryType = this.state.entryType;
                usercomment.logInuser = userName;
                usercomment.CreatedOn = currentDateTime;
                usercomment.CreatedBy = user_UniqueID;
                usercomment.CreatedForName = "";
                usercomment.Comments = this.state.mentionData.newPlainTextValue;
                usercomment.htmlcomment = comment1.replaceAll('\n', '<br/>');
                usercomment.tenantID = tenantID;
                usercomment.date = updatedCommentItem.transcationDate;
                userComments.push(usercomment);
            }



            let newcomment: any = {};
            this.state.htmlcomment.forEach(element => {
                itemComment.push(element);
            });

            itemComment.push(<li className="d-flex">
                <div className="profile"><img className={this.state.logInUserImage === "" ? 'default' : ''}
                    src={this.state.logInUserImage === "" ? profile : this.state.logInUserImage} width="36" height="36" alt="cimmple" /></div>
                <div className="profile-details">
                    <div className="name">{userName}<span> {Utils.getLocalDateTimeWithoutUtc(new Date())}</span></div>
                    <div className="comment" dangerouslySetInnerHTML={{ __html: comment1.replaceAll('\n', '<br/>') }}></div>
                </div>
            </li>);


            this.setState({
                htmlcomment: [],
                userComments: [],
                value: '',
                mentionData: {}
            }, () => {
                this.setState({ htmlcomment: itemComment, userComments }, () => {
                    if (this.state.uniqueNo > 0) {
                        this.saveComments();
                    }
                });
            });
        }
        setTimeout(() => {
            this.scrollToBottomlist.current.scrollTo({
                top: this.scrollToBottomlist.current.scrollHeight,
                behavior: 'smooth',
            });
        }, 50);
    };



    usernameList = () => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let userName = storage === null ? 0 : (storage.userName as any);
        let userRequest: any = {};
        userRequest.hotelid = this.state.hotelid;
        ProductService.usernameList(userRequest)
            .then(async (result: any | null) => {
                if (result !== null) {
                    if (result.length > 0) {
                     
                        let logInUser = result.filter(x => x.username === userName);
                        let logInUserimage = logInUser.length > 0 ? logInUser[0].image : '';
                        let userMentionData:any = [];
                   
                        result.forEach(element => {
                            let item :any =  {};
                            item.id = element.userName;
                            item.display = element.userName;
                            item.image = '';//element.image;
                            //item.email = element.email,
                            //item.position = element.position, 
                            userMentionData.push(item)  ;                        
                        });
                        this.setState({ users: result, logInUserImage: logInUserimage,userMentionData:userMentionData }, () => {
                          
                            //this.loadPrevComment();
                        });
                    }

                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    };


    saveComments = () => {

        CommonService.SaveComment(this.state.userComments[0])
            .then(async (result: any | null) => {
                if (result !== null) {
                    this.loadComments();
                    if (result.saveStatus === "Success") {

                       
                    }
                    else{
                      
                        if(result.messageCode === "Validation"){
                            toast.error(result.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                containerId: "InvoiceSlideout",
                            });
                           // this.setState(() => {
                                this.props.commentModalClose();
                        //})
                        }   
                    }
                    
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });

    };

    loadComments = () => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let userName = storage === null ? 0 : (storage.userName as any);
        let request: any = {};
        request.uniqueNo = this.props.uniqueNo;
        request.entryType = this.props.entryType;
        CommonService.GetComments(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    if (result.length > 0) {

                        let itemComment: any[] = [];
                        result.forEach(element => {
                            itemComment.push(<li className="d-flex">
                                <div className="profile"><img className={this.state.logInUserImage === "" ? 'default' : ''}
                                    src={this.state.logInUserImage === "" ? profile : this.state.logInUserImage} width="36" height="36" alt="cimmple" /></div>
                                <div className="profile-details">
                                    <div className="name">{userName}<span> {Utils.getLocalDateTimeWithoutUtc(element.createdOn)}</span></div>
                                    <div className="comment" dangerouslySetInnerHTML={{ __html: element.comments.replaceAll('\n', '<br/>') }}></div>
                                </div>
                            </li>);
    
                        });
                        

                        this.setState({ htmlcomment: itemComment}, () => {
                        });
                    }

                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    };


    render() {
      

        

        return (
 
            <div className={this.state.value.trim().length > 0 ? "filter-sec upload-section comment-box add-comment-button" : "filter-sec upload-section comment-box"}>
            <div className='form-group'>
<div className='d-flex'>

<Form.Label className="mr-auto">
                        {
                          <div className="upload-link mrgn-top btn wht-bg link-btn">
                            
                            
                            Add Comments
                            
                           
                          </div>
                        }
                      </Form.Label>

{/* <Form.Label  className="mr-auto commentlabel">  Add Comments

 <img className={this.state.logInUserImage === "" ? 'default' : ''}
                    src={this.state.logInUserImage === "" ? profile : this.state.logInUserImage} width="15" height="15" alt="cimmple" />
</Form.Label> */}
{/* <Form.Label className="mr-auto commentlabel">
<button


                          type="button"
                          className="btn wht-bg link-btn mr-auto"
                        >
                         Add Comments
                        </button>
                        </Form.Label> */}
</div>

 
                <div className={this.state.addCommentDisabled ? "comment-area d-flex disabled p-4 rounded-3 text-center text-secondary" : "comment-area d-flex p-4 rounded-3 text-center text-secondary"}>
                
                    <div className="inpute-field">
                        {this.state.userMentionData.length > 0 && <MentionsInput
                            id="txtcomment"
                            className="mentions__mention"
                            markup="@{{__type__||__id__||__display__}}"
                            value={this.state.value}
                            onChange={this.handleChange}
                            onClick={this.handleFocus}
                            placeholder="Type @ to notify another user."
                            onKeyDown={(e: any) => this.onTAB(e)}
                           //singleLine

                        >
                            <Mention
                                type="user"
                                trigger="@"
                                data={this.state.userMentionData}
                                className="mentions__mention"
                                displayTransform={(id, display) => `@${display}`}
                                renderSuggestion={(entry, search, highlightedDisplay, index, focused) => (
                                    <div className="d-flex align-items-center justify-content-start">
                                        <div className="profile">
                                            <img className={entry.image === "" ? 'default' : ''} src={entry.image === "" ? profile : entry.image} width="36" height="36" alt={entry.display} />
                                        </div>
                                        <div>
                                            <div className="user-name">{entry.display}</div>
                                            <div className="user-pos">{entry.display}</div>
                                        </div>
                                    </div>
                                )}
                            />
                        </MentionsInput>}
                        {this.state.value.trim().length > 0 && (
                            <div className="d-flex justify-content-end add-comment">
                                <button id="btncomment" type="button" className="btn btn-primary" onClick={this.addComment} >
                                    Add Comment
                                </button>
                            </div>
                        )}

                        {/* <hr />
                        {JSON.stringify(this.state.mentionData)}
                        <hr />
                        <p>The raw text is:</p>
                        <p>
                            {this.state.value}
                        </p> */}
                    </div>
                </div>
                <div className="comment-list">
                    
                    {this.state.htmlcomment.length === 0 && (
                        <div className='no-comment-placeholder'></div>
                    )}

                    <ul ref={this.scrollToBottomlist}>
                        {this.state.htmlcomment}
                    </ul>

                </div>
            </div>
            </div>
            
        );
    }
}