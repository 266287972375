import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { VendorService } from "../../Common/Services/VendorService";
import { InvoiceService } from "../../Common/Services/InvoiceService";
import { Customerorderslideout } from '../Customerorderslideout';
import { Utils } from "../../Common/Utilis";
import _ from "lodash";
import paginationFactory from 'react-bootstrap-table2-paginator';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import createDOMPurify from "dompurify";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import moment from "moment";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
//import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import {InvoiceEntryslideout}  from './InvoiceEntryslideout';
const { SearchBar } = Search;

const DOMPurify = createDOMPurify(window);

export class InvoiceList extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.bindfilter = this.bindfilter.bind(this);
    this.state = {
      footershow: false,
      selectedRows: [],
      selectedRowscustomerid: [],

      invoiceTotal: 0,
      isstatus: true,
      isorderdate: true,
      iscustomername: true,
      iscustomercode: true,
      iscustomerpo: true,
      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      searcResult: [],
      customerOrderResult: [],
      invoiceId: 0,
      orderIds: [],
      selectedcolumn: "All",
      selectOptions: [
        {

          id: 'All', name: 'All'
        },
        {

          id: 'Vendor PO No.', name: 'Vendor PO No.'
        },
        { id: 'Vendor Code', name: 'Vendor Code' },
        { id: 'Vendor Name', name: 'Vendor Name' },
        { id: 'Order Date', name: 'Order Date' },
        { id: 'Status', name: 'Status' },


      ],
    };


  }


  componentDidMount() {
    this.getVOItems();
  }

  getVOItems() {
    let saveRequest: any = {};


    saveRequest.recordCount = 0;
    VendorService.GetAllVendorOrder(saveRequest)
      .then(async (result: any[] | null) => {

        if (result != null) {
          // console.log(result);
          this.setState({ customerOrderResult: result }, () => {
            this.bindfilter();
          });
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  }

  shownewslideout = () => {
    this.setState({ showSlideout: true, orderid: 0, isSpinner: false, });
  };

  showslideout = () => {
    this.setState({ showSlideout: true, isSpinner: false, });
  };


  showReceivableslideout = (row: any) => {
    if (this.state.customerOrderResult.filter((x) => x.selected === true).length === 0) {
      toast.error('Please select jobs to ship under this order');
      reject();
      return;
    }
    this.setState({
      showReceivableslideout: true, orderid: row.orderID,
      invoiceId: 0,
      orderIds: this.state.customerOrderResult.filter(x => x.selected === true).map(r => r.orderID),
      isSpinner: false,
    });

  };
  discard = () => {
    
    let customerOrderResult = this.state.customerOrderResult;
    customerOrderResult.forEach(element => {
     element.selected = false;      
    });

    this.setState({ customerOrderResult: customerOrderResult, footershow: false, invoiceTotal: 0, selectedRows: [], selectedRowscustomerid: [] });
   
  };
  
  showRcvslideout = () => {

    
    this.setState({
      showReceivableslideout: true ,
      invoiceId: 0,
      orderIds: this.state.customerOrderResult.filter(x => x.selected === true).map(r => r.orderID),
      isSpinner: false,
      footershow: false,
      selectedRows: [],
      selectedRowscustomerid:[],
    });
  };
  closeSlideOut = () => {
    this.setState({ showSlideout: false, isSpinner: false,invoiceTotal:0,selectedRows:[],selectedRowscustomerid:[],});
    //this.getCustomerItems();
  };

  closeInvoiceSlideOut = () => {
    this.setState({ showReceivableslideout: false, isSpinner: false, invoiceTotal:0,selectedRows:[]});
    this.getVOItems();
  };

  rowEvents = {


    onClick: (e: any, row: any, rowIndex: any) => {
      if (row.mainType === undefined) {

        if (row.invoiceNo !== undefined && row.invoiceNo.length > 0) {
          this.setState({
            showReceivableslideout: true,
             orderid: row.orderId,
             invoiceId: row.id,
            isSpinner: false,
          });

        }
        else
          this.setState({
            showSlideout: true, orderid: row.orderID, invoiceId: 0, isSpinner: false,
          });

      }

    },
  };

  bindfilter = () => {
    let selectOptions = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.selectOptions))
    );

    this.setState({ selectOptions: selectOptions });

  };

  handleSelectedItem1 = (control: any, id: any) => {
    let searcResult1 = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.searcResult))
    );

    let chkisstatus = id == "Status" ? true : false;
    let chkisorderdate = id == "Order Date" ? true : false;
    let chkiscustomername = id == "Vendor Name" ? true : false;
    let chkiscustomercode = id == "Vendor Code" ? true : false;
    let chkiscustomerpo = id == "Vendor PO" ? true : false;


    if (id == "All") {
      chkisstatus = true;
      chkisorderdate = true;
      chkiscustomername = true;
      chkiscustomercode = true;
      chkiscustomerpo = true;

    }


    this.setState({
      searcResult: [], selectedcolumn: id, isstatus: chkisstatus, isorderdate: chkisorderdate,
      iscustomername: chkiscustomername, iscustomercode: chkiscustomercode, iscustomerpo: chkiscustomerpo
    },
      () => {
        this.setState({ searcResult: searcResult1 });

      }


    );

  };


  handleOnSelect = (row, isSelect) => {
    let customerOrderResult = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.customerOrderResult))
    );

    let listItem = customerOrderResult.filter(
      (x) => x.rowId === row.rowId
    );

  
    this.setState({ customerOrderResult: customerOrderResult }, () => { this.setState({ customerOrderResult: this.state.customerOrderResult }); });



  };

  handleOnSelectAll = (isSelect, rows) => {


    let checklistItem = this.state.selectedRowscustomerid.filter(
      (x) => x !== rows[0].customerID
    );

    const ids = rows.map(r => r.rowId);
    let { invoiceTotal, selectedRows, selectedcustomerid, selectedRowscustomerid } = this.state;
    let customerOrderResult = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.customerOrderResult))
    );

    let listItems = customerOrderResult.filter(
      (x) => ids.includes(x.rowId)
    );




    if (checklistItem.length == 0) {
      if (isSelect) {
        invoiceTotal += rows[0].totalAmount;
        selectedcustomerid = rows[0].customerID;
      }
      else {
        invoiceTotal -= rows[0].totalAmount;
        selectedcustomerid = 0;
      }


      if (isSelect) {
        selectedRows = [...this.state.selectedRows, rows[0].orderID];
        selectedRowscustomerid = [...this.state.selectedRowscustomerid, rows[0].customerID];


      } else {
        selectedRows = this.state.selectedRows.filter(x => x !== rows[0].orderID);
        selectedRowscustomerid = this.state.selectedRowscustomerid.filter(x => x !== rows[0].customerID);
      }
    }
    else
    {

      toast.error("if you make one invoice for multiples order then customer should be same ");
      
    }
    if (checklistItem.length == 0) {
    if (listItems.length > 0) {
      listItems.forEach((element: any, index: any) => {

        if (checklistItem.length == 0) {
          element.selected = isSelect;
        }

      });
    };
  }

    this.setState({ customerOrderResult: customerOrderResult, footershow: selectedRows.length>0 ? true :false, invoiceTotal: invoiceTotal, selectedRows: selectedRows, selectedRowscustomerid: selectedRowscustomerid });
  };


  render() {
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {

      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      },
      {
        text: '25', value: 25
      },
      {
        text: '50', value: 50
      },

      {
        text: 'All', value: this.state.searcResult.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };


    const columns = [
      // {
      //   dataField: 'orderID',
      //   text: '#',
      //   headerStyle: { width: '10px' },
      // },
      {
        dataField: 'vendorPoNumber',
        text: 'Vendor PO No.',
        sort: true,
        headerStyle: { width: '100px' },
        searchable: this.state.iscustomerpo,
      },
      {
        dataField: 'vendorCode',
        text: 'Vendor Code',
        sort: true,
        hidden: true,
        headerStyle: { width: '10px' },
        searchable: this.state.iscustomercode,
      },
      {
        dataField: 'vendorName',
        text: 'Vendor Name',
        sort: true,
        searchable: this.state.iscustomername,
        // headerStyle: { width: '300px' },
      },


      {
        dataField: 'orderDate',
        text: 'Order Date',
        sort: true,

        headerStyle: { width: '100px' },
        searchable: this.state.isorderdate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.orderDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },

      // ,
      {
        dataField: 'totalAmount',
        text: 'Order Amount',
        headerClasses: 'amount',
        classes: 'text-end pe-4 divgreen',
        sort: true,
        searchable: false,
        //headerStyle: { width: '300px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {Utils.currencyFormat(row.totalAmount)}
            </EllipsisWithTooltip>
          );
        },
      },



      // {
      //   dataField: '',
      //   text: '',
      //   headerStyle: { width: '100px' },
      // },

      {
        dataField: 'status',
        text: 'Order Status',
        sort: true,
        searchable: this.state.isstatus,
        headerStyle: { width: '700px' },
      },
      
      // {
      //   dataField: 'Inv',
      //   text: '',
      //   sort: true,
      //   searchable: false,
      //   //headerStyle: { width: '300px' },
      //   formatter: (cell: any, row: any, rowIndex: any) => {


      //     return (
      //       <EllipsisWithTooltip placement="bottom">
      //         {<Button className='btn btn-primary btn-sm py-0 btn-sml' onClick={(x) => this.showReceivableslideout(row)}>Save Invoice</Button>}
      //       </EllipsisWithTooltip>
      //     );
      //   },
      // },
      {
        dataField: '',
        text: 'Action',


        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="btn-group">
              <Dropdown
                onClick={(e) => { e.stopPropagation(); }}
                className="more-action"
                alignRight>
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary-white more"
                  id={"dropdown-more" + rowIndex}

                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventkey={"comments"} onClick={(x) => this.showReceivableslideout(row)}>Save Invoice </Dropdown.Item>
                  
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },

    ];


    const invoiceColumns = [
      // {
      //   dataField: 'orderID',
      //   text: '#',
      //   headerStyle: { width: '10px' },
      // },
      {
        dataField: 'invoiceNo',
        text: 'Invoice No.',
        sort: true,
        headerStyle: { width: '100px' },
        searchable: this.state.iscustomerpo,
      },
      {
        dataField: 'invoiceDate',
        text: 'Invoice Date',
        sort: true,

        headerStyle: { width: '100px' },
        searchable: this.state.isorderdate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.invoiceDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: 'vendorName',
        text: 'Vendor Name',
        sort: true,
        searchable: this.state.iscustomername,
        // headerStyle: { width: '300px' },
      },


      {
        dataField: 'dueDate',
        text: 'Due Date',
        sort: true,

        headerStyle: { width: '100px' },
        searchable: this.state.isorderdate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.orderDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },

      
      {
        dataField: 'accountingPeriod',
        text: 'Accounting Period',
        sort: true,
        searchable: this.state.iscustomername,
        // headerStyle: { width: '300px' },
      },

      {
        dataField: 'totalAmount',
        text: 'Total Amount',
        headerClasses: 'amount',
        classes: 'text-end pe-4 divgreen',
        sort: true,
        searchable: false,
        headerStyle: { width: '100px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {Utils.currencyFormat(row.totalAmount)}
            </EllipsisWithTooltip>
          );
        },
      },

      {
        dataField: '',
        text: '',

        sort: false,
        searchable: false,
        headerStyle: { width: '100px' },

      },

      // {
      //   dataField: '',
      //   text: '',
      //   headerStyle: { width: '100px' },
      // },


    ];

    const selectOptions = [{
      id: 0, column: 'Vendor PO'
    },
    { id: 1, column: 'Vendor Code' },
    { id: 2, column: 'Vendor Name' },
    { id: 3, column: 'Order Date' },
    { id: 4, column: 'Status' },

    ];

    const {

      showSlideout,
      showReceivableslideout,

    } = this.state;

     
    const expandSplitRow = {
      parentClassName: 'parent-expand-foo',
      bgColor: '#00BFFF',
      renderer: (row: any, rowIndex: any) =>  renderItemTableChild(row, rowIndex) ,
      showExpandColumn: true,
      // expandByColumnOnly: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        
        if (expanded) {
          this.setState({expanded:expanded},()=>{
            $('.selection-cell input').css('display', 'none');
          });
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };
    const whiteBgFormatter = (column, colIndex) => {
      return <div className="white-bg"></div>;
    };

    const renderItemTableChild = (mainrow, rowIndex) => {

      let splitDetail: any = [];
      splitDetail = this.state.customerOrderResult.filter(
        (x) => x.orderID === mainrow.orderID && x.mainType === 2
      );

      const columnCOAChild = [
        {
          dataField: "orderID",
          text: "#",
          hidden: true,
        },    
        {
          dataField: "id",
          text: "#",
          hidden: true,
          classes:"expanding-bar",
        },
        
        {
          dataField: "srno",
          text: "Sr No.",
          hidden: false,
          editable: false,
          headerStyle: { minWidth: '20px',width:'20px' },
           classes:"expanding-bar",
          headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
          formatter: (cell: any, row: any, rowIndex: any) => {

            return (
              <div>
                {rowIndex + 1}
              </div>
            );
          },
        },
        
        {
          dataField: "partname",
          text: "Part Name",
          classes:"expanding-bar text-left",
          headerStyle: { minWidth: '100px',width:'100px' },
        },

        

        {
          dataField: "dueDate",
          text: "Due Date",          
          hidden:true,
        classes:"expanding-bar",
        headerStyle: { minWidth: '100px',width:'100px' },
        },

        {
          dataField: "jobDesc",
          text: "Description",
          hidden:true,
           classes:"expanding-bar",
           headerStyle: { minWidth: '100px',width:'100px' },
        },

        {
          dataField: "unit",
          text: "Unit",
         classes:"expanding-bar",
         headerStyle: { minWidth: '100px',width:'100px' },
        },
        {
          dataField: "qtyOrdered",
          text: "Qty Ordered",
          classes:"expanding-bar",
          headerStyle: { minWidth: '100px',width:'100px' },

        },

        {
          dataField: "unitPrice",
         
          text: "Unit Price",
          classes:"expanding-bar",
          headerStyle: { minWidth: '100px',width:'100px' },

        },

        {
          dataField: "discount",
          text: "Discount",
           classes:"expanding-bar",
           headerStyle: { minWidth: '100px',width:'100px' },
        },
        {
          dataField: "totalAmount",
          text: "Total",
          classes:"expanding-bar text-end pe-4 divgreen",
          headerClasses: 'text-end pe-4',
          headerStyle: { minWidth: '100px',width:'100px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {Utils.currencyFormat(row.unitPrice * row.qtyOrdered - row.discount)}
              </EllipsisWithTooltip>
            );
          },
        },


        {
          dataField: "jobno",
          text: "Job Order",
         classes:"expanding-bar",
         headerStyle: { minWidth: '20px',width:'200px' },

        }


      ];
      
      return (
        <>
        <div className='px-4 p-4'>
       
          <BootstrapTable
        
            id="tblCOAchild"
            keyField="rowId"
            data={splitDetail}
            columns={columnCOAChild}
          //  rowClasses={ rowClasses }
            //rowEvents={this.rowEvents}
            selectRow={{
              mode: 'checkbox', clickToSelect: false,
               
              hideSelectAll: false,
              
              selected: splitDetail.filter((x) => x.selected === true).map(r => r.rowId),
              onSelect: this.handleOnSelect,
              onSelectAll: this.handleOnSelectAll,
              hideSelectColumn: false,
              // mode: 'checkbox', clickToSelect: false,
              selectColumnStyle: {
                backgroundColor: '#5c82ab08'
              },
      
              // hideSelectAll: false,
              
              // selected: splitDetail.filter((x) => x.selected === true).map(r => r.rowId),
              // onSelect: this.handleOnSelect,
              // onSelectAll: this.handleOnSelectAll,
              // hideSelectColumn: false,
               
            }}

          />
         
        </div>
        </>
      );
    };


 
  




    return (
      <>
        <React.Fragment>



          <Container fluid className="body-sec">
          <div className="page-heading underline d-flex">

           Pending Invoice List
              <div className="action-group d-flex flex-row ml-auto">
                {/* <Button onClick={this.shownewslideout}>Add Customer Order</Button> */}
              </div>
            </div>

            <Tab.Container transition={false} defaultActiveKey="first" >

              <section className="pb-3">
                <Nav className="nav nav-underline border-bottom">
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="first">Inbox <span className="badge badge-light">{this.state.customerOrderResult.filter(
                    (x) => x.mainType == 1
                  ).length}</span>


                    </Nav.Link>
                  </Nav.Item>
                  

                </Nav>
              </section>

              <Tab.Content>
                <Tab.Pane eventKey="first">

                  <ToolkitProvider
                    keyField="id"
                    data={this.state.customerOrderResult.filter((x) => x.mainType === 1)}
                    columns={columns}

                     
                    search
                  >
                    {
                      props => (


                        <div >
                          <section className="d-flex flex-wrap gap-3 pb-2">
                            <div className="position-relative">
                              <SearchBar {...props.searchProps} />
                            </div>

                            <div
                            //className="input-group w-auto"
                            >
                              <Form.Label>
                                <SingleDropdownListWithoutSearch

                                  itemList={this.state.selectOptions}
                                  handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                  defaultItem={this.state.selectedcolumn}
                                  defaultText={"Select Filter"}
                                  defaultName={this.state.selectedcolumn}
                                  controlID="1"
                                  id={"ddlselected"}

                                /></Form.Label>
                            </div>

                          </section>
                          <section className="pb-3">
                            <div className="table-responsive divbottom"  >
                              <div className="ml-auto transactions-list">
                              <BootstrapTable id="tblCO"
                            {...props.baseProps}
                            keyField="rowId"
                            rowEvents={this.rowEvents}
                            expandRow={expandSplitRow}
                            pagination={paginationFactory()}
                           
                          />
                                   {(this.state.footershow &&
                            <div className="TabFooter-Pay"
                            style={{ minWidth: "83.7%", width: "992px", maxWidth: "100%" }}
                            >
                              <div className="bulkSelectFooter">
                                <span className="shift-selected-count">{this.state.selectedRows.length}
                                  {" "}Selected</span>
                                <div className="FooterHorLine"></div>
                                <span className="shift-selected-count">Invoice Total: {Utils.currencyFormatWithOutdoller(this.state.invoiceTotal)}</span>
                                <div className="action-group d-flex flex-row mr-auto">
                                  <Button type="button"
                                  //className="btn-outline-primary btnHeight"
                                  //  onClick={(e) => { this.handleBtnEvent(e, "", 'bulk') }}
                                   onClick={(x) => this.showRcvslideout()}
                                  >Save Invoice</Button>

                                  <Button type="button"
                                   className='space btn btn-primary btn-discard'
                                   onClick={(x) => this.discard()}
                                  >Discard</Button>
                                </div>
                              </div>
                            </div>)}

                              </div>
                            </div>
                          </section>
                        </div>

                      )
                    }
                  </ToolkitProvider>


                </Tab.Pane>
                

              </Tab.Content>

            </Tab.Container>

            {(showSlideout &&
                <Customerorderslideout
                  closeSlideOut={this.closeSlideOut}
                  orderid={this.state.orderid}
                >


                </Customerorderslideout>
              )}

              {(showReceivableslideout &&
                <InvoiceEntryslideout
                  closeSlideOut={this.closeInvoiceSlideOut}
                  orderIds={this.state.orderIds}
                  invoiceId={this.state.invoiceId}
                >


                </InvoiceEntryslideout>
              )}



          </Container>



        </React.Fragment>
      </>
    );
  }
}