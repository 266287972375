import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { CustomerService } from "../Common/Services/CustomerService";
import { Customerorderslideout } from './Customerorderslideout';
import { ShippingSlideout } from './ShippingSlideout';
import { Utils } from "../Common/Utilis";
import _ from "lodash";
import paginationFactory from 'react-bootstrap-table2-paginator';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import createDOMPurify from "dompurify";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FaCircle, FaEllipsis } from "react-icons/fa6";
import moment from "moment";
import { SingleDropdownListWithoutSearch } from "../Common/Components/SingleDropdownListWithoutSearch";
//import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import chevronRight from "../Common/Assets/Images/icons/bx-chevron-right.svg";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
const { SearchBar } = Search;
const DOMPurify = createDOMPurify(window);
export class Customerorder extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.bindfilter = this.bindfilter.bind(this);
    this.state = {
      isstatus: true,
      isorderdate: true,
      iscustomername: true,
      iscustomercode: true,
      iscustomerpo: true,
      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      searcResult: [],
      shippingResult: [],
      shippedResult: [],
      orderid: 0,
      shipmentId: 0,
      jobIds: [],
      selectedcolumn: "All",
      selectOptions: [
        {

          id: 'All', name: 'All'
        },
        {

          id: 'Customer PO', name: 'Customer PO'
        },
        { id: 'Customer Code', name: 'Customer Code' },
        { id: 'Customer Name', name: 'Customer Name' },
        { id: 'Order Date', name: 'Order Date' },
        { id: 'Status', name: 'Status' },

      ],
    };
  }

  componentDidMount() {
    this.getCustomerItems();
    this.getShippingItems();
    this.getShippedItems();
  }

  getCustomerItems() {
    CustomerService.GetCustomerOrder()
      .then(async (result: any[] | null) => {
        if (result != null) {
          // console.log(result);
          this.setState({ searcResult: result }, () => {
            this.bindfilter();
          });
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  }

  getShippingItems() {
    CustomerService.GetCustomerShipping()
      .then(async (result: any[] | null) => {
        if (result != null) {
          // console.log(result);
          this.setState({ shippingResult: result }, () => {
            this.bindfilter();
          });
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  }

  getShippedItems() {
    CustomerService.GetCustomerShipped()
      .then(async (result: any[] | null) => {
        if (result != null) {
          // console.log(result);
          this.setState({ shippedResult: result }, () => {
            this.bindfilter();
          });
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  }


  shownewslideout = () => {
    this.setState({ showSlideout: true, orderid: 0, isSpinner: false, });
  };

  showslideout = () => {
    this.setState({ showSlideout: true, isSpinner: false, });
  };


  showShippingslideout = (row: any) => {

    if (this.state.shippingResult.filter((x) => x.selected === true && x.orderID === row.orderID).length === 0) {
      // alert('Please select jobs to ship under this order');
      // return;

      toast.error('Please select jobs to ship under this order');
      reject();
      return;
    }
    this.setState({
      showShippingSlideout: true, orderid: row.orderID,
      shipmentId: 0,
      jobIds: this.state.shippingResult.filter(x => x.selected === true && x.orderID === row.orderID).map(r => r.jobId),
      isSpinner: false,
    });
  };

  closeSlideOut = () => {
    this.setState({ showSlideout: false, isSpinner: false, });
    this.getCustomerItems();
    this.getShippingItems();
    this.getShippedItems();
  };

  closeShippingSlideOut = () => {
    this.setState({ showShippingSlideout: false, isSpinner: false, });
    this.getCustomerItems();
    this.getShippingItems();
    this.getShippedItems();
  };

  rowEvents = {


    onClick: (e: any, row: any, rowIndex: any) => {
      
      if (row.rowType === undefined) {

        if (row.shipmentNo !== undefined && row.shipmentNo.length > 0) {
          this.setState({
            showShippingSlideout: true, orderid: row.orderId,
            shipmentId: row.id,
            isSpinner: false,
          });

        }
        else
          this.setState({
            showSlideout: true, orderid: row.orderID, shipmentId: 0, isSpinner: false,
          });

      }

    },
  };

  bindfilter = () => {
    let selectOptions = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.selectOptions))
    );

    this.setState({ selectOptions: selectOptions });

  };

  handleSelectedItem1 = (control: any, id: any) => {
    let searcResult1 = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.searcResult))
    );

    let chkisstatus = id == "Status" ? true : false;
    let chkisorderdate = id == "Order Date" ? true : false;
    let chkiscustomername = id == "Customer Name" ? true : false;
    let chkiscustomercode = id == "Customer Code" ? true : false;
    let chkiscustomerpo = id == "Customer PO" ? true : false;


    if (id == "All") {
      chkisstatus = true;
      chkisorderdate = true;
      chkiscustomername = true;
      chkiscustomercode = true;
      chkiscustomerpo = true;

    }


    this.setState({
      searcResult: [], selectedcolumn: id, isstatus: chkisstatus, isorderdate: chkisorderdate,
      iscustomername: chkiscustomername, iscustomercode: chkiscustomercode, iscustomerpo: chkiscustomerpo
    },
      () => {
        this.setState({ searcResult: searcResult1 });

      }


    );

  };


  handleOnSelect = (row, isSelect) => {


    let shippingResult = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.shippingResult))
    );

    let listItem = shippingResult.filter(
      (x) => x.rowId === row.rowId
    );

    listItem[0].selected = isSelect;

    this.setState({ shippingResult: shippingResult }, () => { this.setState({ shippingResult: this.state.shippingResult }); });



  };

  handleOnSelectAll = (isSelect, rows) => {

    const ids = rows.map(r => r.rowId);

    let shippingResult = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.shippingResult))
    );

    let listItems = shippingResult.filter(
      (x) => ids.includes(x.rowId)
    );

    if (listItems.length > 0) {
      listItems.forEach((element: any, index: any) => {
        element.selected = isSelect;
      });
    };

    this.setState({ shippingResult: shippingResult });
  };

  render() {

    const columns = [

      {
        dataField: 'customerPoNumber',
        text: 'Order No.',
        sort: true,
        headerStyle: { minWidth: '100px', width: '100px' },
        searchable: this.state.iscustomerpo,
        className: "text-truncate",
      },
      {
        dataField: 'customercode',
        text: 'Customer Code',
        sort: true,
        headerStyle: { minWidth: "100px", width: '100px' },
        className: "text-truncate",

        searchable: this.state.iscustomercode,
      },
      {
        dataField: 'customerName',
        text: 'Customer Name',
        sort: true,
        searchable: this.state.iscustomername,
        className: "text-truncate",
        headerStyle: { minWidth: "200px", Width: "200px" },

      },
      {
        dataField: 'orderDate',
        text: 'Order Date',
        sort: true,

        headerStyle: { minWidth: "200px" },
        searchable: this.state.isorderdate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.orderDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },

      // ,
      {
        dataField: 'totalAmount',
        text: 'Order Amount',
        headerClasses: 'text-end pe-4',
        classes: 'text-end pe-4 divgreen',
        headerStyle: { minWidth: "200px" },
        sort: true,
        searchable: false,
        //headerStyle: { width: '300px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {Utils.currencyFormat(row.totalAmount)}
            </EllipsisWithTooltip>
          );
        },
      },


      {
        dataField: 'status',
        text: 'Status',
        headerClasses: 'text-center',
        classes: 'text-center',
        sort: true,
        headerStyle: { minWidth: "100px" },
        searchable: this.state.isstatus,
        // headerStyle: { width: '700px' },
      },


      {
        dataField: '',
        text: '',
        headerClasses: 'text-center',
        classes: 'text-center',
        sort: false,

        searchable: this.state.isstatus,
        // headerStyle: { width: '700px' },
      },



    ];

    const shippedColumns = [

      {
        dataField: 'customerPoNumber',
        text: 'Order No.',
        sort: true,
        headerStyle: { minWidth: '100px', width: '100px' },
        searchable: this.state.iscustomerpo,
        className: "text-truncate",
      },

      {
        dataField: 'customercode',
        text: 'Customer Code',
        sort: true,
        headerStyle: { minWidth: "100px", width: '100px' },
        className: "text-truncate",
        searchable: this.state.iscustomercode,
      },
      {
        dataField: 'customerName',
        text: 'Customer Name',
        sort: true,
        searchable: this.state.iscustomername,
        className: "text-truncate",
        headerStyle: { minWidth: "200px", Width: "200px" },
      },
      {
        dataField: 'shipmentNo',
        text: 'Shipment No.',
        sort: true,
        headerStyle: { width: '200px' },
        searchable: this.state.iscustomerpo,
      },

      {
        dataField: 'shipmentDate',
        text: 'Shipment Date',
        sort: true,

        headerStyle: { minWidth: "100px" },
        searchable: this.state.isorderdate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.shipmentDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },

      // ,
      {
        dataField: 'courierTrackingNo',
        text: 'Courier Tracking No',

        sort: true,
        searchable: false,
        headerStyle: { minWidth: "100px" },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.courierTrackingNo}
            </EllipsisWithTooltip>
          );
        },
      },



      // {
      //   dataField: '',
      //   text: '',
      //   headerStyle: { width: '100px' },
      // },

      {
        dataField: 'totalBoxNo',
        text: 'Total Box No',
        sort: true,
        searchable: this.state.isstatus,
        headerStyle: { width: '700px' },
        headerClasses: 'amount',
        classes: 'text-end pe-4',
      },

    ];

    const shippingColumns = [
      // {
      //   dataField: 'orderID',
      //   text: '#',
      //   headerStyle: { width: '10px' },
      // },
      {
        dataField: 'customerPoNumber',
        text: 'Order No.',
        sort: true,
        headerStyle: { width: '100px' },
        searchable: this.state.iscustomerpo,
      },
      {
        dataField: 'customercode',
        text: 'Customer Code',
        sort: true,
        headerStyle: { width: '100px' },
        searchable: this.state.iscustomercode,
      },
      {
        dataField: 'customerName',
        text: 'Customer Name',
        sort: true,
        searchable: this.state.iscustomername,
        // headerStyle: { width: '300px' },
      },


      {
        dataField: 'orderDate',
        text: 'Order Date',
        sort: true,

        headerStyle: { width: '100px' },
        searchable: this.state.isorderdate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.orderDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },

      // ,
      {
        dataField: 'totalAmount',
        text: 'Order Amount',
        headerClasses: 'amount',
        classes: 'text-end pe-4 divgreen',
        sort: true,
        searchable: false,
        headerStyle: { width: '200px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {Utils.currencyFormat(row.totalAmount)}
            </EllipsisWithTooltip>
          );
        },
      },

      {
        dataField: '',
        text: '',

        sort: false,
        searchable: false,
        headerStyle: { width: '100px' },

      },

      // {
      //   dataField: '',
      //   text: '',
      //   headerStyle: { width: '100px' },
      // },

      {
        dataField: 'status',
        text: 'Order Status',
        sort: true,
        searchable: this.state.isstatus,


        formatter: (cell: any, row: any, rowIndex: any) => {

          if (row.completeStatus == "Partial") {
            return (
              <div>
                <h6>
                  {row.status}  <span className="badge badge-pill badge-primary color-orange">  ready to {row.completeStatus.toLowerCase()} ship </span>
                </h6>
              </div>
            );
          }
          else {

            return (
              <div>
                <h6>
                  {row.status}  <span className="badge badge-pill badge-primary color-green">  ready to {row.completeStatus.toLowerCase()} ship </span>
                </h6>
              </div>
            );
          }

        }
      },


      // {
      //   dataField: 'Ship',
      //   text: '',
      //   sort: true,
      //   searchable: false,
      //   //headerStyle: { width: '300px' },
      //   formatter: (cell: any, row: any, rowIndex: any) => {


      //     return (
      //       <EllipsisWithTooltip placement="bottom">
      //         {<Button onClick={(x) => this.showShippingslideout(row)}>Ship</Button>}
      //       </EllipsisWithTooltip>
      //     );
      //   },
      // },

      {
        dataField: '',
        text: 'Action',


        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="btn-group">
              <Dropdown
                onClick={(e) => { e.stopPropagation(); }}
                className="more-action"
                alignRight>
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary-white more"
                  id={"dropdown-more" + rowIndex}

                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventkey={"comments"} onClick={(x) => this.showShippingslideout(row)}>Ship </Dropdown.Item>

                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ];

    const selectOptions = [{
      id: 0, column: 'Customer PO'
    },
    { id: 1, column: 'Customer Code' },
    { id: 2, column: 'Customer Name' },
    { id: 3, column: 'Order Date' },
    { id: 4, column: 'Status' },

    ];

    const {

      showSlideout,
      showShippingSlideout,

    } = this.state;


    const expandSplitRow = {
      onlyOneExpanding: false,
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => renderItemTableChild(row, rowIndex),
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };
    const whiteBgFormatter = (column, colIndex) => {
      return <div className="white-bg"></div>;
    };

    const renderItemTableChild = (mainrow, rowIndex) => {

      let splitDetail: any = [];
      splitDetail = this.state.shippingResult.filter(
        (x) => x.orderID === mainrow.orderID && x.rowType === 2
      );

      const columnCOAChild = [

        {
          dataField: "jobNo",
          text: "Job No.",
          classes: "expanding-bar",
          headerStyle: { minWidth: '100px', width: '100px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (

              <EllipsisWithTooltip placement="bottom">
                {row.jobNo}
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "totalQty",
          text: "Total Qty",
          hidden: false,
          classes: "expanding-bar",
          headerStyle: { minWidth: '100px', width: '100px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.totalQty}
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "shippingQty",
          text: "Remaining Qty",
          hidden: false,
          classes: "expanding-bar",
          headerStyle: { minWidth: '100px', width: '100px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.shippingQty}
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "manualTracking",
          text: "Tracking",
          hidden: false,
          classes: "expanding-bar",
          headerStyle: { minWidth: '100px', width: '100px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.manualTracking ? 'Manual' : 'Tracked'}
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "rowId",
          text: "rowId",
          hidden: true,
          classes: "expanding-bar",
          headerStyle: { minWidth: '500px', width: '500px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.rowId}
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "",
          text: "",
          classes: "expanding-bar",
          headerClasses: "blankLastChildofChild",
          headerStyle: { minWidth: '500px', width: '500px' },
        },



      ];

      return (
        <>
          <div className='px-4 p-4'>
            <BootstrapTable
              id="tblCOAchild"
              keyField="rowId"
              data={splitDetail}
              columns={columnCOAChild}
              //rowEvents={this.rowEvents}
              selectRow={{
                mode: 'checkbox', clickToSelect: false, hideSelectAll: false,
                selected: splitDetail.filter((x) => x.selected === true).map(r => r.rowId),
                onSelect: this.handleOnSelect,
                onSelectAll: this.handleOnSelectAll,
                hideSelectColumn: false,
                selectColumnStyle: {
                  backgroundColor: '#5c82ab08'
                },
              }}

            />
          </div>
        </>
      );
    };
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    // const options = {
    //   // pageStartIndex: 0,
    //   sizePerPageList: [{
    //     text: '10', value: 10
    //   }, {
    //     text: '25', value: 25
    //   }, {
    //     text: 'All', value: this.state.searcResult.length
    //   }],
    //   showTotal: true,
    //   paginationTotalRenderer: customTotal,
    //   sizePerPage: 10,
    //   // hideSizePerPage: false,
    //   // hidePageListOnlyOnePage: false,
    // };
    const options = {

      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      },
      {
        text: '25', value: 25
      },
      {
        text: '50', value: 50
      },

      {
        text: 'All', value: this.state.searcResult.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };

    return (
      <>
        <React.Fragment>






          <Container fluid className="body-sec" >
            <section>

              <div className="page-heading underline d-flex">

                Customer Orders
                <div className="action-group d-flex flex-row ml-auto">
                  <Button onClick={this.shownewslideout}
                  // className="btn-primary wht-bg link-btn mr-auto"
                  >Add Order</Button>
                </div>
              </div>


              <Tab.Container transition={false} defaultActiveKey="first" >

                <section className="pb-3">
                  <Nav className="nav nav-underline border-bottom">
                    <Nav.Item className="nav-item">
                      <Nav.Link eventKey="first">All  Orders
                        {/* <span className="badge badge-light">{this.state.searcResult.length}</span> */}

                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link eventKey="second">Ready to Ship
                        {/*<span className="badge badge-light">
                 {this.state.shippingResult.filter(
                        (x) => x.rowType == 2
                      ).length}</span> */}

                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link eventKey="third">Shipped
                        {/* <span className="badge badge-light"> 
              {this.state.shippedResult.length}</span>
               */}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </section>

                <Tab.Content>
                  <Tab.Pane eventKey="first">

                    <ToolkitProvider
                      keyField="id"
                      data={this.state.searcResult}
                      columns={columns}
                      //className="table table-striped table-borderless align-middle"
                      search
                    >
                      {
                        props => (


                          <div >
                            <section className="d-flex flex-wrap gap-3 pb-2">
                              <div className="position-relative">
                                <SearchBar {...props.searchProps} />
                              </div>

                              <div
                              //className="input-group w-auto"
                              >
                                <Form.Label>
                                  <SingleDropdownListWithoutSearch

                                    itemList={this.state.selectOptions}
                                    handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                    defaultItem={this.state.selectedcolumn}
                                    defaultText={"Select Filter"}
                                    defaultName={this.state.selectedcolumn}
                                    controlID="1"
                                    id={"ddlselected"}
                                    key="id"
                                  /></Form.Label>
                              </div>

                            </section>
                            <section className="pb-3">
                              <div className="table-responsive divbottom"  >
                                <div className="ml-auto transactions-list">
                                  <BootstrapTable
                                    {...props.baseProps}
                                    rowEvents={this.rowEvents}
                                    pagination={paginationFactory(options)}

                                  />
                                </div>
                              </div>
                            </section>
                          </div>

                        )
                      }
                    </ToolkitProvider>



                  </Tab.Pane>
                  <Tab.Pane eventKey="second">

                    <ToolkitProvider
                      keyField="id"

                      data={this.state.shippingResult.filter((x) => x.rowType === 1)}
                      columns={shippingColumns}
                      //className="table table-borderless align-middle"
                      search
                    >
                      {
                        props => (


                          <div >
                            <section className="d-flex flex-wrap gap-3 pb-2">
                              <div className="position-relative">
                                <SearchBar {...props.searchProps} />
                              </div>

                              <div
                              //className="input-group w-auto"
                              >
                                <Form.Label>
                                  <SingleDropdownListWithoutSearch

                                    itemList={this.state.selectOptions}
                                    handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                    defaultItem={this.state.selectedcolumn}
                                    defaultText={"Select Filter"}
                                    defaultName={this.state.selectedcolumn}
                                    controlID="1"
                                    id={"ddlselected1"}
                                    key="id"
                                  /></Form.Label>
                              </div>

                            </section>
                            <section className="pb-3">
                              <div className="table-responsive divbottom"  >
                                <div className="ml-auto transactions-list">
                                  <BootstrapTable
                                    {...props.baseProps}
                                    keyField="rowId"
                                    rowEvents={this.rowEvents}
                                    pagination={paginationFactory(options)}
                                    expandRow={expandSplitRow}

                                  />
                                </div>
                              </div>
                            </section>
                          </div>

                        )
                      }
                    </ToolkitProvider>

                  </Tab.Pane>
                  <Tab.Pane eventKey="third">


                    <ToolkitProvider
                      keyField="id"
                      data={this.state.shippedResult}
                      columns={shippedColumns}
                      className="table table-borderless align-middle"
                      search
                    >
                      {
                        props => (


                          <div >
                            <section className="d-flex flex-wrap gap-3 pb-2">
                              <div className="position-relative">
                                <SearchBar {...props.searchProps} />
                              </div>

                              <div
                              //className="input-group w-auto"
                              >
                                <Form.Label>
                                  <SingleDropdownListWithoutSearch

                                    itemList={this.state.selectOptions}
                                    handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                    defaultItem={this.state.selectedcolumn}
                                    defaultText={"Select Filter"}
                                    defaultName={this.state.selectedcolumn}
                                    controlID="1"
                                    id={"ddlselected2"}
                                    key="id"
                                  /></Form.Label>
                              </div>

                            </section>
                            <section className="pb-3">
                              <div className="table-responsive divbottom"  >
                                <div className="ml-auto transactions-list">
                                  <BootstrapTable
                                    {...props.baseProps}
                                    rowEvents={this.rowEvents}
                                    pagination={paginationFactory(options)}

                                  />
                                </div>
                              </div>
                            </section>
                          </div>

                        )
                      }
                    </ToolkitProvider>


                  </Tab.Pane>
                </Tab.Content>

              </Tab.Container>


              {/* <Tabs defaultIndex={0} onSelect={(index) => console.log(index)}
 
                selectedTabClassName="is-selected" >
                <TabList>
                <section >

                    

                      <Tab>All  Orders<span className="badge badge-light">{this.state.searcResult.length}</span></Tab>


                      <Tab>Ready to Ship <span className="badge badge-light">{this.state.shippingResult.filter(
                        (x) => x.rowType == 2
                      ).length}</span>
                      </Tab>
                      <Tab>Shipped <span className="badge badge-light"> {this.state.shippedResult.length}</span>
                      </Tab>


                     



                  </section>

                </TabList>



                <TabPanel>


                  <ToolkitProvider
                    keyField="id"
                    data={this.state.searcResult}
                    columns={columns}
                    // className="table table-borderless align-middle"
                    search
                  >
                    {
                      props => (


                        <div >
                          <section className="d-flex flex-wrap gap-3 pb-3">
                            <div className="position-relative">
                              <SearchBar {...props.searchProps} />
                            </div>

                            <div 
                            //className="input-group w-auto"
                            >
                              <Form.Label>
                                <SingleDropdownListWithoutSearch
                                  width="300px"
                                  itemList={this.state.selectOptions}
                                  handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                  defaultItem={this.state.selectedcolumn}
                                  defaultText={"Select Filter"}
                                  defaultName={this.state.selectedcolumn}
                                  controlID="1"
                                  id={"ddlselected"}

                                /></Form.Label>
                            </div>

                          </section>
                          <section className="pb-3">
                            <div className="table-responsive ml-auto">
                              <BootstrapTable
                                {...props.baseProps}
                                rowEvents={this.rowEvents}
                                pagination={paginationFactory(options)}

                              />
                            </div>
                          </section>
                        </div>

                      )
                    }
                  </ToolkitProvider>



                </TabPanel>
                <TabPanel>


                  <ToolkitProvider

                    data={this.state.shippingResult.filter((x) => x.rowType === 1)}
                    columns={shippingColumns}

                    search
                  >
                    {
                      props => (
                        <div>
                          <section className="d-flex flex-wrap gap-3 pb-3">
                            <div className="position-relative">
                              <SearchBar {...props.searchProps} />

                            </div>

                            <div 
                            //className="input-group w-auto"
                            >
                              <Form.Label>
                                <SingleDropdownListWithoutSearch
                                  // tabIndex={this.state.tabEnable}
                                  itemList={this.state.selectOptions}
                                  handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                  defaultItem={this.state.selectedcolumn}
                                  defaultText={"Select Filter"}
                                  defaultName={this.state.selectedcolumn}
                                  controlID="1"
                                  id={"ddlselected"}
                                //onTAB={this.onTAB.bind(this, "ddlprocess-" + rowIndex + "ddl", rowIndex)}
                                // disabled={disable}
                                /></Form.Label>

                            </div>
                          </section>
                          <section className="pb-3">
                            <div className="table-responsive">
                              <BootstrapTable id="tblShipping" className="table table-borderless align-middle"
                                {...props.baseProps}
                                keyField="rowId"
                                rowEvents={this.rowEvents}
                                expandRow={expandSplitRow}
                                pagination={paginationFactory()}
                              />

                            </div>
                          </section>
                        </div>
                      )
                    }
                  </ToolkitProvider>

                </TabPanel>
                <TabPanel>


                  <ToolkitProvider

                    data={this.state.shippedResult}
                    columns={shippedColumns}

                    search
                  >
                    {
                      props => (
                        <div>
                          <section className="d-flex flex-wrap gap-3 pb-3">
                            <div className="position-relative">
                              <SearchBar {...props.searchProps} />
                            </div>

                            <div
                            // className="input-group w-auto"
                             >

                              <Form.Label>
                                <SingleDropdownListWithoutSearch
                                  // tabIndex={this.state.tabEnable}
                                  itemList={this.state.selectOptions}
                                  handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                  defaultItem={this.state.selectedcolumn}
                                  defaultText={"Select Filter"}
                                  defaultName={this.state.selectedcolumn}
                                  controlID="1"
                                  id={"ddlselected"}
                                //onTAB={this.onTAB.bind(this, "ddlprocess-" + rowIndex + "ddl", rowIndex)}
                                // disabled={disable}
                                /></Form.Label>
                            </div>
                          </section>
                          <section className="pb-3">
                            <div className="table-responsive">
                              <BootstrapTable id="tblShipped" className="table table-borderless align-middle"
                                {...props.baseProps}
                                keyField="rowId"
                                rowEvents={this.rowEvents}
                                // expandRow={expandSplitRow}
                                pagination={paginationFactory()}
                              />
                            </div>
                          </section>
                        </div>
                      )
                    }
                  </ToolkitProvider>

                </TabPanel>

              </Tabs> */}



              {(showSlideout &&
                <Customerorderslideout
                  closeSlideOut={this.closeSlideOut}
                  orderid={this.state.orderid}
                
                >


                </Customerorderslideout>
              )}



              {(showShippingSlideout &&
                <ShippingSlideout
                  closeSlideOut={this.closeShippingSlideOut}
                  orderid={this.state.orderid}
                  jobids={this.state.jobIds}
                  shipmentId={this.state.shipmentId}
                >


                </ShippingSlideout>
              )}
            </section>
          </Container>



        </React.Fragment>
      </>
    );
  }
}