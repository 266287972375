import React, { useEffect, useState } from "react";
import {
  Form,
  Dropdown,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal
} from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";

import _ from "lodash";
import "../../Modules/customer.scss";
import { FiChevronDown } from "react-icons/fi";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";

import { resolve, reject } from "q";
import { Utils } from "../Utilis";
import { CustomerService } from "../Services/CustomerService";
import { CustomerMasterSlidout } from "../../Modules/Masters/CustomerMasterSlidout";
let isValidInput: any = "";
let isShowGeneric: boolean = false;
let selectedRow: any = {};
let currentTabIndex: any = 0;
let MaxTabIndex: any = 0;
let MaxContractTabIndex: any = 0;
let requestInProcess = false;
export class CustomerSearch extends React.Component<any, any> {

  private inputSearch: any;
  constructor(props: any) {
    super(props);
    this.inputSearch = React.createRef();

    this.state = {

  
      loader: false,
     
      entryType: props.entryType,
      pageName: props.pageName,
      selectedCustomer: "",
      selectedCustomercode: "",
      address: "",
      firstname: "",
      apartment: "",
      country: "US",
      TenantID: 0,
      oldselectedVendor: "",
      customerForCreate: "",
      selectedContract: "",
      oldSelectedContract: "",
      customerList: [],
      mainCustomerList: [],
      customerAddressList: [],
      customerNameLatest: "",
      isListOpen: false,
      isContractDetailsOpen: false,
      contractDetails: [],
      options : [],
      vendorforDetails: "",
      customerType: "",
      vendorStatus: "app",
      isNewRequest: false,
      isNewCustomer: false,
      isNewContract: false,
      isAddressSelect: false,
      isNewAddress: false,
      isGeneric: false,
      isFoundCurrent: false,
      isFoundOther: false,
      isAddVendorPerm: false,
      isAddVendorAddressPerm: false,
      isAddContractPerm: false,
      isCreateVendorWithApp: false,
      isGenericVendorPerm: false,
      btnName: "",
      // Inputs Control States
      newVendorName: "",
      vendorCheck: false,
      salesTaxCheck: false,
      paymentMethod: "Check",
      invoicesDuesAfter: "0",
      isfirst: true,
      addressID: 0,
      city: "",
      states: "",
      zip: "",
      customerNo: "",
      taxIDPrefix: "TAX ID",
      taxIdSuffix: "",
      phone: "",
      customercode: "",

      email: "",
      lastVid: 0,
      // End Here
      lastVType: "",
      isAddVendorBtnVisible: false,
      isMatchingMsgVisible: false,
      addVendorFooterVisible: false,
      callFromPage: props.callFromPage,
      selectedContractRowID: 0,
      customerAlias: "",
      cursor: -1,
      cursorDetails: 1,
      tabEnableVendor: 1,
      postingRule: "",
      editedVid: 0,
      AddNewPostingRule: false,
      rules: "",
      newRuleError: false,
      LastUpdatedBy: "",
      LastUpdatedTime: "",
      selectedVID: 0,
      isEditInvoiceRules: false,
      disabled: props?.disabled,
      zipCodeError: false,
      isCustIDExist: false,
      IsActive: true

    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    // this.handleSelectFromParent=this.handleSelectFromParent.bind(this);
  }



  componentDidMount() {

    document.addEventListener("onKeyPress", this.taxIDCheck, false);
  }


  componentWillUnmount() {

    document.addEventListener("onKeyPress", this.taxIDCheck, false);

  }


  escFunction = (event) => {
    if (event.keyCode === 27) {
      //esc key press
      this.setState({
        isListOpen: false,
        isfirst: false,
        addVendorFooterVisible: false,
        isNewCustomer: false,
        isNewContract: false,
        isNewRequest: false,
      });
    }
  };


  GetCustomerTranlist = (requestlist: any) => {
    CustomerService.GetCustomerlist(requestlist) //Customer list service method
      .then(async (result: any | null) => {

        if (result !== null) {

          this.updateParentState(result);
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };


  GetCustomerTranlistRef = (requestlist: any, item: any) => {
    CustomerService.GetCustomerlist(requestlist) //Customer list service method
      .then(async (result: any | null) => {

        if (result !== null) {

          
          this.updateParentState(result);
          this.handleSelectFromParent(item);
         
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };
  handleSelectFromParent = (item: any) => {

    
    this.setState({  isListOpen: false,
      selectedCustomer: item.company_name,
      firstname:item.first,
      customerForCreate: item.company_name?.trim(),
      address: item.address?.trim() + "\n" + item.apartment?.trim() + "\n" + item.city?.trim() + ", " + item.state?.trim() + "\n" + item.zipcode?.trim(),
      // + " " + item.country?.trim(),
      customercode: item.customercode,
      customerAlias: item.customerAlias,
      // oldSelectedContract: item[0].accountNo,
      selectedCustomercode: item.customercode === "" || item.customercode === undefined
        ? ""
        : " (" + item.customercode + ")",
      editedVid: item.customer_id,
      selectedVID: item.customer_id
    }
    , () => {
      //this.handleCustomerChange(item);
    });

  };

  closeSlideOut = (item: any) => {
    

    this.setState({ showSlideout: false, isSpinner: false, });

    if (item != null) {
      this.clearFieldValues();

      let requestlist = { ...this.state.requestlist };

      this.GetCustomerTranlistRef(requestlist,item);
      // this.setState({isfirst:false});
      setTimeout(() => {
        this.handleCustomerChange(item);
      }, 500);
    }

    // alert('ok'); 

  };

  closeSlideOut2 = async () => {
    this.setState({ showSlideout: false, isSpinner: false, });

  };




  updateParentState = (result: any) => {

    this.setState(
      {
        customerList: [],
        mainCustomerList: [],
      },
      () => {
        this.setState(
          {
            customerList: result,
            mainCustomerList: result,
            loader: false,
          },
          () => {
            this.props.updateCustomerList(result);

          }
        );
      }
    );
  };



  handleClick = () => {
    selectedRow = {};
    MaxTabIndex = 0;
    

    if (this.props.orderid==0 ) {
      $("#inputSearch").select();
    }
    else {
      this.setState({ isListOpen:  true }, () => {

        $("#inputSearch").select();

        let requestlist = { ...this.state.requestlist };

        this.GetCustomerTranlist(requestlist);
      }


      );
    }
    // let requestlist = { ...this.state.requestlist };

    // this.GetCustomerTranlist(requestlist);

  };

  filterFromVendorList = (name: any, callFrom) => {

    selectedRow = {};
    MaxTabIndex = 0;
    this.setState({
      selectedCustomer: name.toString(),
      customerForCreate: name.toString()?.trim(),
      addVendorFooterVisible: true,

      cursor: -1,
      cursorDetails: 1,
    });
    let newResult: any = [];
    if (name?.trim().length > 0) {
      let customerDataFilter: any[] = [];
      customerDataFilter = _.cloneDeep(this.state.mainCustomerList);


      newResult = _.filter(customerDataFilter, (o) => 
        _.includes(
            o.company_name.toString().toLowerCase(),
            name?.trim().toString().toLowerCase()
        ) || (o.companyAlias && _.includes(
            o.companyAlias.toString().toLowerCase(),
            name?.trim().toString().toLowerCase()
        ))
      );
      
      // newResult=  newResult.sort(function(a, b) {
      //   return a.company_name.toString().toLowerCase().charAt(0).localeCompare(name?.trim().toString().toLowerCase().charAt(0));
      // });
      newResult = newResult.sort((a, b) => {
        const nameA = a.company_name.toString().toLowerCase();
        const nameB = b.company_name.toString().toLowerCase();
        
        const startsWithA = nameA.startsWith(name);
        const startsWithB = nameB.startsWith(name);
        
        if (startsWithA && !startsWithB) {
          return -1; 
        }
        if (!startsWithA && startsWithB) {
          return 1; 
        }
        return nameA.localeCompare(nameB); 
      });

     
      newResult.map(
        (item, index) => (
          (item["id"] = index)
        )
      );

// console.lof

      if (callFrom === "dropdown") {
        const arrayResult: any = [];
        this.props.handleCustomerChange(arrayResult);
      }
      MaxTabIndex = newResult.length === 0 ? 1 : 0;
      this.setState({
        customerList: newResult,

      });

      if (newResult.length > 0) {
        this.setState({
          isListOpen: true,
          isMatchingMsgVisible: false,
          isAddVendorBtnVisible: false,
        });
      } else {
        this.setState({
          isListOpen: false,
          isfirst: false,
          isMatchingMsgVisible: true,
          isAddVendorBtnVisible: true,
        });
      }
    } else {
      this.setState({
        isListOpen: false,
        isfirst: false,
        isMatchingMsgVisible: false,
        isAddVendorBtnVisible: false,
      });
    }
    if (name?.trim().length === 0) {
      if (callFrom === "dropdown") {
        const arrayResult: any = [];
        this.props.handleCustomerChange(arrayResult);
      }
      this.setState({
        isListOpen: false,
        isfirst: false,
        isMatchingMsgVisible: false,
      });
    }

  };



  //Handle change when entering the value in vendor search box
  handleChange = (event, eventFrom) => {
    if (eventFrom == "IsActive") {

      this.setState({ IsActive: event.target.checked });
    }
    else {

      this.setState({ selectedCustomercode: "", oldSelectedContract: "" });
      if (!this.state.isNewRequest)
        this.filterFromVendorList(event.target.value, eventFrom); //Calling this fun to filter Customer List
    }

  };

  //Close vendor control on outside click
  handleOutsideClick = (e) => {
    if (!this.state.loader && !this.state.isNewRequest)
      this.clearFieldValuesFromOutsideClick();
    this.setState({ isListOpen: false, isfirst: false, addVendorFooterVisible: false });
  };

  clearFieldValuesFromOutsideClick = () => {
    this.setState({
      vendorCheck: false,
      invoicesDuesAfter: "0",
      salesTaxCheck: false,
      address: "Select Address",
      addressID: 0,
      city: "",
      states: "",
      zip: "",
      customerNo: "",
      taxIDPrefix: "TAX ID",
      taxIdSuffix: "",
      phone: "",
      email: "",
      isContractDetailsOpen: false,
      isListOpen: true,
      isfirst: true,
      addVendorFooterVisible: true,
      isNewCustomer: false,
      isNewContract: false,
      isNewRequest: false,
      customerForCreate: this.state.selectedCustomer,
    });
  };

  //Fun to open a form for adding a New Customer
  addNewVendor = () => {
    const company_name = this.state.selectedCustomer
      .toString()
      .toLowerCase()
      ?.trim();


    const found = _.some(this.state.mainCustomerList, function (el) {
      return el.company_name.toLowerCase() === company_name;
    }); //found: true, IF the Customer name already exist in DB

    if (!found) {
      this.clearFieldValues();
      this.setState({
        btnName: "Add Customer",
        isNewRequest: true,
        isNewCustomer: true,
        isContractDetailsOpen: false,
        isListOpen: false,
        isfirst: false,
        newVendorName: this.state.selectedCustomer,
        customerForCreate:
          this.state.selectedCustomer?.trim().length > 250
            ? this.state.selectedCustomer.substring(0, 250)?.trim()
            : this.state.selectedCustomer?.trim(),


        selectedCustomercode: this.state.customercode == "" ? "" : " (" + this.state.customercode?.trim() + ")",
        // " (" + this.state.customercode?.trim() + ")",

        customerAlias: this.state.customerAlias,
        address: "",
        isNewContract: false,
        isAddressSelect: true,
        isNewAddress: true,
        addVendorFooterVisible: false,
        salesTaxCheck: true,
      }, () => {
        $('#vendorname').focus();
      });
    } else {
      toast.error("Customer already exists.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
  };



  clearFieldValues = () => {
    this.setState({
      vendorCheck: false,
      invoicesDuesAfter: "0",
      salesTaxCheck: false,
      address: "Select Address",
      addressID: 0,
      city: "",
      states: "",
      zip: "",
      customerNo: "",
      taxIDPrefix: "TAX ID",
      taxIdSuffix: "",
      phone: "",
      customercode: "",
      customerAlias: "",
      email: "",
      isContractDetailsOpen: false,
      isListOpen: true,
      isfirst: false,
      addVendorFooterVisible: true,
      isNewCustomer: false,
      isNewContract: false,
      isNewRequest: false,
      customerForCreate: this.state.selectedCustomer,
      selectedCustomercode: this.state.customercode,
      cursor: -1,
      cursorDetails: 1
    }, () => {
      $('#SlideBody').scrollTop(0);
      this.moveCursorToEnd();
      selectedRow = {};
    });
  };

  moveCursorToEnd = () => {
    let el: any = document.getElementById('inputSearch');
    if (el !== null && el !== undefined) {
      el.focus();
      setTimeout(() => {
        if (typeof el.selectionStart == "number") {
          el.selectionStart = el.selectionEnd = el.value.length;
        } else if (typeof el.createTextRange != "undefined") {
          var range = el.createTextRange();
          range.collapse(false);
          range.select();
        }

      }, 0);
    }
  };

  handleCustomerChange = (item: any) => {




    this.setState(
      {
        selectedCustomer: item.company_name?.trim(),
        customerForCreate: item.company_name?.trim(),
        address: item.address?.trim(),
        firstname:item.firstname,
        customerAlias: item.customerAlias?.trim(),
        isListOpen: false,
        isfirst: false,
        selectedCustomercode: item.customercode?.trim() == "" ? "" : " (" + item.customercode?.trim() + ")",
        selectedVID: item.customer_id

      },
      () => {
        const arrayResult: any = [];
        arrayResult.push(item);


        this.props.handleCustomerChange(arrayResult); //Calling the parent change method

      }
    );





  };

  handleNotApprove = () => {
    // Nothing to do in this fun
  };

  handleAddNewAddress = (event: any) => {
    this.setState({
      isAddressSelect: true,
      isNewAddress: true,
      address: "",
      country: "US",
      phone: "",
      customercode: "",
      customerAlias: "",
      email: "",
      addressID: 0,
      customerNo: "",
      //taxIDPrefix: "TAX ID",
      //taxIdSuffix: "",
    }, () => {
      $("#streetaddressText").focus();
      $("#customerno").removeClass("alert-danger");
    });
  };

  onClickVendor = (items: any) => {

    

    this.handleCustomerChange(items);


  };



  handleKeyDownArrow = (e, items, action) => {
    
    const { cursor, customerList } = this.state;
    if (e.keyCode === 38 && cursor > 0 && this.state.isListOpen && action === "vendorRow") {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1
      }), () => {
        let selRow = customerList.filter((item) => item.id === cursor - 1);
        selectedRow = {};
        selectedRow = (selRow[0]);
        setTimeout(() => {
          var tabindex: any = $('.vendorListFocusSet').attr('tabindex');

          if (Number(currentTabIndex) === 1) {
            $('.vendorListFocusSet').focus();
          } else {
            currentTabIndex = (Number(currentTabIndex) - 1);
            $('[tabindex=' + currentTabIndex + ']').focus();
          }

        }, 0);
      });

    } if (e.keyCode === 38 && cursor === 0 && this.state.isListOpen && action === "vendorRow") {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1
      }), () => {
        selectedRow = {};
        MaxTabIndex = 0;
        setTimeout(() => {
          this.handleClick();
          $('#SlideBody').scrollTop(0);
          $('#inputSearch').focus();
        }, 0);
      });

    } else if (e.keyCode === 40 && cursor < customerList.length - 1 && this.state.isListOpen && action === "vendorRow") {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }), () => {
        let selRow = customerList.filter((item) => item.id === cursor + 1);
        selectedRow = {};
        selectedRow = (selRow[0]);
        setTimeout(() => {
          var tabindex: any = $('.vendorListFocusSet').attr('tabindex');
          currentTabIndex = tabindex;
          if (Number(MaxTabIndex) === this.state.cursor) {
            $('.vendorListFocusSet').focus();
          } else {
            $('[tabindex=' + tabindex + ']').focus();
          }
        }, 0);
      });

    } else if (e.keyCode === 40 && cursor === customerList.length - 1 && this.state.isListOpen && action === "vendorRow") {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }), () => {
        selectedRow = {};
        setTimeout(() => {
          var tabindex: any = $('.vendorListFocusSet').attr('tabindex');
          currentTabIndex = tabindex;
          $('[tabindex=' + tabindex + ']').focus();
        }, 0);
      });

    } else if (e.keyCode === 40 && cursor === customerList.length && this.state.isListOpen && action === "GenericSelect") {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }), () => {
        selectedRow = {};
        setTimeout(() => {
          var tabindex: any = $('.vendorListFocusSet').attr('tabindex');

          currentTabIndex = MaxTabIndex;
          if (Number(MaxTabIndex) === this.state.cursor) {
            $('.vendorListFocusSet').focus();
          } else {
            $('[tabindex=' + tabindex + ']').focus();
          }
        }, 0);
      });

    } else if (e.keyCode === 40 && this.state.isMatchingMsgVisible && action === "GenericSelect") {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }), () => {
        selectedRow = {};
        setTimeout(() => {
          var tabindex: any = $('.vendorListFocusSet').attr('tabindex');

          currentTabIndex = MaxTabIndex;
          if (Number(MaxTabIndex) === this.state.cursor) {
            $('.vendorListFocusSet').focus();
          } else {
            $('[tabindex=' + tabindex + ']').focus();
          }
        }, 0);
      });

    } else if (e.keyCode === 38 && cursor === customerList.length && this.state.isListOpen && action === "GenericSelect") {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1
      }), () => {
        let selRow = customerList.filter((item) => item.id === cursor - 1);
        selectedRow = {};
        selectedRow = (selRow[0]);
        setTimeout(() => {
          var tabindex: any = $('.vendorListFocusSet').attr('tabindex');
          if (Number(currentTabIndex) === 1) {
            $('.vendorListFocusSet').focus();
          } else {
            currentTabIndex = (Number(currentTabIndex) - 1);
            $('[tabindex=' + currentTabIndex + ']').focus();
          }

        }, 0);
      });

    } else if (e.keyCode === 38 && (this.state.isListOpen || this.state.isMatchingMsgVisible) && action === "AddVendorBtn") {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1
      }), () => {
        let selRow = customerList.filter((item) => item.id === cursor - 1);
        selectedRow = {};
        selectedRow = (selRow[0]);
        setTimeout(() => {
          currentTabIndex = (Number(MaxTabIndex));
          $('[tabindex=' + currentTabIndex + ']').focus();
        }, 0);
      });

    } else if (e.keyCode === 38 && !this.state.isListOpen && this.state.isMatchingMsgVisible && action === "GenericSelect") {

      selectedRow = {};
      MaxTabIndex = 0;
      setTimeout(() => {
        this.handleClick();
        //this.setState({cursor:-1});
        $('#inputSearch').focus();
      }, 0);


    } else if (e.keyCode === 39 && cursor > -1 && this.state.isListOpen && action === "vendorRow") { // for right arrow
      if (this.state.pageName === "customerorderentry") {
        if (selectedRow?.customerType.toLowerCase() === "current") {
          if (selectedRow?.ctCount > 1)
            this.onClickVendor(selectedRow);

        }
      } else {
        if (selectedRow?.customerType.toLowerCase() === "current" && selectedRow.approval?.toString().toLowerCase() !== "no") {
          if (selectedRow?.ctCount > 1)
            this.onClickVendor(selectedRow);

        }
        else if (selectedRow.approval?.toString().toLowerCase() !== "no") {

        }
      }
    
     
    } else if (e.keyCode === 37 && cursor < customerList.length - 1 && (this.state.isContractDetailsOpen || this.state.isNewRequest) && action === "vendorRow") {// for left arrow
      this.clearFieldValues();
    } else if (e.keyCode === 13 && this.state.isListOpen && action === "vendorRow") {// for Enter Key

      this.onClickVendor(selectedRow);
    } else if (e.keyCode === 9) {// For disable tab
      e.preventDefault();
    } else if (e.keyCode === 13 && (this.state.isListOpen || this.state.isMatchingMsgVisible) && action === "AddVendorBtn") {// for Enter Key
      this.addNewVendor();
    }
  };

  handleKeyDown = (e) => {
    
    const { cursor, customerList } = this.state;
    if (e.keyCode === 40 && customerList.length === 0 && this.state.isMatchingMsgVisible) {
      this.setState(prevState => ({
        cursor: prevState.cursor + 2
      }), () => {
        let selRow = customerList.filter((item) => item.id === cursor + 1);
        selectedRow = {};
        selectedRow = (selRow[0]);
        setTimeout(() => {
          var tabindex: any = $('.GenericVendor').attr('tabindex');
          if (tabindex === undefined) {
            $('[tabindex=' + MaxTabIndex + ']').focus();
          } else {
            currentTabIndex = tabindex;
            $('[tabindex=' + tabindex + ']').focus();
          }
        }, 0);
      });

    } if (e.keyCode === 40 && cursor < customerList.length - 1 && this.state.isListOpen) {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }), () => {
        let selRow = customerList.filter((item) => item.id === cursor + 1);
        selectedRow = {};
        selectedRow = (selRow[0]);
        setTimeout(() => {
          $('.vendorListFocusSet').focus();
        }, 0);
      });
    }
    else if (e.keyCode === 9) {// for Disable Tab Key
      e.preventDefault();
    }
  };

  resetVendorSearchControl = () => {
    this.setState(() => ({ selectedCustomer: "", selectedCustomercode: "", selectedContract: "" }));
  };

  handleContractDetailBox = (e, action, item) => {
    const { cursorDetails, contractDetailList } = this.state;
    if (e.keyCode === 37 && this.state.isContractDetailsOpen) { // for left arrow click
      this.clearFieldValues();
    } if (e.keyCode === 13 && this.state.isContractDetailsOpen && action === "back") {// for Enter Key
      this.clearFieldValues();
    } if (e.keyCode === 38 && this.state.isContractDetailsOpen && action === "back") {
      this.setState(prevState => ({
        cursorDetails: prevState.cursorDetails
      }), () => {
        selectedRow = {};
        setTimeout(() => {
          var tabindex: any = $('.contractListFocusSet').attr('tabindex');
          $('[tabindex=' + 1 + ']').focus();
        }, 0);
      });
    } if (e.keyCode === 40 && this.state.isContractDetailsOpen && action === "back") {
      this.setState(prevState => ({
        cursorDetails: prevState.cursorDetails + 1
      }), () => {
        selectedRow = {};
        setTimeout(() => {
          var tabindex: any = $('.contractListFocusSet').attr('tabindex');
          $('[tabindex=' + tabindex + ']').focus();
        }, 0);
      });
    } if (e.keyCode === 40 && this.state.isContractDetailsOpen && action === "contractList") {
      this.setState(prevState => ({
        cursorDetails: prevState.cursorDetails + 1
      }), () => {
        selectedRow = {};
        selectedRow = item;
        setTimeout(() => {
          var tabindex: any = $('.contractListFocusSet').attr('tabindex');
          $('[tabindex=' + tabindex + ']').focus();
        }, 0);
      });
    } if (e.keyCode === 38 && this.state.isContractDetailsOpen && action === "contractList") {
      this.setState(prevState => ({
        cursorDetails: prevState.cursorDetails - 1
      }), () => {
        selectedRow = {};
        selectedRow = item;
        setTimeout(() => {
          var tabindex: any = $('.contractListFocusSet').attr('tabindex');
          if (tabindex === undefined) {
            $('[tabindex=' + 1 + ']').focus();
          } else {
            $('[tabindex=' + tabindex + ']').focus();
          }

        }, 0);
      });
    } if (e.keyCode === 38 && this.state.isContractDetailsOpen && action === "AddContract") {
      this.setState(prevState => ({
        cursorDetails: prevState.cursorDetails - 1
      }), () => {
        selectedRow = {};
        setTimeout(() => {
          var tabindex: any = $('.contractListFocusSet').attr('tabindex');
          if (tabindex === undefined) {
            $('[tabindex=' + 1 + ']').focus();
          } else {
            $('[tabindex=' + tabindex + ']').focus();
          }

        }, 0);
      });
    } if (e.keyCode === 13 && this.state.isContractDetailsOpen && action === "contractList") {// for Enter Key
      // this.onClickContractDetailsList(item);
    } if (e.keyCode === 13 && this.state.isContractDetailsOpen && action === "AddContract") {// for Enter Key

    } if (e.keyCode === 9) {// for Disable Tab Key
      e.preventDefault();
    }
  };

  handleKeyTabForm = (e, action) => {
    if (e.keyCode === 27 && this.state.isNewRequest) { // for Left Arrow
      this.clearFieldValues();
    }
    if (e.keyCode === 9 && this.state.isNewRequest) { // for Tab pressing
      setTimeout(() => {
        if (action === "vendorname")
          $('[tabindex=' + 2 + ']').focus();
        if (action === "vendorCheck")
          $('[tabindex=' + 3 + ']').focus();
        if (action === "paymentMethod")
          $('[tabindex=' + 4 + ']').focus();
        if (action === "invoices")
          $('[tabindex=' + 5 + ']').focus();
        if (action === "salesTaxCheck")
          $('[tabindex=' + 6 + ']').focus();
        if (action === "taxIDPrefix")
          $('[tabindex=' + 7 + ']').focus();
        if (action === "taxIdSuffix")
          $('[tabindex=' + 8 + ']').focus();
        if (action === "addressNew")
          $('[tabindex=' + 9 + ']').focus();
        if (action === "city")
          $('[tabindex=' + 10 + ']').focus();
        if (action === "states")
          $('[tabindex=' + 11 + ']').focus();
        if (action === "zip")
          $('[tabindex=' + 12 + ']').focus();
        if (action === "customerno")
          $('[tabindex=' + 13 + ']').focus();
        if (action === "phone")
          $('[tabindex=' + 14 + ']').focus();
        if (action === "email")
          $('[tabindex=' + 15 + ']').focus();
        if (action === "cancel")
          $('[tabindex=' + 16 + ']').focus();
        if (action === "saveBtn")
          if (this.state.isNewContract)
            if (this.state.isNewAddress)
              $('[tabindex=' + 8 + ']').focus();
            else
              $('[tabindex=' + 6 + ']').focus();
          // if (this.state.isNewAddress)
          //   setTimeout(() => {
          //     $('[tabindex=' + 6 + ']').focus();
          //   }, 0);
          // else
          //   setTimeout(() => {
          //     $('[tabindex=' + 7 + ']').focus();
          //   }, 0);
          else
            $('[tabindex=' + 1 + ']').focus();
      }, 0);
    }
  };

  //Fun for rendring the vendor list for current and Other Ehid's
  renderVendorList = (items: any, index: any, vendorType: any) => {

    //listRowIndex = listRowIndex + 1;
    let rNo = Number(items.id);
    MaxTabIndex = rNo + 1;

    return (
      <div
        className={this.state.cursor === items.id ? 'activeVendor currentHIDRepeat vendorListItem vendorListFocusSet' : "vendorListItem currentHIDRepeat"}
        tabIndex={rNo}
        onKeyDown={(e) => { this.handleKeyDownArrow(e, items, 'vendorRow'); }}
      >
        <Row
          className="VendorListRow"

        >
          <Col sm="11">
            <div
              className="selectableDiv"
              onClick={(e) => { this.onClickVendor(items); }}
            >
              <div className="d-flex">

                <span className="customerName">{items.company_name}   :  {items.customercode}</span>

              </div>


            </div>
          </Col>

        </Row>
      </div>
    );

  };

  renderAddContractorDetailsBtn = (items: any) => {
    return this.state.pageName === "TransactionPage" ? (
      items.ctCount > 1 &&
        items.customerType?.toString().toLowerCase() === "current" ? (
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 320 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          className="rightIcon"

        >
          <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
        </svg>
      ) : items.approval?.toString().toLowerCase() !== "no" ? (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="addContract">Add New Contract</Tooltip>}
        >
          <svg
            className="AddVendorrightIcon"
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 448 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"

          >
            <path d="M352 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm96-160v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path>
          </svg>
        </OverlayTrigger>
      ) : (
        <svg
          className="AddVendorrightIcon"
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 448 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          onClick={(e) => {
            this.handleNotApprove();
          }}
        >
          <path d="M352 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm96-160v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path>
        </svg>
      )
    ) : items.ctCount > 1 &&
      items.customerType?.toString().toLowerCase() === "current" ? (
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 320 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
        className="rightIcon"

      >
        <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
      </svg>
    ) : (
      this.state.isAddContractPerm && items.approval?.toString().toLowerCase() === "app" && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="addContract">Add New Contract</Tooltip>}
        >
          <svg
            className="AddVendorrightIcon"
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 448 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"

          >
            <path d="M352 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm96-160v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path>
          </svg>
        </OverlayTrigger>
      )
    );
  };

  renderAdVendorBtn = () => {
    return this.state.pageName === "TransactionPage" ? (
      <>

        {this.state.isAddVendorPerm && (
          <div className={this.state.cursor === MaxTabIndex + 1 ? 'activeVendor AddVendorFooter vendorListFocusSet' : "AddVendorFooter"}
            tabIndex={MaxTabIndex + 1}
            onKeyDown={(e) => { this.handleKeyDownArrow(e, "", 'AddVendorBtn'); }}
          >
            <button
              id="addNewVendor"
              type="button"
              className="btn wht-bg addVendorButton"
              onClick={this.addNewVendor}
            >
              Add New Customer "{this.state.customerForCreate?.trim()}"
            </button>
          </div>
        )}
      </>
    ) : (

      <>
        <div className={this.state.cursor === MaxTabIndex + 1 ? 'activeVendor AddVendorFooter vendorListFocusSet' : "AddVendorFooter"}
          tabIndex={MaxTabIndex + 1}
          onKeyDown={(e) => { this.handleKeyDownArrow(e, "", 'AddVendorBtn'); }}
        >
          <button
            id="addNewVendor"
            type="button"
            className="btn wht-bg txt-green addVendorButton"
            onClick={this.addNewVendor}
          >
            Add New Customer "{this.state.customerForCreate?.trim()}"
          </button>
        </div>
      </>

    );
  };



  showModal = () => {
    if (this.state.isEditInvoiceRules)
      this.setState({ AddNewPostingRule: true, rules: this.state.postingRule, newRuleError: false });
  };
  hideModal = () => {
    this.setState({ AddNewPostingRule: false, });
  };
  handleRules = (event) => {
    let { value } = event.target;
    this.setState({ rules: value, newRuleError: false });
    this.setState({ rules: value, newRuleError: false });
  };

  showNewSlideOut = () => {
    this.setState({ showSlideout: true, customerid: 0, isSpinner: false });
  };

  componentWillReceiveProps(nextProps: { disabled: any }) {
    this.setState({ disabled: nextProps.disabled });
  }

  renderCustomerSearchBox = () => {

    if ((this.state.selectedCustomer.length) > 250 && !this.state.isListOpen)
      return (<OverlayTrigger
        placement="bottom"
        delay={{ show: 100, hide: 200 }}
        overlay={
          <Tooltip id={"ddd"}>
            {this.state.selectedCustomer + this.state.selectedCustomercode}
          </Tooltip>
        }>
        <input
          ref={this.inputSearch}
          id="inputSearch"
          key={"text-Filter"}
          type="text"
          className="form-control searchVendor VendorDisabled"
          onChange={(e) => {
            this.handleChange(e, "dropdown");
          }}
          onClick={this.handleClick}
          disabled={this.state.disabled ? true : this.state.isNewRequest}
          placeholder="Enter Customer Name"
          //title={this.state.selectedCustomer.length > 20 ? this.state.selectedCustomer + this.state.selectedContract : ""}
          autoComplete="Off"
          value={
            this.state.selectedCustomer + this.state.selectedCustomercode
          }
          onKeyDown={this.handleKeyDown}
        />
      </OverlayTrigger>);
    else
      return (<input
        ref={this.inputSearch}
        id="inputSearch"
        key={"text-Filter"}
        type="text"
        className="form-control searchVendor VendorDisabled"
        onChange={(e) => {
          this.handleChange(e, "dropdown");
        }}
        onClick={this.handleClick}
        disabled={this.state.disabled ? true : this.state.isNewRequest}
        placeholder="Enter Customer Name"
        autoComplete="Off"
        value={
          this.state.selectedCustomer + this.state.selectedCustomercode
        }
        onKeyDown={this.handleKeyDown}
      />);
  };

  render() {
    const {
      showSlideout
    } = this.state;


    let data = this.state.customerList;
    let isSpclCharMsg = "Fields containing only special characters is not allowed. Please update the field.";
    return (


      <React.Fragment>
        {this.state.loader && (
          <div className="matrixSpinnerCenter">
            <Spinner animation="border" variant="secondary" />
          </div>
        )}

        {(showSlideout &&

          <CustomerMasterSlidout
            closeSlideOut={this.closeSlideOut}
            customerid={this.state.customerid}
            customername={this.state.customerForCreate?.toUpperCase()}
            customerAlias={this.state.customerAlias?.toUpperCase()}
            address={this.state.address}
            apartment={this.state.apartment}
            city={this.state.city}
            zip={this.state.zip}
            states={this.state.states}
            country={this.state.country}
            IsActive={this.state.IsActive}
            id={this.state.id}
            isCadd={true}
          >


          </CustomerMasterSlidout>)}
        <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
          <div className="vendorSearch">

            <>
              <div className="SearchInput d-flex">
                {this.renderCustomerSearchBox()}

                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="postingRule"></Tooltip>}
                >
                  <svg
                    stroke="currentColor"
                    fill="#6a6e73"
                    stroke-width="0"
                    version="1.1"
                    viewBox="0 0 16 16"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                    className="postingToolTip"
                    onClick={this.showModal}
                  >
                    <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                    <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                    <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                  </svg>
                </OverlayTrigger>
                {this.state.selectedContract !== "" && this.state.pageName === "InvoiceEntry" && (this.state.postingRule === "" || this.state.postingRule === undefined) && (
                  <svg
                    stroke="currentColor"
                    fill="#6a6e73"
                    stroke-width="0"
                    version="1.1"
                    viewBox="0 0 16 16"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                    className="postingToolTip"
                    onClick={this.showModal}
                  >
                    <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                    <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                    <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                  </svg>
                )}
              </div>

              {this.state.isListOpen && this.state.customerList.length > 0 && (
                <React.Fragment>
                  <div className="VendorList" id="VendorList">
                    <div className="scrollList VendorListScroll" id="VendorListScrol">

                      <div className="borderDiv" id="borderDiv">
                        <div className="CurrentEHID" id="CurrentEHID">


                          {this.state.customerList.map((items, index) =>
                            this.renderVendorList(items, index, "Current")
                          )}
                        </div>
                      </div>


                    </div>
                    {this.renderAdVendorBtn()}
                  </div>
                </React.Fragment>
              )}

              {/* isMatchingMsgVisible */}

              {this.state.addVendorFooterVisible && (
                <div className="VendorList">
                  {this.state.isMatchingMsgVisible && (
                    <div className="scrollList">
                      <div className="borderDiv">
                        <div className="CurrentEHID">
                          <div className="VendorListLoading">
                            No matching customer exist.
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.isAddVendorBtnVisible &&
                    this.renderAdVendorBtn()}
                </div>
              )}
            </>


            {this.state.isNewRequest && (
              <div className={this.state.isNewRequest ? "isFromCCSI" : ""}>
                <div className="addVendorForm">
                  <div className="borderDiv">
                    <div className="NewContractHeader2">
                      <div>
                        <div className="float-sm-start">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 320 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={this.clearFieldValues}
                            className="NewContractBackButton"
                            onKeyDown={(e) => { this.handleKeyTabForm(e, 'back'); }}
                          >
                            <path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path>
                          </svg>{" "}
                          Add New {this.state.isNewCustomer ? "Customer" : "Contract"}
                        </div>
                        <div className="float-end">
                        
                          <button type="button" className="close" aria-label="Close"
                            onClick={this.clearFieldValues}

                            onKeyDown={(e) => { this.handleKeyTabForm(e, 'cancel');; }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>

                        </div>

                      </div>
                    </div>
                    <div className="NewContractHeader">

                      <div className="CurrentEHID   scroll search-control-height">
                        <div>

                          <div className="row">

                            <div className="col-12 col-sm-8" >
                              <Form.Group className="mr-0">
                                <Form.Label>
                                  Customer Full Name <strong className="text-danger">*</strong>
                                </Form.Label>
                                <Form.Control
                                  className="search-input-height"
                                  id="vendorname"
                                  value={this.state.customerForCreate?.toUpperCase()}
                                  onChange={(e: any) =>
                                    this.onInputChange(e, "vendorname")
                                  }
                                  placeholder="Enter Name"
                                  autoComplete="Off"
                                  maxLength={Number(250)}
                                  autoFocus={this.state.customerForCreate?.toUpperCase()}
                                  // disabled={this.isDisabled("VendorNameControl")}

                                  onBlur={(e) => {
                                    this.validateInputsFormat("vendorname");
                                  }}
                                  tabIndex={1}
                                  onKeyDown={(e) => { this.handleKeyTabForm(e, 'vendorname'); }}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-12 col-sm-4" >
                              <Form.Group className="mr-0">
                                <Form.Label>
                                  Customer Alias
                                </Form.Label>
                                <Form.Control
                                  className="search-input-height"
                                  id="vendorname"
                                  value={this.state.customerAlias?.toUpperCase()}
                                  onChange={(e: any) =>
                                    this.onInputChange(e, "customeralias")
                                  }
                                  placeholder="Enter Alias"
                                  autoComplete="Off"
                                  maxLength={Number(50)}
                                  autoFocus={this.state.customerAlias?.toUpperCase()}
                                  // disabled={this.isDisabled("VendorNameControl")}

                                  onBlur={(e) => {
                                    this.validateInputsFormat("vendorname");
                                  }}
                                  tabIndex={1}
                                  onKeyDown={(e) => { this.handleKeyTabForm(e, 'customeralias'); }}
                                />
                              </Form.Group>
                            </div>
                          </div>

                        </div>




                        <React.Fragment>
                          {/* <div className="addVendorRow3">
                            <Row>
                              <Col sm="12">
                                <Form.Group>
                                  <Form.Label>
                                    First name and last name of recipient{" "}
                                    
                                  </Form.Label>
                                  <Form.Control
                                    value={this.state.firstname}
                                    className="search-input-height"
                                    onChange={(e: any) =>
                                      this.onInputChange(e, "firstname")
                                    }
                                    id="streetaddressText"
                                    placeholder="Enter First name and last name of recipient"
                                    autoComplete="Off"
                                    maxLength={Number(100)}
                                    tabIndex={8}
                                    onKeyDown={(e) => { this.handleKeyTabForm(e, 'firstname'); }}
                                  />
                                   
                                </Form.Group>
                              </Col>
                            </Row>
                          </div> */}
                          <div className="mt-3">
                            <Row>
                              <Col sm="12">
                                <Form.Group>
                                  <Form.Label>
                                    <Row>
                                      <Col className="col-md-auto">
                                        Address 1{" "}     <strong className="text-danger">*</strong> </Col>
                                      <Col className="col-md-auto"> <Form.Check
                                        //custom
                                        type="checkbox"

                                        //key={"custom-footer"}
                                        checked={this.state.IsActive}
                                        id={"chkActive"}
                                        label={"Use the same as Shipping Address"}
                                        onChange={(e: any) => this.handleChange(e, "IsActive")}
                                      //ref={this.footerchk}

                                      />

                                      </Col>
                                    </Row>
                                  </Form.Label>
                                  <Form.Control
                                    value={this.state.address}
                                    className="search-input-height"
                                    onChange={(e: any) =>
                                      this.onInputChange(e, "address")
                                    }
                                    id="streetaddressText"
                                    placeholder="Enter Street number and name"
                                    autoComplete="Off"
                                    maxLength={Number(100)}
                                    tabIndex={8}
                                    onKeyDown={(e) => { this.handleKeyTabForm(e, 'address'); }}
                                  />
                                  {this.state.address.length > 0 && !this.validateAddress(this.state.address) && <div className="textError">{isSpclCharMsg}</div>}
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                          <div className="mt-3">
                            <Row>
                              <Col sm="8">
                                <Form.Group>
                                  <Form.Label>
                                    Address 2{" "}
                                    {/* <strong className="text-danger">*</strong> */}
                                  </Form.Label>
                                  <Form.Control
                                    value={this.state.apartment}
                                    className="search-input-height"
                                    onChange={(e: any) =>
                                      this.onInputChange(e, "apartment")
                                    }
                                    id="apartment"
                                    placeholder="Enter Apartment or Unit and its number"
                                    autoComplete="Off"
                                    maxLength={Number(100)}
                                    tabIndex={8}
                                    onKeyDown={(e) => { this.handleKeyTabForm(e, 'apartment'); }}
                                  />
                                  {this.state.apartment.length > 0 && !this.validateAddress(this.state.apartment) && <div className="textError">{isSpclCharMsg}</div>}
                                </Form.Group>
                              </Col>
                              <Col sm="4" className="pr-0">
                                <Form.Group className="mr-0">
                                  <Form.Label>
                                    City{" "}
                                    <strong className="text-danger">*</strong>
                                  </Form.Label>
                                  <Form.Control
                                    id="city"
                                    value={this.state.city}
                                    className="search-input-height"
                                    onChange={(e: any) =>
                                      this.onInputChange(e, "city")
                                    }
                                    placeholder="Enter City"
                                    maxLength={50}
                                    tabIndex={9}
                                    onKeyDown={(e) => { this.handleKeyTabForm(e, 'city'); }}
                                  />
                                  {this.state.city.length > 0 && !this.validateAddress(this.state.city) && <div className="textError">{isSpclCharMsg}</div>}
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                          <div className="mt-3">
                            <Row>

                              <Col sm="4" className="pr-0">
                                <Form.Group className="mr-0">
                                  <Form.Label>
                                    State{" "}
                                    <strong className="text-danger">*</strong>
                                  </Form.Label>
                                  <Form.Control
                                    id="state"
                                    value={this.state.states}
                                    onChange={(e: any) =>
                                      this.onInputChange(e, "state")
                                    }
                                    placeholder="Enter State"
                                    className="search-input-height"
                                    maxLength={50}
                                    tabIndex={10}
                                    onKeyDown={(e) => { this.handleKeyTabForm(e, 'states'); }}
                                  />

                                  {this.state.states.length > 0 && !this.validateAddress(this.state.states) && <div className="textError">{isSpclCharMsg}</div>}
                                </Form.Group>
                              </Col>
                              <Col sm="4">
                                <Form.Group>
                                  <Form.Label>
                                    Zip{" "}
                                    <strong className="text-danger">*</strong>
                                  </Form.Label>
                                  <Form.Control
                                    id="zip"
                                    value={this.state.zip}
                                    className={this.state.zipCodeError ? "alert-danger search-input-height" : "search-input-height"}
                                    onChange={(e: any) => this.onInputChange(e, "zip")}
                                    placeholder="Enter Zip"
                                    maxLength={10}
                                    tabIndex={11}
                                    onKeyDown={(e) => { this.handleKeyTabForm(e, 'zip'); }}
                                    onBlur={(e) => { this.validateInputsFormat("zip"); }}
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm="4" className="pr-0">
                                <Form.Group className="mr-0">
                                  <Form.Label>
                                    Country{" "}
                                    <strong className="text-danger">*</strong>
                                  </Form.Label>
                                  <Form.Control
                                    id="country"
                                    value={this.state.country}
                                    onChange={(e: any) =>
                                      this.onInputChange(e, "country")
                                    }
                                    placeholder="Enter Country"
                                    className="search-input-height"
                                    maxLength={50}
                                    tabIndex={12}
                                    onKeyDown={(e) => { this.handleKeyTabForm(e, 'country'); }}
                                  />
                                  {this.state.country.length > 0 && !this.validateAddress(this.state.country) && <div className="textError">{isSpclCharMsg}</div>}
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>

                        </React.Fragment>



                      </div>
                    </div>
                    <div className="footerNewVendor">
                      <Row className="row">
                        <Col sm="8">
                          <button
                            className="btn wht-bg VendorCancelBtn"
                            onClick={this.showNewSlideOut}
                            tabIndex={16}
                          // onKeyDown={(e) => { this.handleKeyTabForm(e, 'cancel');; }}
                          >
                            <span className="badge orange-bg">   Add More customer details </span>
                          </button>
                        </Col>
                        <Col sm="4">
                          <Button
                            disabled={this.state.loader}
                            className="addNewVendor"
                            id="saveBtn"
                            onClick={() => this.handleSaveRequest()}
                            tabIndex={15}
                            onKeyDown={(e) => { this.handleKeyTabForm(e, 'saveBtn'); }}
                          >
                            {this.state.btnName}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </OutsideClickHandler>

      </React.Fragment>

    );
  }

  pCodeValidate = (val: any) => {
    return /^\d{5}(-\d{4})?$/.test(val);
  };

  validateAddress = (val: any) => {
    return /^(?=.*[a-zA-Z0-9])/.exec(val);
  };

  //Validate fields value format correct or not
  validateInputsFormat = (controlID: any) => {
    let { taxIdSuffix, zip } = this.state;
    if (
      controlID === "vendorname" &&
      this.state.customerForCreate?.trim() !== ""
    ) {
      if (this.state.customerForCreate?.trim().length < 3) {
        toast.error("Customer name should be atleast 3 characters.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        $("#vendorname").addClass("alert-danger");
      } else {
        $("#vendorname").removeClass("alert-danger");
      }
    }

    if (controlID === "EmailControl" && this.state.email?.trim() !== "") {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!emailRegex.test(this.state.email?.toLowerCase()?.trim())) {
        $("#email").addClass("alert-danger");
        // toast.error("E-Mail format is not correct.", {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        // });
      } else {
        $("#email").removeClass("alert-danger");
      }
    }
    if (controlID === "PhoneControl" && this.state.phone?.trim() !== "") {
      if (
        this.state.phone?.trim().length < 7 ||
        this.state.phone?.trim().length > 20
      ) {
        // toast.error("Mobile Number must be between 7 to 20 digits.", {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        // });
        $("#phone").addClass("alert-danger");
      } else {
        $("#phone").removeClass("alert-danger");
      }
    }
    if (controlID === "zip" && zip?.trim() !== "") {
      let { zipCodeError, zip } = this.state;
      let pCode = zip.toString().replace(/-/g, '').trim();
      if ((pCode?.length > 5 && pCode?.length === 9) && !pCode?.includes("-")) {
        pCode = pCode.substr(0, 5) + "-" + pCode.substr(5, pCode?.length - 5);
      }
      zip = pCode;
      zipCodeError = !this.pCodeValidate(zip);
      if (zipCodeError && pCode.length > 5) {
        toast.error("Please enter 9 digit zip.", { position: toast.POSITION.BOTTOM_RIGHT });
      } else if (zipCodeError) {
        toast.error("Please enter 5 digit zip.", { position: toast.POSITION.BOTTOM_RIGHT });
      }
      this.setState({ zipCodeError, zip });
    }
    if (controlID === "taxIdSuffix" && this.state.taxIdSuffix?.trim() !== "") {
      if (taxIdSuffix?.trim().length < 9) {
        toast.error(`${this.state.taxIDPrefix} should be 9 digit.`,
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        $("#taxIdSuffix").addClass("alert-danger");
        return;
      } else if (this.allCharactersSame(taxIdSuffix?.trim()?.replaceAll("-", "")?.substr(0, 9))) {
        toast.error(this.state.taxIDPrefix + " cannot be the same digit repeating.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        $("#taxIdSuffix").addClass("alert-danger");
        return;
      } else if (taxIdSuffix?.trim()?.length > 8) {
        if (this.state.taxIDPrefix?.toLowerCase() === "tax id")
          taxIdSuffix = taxIdSuffix?.substr(0, 2) + "-" + taxIdSuffix?.substr(2, taxIdSuffix?.length - 2);
        else
          taxIdSuffix = taxIdSuffix?.substr(0, 3) + "-" + taxIdSuffix?.substr(3, 2) + "-" + taxIdSuffix?.substr(5, taxIdSuffix?.length - 4);
        this.setState({ taxIdSuffix });
      }
      //   if (
      //     this.state.taxIdSuffix?.trim().replace("-", "").length > 9 ||
      //     this.state.taxIdSuffix?.trim().replace("-", "").length < 9
      //     //this.state.taxIdSuffix?.trim().replace("-", "").length > 20
      //   ) {
      //     $("#taxIdSuffix").addClass("alert-danger");
      //     toast.error(
      //       this.state.taxIDPrefix + " must be 9 digits.",
      //       {
      //         position: toast.POSITION.BOTTOM_RIGHT,
      //       }
      //     );
      //   } else if (this.allCharactersSame(this.state.taxIdSuffix?.trim())) {
      //     toast.error(
      //       this.state.taxIDPrefix + " cannot be the same digit repeating.",
      //       {
      //         position: toast.POSITION.BOTTOM_RIGHT,
      //       }
      //     );
      //     $("#taxIdSuffix").addClass("alert-danger");
      //   } else {
      //     $("#taxIdSuffix").removeClass("alert-danger");
      //   }
    }
  };

  onFocus = (e, controlID) => {
    if (controlID === "taxIdSuffix") {
      this.setState({ taxIdSuffix: this.state.taxIdSuffix.trim().replaceAll('-', '') });
    }
  };

  //Check if all characters are not same in taxid/ssn
  allCharactersSame = (s): boolean => {
    let n = s.length;
    for (let i = 1; i < n; i++) if (s[i] != s[0]) return false;

    return true;
  };

  //Allow entering -(dash) in input field
  taxIDCheck = (event) => {
    var charCode = event.which ? event.which : event.keyCode;
    if (
      charCode != 46 &&
      charCode != 45 &&
      charCode > 31 &&
      (charCode < 48 || charCode > 57)
    )
      isValidInput = "No";
    else isValidInput = "Yes";
  };

  setZipError = (value) => {
    let zipActLen = (value.includes("-") ? 10 : 9);
    let zipLen = value.length;
    let pcodeError: boolean = false;
    if (zipLen < 5) {
      pcodeError = true;
    } else if (zipLen > 5 && (zipLen < zipActLen)) {
      pcodeError = true;
    } else {
      pcodeError = false;
    }
    return pcodeError;
  };

  //Set input values to the states when entering by the user
  onInputChange = (e: any, type: any) => {
    if (type === "address") {
      let isVal = e.currentTarget.value.trimStart();
      if (this.state.isNewAddress && !this.validateAddress(isVal)) {
        $("#streetaddressText").addClass("alert-danger");
      } else {
        $("#streetaddressText").removeClass("alert-danger");
      }
      this.setState({ address: e.currentTarget.value });
    } else if (type === "city") {
      let isVal = e.currentTarget.value.trimStart();
      if (!this.validateAddress(isVal)) {
        $("#city").addClass("alert-danger");
      } else {
        $("#city").removeClass("alert-danger");
      }
      this.setState({ city: isVal });
    } else if (type === "state") {
      let isVal = e.currentTarget.value.trimStart();
      if (!this.validateAddress(isVal)) {
        $("#state").addClass("alert-danger");
      } else {
        $("#state").removeClass("alert-danger");
      }
      this.setState({ states: isVal });
    } else if (type === "zip") {
      //this.setState({ zip: e.currentTarget.value });
      // let val = Utils.removeSpecialCharcterFromString(e.currentTarget.value)?.substring(0, 9);
      // if (!isNaN(val)) {
      //   $("#zip").removeClass("alert-danger");
      //   this.setState({ zip: val });
      // }
      let val = (e.currentTarget.value).trim();
      val = val.indexOf("-") === 5 ? val : val.replace(/[^0-9]/g, '');
      val = val.trim().replace(/[^0-9-]/g, '');
      val = val?.substring(0, val.indexOf("-") === 5 ? 10 : 9);
      let { zipCodeError, zip } = this.state;
      zipCodeError = this.setZipError(val);//!this.pCodeValidate(val)
      zip = val;
      this.setState({ zipCodeError, zip });
    }
    else if (type === "customerno") {
      $("#customerno").removeClass("alert-danger");
      this.setState({ customerNo: e.currentTarget.value });
    }
    else if (type === "vendorname") {
      $("#vendorname").removeClass("alert-danger");
      this.setState({ customerForCreate: e.currentTarget.value });
    }

    else if (type === "customeralias") {
      $("#customeralias").removeClass("alert-danger");
      this.setState({ customerAlias: e.currentTarget.value });
    }

    else if (type === "taxIdSuffix") {
      if (isValidInput === "Yes") {
        $("#taxIdSuffix").removeClass("alert-danger");
        let inputValue = Utils.removeSpecialCharcterFromString(e.currentTarget.value)?.substring(0, 9);
        if (!isNaN(inputValue)) {
          this.setState({ taxIdSuffix: inputValue }, () => {
            $("#taxIdSuffix").removeClass("alert-danger");
          });
        }
        //this.setState({ taxIdSuffix: e.currentTarget.value });
      }
    } else if (type === "phone") {
      // let val = e.currentTarget.value.replace(/[@\/\\^\/\\&\/\\#,+()$~%.'":*?<>{}-]/g, '');
      let val = e.currentTarget.value.replace(/[^0-9()-\s]/g, '');
      //if (!isNaN(val)) {
      if (this.state.isNewAddress) {
        this.setState({ phone: val });
      }
      //}
    } else if (type === "email") {
      if (this.state.isNewAddress)
        this.setState({ email: e.currentTarget.value });
    }
    else if (type === "apartment") {
      if (this.state.isNewAddress)
        this.setState({ apartment: e.currentTarget.value });
    }
    else if (type === "country") {
      if (this.state.isNewAddress)
        this.setState({ country: e.currentTarget.value });
    }
    else if (type === "firstname") {
      if (this.state.isNewAddress)
        this.setState({ firstname: e.currentTarget.value });
    }
  };



  //Disabled the fields on creating a new contract
  isDisabled = (controlId: any): boolean => {
    if (this.state.isNewContract /*&& !this.state.isNewAddress*/) {
      if (
        controlId === "VendorNameControl" /*&&
        this.state.customerForCreate?.trim() !== ""*/
      ) {
        return true;
      } else if (
        controlId === "PaymentMethod" /*&&
        this.state.paymentMethod?.trim() !== ""*/
      ) {
        return true;
      } else if (
        controlId === "PhoneControl" &&
        // !this.state.isNefirstwAddress &&
        // this.state.phone?.trim() !== ""
        this.state.isNewContract &&
        !this.state.isNewAddress
        /*|| this.state.phone?.trim() !== ""*/
      ) {
        return true;
      } else if (
        controlId === "EmailControl" &&
        !this.state.isNewAddress &&
        this.state.isNewContract
        /*|| this.state.email?.trim() !== ""*/
      ) {
        return true;
      } else if (
        controlId === "invoices" /*&& this.state.invoicesDuesAfter !== ""*/
      ) {
        return true;
      } else if (
        controlId ===
        "taxIdSuffix" /*&& !this.state.isNewAddress this.state.taxIdSuffix !== ""*/
      ) {
        return true;
      } else if (
        controlId ===
        "taxIDPrefix" /*&& !this.state.isNewAddress this.state.taxIDPrefix !== ""*/
      ) {
        return true;
      } else if (controlId === "salesTaxCheck") {
        return true;
      } else if (controlId === "vendorCheck") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  //Validate all input values before save
  checkValidation = () => {
    let isValid: any = [];
    let focusSet = false;
    if (this.state.customerForCreate?.trim() === "") {
      $("#vendorname").focus();
      $("#vendorname").addClass("alert-danger");
      focusSet = true;
      isValid.push(false);
    } else {
      $("#vendorname").blur();
      isValid.push(true);
      focusSet = focusSet ? true : false;
    }
    if (this.state.invoicesDuesAfter?.trim() === "") {
      if (!focusSet) {
        $("#invoices").focus();
        focusSet = true;
      }
      $("#invoices").addClass("alert-danger");
      isValid.push(false);
    } else {
      focusSet = focusSet ? true : false;
      $("#invoices").blur();
      isValid.push(true);
    }
    // if (this.state.taxIdSuffix?.trim() === "") {
    //   $("#taxIdSuffix").addClass("alert-danger");
    //   isValid.push(false);
    //   if (!focusSet) {
    //     $("#taxIdSuffix").focus();
    //     focusSet = true;
    //   }
    // } else {
    //   $("#taxIdSuffix").blur();
    //   focusSet = focusSet ? true : false;
    //   isValid.push(true);
    // }
    if (this.state.address?.trim() === "" || /*!this.validateAddress(this.state.address?.trim()) ||*/ this.state.address?.trim() === "Select Address") {
      if (this.state.isNewAddress && !this.validateAddress(this.state.address?.trim())) {
        $("#streetaddressText").addClass("alert-danger");
        isValid.push(false);
        if (!focusSet) {
          $("#streetaddressText").focus();
          focusSet = true;
        }
      } else {
        $("#streetaddressDrop").addClass("alert-danger");
        isValid.push(false);
        if (!focusSet) {
          $("#streetaddressDrop").focus();
          focusSet = true;
        }
      }
    } else {
      if (this.state.isNewAddress) {
        $("#streetaddressText").blur();
        focusSet = focusSet ? true : false;
        isValid.push(true);
      } else {
        $("#streetaddressDrop").blur();
        focusSet = focusSet ? true : false;
        isValid.push(true);
      }
    }

    if ((this.state.city?.trim() === "" || !this.validateAddress(this.state.city?.trim())) && this.state.isNewAddress) {
      if (!focusSet) {
        $("#city").focus();
        focusSet = true;
      }
      $("#city").addClass("alert-danger");
      isValid.push(false);
    } else {
      $("#city").blur();
      isValid.push(true);
      focusSet = focusSet ? true : false;
    }
    if ((this.state.states?.trim() === "" || !this.validateAddress(this.state.states?.trim())) && this.state.isNewAddress) {
      // $("#Modal-ddl10").addClass("alert-danger");
      $("#state").addClass("alert-danger");
      isValid.push(false);
      if (!focusSet) {
        //$("#Modal-ddl10").focus();
        focusSet = true;
      }
    } else {
      //$("#Modal-ddl10").blur();
      focusSet = focusSet ? true : false;
      isValid.push(true);
    }
    if (this.state.zip?.trim() === "" && this.state.isNewAddress) {
      // $("#zip").addClass("alert-danger");
      let { zipCodeError } = this.state;
      zipCodeError = true;
      this.setState({ zipCodeError });
      isValid.push(false);
      if (!focusSet) {
        $("#zip").focus();
        focusSet = true;
      }
    } else {
      $("#zip").blur();
      focusSet = focusSet ? true : false;
      isValid.push(true);
    }
    if (this.state.customerNo?.trim() === "") {
      $("#customerno").addClass("alert-danger");
      isValid.push(false);
      if (!focusSet) {
        $("#customerno").focus();
        focusSet = true;
      }
    } else {
      $("#customerno").blur();
      focusSet = focusSet ? true : false;
      isValid.push(true);
    }

    const returnValue = isValid.some((item) => item === false);
    if (!returnValue) {
      if (
        $("#vendorname").hasClass("alert-danger") &&
        this.state.customerForCreate !== ""
      ) {
        return "customerName";
      } else {
        $("#vendorname").removeClass("alert-danger");
      }
      // if ($("#zip").hasClass("alert-danger") && (this.state.zip.trim().length < 5 || (this.state.zip.trim().length > 5 && this.state.zip.trim().length < 9)) && this.state.zip.trim() !== "") {
      if ($("#zip").hasClass("alert-danger") && this.state.zip.trim() !== "") {
        return "zip";
      } else {
        $("#zip").removeClass("alert-danger");
      }
      if (
        $("#taxIdSuffix").hasClass("alert-danger") &&
        this.state.taxIdSuffix !== ""
      ) {
        return "taxIdSuffix";
      } else {
        $("#taxIdSuffix").removeClass("alert-danger");
      }
      if ($("#phone").hasClass("alert-danger") && this.state.phone !== "") {
        return "phone";
      } else {
        $("#phone").removeClass("alert-danger");
      }
      if ($("#email").hasClass("alert-danger") && this.state.email !== "") {
        return "email";
      } else {
        $("#email").removeClass("alert-danger");
      }

    }
    return returnValue ? "Required" : "Succeed";
  };

  checkTotalLength = () => {
    let cityLength = this.state.city?.substring(0, 50).trim().length;
    let stateLength = this.state.states?.substring(0, 50).trim().length;
    let zipLength = this.state.zip?.substring(0, 50).trim().length;
    let totalLength = (Number(cityLength) + Number(stateLength) + Number(zipLength));
    if (totalLength > 50) {
      return 'Exceed';
    } else {
      return 'NotExceed';
    }
  };

  //Save all entered data into db and handle the created vendor as selected in dropdown
  handleSaveRequest = async () => {

    if (requestInProcess == true) {
      toast.error("Subsequent request has been made.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    if (this.state.city?.trim() == ''
      || this.state.states?.trim() == ''
      || this.state.country?.trim() == ''
      || this.state.address?.trim() == ''
     // || this.state.apartment?.trim() == ''
      || this.state.zip?.trim() == '') {
      toast.error("Please enter mandatory fields.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    requestInProcess = true;

    let formData: any = {};

    formData.company_name = this.state.customerForCreate?.substring(0, 250).trim().toUpperCase();

    formData.phone_number = this.state.phone?.substring(0, 20).trim();

    formData.email = this.state.email?.trim();
    formData.TenantID = this.state.TenantID;
    formData.firstname = this.state.firstname?.trim();
    formData.address = this.state.address?.trim();
    formData.apartment = this.state.apartment?.trim();
    formData.country = this.state.country?.trim();
    formData.city = this.state.city?.trim();
    formData.states = this.state.states?.trim();
    formData.zip = this.state.zip?.trim();
    formData.companyAlias = this.state.customerAlias.trim();

    if (this.state.IsActive == true) {
      formData.shippingZipCode = this.state.zip?.trim();
      formData.shippingCountry = this.state.country?.trim();
      //result.shippingStates,
      formData.shippingStates = this.state.states?.trim();
      formData.shippingCity = this.state.city?.trim();
      formData.shippingApartment = this.state.apartment?.trim();
      formData.shippingAddress = this.state.address?.trim();

    }

    //formData.addresses.country=this.state.country?.trim();

    CustomerService.SaveCustomerData(formData)
      .then(async (result: any | null) => {


        if (result !== null) {

          if (result.customer_id != "" || result.customer_id != null) {
            let requestlist: any = {};

            if (!this.state.isGeneric) {
              toast.success(
                this.state.isNewCustomer
                  ? "Customer created successfully."
                  : "Contract created successfully.",
                {
                  position: toast.POSITION.BOTTOM_RIGHT,
                }
              );
            }


            try {
              let vendorContractRequest: any = {};
            
              vendorContractRequest.customerType = "Current";
              vendorContractRequest.VendorId = this.state.lastVid;

              const response: any = await Promise.all([
                this.state.isNewContract
                  ? CustomerService.GetVendorContract(vendorContractRequest)
                  : null,
                CustomerService.GetCustomerlist(requestlist),
              ]);


              if (response[1] != null) {

                this.updateParentState(response[1]); //Update the vendor list to the parent page if new vendor created
                this.setState(
                  {
                    isListOpen: false,
                    isfirst: false,
                    addVendorFooterVisible: true,
                    isNewRequest: false,
                    isContractDetailsOpen: false,
                    lastVType: "",
                    AID: 0,
                    selectedCustomer: this.state.customerForCreate,
                    selectedCustomercode: this.state.customercode == "" ? "" : " (" + this.state.customercode + ")",
                    selectedContract: "",
                    customerAlias: this.state.customerAlias,
                  },
                  () => {

                    if (this.state.isNewCustomer || this.state.isGeneric) {

                      const createdVendor: any = response[1]?.filter(

                        (item) =>
                          item.company_name?.toLowerCase() ===
                          this.state.customerForCreate?.toLowerCase()

                      );

                      // createdVendor[0].accountNo = this.state.customerNo?.trim();
                      this.setState(
                        {
                          isNewCustomer: false,
                          isNewContract: false,
                          lastVid: 0,
                          isGeneric: false,
                          selectedCustomer: this.state.customerForCreate,
                          selectedCustomercode: this.state.customercode == "" ? "" : " (" + this.state.customercode + ")",
                          //" (" + this.state.customercode + ")",
                          selectedContract: "",

                          customerAlias: this.state.customerAlias,
                        },
                        () => {
                          setTimeout(() => {
                            this.handleCustomerChange(createdVendor[0]);
                          }, 500);
                        }
                      );
                    }
                    this.clearFieldValues();
                    this.setState({
                      isNewCustomer: false,
                      isNewContract: false,
                      lastVid: 0,
                    });
                  }
                );
              }
            } catch (error) {
              toast.error((error as any).message, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          } else if (result.message?.toLowerCase() === "vendorexists") {
            this.setState({ loader: false });
            toast.error("Customer name already exists.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (result.message?.toLowerCase() === "samecontractexist") {
            this.setState({ loader: false });
            toast.error("Same contract exists for this EHID.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
        this.setState({ loader: false });
        requestInProcess = false;
        resolve();
      })
      .catch((error) => {
        this.setState({ loader: false });
        requestInProcess = false;
        reject();
      }
      );
  };

}
