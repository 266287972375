import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { DocumentService } from "../../Common/Services/DocumentService";
import paginationFactory from 'react-bootstrap-table2-paginator';

import _ from "lodash";
 
import createDOMPurify from "dompurify";
 
import moment from "moment";
const columns = [
    {
      dataField: 'id',
      text: '#',
    },
   
    {
      dataField: 'documentname',
      text: 'Document Name',
      sort: true,
    },
    {
      dataField: 'view',
      text: 'View Document',
      sort: true,
    },
    
    
   
  ];
  
export class Documents extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
         
          htmlItem: [],
          htmlErrorItem: [],
          htmlWarningItem: [],
          searcResult: [],
          orderid:0,
        };
        
     
      }
      componentDidMount() {
        let requestlist = { ...this.state.requestlist };
        this.getDocuments(requestlist);
      }


 
      getDocuments = (requestlist: any) => {
 

        DocumentService.GetDocumentslist(requestlist)
    .then(async (result: any[] | null) => {
        
      if (result != null) {
        
        this.setState({ searcResult: result }, () => {
           
        });
      }
      resolve();
    })
    .catch((err: any) => {
      toast.error(`Server Error, ${err}`);
      reject();
    });
  };

    render() { 
        return (
            <>
<React.Fragment>

<div className="home d-flex">
  
        
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
            
            <div className="mr-auto">Dcouments</div>
            <div className="action-group d-flex flex-row ml-auto">
            <Button>Add Dcoument</Button>
              </div>
              </div>
              
              <BootstrapTable
        keyField="id"
        data={this.state.searcResult}
        columns={columns}
       // rowEvents={this.rowEvents}
       pagination={ paginationFactory() }
      />

   </Container>
       
      
      </div>
      </React.Fragment>
            </>
        );
    }

}
